import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BatchInner from "./BatchInner";

class MiscellaneousServiceVoucherInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="report">
        <table width="100%" height="265" border="0" align="left">
          <tbody>
            <tr>
              <td colspan="2"></td>
              <td>
                <p style={{ textAlign: "center" }}>
                  <img
                    src="../vendors/images/holiday_makers_logo.jpg"
                    width="160"
                    alt=""
                  />
                </p>
                <p style={{ textAlign: "center" }}>
                  <font
                    color="#FF6C04"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "24px",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                    }}
                  >
                    Service{} Voucher
                  </font>
                </p>
              </td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td colspan="2">
                <p className="font-14 mb-5 reportFont pl-15">
                  <strong className="weight-600">
                    {this.props.selectedItem.venderName}
                    <br />
                  </strong>
                  {this.props.selectedItem.venderAddress1 && (<>{this.props.selectedItem.venderAddress1}<br /></>)}
                  {this.props.selectedItem.venderAddress2 && (<>{this.props.selectedItem.venderAddress2}</>)}
                </p>
              </td>
              <td>&nbsp;</td>
              <td colspan="2">
                <div className="col-md-12 font-14">
                  <div className="row">
                    <div className="col-md-6">Service Type :</div>
                    <div className="col-md-6">
                      <strong className="weight-600">Miscellaneours</strong>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">Client Name :</div>
                    <div className="col-md-6">
                      <strong className="weight-600">
                        {this.props.selectedItem.paxName}
                      </strong>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">Job Number :</div>
                    <div className="col-md-6">
                      <strong className="weight-600">
                        {" "}
                        {this.props.selectedItem.jobCardNo}
                      </strong>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">Booking Ref :</div>
                    <div className="col-md-6">
                      <strong className="weight-600">
                        {" "}
                        {this.props.selectedItem.miscellaneousCode}
                      </strong>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">Date :</div>
                    <div className="col-md-6">
                      <strong className="weight-600">
                        {" "}
                        {this.props.selectedItem.misDateVoucher}
                      </strong>
                    </div>
                  </div>

                </div>
              </td>
            </tr>

            <tr>
              <td colspan="5">
                <table
                  class="table table-striped"
                >
                  <thead>
                    <tr
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        background: "#f6f6f7!important",
                      }}
                    >
                      <th
                        width="20%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Pax Name</strong>
                      </th>
                      
                      <th
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Passport No</strong>
                      </th>
                      <th
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Visa Type</strong>
                      </th>
                      <th
                        width="50%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Remarks</strong>
                      </th>
                      <th
                        width="10%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Amount</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="tableOddrow"
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        {this.props.selectedItem.paxName}
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <strong>{this.props.selectedItem.passportNo}</strong>
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <strong>{this.props.selectedItem.visaTypeName}</strong>
                      </td>
                      
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        {this.props.selectedItem.remarks}
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        {this.props.selectedItem.totalCostPrice}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              
            </tr>
            <tr>
                  <td colspan="2"></td>
                  <td>
                  <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
          <p align="center">
            {" "}
            Office 418, City 4 Building, Al Garhoud, Dubai, United Arab Emirates
            <br />
            +971 4 257 9192 | ops@holidaymakers.ae | www.holidaymakers.ae
          </p>
                  </td>
                  <td colspan="2"></td>
                </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

export default MiscellaneousServiceVoucherInner;
