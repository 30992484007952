import React from "react";

class ViewProformaInvoiceInner extends React.Component {
  constructor(props) {
    super(props);
  }
  GetJobCardDetails = (items) => {
    return (
      <>
        {items.map((i) => {
          return (
            <>
              <span>
                {i.jobCardNo} {i.paxName} x{i.adults}Adults
              </span>
              <br />
            </>
          );
        })}
      </>
    );
  };
  render() {
    return (
      <div>
        <div className="invoice-wrap">
          <div className="invoice-box" id="report">
            <div className="row pb-30 mt-30">
              <div className="col-md-4 mt-30">
                <img
                  className="pl-15"
                  style={{ paddingTop: "0.8rem" }}
                  src="../vendors/images/holiday_makers_rlogo.jpg"
                  width="160"
                  alt=""
                />
                <p className="font-14 mb-5 reportFont pl-15">
                  <strong className="weight-600">
                    Holiday Makers Tourism LLC
                    <br />
                  </strong>
                  Office 418, City 4 Building,
                  <br /> Al Garhoud, Dubai,
                  <br /> United Arab Emirates
                </p>
              </div>
              <div className="col-md-8 mt-30">
                <h4 className="reportTitle weight-600 reportFont">PROFORMA INVOICE</h4>
                <h6 className="text-right 	mb-30 weight-600 reportFont">
                  # {this.props.invoice.invoiceCode}
                </h6>
                <div className="text-right">
                  <p className="font-14 mb-5">
                    <strong>Due Amount</strong>
                  </p>
                  <h6 className="font-18 mb-5" style={{ color: "#FF6C04" }}>
                    <b>AED {this.props.invoice.invoiceAmount}</b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="row pb-30">
              <div className="col-md-6">
                <p className="font-14 mb-5 pl-15">
                  Bill To
                  <br />
                  <strong className="weight-600">
                    {this.props.invoice.customerName}
                  </strong>
                  {this.props.invoice.addressLine1 && (
                    <>
                      <br />
                      {this.props.invoice.addressLine1}
                    </>
                  )}
                  {this.props.invoice.addressLine2 && (
                    <>
                      <br />
                      {this.props.invoice.addressLine2}
                    </>
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <p className="font-14 mb-5">
                    Invoice Date :{" "}
                    <strong className="weight-600">
                      {this.props.invoice.invoiceDate}
                    </strong>
                  </p>
                  
                  <p className="font-14 mb-5">
                    Due Date :{" "}
                    <strong className="weight-600">
                      {this.props.invoice.invoiceDueDate}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="invoice-desc pb-30 font-14 pl-15">
              <div className="invoice-desc-head clearfix font-14">
                <div className="invoice-sub font-14">Description</div>
                <div className="invoice-subtotal font-14">Amount</div>
              </div>
              <div className="invoice-desc-body">
                <ul>
                  <li className="clearfix">
                    <div className="invoice-sub font-14">
                      {this.props.invoice.description}{" "}
                      {this.props.invoice.jobCardNo}
                    </div>

                    <div className="invoice-subtotal font-14">
                      <span className="weight-600 font-14">
                        AED{" "}
                        {this.props.invoice.invoiceAmount}
                      </span>
                    </div>
                  </li>

                  <li className="clearfix">
                    <div className="invoice-total font-14">Net Amount</div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        AED{" "}
                        {this.props.invoice.invoiceAmount}
                      </span>
                    </div>
                  </li>
                  
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14">Gross Amount</div>
                    <div
                      className="invoice-subtotal"
                      style={{ color: "#fff", backgroundColor: "#FF6C04" }}
                    >
                      <span className="weight-600 font-14">
                        AED{" "}
                        {this.props.invoice.invoiceAmount}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="invoice-desc-footer">
                <div className="invoice-desc-body">
                  <div>
                    <strong className="font-14 mb-5">Note</strong>
                    <p className="font-14 mb-5"></p>
                  </div>
                  <div>
                    <strong className="font-14 mb-5">Account Details </strong>
                    <p className="font-14 mb-5">
                      <strong className="weight-600 font-14">
                        REMITTANCE TO "HOLIDAY MAKERS TOURISM LLC"
                      </strong>
                    </p>
                    <p className="font-14 mb-5 font-14">A/C NO 1015576445201</p>
                    <p className="font-14 mb-5 font-14">
                      EMIRATES BANK INTERNATIONAL,
                    </p>
                    <p className="font-14 mb-5 font-14">
                      IBAN - AE230260001015576445201
                    </p>
                    <p className="font-14 mb-5 font-14">SWIFT CODE EBILAEAD</p>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
            <p align="center">
              {" "}
              Office 418, City 4 Building, Al Garhoud, Dubai, United Arab
              Emirates
              <br />
              +971 4 257 9192 | ops@holidaymakers.ae | www.holidaymakers.ae
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewProformaInvoiceInner;
