import React from "react";
import dayjs, { Dayjs } from "dayjs";
import "../src/styles/report.css";

class JobCardFullReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobCard: null,
      transports: [],
      TCost: 0,
      TSell: 0,
      TProfit: 0,
      hotels: [],
      HCost: 0,
      HSell: 0,
      HProfit: 0,
      visas: [],
      VCost: 0,
      VSell: 0,
      VProfit: 0,
      miscellanea: [],
      MCost: 0,
      MSell: 0,
      MProfit: 0,
    };
  }
  printDiv = (divName) => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    window.location.reload();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getJobs();
    await this.getJobTransportations();
    await this.getJobHotels();
    await this.getJobVisas();
    await this.getJobMiscellanea();
  };
  getJobs = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          jobCard: response.jobCard,
        });
      });
  };
  getJobTransportations = async () => {
    const transportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportationsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.jobCardTransportations.map((i) => {
          debugger;
          transportList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            pickupLocation: i.pickupLocation,
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount:
            i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
          sellTaxAmount:
            i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
          totalCostPrice:
            i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          totalSellPrice:
            i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
          parking: i.parking,
          extras: i.extras,
          extrasTaxAmount: i.extrasTaxAmount,
          water: i.water,
          parkingSell: i.parkingSell,
          extrasSell: i.extrasSell,
          extrasTaxAmountSell: i.extrasTaxAmountSell,
          waterSell: i.waterSell,
          });
        });

        this.setState({
          transports: transportList,
          isModalOpen: false,
          TCost: response.totalCostAmount,
          TSell: response.totalSellAmount,
          TProfit: response.profit,
        });
      });
  };

  getJobHotels = async () => {
    const hotelList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobHotelsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardHotels.map((i) =>
          hotelList.push({
            id: i.id,
            hotelCode: i.hotelCode,
            hotelName: i.hotelName,
            checkIn:
              i.checkIn == null
                ? null
                : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
            checkOut:
              i.checkOut == null
                ? null
                : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          hotels: hotelList,
          isModalOpen: false,
          HCost: response.totalCostAmount,
          HSell: response.totalSellAmount,
          HProfit: response.profit,
        });
      });
  };
  getJobVisas = async () => {
    const visaList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobVisasByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardVisas.map((i) =>
          visaList.push({
            id: i.id,
            visaCode: i.visaCode,
            passportNo: i.passportNo,
            visaTypeName: i.visaTypeName,
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          visas: visaList,
          isModalOpen: false,
          VCost: response.totalCostAmount,
          VSell: response.totalSellAmount,
          VProfit: response.profit,
        });
      });
  };

  getJobMiscellanea = async () => {
    const miscellaneousList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobMiscellaneaByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardMiscellaneous.map((i) =>
          miscellaneousList.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            paxName: i.paxName,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          miscellanea: miscellaneousList,
          isModalOpen: false,
          MCost: response.totalCostAmount,
          MSell: response.totalSellAmount,
          MProfit: response.profit,
        });
      });
  };
  render() {
    return (
      this.state.jobCard && (
        <div className="bodyClass">
          <div className="divClass">
            <table id="report"
              className="tableClass"
              align="center"
              style={{ boxShadow: "0 0 5px #888", padding: "10px" }}
            >
              <tbody>
                <tr>
                  <td>
                    <div classname="divClass" >
                      <table className="tableClass tableFull">
                        <tbody>
                          <tr>
                            <th colSpan={2} width="147" className="textLeft">
                              {this.state.jobCard.headerTitle}{" "}
                            </th>
                            <th width="382" rowspan="2" className="textRight">
                              <img
                                src="../vendors/images/holiday_makers_logo.jpg"
                                width="125"
                                height="41"
                                alt=""
                              />
                            </th>
                          </tr>
                          <tr>
                            <td width="147" className="textLeft">
                              &nbsp;
                            </td>
                            <td width="524" className="textLeft">
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td bgcolor="#E7E7E7" classname="textleft">
                              Customer
                            </td>
                            <td colspan="2" classname="textleft">
                              {this.state.jobCard.customerName}
                            </td>
                          </tr>
                          <tr>
                            <td bgcolor="#E7E7E7" classname="textleft">
                              Customer Ref
                            </td>
                            <td colspan="2" classname="textleft">
                              {this.state.jobCard.customerRef}
                            </td>
                          </tr>
                          <tr>
                            <td bgcolor="#E7E7E7" classname="textleft">
                              Remarks
                            </td>
                            <td colspan="2" classname="textleft">
                              {this.state.jobCard.remarks}
                            </td>
                          </tr>
                          <tr>
                            <td bgcolor="#E7E7E7" classname="textleft">
                              Creation Date
                            </td>
                            <td colspan="2" classname="textleft">
                              {dayjs(this.state.jobCard.createdDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" classname="textleft">
                              {this.state.transports && this.state.transports.length>0 && (
                              <table
                                classname="tableClass tableFull"
                                width="803"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      colspan="4"
                                      bgcolor="#E7E7E7"
                                      classname="textleft"
                                      scope="col"
                                    >
                                      Tranportation
                                    </th>
                                  </tr>
                                  <tr>
                                    <td colspan="4" classname="textleft">
                                      <table
                                        classname="tableClass"
                                        width="1055"
                                        height="131"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width="71"
                                              height="25"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Booking Ref</strong>
                                            </td>
                                            <td
                                              width="65"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Pickup Time</strong>
                                            </td>
                                            <td
                                              width="71"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Pickup Location</strong>
                                            </td>
                                            <td
                                              width="70"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Drop-off Location</strong>
                                            </td>
                                            <td
                                              width="47"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Total PAX</strong>
                                            </td>
                                            <td
                                              width="17"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>A</strong>
                                            </td>
                                            <td
                                              width="18"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>C</strong>
                                            </td>
                                            <td
                                              width="82"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Vehicle Type</strong>
                                            </td>
                                            <td
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong> Flight No</strong>
                                            </td>
                                            <td
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Flight Time </strong>
                                            </td>
                                            <td
                                              width="104"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>&nbsp;Pax Name</strong>
                                            </td>
                                            <td
                                              width="260"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Remarks</strong>
                                            </td>
                                            <td
                                              width="51"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Buy</strong>
                                            </td>
                                            <td
                                              width="51"
                                              bgcolor="#E7E7E7"
                                              style={{ color: "#000000" }}
                                              scope="col"
                                            >
                                              <strong>Sell</strong>
                                            </td>
                                          </tr>
                                          {this.state.transports.map((i) => (
                                            <tr>
                                              <td
                                                height="24"
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.transportationCode}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.pickupTime}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.pickupLocation}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.dropoffLocation}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.totalPax}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.adults}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.children}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.vehicleType}
                                              </td>
                                              <td
                                                width="35"
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.flightNo}
                                              </td>
                                              <td
                                                width="55"
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.flightTime}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.paxName}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.remarks}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.totalCostPrice}
                                              </td>
                                              <td
                                                bgcolor="#F5F5F5"
                                                className="tableTextCol1"
                                              >
                                                {i.totalSellPrice}
                                              </td>
                                            </tr>
                                          ))}

                                          <tr>
                                            <td
                                              height="36"
                                              colspan="12"
                                              bgcolor="#E7E7E7"
                                              className="tableTextCol1"
                                            >
                                              <strong>
                                                Total :{" "}
                                                {this.state.TProfit > 0 ? (
                                                  <img
                                                    src="../vendors/images/up.png"
                                                    width="10"
                                                    height="10"
                                                    alt=""
                                                  />
                                                ) : (
                                                  <img
                                                    src="../vendors/images/dw.png"
                                                    width="10"
                                                    height="10"
                                                    alt=""
                                                  />
                                                )}
                                                {this.state.TProfit}
                                              </strong>
                                            </td>
                                            <td
                                              bgcolor="#E7E7E7"
                                              className="tableTextCol1"
                                            >
                                              <strong>
                                                {this.state.TCost}
                                              </strong>
                                            </td>
                                            <td
                                              bgcolor="#E7E7E7"
                                              className="tableTextCol1"
                                            >
                                              <strong>
                                                {this.state.TSell}
                                              </strong>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              )}
                              {this.state.hotels &&
                                this.state.hotels.length > 0 && (
                                  <>
                                    <br />
                                    <table
                                      classname="tableClass"
                                      width="803"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <th
                                            colspan="2"
                                            bgcolor="#E7E7E7"
                                            classname="textleft"
                                            scope="col"
                                          >
                                            Hotels<strong></strong>
                                          </th>
                                        </tr>
                                        <tr>
                                          <td colspan="2" classname="textleft">
                                            <table
                                              classname="tableClass"
                                              width="1055"
                                              height="131"
                                              border="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    width="93"
                                                    height="25"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Booking Ref</strong>
                                                  </td>
                                                  <td
                                                    width="198"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>
                                                      Passenger Name
                                                    </strong>
                                                  </td>
                                                  <td
                                                    width="45"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Adults</strong>
                                                  </td>
                                                  <td
                                                    width="50"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Children</strong>
                                                  </td>
                                                  <td
                                                    width="41"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Infants</strong>
                                                  </td>
                                                  <td
                                                    width="101"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Hotel Name</strong>
                                                  </td>
                                                  <td
                                                    width="72"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Check-in</strong>
                                                  </td>
                                                  <td
                                                    width="68"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Check-out</strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Remarks</strong>
                                                  </td>
                                                  <td
                                                    width="51"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Buy</strong>
                                                  </td>
                                                  <td
                                                    width="51"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol2"
                                                  >
                                                    <strong>Sell</strong>
                                                  </td>
                                                </tr>
                                                {this.state.hotels.map((i) => (
                                                  <tr>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.hotelCode}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.paxName}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.adults}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.children}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.infants}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.hotelName}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.checkIn}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.checkOut}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.remarks}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.totalCostPrice}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.totalSellPrice}
                                                    </td>
                                                  </tr>
                                                ))}
                                                <tr>
                                                  <td
                                                    height="36"
                                                    colspan="9"
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol1"
                                                  >
                                                    <strong>
                                                      Total :
                                                      {this.state.HProfit >
                                                      0 ? (
                                                        <img
                                                          src="../vendors/images/up.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="../vendors/images/dw.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      )}
                                                      {this.state.HProfit}{" "}
                                                    </strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol1"
                                                  >
                                                    <strong>
                                                      {this.state.HCost}
                                                    </strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    className="tableTextCol1"
                                                  >
                                                    <strong>
                                                      {this.state.HSell}
                                                    </strong>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td classname="textleft">&nbsp;</td>
                                          <td classname="textleft">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              {this.state.visas &&
                                this.state.visas.length > 0 && (
                                  <>
                                    <br />
                                    <table
                                      classname="tableClass"
                                      width="803"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <th
                                            colspan="2"
                                            bgcolor="#E7E7E7"
                                            classname="textleft"
                                            scope="col"
                                          >
                                            Visa<strong></strong>
                                          </th>
                                        </tr>
                                        <tr>
                                          <td colspan="2" classname="textleft">
                                            <table
                                              classname="tableClass"
                                              width="1055"
                                              height="131"
                                              border="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    width="85"
                                                    height="25"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Booking Ref</strong>
                                                  </td>
                                                  <td
                                                    width="172"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>
                                                      Passenger Name
                                                    </strong>
                                                  </td>
                                                  <td
                                                    width="98"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>
                                                      Passport Number
                                                    </strong>
                                                  </td>
                                                  <td
                                                    width="149"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Visa Type</strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Remarks</strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Buy</strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Sell</strong>
                                                  </td>
                                                </tr>
                                                {this.state.visas.map((i) => (
                                                  <tr>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.visaCode}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.paxName}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.passportNo}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.visaTypeName}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.remarks}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.totalCostPrice}
                                                    </td>
                                                    <td
                                                      height="24"
                                                      bgcolor="#F5F5F5"
                                                      className="tableTextCol1"
                                                    >
                                                      {i.totalSellPrice}
                                                    </td>
                                                  </tr>
                                                ))}
                                                <tr>
                                                  <td
                                                    height="36"
                                                    colspan="5"
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    <strong>
                                                      Total :
                                                      {this.state.VProfit >
                                                      0 ? (
                                                        <img
                                                          src="../vendors/images/up.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="../vendors/images/dw.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      )}
                                                      {this.state.VProfit}{" "}
                                                    </strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    <strong>
                                                      {this.state.VCost}
                                                    </strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    <strong>
                                                      {this.state.VSell}
                                                    </strong>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td classname="textleft">&nbsp;</td>
                                          <td classname="textleft">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              {this.state.miscellanea &&
                                this.state.miscellanea.length > 0 && (
                                  <>
                                    <br />
                                    <table
                                      classname="tableClass"
                                      width="803"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <th
                                            colspan="2"
                                            bgcolor="#E7E7E7"
                                            classname="textleft"
                                            scope="col"
                                          >
                                            Miscellaneous<strong></strong>
                                          </th>
                                        </tr>
                                        <tr>
                                          <td colspan="2" classname="textleft">
                                            <table
                                              classname="tableClass"
                                              width="1055"
                                              height="131"
                                              border="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    width="76"
                                                    height="25"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Booking Ref</strong>
                                                  </td>
                                                  <td
                                                    width="144"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>
                                                      Passenger Name
                                                    </strong>
                                                  </td>
                                                  <td
                                                    width="279"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Description</strong>
                                                  </td>
                                                  <td
                                                    width="93"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>
                                                      Date &amp; Time
                                                    </strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Remarks</strong>
                                                  </td>
                                                  <td
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Buy</strong>
                                                  </td>
                                                  <td
                                                    width="67"
                                                    bgcolor="#E7E7E7"
                                                    classname="tableTextCol2"
                                                  >
                                                    <strong>Sell</strong>
                                                  </td>
                                                </tr>
                                                {this.state.miscellanea.map(
                                                  (i) => (
                                                    <tr>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.miscellaneousCode}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.paxName}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.description}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.misDate}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.remarks}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.totalCostPrice}
                                                      </td>
                                                      <td
                                                        height="24"
                                                        bgcolor="#F5F5F5"
                                                        className="tableTextCol1"
                                                      >
                                                        {i.totalSellPrice}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <td
                                                    height="36"
                                                    colspan="3"
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    <strong>
                                                      Total :
                                                      {this.state.MProfit >
                                                      0 ? (
                                                        <img
                                                          src="../vendors/images/up.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="../vendors/images/dw.png"
                                                          width="10"
                                                          height="10"
                                                          alt=""
                                                        />
                                                      )}
                                                      {this.state.MProfit}{" "}
                                                    </strong>
                                                  </td>
                                                  <td bgcolor="#F3F3F3"></td>
                                                  <td
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    &nbsp;
                                                  </td>
                                                  <td
                                                    bgcolor="#F3F3F3"
                                                    classname="tableTextCol1"
                                                  >
                                                    <strong>
                                                      {this.state.MCost}
                                                    </strong>
                                                  </td>
                                                  <td bgcolor="#F3F3F3">
                                                    <span classname="tableTextCol1">
                                                      <strong>
                                                        {this.state.MSell}
                                                      </strong>
                                                    </span>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td classname="textleft">&nbsp;</td>
                                          <td classname="textleft">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              <br />
                              <table
                                width="803"
                                border="0"
                                style={{ margin: "0px", padding: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      bgcolor="#ABECCB"
                                      classname="textleft"
                                      scope="col"
                                    >
                                      Job Card Performance
                                    </th>
                                    <th
                                      bgcolor="#ABECCB"
                                      classname="textleft"
                                      scope="col"
                                    >
                                      480{" "}
                                      <img
                                        src="vendors/images/up.png"
                                        width="15"
                                        height="15"
                                        alt=""
                                      />{" "}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      colspan="2"
                                      bgcolor="#FFFFFF"
                                      classname="textleft"
                                      scope="col"
                                    >
                                      &nbsp;
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      colspan="2"
                                      classname="textleft"
                                      scope="col"
                                    >
                                      Finances
                                      <span classname="textLeftColor1"></span>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td colspan="2" classname="textleftcolor2">
                                      <br />
                                      <table
                                        classname="tableClass"
                                        width="1000"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <th classname="color1" scope="col">
                                              <hr />
                                            </th>
                                            <th
                                              colspan="2"
                                              classname="textCenterColor1"
                                              scope="col"
                                            >
                                              Payments
                                            </th>
                                            <th
                                              colspan="2"
                                              classname="color1"
                                              scope="col"
                                            >
                                              <hr />
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              width="248"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Vendor Name
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Invoice Number
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Amount
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Remarks
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Date
                                            </th>
                                          </tr>
                                          <tr>
                                            <td colspan="2" bgcolor="#E7E7E7">
                                              <strong>Total</strong>
                                            </td>
                                            <td bgcolor="#E7E7E7">
                                              <strong></strong>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <br />
                                      <table
                                        classname="tableClass"
                                        width="1000"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <th classname="color1" scope="col">
                                              <hr />
                                            </th>
                                            <th
                                              colspan="2"
                                              classname="textcentercolor1"
                                              scope="col"
                                            >
                                              Receipts
                                            </th>
                                            <th
                                              colspan="2"
                                              classname="color1"
                                              scope="col"
                                            >
                                              <hr />
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Invoice Number
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Amount
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Remarks
                                            </th>
                                            <th
                                              width="114"
                                              bgcolor="#E7E7E7"
                                              classname="color1"
                                              scope="col"
                                            >
                                              Date
                                            </th>
                                          </tr>
                                          <tr>
                                            <td colspan="2" bgcolor="#E7E7E7">
                                              <strong>'Total</strong>
                                            </td>
                                            <td bgcolor="#E7E7E7">
                                              <strong></strong>
                                            </td>
                                            <td>&nbsp;</td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                          </tr>
                                          <tr>
                                            <td bgcolor="#E7E7E7">
                                              <strong>
                                                Cashflow Performance
                                              </strong>
                                            </td>
                                            <td bgcolor="#E7E7E7">
                                              <span classname="textLeftColor1">
                                                <strong classname="textleftcolor3">
                                                  0{" "}
                                                  <img
                                                    src="vendors/images/dw.png"
                                                    width="15"
                                                    height="15"
                                                    alt=""
                                                  />
                                                </strong>
                                              </span>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div classname="divClass" class="divFooter">
                                Printed from <strong>Logistics Pro</strong>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
                    <input
                      type="button"
                      onClick={(e) => {
                        this.printDiv("report");
                      }}
                      value="Print Entire Job Card"
                    />
          </div>
          
        </div>
      )
    );
  }
}

export default JobCardFullReport;
