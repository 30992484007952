import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BatchInner from "./BatchInner";

class BatchCopyItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  copyToClip = () => {
    var el = document.getElementById("report");
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    this.setErrorMessage("Batch copied to clipboard, You can paste", 1);
  };
  render() {
    return (
      <div className="main-container">
        <div
          className="card-box mb-30"
          style={{ padding: "10px", width: "fit-content" }}
        >
          <div id="report" style={{ overflow: "inherit" }}>
            <img
              src="../vendors/images/holiday_makers_logo.jpg"
              width="160"
              alt=""
            />
            <br />
            <p
              style={{
                fontFamily:
                  "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                fontSize: "14.5px",
                margin: "0",
                paddingTop: "15px",
              }}
            >
              Batch NO&nbsp;:&nbsp;
              <strong>{this.props.batchNo}</strong>
            </p>
            <p
              style={{
                fontFamily:
                  "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                fontSize: "14.5px",
                margin: "0",
                paddingTop: "15px",
              }}
            >
              Supplier Name&nbsp;:&nbsp;
              <strong>{this.props.suplier}</strong>
            </p>
            <p
              style={{
                fontFamily:
                  "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                fontSize: "14.5px",
                margin: "0",
                paddingTop: "15px",
              }}
            >
              Duty Date&nbsp;:&nbsp;
              <strong>{this.props.date}</strong>
            </p>
            <br />
            <div className="pb-20">
              <table
                class="table table-striped table-fixed"
                style={{ overflow: "inherit" }}
              >
                <thead>
                  <tr
                    style={{
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14.5px",
                      background: "#f6f6f7!important",
                    }}
                  >
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Booking Ref</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Pickup Time</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Pickup Location</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Dropoff Location</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Flight</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>PAX Name</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Total PAX</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>A</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>C</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>I</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Vehicle Type</strong>
                    </th>
                    <th
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        whiteSpace: "nowrap",
                        padding:"10px",
                        textAlign:"left"
                      }}
                    >
                      <strong>Remarks</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.batchItems.map((i, index) =>
                    !(index % 2) ? (
                      <tr
                        className="tableOddrow"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          background: "#f5cc98 !important",
                        }}
                      >
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.bookingRef}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          <strong>{i.pickupTime}</strong>
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          <strong>{i.pickupLocation}</strong>
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.dropoffLocation}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.flightDetail}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.paxName}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.totalPax}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.adults}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.children}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.infants}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.vehicleType}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.remarks}&nbsp;
                        </td>
                      </tr>
                    ) : (
                      <tr style={{ fontSize: "14.5px;" }}>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.bookingRef}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          <strong>{i.pickupTime}</strong>
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          <strong>{i.pickupLocation}</strong>
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.dropoffLocation}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.flightDetail}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.paxName}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.totalPax}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.adults}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.children}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.infants}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.vehicleType}
                        </td>
                        <td
                          style={{
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14.5px",
                            whiteSpace: "nowrap",
                            padding:"10px"
                          }}
                        >
                          {i.remarks}&nbsp;
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <button
            type="button"
            style={{ margin: "10px" }}
            className="btn btn-primary pull-right"
            onClick={(e) => {
              this.copyToClip();
            }}
          >
            Copy to Clipboard
          </button>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default BatchCopyItems;
