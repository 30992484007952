import React from 'react'
import { useParams } from 'react-router-dom';
import LayoutAdmin from '../components/shards/LayoutAdmin'
import ViewJobCard from '../components/ViewJobCard'

export default function ViewJobCardWarp() {
 
    let { jobCardId } = useParams();
  return (
    <LayoutAdmin><ViewJobCard id={jobCardId}/></LayoutAdmin>
  )
}
