import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs, { Dayjs } from "dayjs";
import { Accordion, AccordionTab } from "primereact/accordion";
import { MultiSelect } from "primereact/multiselect";
import PreLoader from "../components/shards/PreLoader";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import BatchCopyItems from "../components/BatchCopyItems";
import PaymentVoucherReport from "./PaymentVoucherReport";

class ViewPaymentVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      paymentVoucherCode: "",
      invoiceNo: "",
      jobCardNumber: "",
      paymentVoucherDateFrom: null,
      paymentVoucherDateTo: null,
      vendorId: [],
      vendors: [],
      paymentVoucherLineItems: [],
      paymentVoucherItem: null,
      isPaymentVoucherModalOpen: false,
      paymentVoucherId: null,
      isLoading: false,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    await this.getPaymentVouchers(true);
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getPaymentVouchers = async (isFirstLoad) => {
    this.setState({
      isLoading: true,
    });
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetPaymentVouchers?` +
        new URLSearchParams({
          paymentVoucherCode: this.state.paymentVoucherCode,
          invoiceNo: this.state.invoiceNo,
          vendorId: this.state.vendorId.toString(),
          paymentVoucherDateFrom: this.state.paymentVoucherDateFrom
            ? new Date(this.state.paymentVoucherDateFrom).toDateString()
            : "",
          paymentVoucherDateTo: this.state.paymentVoucherDateTo
            ? new Date(this.state.paymentVoucherDateTo).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
          isFirstLoad:isFirstLoad
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.paymentVouchers.map((i) => {
          debugger;

          batchList.push({
            id: i.id,
            paymentVoucherCode: i.paymentVoucherCode,
            paymentVoucherAmount:
              i.paymentVoucherAmount == null
                ? null
                : i.paymentVoucherAmount.toFixed(2),
            invoiceNo: i.invoiceNo,
            vendorName: i.vendorName,
            vendorAddressLine1: i.vendorAddressLine1,
            vendorAddressLine2: i.vendorAddressLine2,
            vendorBankCode: i.vendorBankCode,
            vendorBankName: i.vendorBankName,
            remark: i.remark,
            paymentVoucherDate:
              i.paymentVoucherDate == null
                ? null
                : dayjs(i.paymentVoucherDate).format("DD/MM/YYYY"),
            paymentVoucherLineItems: this.GetPaymentVoucherItems(
              i.paymentVoucherGenerateItem
            ),
          });
        });
        this.setState({
          isLoading: false,
        });
        if (
          !(
            this.state.paymentVoucherCode ||
            this.state.vendorId ||
            this.state.paymentVoucherDateFrom ||
            this.state.paymentVoucherDateTo ||
            this.state.invoiceNo
          )
        ) {
          this.setState({
            batches: batchList,
          });
        }
        this.setState({
          filterBatches: batchList,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const batchItems = [];
    items.map((i) => {
      batchItems.push({
        id: i.id,
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null
            ? ""
            : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? "" : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        flight: `${i.flightNo} ${
          i.flightTime == null ? "" : dayjs(i.flightTime).format("HH:mm")
        }`,
        remarks: i.remarks,
        batchNo: i.batchNo,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.totalCostTaxAmount == null ? null : i.totalCostTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        parking: i.parking == null ? null : i.parking.toFixed(2),
        water: i.water == null ? null : i.water.toFixed(2),
        extras: i.extras == null ? null : i.extras.toFixed(2),
      });
    });
    return batchItems;
  };

  GetPaymentVoucherItems = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    items.transportations.map((i) => {
      batchTransportationItems.push({
        id: i.id,
        batchNo: i.batchNo,
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null
            ? null
            : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.totalCostTaxAmount == null ? null : i.totalCostTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        parking: i.parking == null ? null : i.parking.toFixed(2),
        water: i.water == null ? null : i.water.toFixed(2),
        extras: i.extras == null ? null : i.extras.toFixed(2),
        type: "Transportation",
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount: i.costBaseAmount,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        type: "Hotel",
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        costBaseAmount: i.costBaseAmount,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        type: "Visa",
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount: i.costBaseAmount,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        type: "Miscellaneous",
      });
    });
    debugger;

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          vendors: response.vendors,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      batchCode: "",
      batchDateFrom: null,
      batchDateTo: null,
      filterBatches: this.state.batches,
    });
  };
  actionBodyTemplate = (item) => {
    return (
      <Button
        onClick={(e) => this.confirm(item)}
        icon="pi pi-times"
        severity="danger"
      />
    );
  };

  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          className="m-1"
          style={{ width: "2rem", height: "2rem" }}
          onClick={(e) =>
            this.setState({
              isModalOpen: true,
              paymentVoucherLineItems: item.paymentVoucherLineItems,
            })
          }
          icon="pi pi-book"
          severity="secondary"
          tooltip="View Items"
        />
        <Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isPaymentVoucherModalOpen: true,
              paymentVoucherId: item.id,
            })
          }
          icon="pi pi-file-pdf"
          severity="secondary"
          tooltip="View Payment Voucher"
        />
      </div>
    );
  };

  removeItemFromBatchHandler = async (e) => {
    this.setState({
      isLoading: true,
    });
    var payload = {
      Id: this.state.paymentVoucherItem.id,
      Type: this.state.paymentVoucherItem.type,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/RemovePaymentVoucherItemFromList`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.setErrorMessage("Delete Successfully", 1);
        window.location.reload();
      });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  confirm = (item) => {
    debugger;
    this.setState({
      paymentVoucherItem: item,
    });
    var accept = this.removeItemFromBatchHandler;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to remove from payment voucher?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  totalPaymentVouchers = () => {
    return this.state.filterBatches.reduce(
      (a, i) => {
        debugger;
        if (
          i.paymentVoucherAmount &&
          (i.paymentVoucherAmount != null) & (i.paymentVoucherAmount != "")
        ) {
          var p = parseFloat(a) + parseFloat(i.paymentVoucherAmount);
          return p.toFixed(2);
        }
      },
      0
    );
};
  footerGroup=(
 <ColumnGroup>
  <Row>
      <Column footer="Totals:" colSpan={4} footerStyle={{ textAlign: 'right' }} />
      <Column footer={this.totalPaymentVouchers} footerStyle={{ padding: "0.2rem 0.2rem" }}/>
      <Column/>
  </Row>
</ColumnGroup>)
  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getPaymentVouchers(false);
    }
  };
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/inheritance.gif"
                            alt=""
                            style={{verticalAlign:"middle"}}
                          />{" "}
                          View Payment Vouchers
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Payment Voucher Number
                              </label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.paymentVoucherCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    paymentVoucherCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice No</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.invoiceNo}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceNo: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Vendor</label>
                              <MultiSelect
                                value={this.state.vendorId}
                                onChange={(e) =>
                                  this.handleChange({
                                    vendorId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.vendors}
                                optionLabel="code"
                                placeholder="Select a Vendor"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Payment Voucher From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.paymentVoucherDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    paymentVoucherDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Payment Voucher To Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.paymentVoucherDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    paymentVoucherDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardNumber}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardNumber: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 col-md-12">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-warning pull-right"
                              onClick={(e) => {
                                this.resetSearchHandler(e);
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.getPaymentVouchers(false);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  {this.state.filterBatches && this.state.filterBatches.length>0 &&( <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.filterBatches}
                      selectionMode="single"
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                      footerColumnGroup={this.footerGroup}
                    >
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="paymentVoucherCode"
                        sortable
                        header="Payment Voucher Number"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="vendorName"
                        sortable
                        header="Vendor Name"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="paymentVoucherDate"
                        sortable
                        header="Payment Voucher Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceNo"
                        sortable
                        header="Invoice No"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="paymentVoucherAmount"
                        sortable
                        header="Payment Voucher Amount"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>)}
                </div>
                <Dialog
                  maximizable
                  visible={this.state.isModalOpen}
                  style={{ width: "80vw" }}
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Payment Voucher Items"
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <Accordion activeIndex={0}>
                    <AccordionTab header="Transportation">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={
                          this.state.paymentVoucherLineItems
                            .batchTransportationItems
                        }
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="jobCardNo"
                          sortable
                          header="Job Card No"
                        ></Column>
                        <Column
                          field="batchNo"
                          sortable
                          header="Batch No"
                        ></Column>
                        <Column
                          field="pickupTime"
                          sortable
                          header="Date & Time"
                          style={{ minWidth: "200px" }}
                        ></Column>

                        <Column
                          field="bookingRef"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="jobCardNo"
                          sortable
                          header="Job CardNo"
                        ></Column>
                        <Column
                          field="batchNo"
                          sortable
                          header="Batch No"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="pickupLocation"
                          sortable
                          header="Pickup Location"
                        ></Column>
                        <Column
                          field="dropoffLocation"
                          sortable
                          header="Dropoff Location"
                        ></Column>
                        <Column
                          field="flight"
                          sortable
                          header="Flight"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="PAX Name"
                        ></Column>
                        <Column
                          field="totalPax"
                          sortable
                          header="Total PAX"
                        ></Column>
                        <Column field="adults" sortable header="A"></Column>
                        <Column field="children" sortable header="C"></Column>
                        <Column field="infants" sortable header="I"></Column>
                        <Column
                          field="vehicleType"
                          sortable
                          header="Vehicle Type"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="costBaseAmount"
                          sortable
                          header="Net Cost"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="parking"
                          sortable
                          header="Parking"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="water"
                          sortable
                          header="Water"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="extras"
                          sortable
                          header="Extras"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="costTaxAmount"
                          sortable
                          header="VAT"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          header="Total Cost"
                          style={{ minWidth: "200px" }}
                        ></Column>
                        <Column
                          field="action"
                          sortable
                          body={this.actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Hotel">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={
                          this.state.paymentVoucherLineItems.batchHotelItems
                        }
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="jobCardNo"
                          sortable
                          header="Job Card No"
                        ></Column>
                        <Column
                          field="hotelCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="adults"
                          sortable
                          header="Adults"
                        ></Column>
                        <Column
                          field="children"
                          sortable
                          header="Children"
                        ></Column>
                        <Column
                          field="infants"
                          sortable
                          header="Infants"
                        ></Column>
                        <Column
                          field="hotelName"
                          sortable
                          header="Hotel Name"
                        ></Column>
                        <Column
                          field="checkIn"
                          sortable
                          header="Check-in"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="checkOut"
                          sortable
                          header="Check-out"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          header="Buy"
                        ></Column>
                        <Column
                          field="action"
                          sortable
                          body={this.actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Visa">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={
                          this.state.paymentVoucherLineItems.batchVisaItems
                        }
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="jobCardNo"
                          sortable
                          header="Job Card No"
                        ></Column>
                        <Column
                          field="visaCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="passportNo"
                          sortable
                          header="Passport No"
                        ></Column>
                        <Column
                          field="visaTypeName"
                          sortable
                          header="Visa Type"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          header="Buy"
                        ></Column>
                        <Column
                          field="action"
                          sortable
                          body={this.actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Miscellaneous">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={
                          this.state.paymentVoucherLineItems
                            .batchMiscellaneousItems
                        }
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="jobCardNo"
                          sortable
                          header="Job Card No"
                        ></Column>
                        <Column
                          field="miscellaneousCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="description"
                          sortable
                          header="Description"
                        ></Column>
                        <Column
                          field="misDate"
                          sortable
                          header="Date & Time"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          header="Buy"
                        ></Column>
                        <Column
                          field="action"
                          sortable
                          body={this.actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                  </Accordion>
                </Dialog>
                <Dialog
                  maximizable
                  visible={this.state.isPaymentVoucherModalOpen}
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Payment Voucher Preview"
                  onHide={(e) => {
                    this.setState({ isPaymentVoucherModalOpen: false });
                  }}
                >
                  <PaymentVoucherReport id={this.state.paymentVoucherId} />
                </Dialog>
                <ConfirmDialog />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ViewPaymentVouchers;
