import React, { useState,useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { SlideMenu } from 'primereact/slidemenu';
import { PanelMenu } from 'primereact/panelmenu';

import { Button } from "primereact/button";
  export default function LeftSlider() {
    const [visible, setVisible] = useState(false);
    const menu = useRef(null);
    const items = [
      {
          label:'Home',
          icon:'pi pi-fw pi-home',
          url:"/"
      },
      {
          label:'Logistics',
          icon:'pi pi-fw pi-truck',
          items:[
              {
                  label:'View Batches',
                  url:"/view_batches"
              },
              {
                  label:'New Batch',
                  url:"/batch_process"
              }
          ]
      },
      {
          label:'Job Cards',
          icon:'pi pi-fw pi-paperclip',
          items:[
              {
                  label:'View Job Cards',
                  url:"/view_job_cards"
              },
              {
                  label:'New Job Cards',
                  url:"/add_job_card"
              }
          ]
      },
      {
          label:'Financials',
          icon:'pi pi-fw pi-dollar',
          items:[
              {
                  label:'Update Cost',
                  icon:'pi pi-fw pi-pencil',
                  url:"/cost_update"
              },
              {
                  label:'Create Invoice',
                  icon:'pi pi-fw pi-calendar-times'
              },
              {
                  label:'Invoices',
                  icon:'pi pi-fw pi-calendar-times',
                  url:"/view_invoices"
              },
              {
                  label:'Daily Report',
                  icon:'pi pi-fw pi-calendar-times',
                  url:"/daily_report"
              },
              {
                  label:'Full Report',
                  icon:'pi pi-fw pi-calendar-times',
                  url:"/full_report"
              }
          ]
      },
      {
          label:'Master Data',
          icon:'pi pi-fw pi-wrench',
          items:[
              {
                  label:'View Customer List',
                  icon:'pi pi-fw pi-pencil',
                  url:"/view_customers"
              },
              {
                  label:'View Vendor List',
                  icon:'pi pi-fw pi-calendar-times',
                  url:"/view_vendors"
              },
              {
                  label:'Save Backup',
                  icon:'pi pi-fw pi-calendar-times'
              }
          ]
      }
  ];
    return (
      <div style={{padding:"0px 1vw"}}>
        
        <div style={{display:"flex"}}>
        <Button
          icon="pi pi-bars"
          onClick={() => setVisible(true)} 
          style={{margin:"2px"}}
        />
        <Button
          icon="pi pi-bars"
          onClick={(event) => menu.current.toggle(event)}
          style={{margin:"2px"}}
        />  
        </div>
        <Sidebar
          visible={visible}
          onHide={() => setVisible(false)}
        >
          <PanelMenu model={items} className="w-full md:w-25rem" />
        </Sidebar>
        
        <div>
        <div className="card flex justify-content-center">
            <SlideMenu ref={menu} model={items} popup viewportHeight={220} menuWidth={175}></SlideMenu>
        </div>
        </div>
      </div>
    );

}