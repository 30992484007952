import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import JobCardFullReport from "../components/JobCardFullReport";

class JobCardFullReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      jobCard: null,
      transports: [],
      TCost: 0,
      TSell: 0,
      TProfit: 0,
      hotels: [],
      HCost: 0,
      HSell: 0,
      HProfit: 0,
      visas: [],
      VCost: 0,
      VSell: 0,
      VProfit: 0,
      miscellanea: [],
      MCost: 0,
      MSell: 0,
      MProfit: 0,
      receipts:[],
      proformaInvoicesReceipts:[],
      vouchers:[],
      invoices:[],
      proformaInvoices:[],
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  aut;
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  componentDidMount = async () => {
    await this.getJobs();
    await this.getJobTransportations();
    await this.getJobHotels();
    await this.getJobVisas();
    await this.getJobMiscellanea();
    await this.getJobFinances();
  };
  getJobs = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        this.setState({
          jobCard: response.jobCard,
        });
      });
  };
  getJobTransportations = async () => {
    const transportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportationsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.jobCardTransportations.map((i) => {
          debugger;
          transportList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            pickupLocation: i.pickupLocation,
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks,
            totalCostTaxAmount:
              i.totalCostTaxAmount == null
                ? null
                : i.totalCostTaxAmount.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
            costBaseAmount:
              i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            parking: i.parking == null ? null : i.parking.toFixed(2),
            parkingTaxAmount:
              i.parkingTaxAmount == null ? null : i.parkingTaxAmount.toFixed(2),
            extras: i.extras == null ? null : i.extras.toFixed(2),
            extrasTaxAmount:
              i.extrasTaxAmount == null ? null : i.extrasTaxAmount.toFixed(2),
            water: i.water == null ? null : i.water.toFixed(2),
            waterTaxAmount:
              i.waterTaxAmount == null ? null : i.waterTaxAmount.toFixed(2),
            parkingSell:
              i.parkingSell == null ? null : i.parkingSell.toFixed(2),
            parkingTaxAmountSell:
              i.parkingTaxAmountSell == null
                ? null
                : i.parkingTaxAmountSell.toFixed(2),
            extrasSell: i.extrasSell == null ? null : i.extrasSell.toFixed(2),
            extrasTaxAmountSell:
              i.extrasTaxAmountSell == null
                ? null
                : i.extrasTaxAmountSell.toFixed(2),
            waterSell: i.waterSell == null ? null : i.waterSell.toFixed(2),
            waterTaxAmountSell:
              i.waterTaxAmountSell == null
                ? null
                : i.waterTaxAmountSell.toFixed(2),
          });
        });

        this.setState({
          transports: transportList,
          isModalOpen: false,
          TCost: response.totalCostAmount,
          TSell: response.totalSellAmount,
          TProfit: response.profit,
        });
      });
  };

  getJobHotels = async () => {
    const hotelList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobHotelsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardHotels.map((i) =>
          hotelList.push({
            id: i.id,
            hotelCode: i.hotelCode,
            hotelName: i.hotelName,
            checkIn:
              i.checkIn == null
                ? null
                : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
            checkOut:
              i.checkOut == null
                ? null
                : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            paxName: i.paxName,
            remarks: i.remarks,
            costBaseAmount: i.costBaseAmount== null ? null : i.costBaseAmount.toFixed(2),
            sellBaseAmount: i.sellBaseAmount== null ? null : i.sellBaseAmount.toFixed(2),
            costTaxAmount: i.costTaxAmount== null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount: i.sellTaxAmount== null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice: i.totalCostPrice== null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice: i.totalSellPrice== null ? null : i.totalSellPrice.toFixed(2),
          })
        );

        this.setState({
          hotels: hotelList,
          isModalOpen: false,
          HCost: response.totalCostAmount,
          HSell: response.totalSellAmount,
          HProfit: response.profit,
        });
      });
  };
  getJobVisas = async () => {
    const visaList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobVisasByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardVisas.map((i) =>
          visaList.push({
            id: i.id,
            visaCode: i.visaCode,
            passportNo: i.passportNo,
            visaTypeName: i.visaTypeName,
            paxName: i.paxName,
            remarks: i.remarks,
            costBaseAmount: i.costBaseAmount== null ? null : i.costBaseAmount.toFixed(2),
            sellBaseAmount: i.sellBaseAmount== null ? null : i.sellBaseAmount.toFixed(2),
            costTaxAmount: i.costTaxAmount== null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount: i.sellTaxAmount== null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice: i.totalCostPrice== null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice: i.totalSellPrice== null ? null : i.totalSellPrice.toFixed(2),
          })
        );

        this.setState({
          visas: visaList,
          isModalOpen: false,
          VCost: response.totalCostAmount,
          VSell: response.totalSellAmount,
          VProfit: response.profit,
        });
      });
  };

  getJobMiscellanea = async () => {
    const miscellaneousList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobMiscellaneaByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardMiscellaneous.map((i) =>
          miscellaneousList.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            paxName: i.paxName,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
            remarks: i.remarks,
            costBaseAmount: i.costBaseAmount== null ? null : i.costBaseAmount.toFixed(2),
            sellBaseAmount: i.sellBaseAmount== null ? null : i.sellBaseAmount.toFixed(2),
            costTaxAmount: i.costTaxAmount== null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount: i.sellTaxAmount== null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice: i.totalCostPrice== null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice: i.totalSellPrice== null ? null : i.totalSellPrice.toFixed(2),
          })
        );

        this.setState({
          miscellanea: miscellaneousList,
          isModalOpen: false,
          MCost: response.totalCostAmount,
          MSell: response.totalSellAmount,
          MProfit: response.profit,
        });
      });
  };

  getJobFinances = async () => {
    const receiptList = [];
    const proformaInvoicesReceiptsList = [];
    const invoiceList = [];
    const proformaInvoicesList = [];
    const voucherList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobFinancRreceiptAndPaymentsJobCardById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardFinance.jobCardFinanceRreceipt.map((i) => {
          receiptList.push({
            id: i.id,
            receiptCode: i.receiptCode,
            invoiceNo: i.invoiceNo,
            receiptDate:
              i.receiptDate == null
                ? null
                : dayjs(i.receiptDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });

        response.jobCardFinance.jobCardFinanceProformaInvoicesReceipt.map(
          (i) => {
            proformaInvoicesReceiptsList.push({
              id: i.id,
              receiptCode: i.receiptCode,
              invoiceNo: i.invoiceNo,
              receiptDate:
                i.receiptDate == null
                  ? null
                  : dayjs(i.receiptDate).format("DD/MM/YYYY"),
              amount: i.amount == null ? null : i.amount.toFixed(2),
              remarks: i.remarks,
            });
          }
        );

        response.jobCardFinance.jobCardFinancePaymentVouchers.map((i) => {
          voucherList.push({
            id: i.id,
            voucherCode: i.voucherCode,
            vendorName: i.vendorName,
            voucherDate:
              i.voucherDate == null
                ? null
                : dayjs(i.voucherDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });

        response.jobCardFinance.jobCardFinanceInvoices.map((i) => {
          invoiceList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });
        response.jobCardFinance.jobCardFinanceProformaInvoices.map((i) => {
          proformaInvoicesList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });
        debugger;
        this.setState({
          receipts: receiptList,
          proformaInvoicesReceipts: proformaInvoicesReceiptsList,
          vouchers: voucherList,
          invoices: invoiceList,
          proformaInvoices: proformaInvoicesList,
        });
      });
  };
  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">Job Card Full Report</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <JobCardFullReport
                    jobCard={this.state.jobCard}
                    transports={this.state.transports}
                    tProfit={this.state.TProfit}
                    hotels={this.state.hotels}
                    hProfit={this.state.HProfit}
                    visas={this.state.visas}
                    vProfit={this.state.VProfit}
                    miscellanea={this.state.miscellanea}
                    mProfit={this.state.MProfit}
                    receipts={this.state.receipts}
                    proformaInvoicesReceipts={this.state.proformaInvoicesReceipts}
                    vouchers={this.state.vouchers}
                    invoices={this.state.invoices}
                    proformaInvoices={this.state.proformaInvoices}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobCardFullReportWrap;
