import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import ViewInvoice from "../pages/ViewInvoice";
class VatReportInner extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpenInvoice: false,
      invoice: null,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />

          <br />
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th>Job Card Numbers</th>
                  <th>Customer Name</th>
                  <th style={{ whiteSpace: "nowrap" }}>Invoice Number</th>
                  <th style={{ textAlign: "right" }}>Subtotal</th>
                  <th style={{ textAlign: "right" }}>VAT(05%)</th>
                  <th style={{ textAlign: "right" }}>Grand Total</th>
                </tr>
              </thead>
              <tbody>
                {this.props.reportItem.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td width={300}>{i.jobCards}</td>
                      <td width={600}>{i.customerName}</td>
                      <td width={200}>{i.invoiceCode}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceNetAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceVatAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceGrossAmount}
                      </td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td width={300}>{i.jobCards}</td>
                      <td width={600}>{i.customerName}</td>
                      <td width={200}>{i.invoiceCode}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceNetAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceVatAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {i.invoiceGrossAmount}
                      </td>
                    </tr>
                  )
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{""}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{""}</td>
                </tr>
                <tr style={{
                    fontSize: "10px !important;"
                  }}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    <strong>{this.props.reportItem.reduce((a, i) => {
                      if (
                        i.invoiceNetAmount &&
                        (i.invoiceNetAmount != null) &
                          (i.invoiceNetAmount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.invoiceNetAmount);
                        return p.toFixed(2);
                      }
                    }, 0)}</strong>
                  </td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                  <strong>{this.props.reportItem.reduce((a, i) => {
                      if (
                        i.invoiceGrossAmount &&
                        (i.invoiceGrossAmount != null) &
                          (i.invoiceGrossAmount != "")
                      ) {
                        var p =
                          parseFloat(a) + parseFloat(i.invoiceGrossAmount);
                        return p.toFixed(2);
                      }
                    }, 0)}</strong>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{""}</td>
                </tr>
                <tr style={{
                    fontSize: "10px !important;" 
                  }}>
                  <td></td>
                  <td></td>
                  <td colSpan={2}><strong>Total VAT Collected AED</strong></td>
                  <td style={{ textAlign: "right" }}>
                  <strong>{this.props.reportItem.reduce((a, i) => {
                      if (
                        i.invoiceVatAmount &&
                        (i.invoiceVatAmount != null) &
                          (i.invoiceVatAmount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.invoiceVatAmount);
                        return p.toFixed(2);
                      }
                    }, 0)}</strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default VatReportInner;
