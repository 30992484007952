import React from 'react'
import { useParams } from 'react-router-dom';
import JobCardAgentReport from './JobCardAgentReport';
import JobCardAgentReportWrap from './JobCardAgentReportWrap';

export default function PrintAgentJobCard() {
  
    let { jobCardId } = useParams();
  return (
    <JobCardAgentReportWrap id={jobCardId}/>
  )
}
