import React from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import ViewProformaReceiptInner from "../components/ViewProformaReceiptInner";
const ExcelJS = require("exceljs");

class ViewProformaReceipt extends React.Component {
  constructor(props) {
    super(props);
  }
  printDiv = (divName) => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.location.reload();
  };

  render() {
    return (
      <div className="main-container">
        <div
          className="card-box  mb-30"
          style={{ padding: "10px", width: "fit-content" }}
        >
          <ViewProformaReceiptInner
            receipt={this.props.receipt}
            ref={(el) => (this.componentRef = el)}
          />
          <div className="row">
            
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  style={{ margin: "10px" }}
                  className="btn btn-primary pull-right"
                >
                  Print Report
                </button>
              )}
              content={() => this.componentRef}
              copyStyles={true}
              pageStyle="print"
            />
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    );
  }
}

export default ViewProformaReceipt;
