import React from 'react'
import { useParams } from 'react-router-dom';
import JobCardFullReport from './JobCardFullReport';
import JobCardFullReportWrap from './JobCardFullReportWrap';

export default function PrintJobCard() {
  
    let { jobCardId } = useParams();
  return (
    <JobCardFullReportWrap id={jobCardId}/>
  )
}
