import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import JobCardFullReport from "../components/JobCardFullReport";
import JobCardAgentReport from "../components/JobCardAgentReport";

class JobCardAgentReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      jobCard: null,
      transports: [],
      TCost: 0,
      TSell: 0,
      TProfit: 0,
      hotels: [],
      HCost: 0,
      HSell: 0,
      HProfit: 0,
      visas: [],
      VCost: 0,
      VSell: 0,
      VProfit: 0,
      miscellanea: [],
      MCost: 0,
      MSell: 0,
      MProfit: 0,
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  aut;
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  componentDidMount = async () => {
    await this.getJobs();
    await this.getJobTransportations();
    await this.getJobHotels();
    await this.getJobVisas();
    await this.getJobMiscellanea();
  };
  getJobs = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger
        this.setState({
          jobCard: response.jobCard,
        });
      });
  };
  getJobTransportations = async () => {
    const transportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportationsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.jobCardTransportations.map((i) => {
          debugger;
          transportList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            pickupLocation: i.pickupLocation,
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            parking: i.parking,
            extras: i.extras,
            extrasTaxAmount: i.extrasTaxAmount,
            water: i.water,
            parkingSell: i.parkingSell,
            extrasSell: i.extrasSell,
            extrasTaxAmountSell: i.extrasTaxAmountSell,
            waterSell: i.waterSell,
          });
        });

        this.setState({
          transports: transportList,
          isModalOpen: false,
          TCost: response.totalCostAmount,
          TSell: response.totalSellAmount,
          TProfit: response.profit,
        });
      });
  };

  getJobHotels = async () => {
    const hotelList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobHotelsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardHotels.map((i) =>
          hotelList.push({
            id: i.id,
            hotelCode: i.hotelCode,
            hotelName: i.hotelName,
            checkIn:
              i.checkIn == null
                ? null
                : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
            checkOut:
              i.checkOut == null
                ? null
                : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          hotels: hotelList,
          isModalOpen: false,
          HCost: response.totalCostAmount,
          HSell: response.totalSellAmount,
          HProfit: response.profit,
        });
      });
  };
  getJobVisas = async () => {
    const visaList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobVisasByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardVisas.map((i) =>
          visaList.push({
            id: i.id,
            visaCode: i.visaCode,
            passportNo: i.passportNo,
            visaTypeName: i.visaTypeName,
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          visas: visaList,
          isModalOpen: false,
          VCost: response.totalCostAmount,
          VSell: response.totalSellAmount,
          VProfit: response.profit,
        });
      });
  };

  getJobMiscellanea = async () => {
    const miscellaneousList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobMiscellaneaByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardMiscellaneous.map((i) =>
          miscellaneousList.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            paxName: i.paxName,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
            remarks: i.remarks,
            costTaxAmount: i.costTaxAmount,
            sellTaxAmount: i.sellTaxAmount,
            totalCostPrice: i.totalCostPrice,
            totalSellPrice: i.totalSellPrice,
          })
        );

        this.setState({
          miscellanea: miscellaneousList,
          isModalOpen: false,
          MCost: response.totalCostAmount,
          MSell: response.totalSellAmount,
          MProfit: response.profit,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">Job Card Agent Report</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <JobCardAgentReport
                    jobCard={this.state.jobCard}
                    transports={this.state.transports}
                    tProfit={this.state.TProfit}
                    hotels={this.state.hotels}
                    hProfit={this.state.HProfit}
                    visas={this.state.visas}
                    vProfit={this.state.VProfit}
                    miscellanea={this.state.miscellanea}
                    mProfit={this.state.MProfit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobCardAgentReportWrap;
