import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import "react-toastify/dist/ReactToastify.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import PaymentVoucherReport from "./PaymentVoucherReport";
import PreLoader from "../components/shards/PreLoader";
class PaymentVoucherGeneration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      customerId: "",
      batchTransportationItems: [],
      batchHotelItems: [],
      batchVisaItems: [],
      batchMiscellaneousItems: [],
      rowClick: true,
      selectedTransportationItem: [],
      selectedHotelItem: [],
      selectedVisaItem: [],
      selectedMiscellaneousItem: [],
      vendorId: "",
      invoice: "",
      paymentVoucherDate: "",
      paymentVoucherAmount: "",
      remarks: "",
      errorMessage: null,
      isModalOpen: false,
      batchNo: "",
      suplierName: "",
      isPaymentVoucherModalOpen: false,
      paymentVoucherId: 0,
      fromDate: "",
      toDate: "",
      totalTransportationAmount: 0,
      totalHotelAmount: 0,
      totalVisaAmount: 0,
      totalMiscellaneousAmount: 0,
      jobCardNumber: "",
      isLoading:false,
      transferColumns: [
        { field: "jobCardNo", header: "Job Card No" },
        { field: "batchNo", header: "Batch No" },
        { field: "pickupLocation", header: "Pickup Location" },
        { field: "dropoffLocation", header: "Drop Off Location" },
        { field: "totalPax", header: "Total PAX" },
        { field: "vehicleType", header: "Vehicle Type" },
        { field: "paxName", header: "Pax Name" },
        { field: "remarks", header: "Remarks" }
      ],
      transferVisibleColumns: [
        { field: "jobCardNo", header: "Job Card No" },
        { field: "batchNo", header: "Batch No" },
        { field: "pickupLocation", header: "Pickup Location" },
        { field: "dropoffLocation", header: "Drop Off Location" },
        { field: "totalPax", header: "Total PAX" },
        { field: "vehicleType", header: "Vehicle Type" },
        { field: "paxName", header: "Pax Name" },
        { field: "remarks", header: "Remarks" }
      ],
      hotelColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "adults", header: "Adults" },
        { field: "children", header: "Children" },
        { field: "infants", header: "Infants" },
        { field: "hotelName", header: "Hotel Name" },
        { field: "checkIn", header: "Check-in" },
        { field: "checkOut", header: "Check-out" },
        { field: "remarks", header: "Remarks" }
      ],
      hotelVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "adults", header: "Adults" },
        { field: "children", header: "Children" },
        { field: "infants", header: "Infants" },
        { field: "hotelName", header: "Hotel Name" },
        { field: "checkIn", header: "Check-in" },
        { field: "checkOut", header: "Check-out" },
        { field: "remarks", header: "Remarks" }
      ],
      visaColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "passportNo", header: "Passport No" },
        { field: "visaTypeName", header: "Visa Type" },
        { field: "remarks", header: "Remarks" }
      ],
      visaVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "passportNo", header: "Passport No" },
        { field: "visaTypeName", header: "Visa Type" },
        { field: "remarks", header: "Remarks" }
      ],
      miscellaneousColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "description", header: "Description" },
        { field: "misDate", header: "Date & Time" },
        { field: "remarks", header: "Remarks" }
      ],
      miscellaneousVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "description", header: "Description" },
        { field: "misDate", header: "Date & Time" },
        { field: "remarks", header: "Remarks" }
      ],
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
          vendors: response.vendors,
        });
      });
  };

  getPaymentVoucherItems = async () => {
    this.setState({
      isLoading: true,
    });
    if (!this.state.vendorId || this.state.vendorId == "") {
      this.setErrorMessage("Please select vendor", 3);
      this.setState({
        isLoading: false,
      });
      return;
    }
    this.setState({
      batchTransportationItems: [],
      selectedTransportationItem: [],
      batchHotelItems: [],
      selectedHotelItem: [],
      batchVisaItems: [],
      selectedVisaItem: [],
      batchMiscellaneousItems: [],
      selectedMiscellaneousItem: [],
    });
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetBatchItemsByVendorId?` +
        new URLSearchParams({
          vendorId: this.state.vendorId,
          fromDate: this.state.fromDate
            ? new Date(this.state.fromDate).toDateString()
            : "",
          toDate: this.state.toDate
            ? new Date(this.state.toDate).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        this.setState({
          isLoading: false,
        });
        response.paymentVoucherGenerateItem.transportations.map((i) => {
          batchTransportationItems.push({
            id: i.id,
            batchNo: i.batchNo,
            bookingRef: i.bookingRef,
            jobCardNo: i.jobCardNo,
            jobCardTitle: i.jobCardTitle,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
            costTaxAmount:
              i.totalCostTaxAmount == null
                ? null
                : i.totalCostTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            parking: i.parking == null ? null : i.parking.toFixed(2),
            water: i.water == null ? null : i.water.toFixed(2),
            extras: i.extras == null ? null : i.extras.toFixed(2),
          });
        });

        response.paymentVoucherGenerateItem.hotels.map((i) => {
          batchHotelItems.push({
            id: i.id,
            jobCardNo: i.jobCardNo,
            jobCardTitle: i.jobCardTitle,
            hotelCode: i.hotelCode,
            hotelName: i.hotelName,
            checkIn:
              i.checkIn == null
                ? null
                : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
            checkOut:
              i.checkOut == null
                ? null
                : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            paxName: i.paxName,
            remarks: i.remarks,
            hotelConfirmation: i.hotelConfirmation,
            roomType: i.roomType,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          });
        });

        response.paymentVoucherGenerateItem.visa.map((i) => {
          batchVisaItems.push({
            id: i.id,
            jobCardNo: i.jobCardNo,
            jobCardTitle: i.jobCardTitle,
            visaCode: i.visaCode,
            passportNo: i.passportNo,
            visaTypeName: i.visaTypeName,
            paxName: i.paxName,
            remarks: i.remarks,
            nationality: i.nationality,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          });
        });

        response.paymentVoucherGenerateItem.miscellaneous.map((i) => {
          batchMiscellaneousItems.push({
            id: i.id,
            jobCardNo: i.jobCardNo,
            jobCardTitle: i.jobCardTitle,
            miscellaneousCode: i.miscellaneousCode,
            paxName: i.paxName,
            paxNumber: i.paxNumber,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
            remarks: i.remarks,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          });
        });

        this.setState({
          batchTransportationItems: batchTransportationItems,
          batchHotelItems: batchHotelItems,
          batchVisaItems: batchVisaItems,
          batchMiscellaneousItems: batchMiscellaneousItems,
          remarks: "",
          isSearch: true,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
  }
  handleTransportationSelectChange(items) {
    debugger;
    var totalCostBase = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalCostPrice)),
      0
    );
    var paymentVoucherAmount =
      totalCostBase +
      this.state.totalHotelAmount +
      this.state.totalVisaAmount +
      this.state.totalMiscellaneousAmount;
    var jobCards = items.map((i) => i.jobCardTitle);
    var jobCardString = jobCards.filter(
      (value, index) => jobCards.indexOf(value) === index
    );
    var remarks = "Transport for " + jobCardString.toString() + "\r\n";
    this.setState({
      selectedTransportationItem: items,
      totalTransportationAmount: totalCostBase,
      paymentVoucherAmount: paymentVoucherAmount.toFixed(2),
      remarks: remarks,
    });
  }

  handleHotelSelectChange(items) {
    debugger;
    var totalCostBase = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalCostPrice)),
      0
    );
    var paymentVoucherAmount =
      this.state.totalTransportationAmount +
      totalCostBase +
      this.state.totalVisaAmount +
      this.state.totalMiscellaneousAmount;
    var jobCards = items.map((i) => i.jobCardTitle);
    var jobCardString = jobCards.filter(
      (value, index) => jobCards.indexOf(value) === index
    );
    var remarks = "Hotel for " + jobCardString.toString() + "\r\n";
    this.setState({
      selectedHotelItem: items,
      totalHotelAmount: totalCostBase,
      paymentVoucherAmount: paymentVoucherAmount.toFixed(2),
      remarks: remarks,
    });
  }

  handleVisaSelectChange(items) {
    debugger;
    var totalCostBase = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalCostPrice)),
      0
    );
    var paymentVoucherAmount =
      this.state.totalTransportationAmount +
      this.state.totalHotelAmount +
      totalCostBase +
      this.state.totalMiscellaneousAmount;
    var jobCards = items.map((i) => i.jobCardTitle);
    var jobCardString = jobCards.filter(
      (value, index) => jobCards.indexOf(value) === index
    );
    var remarks = "Visa for " + jobCardString.toString() + "\r\n";
    this.setState({
      selectedVisaItem: items,
      totalVisaAmount: totalCostBase,
      paymentVoucherAmount: paymentVoucherAmount.toFixed(2),
      remarks: remarks,
    });
  }

  handleMiscellaneousSelectChange(items) {
    debugger;
    var totalCostBase = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalCostPrice)),
      0
    );
    var paymentVoucherAmount =
      this.state.totalTransportationAmount +
      this.state.totalHotelAmount +
      this.state.totalVisaAmount +
      totalCostBase;
    debugger;

    var jobCards = items.map((i) => i.jobCardTitle);
    var jobCardString = jobCards.filter(
      (value, index) => jobCards.indexOf(value) === index
    );
    var remarks = "Miscellaneous for " + jobCardString.toString() + "\r\n";
    this.setState({
      selectedMiscellaneousItem: items,
      totalMiscellaneousAmount: totalCostBase,
      paymentVoucherAmount: paymentVoucherAmount.toFixed(2),
      remarks: remarks,
    });
  }

  validation = () => {
    debugger;
    if (
      (this.state.selectedTransportationItem == null ||
        this.state.selectedTransportationItem.length == 0) &&
      (this.state.selectedHotelItem == null ||
        this.state.selectedHotelItem.length == 0) &&
      (this.state.selectedVisaItem == null ||
        this.state.selectedVisaItem.length == 0) &&
      (this.state.selectedMiscellaneousItem == null ||
        this.state.selectedMiscellaneousItem.length == 0)
    ) {
      this.setErrorMessage("Please select item from grid", 3);
      return false;
    }
    if (this.state.vendorId == "") {
      this.setErrorMessage("Please select vendor", 3);
      return false;
    }
    if (this.state.paymentVoucherDate == null) {
      this.setErrorMessage("Please select payment voucher date", 3);
      return false;
    }

    if (this.state.paymentVoucherAmount <= 0) {
      this.setErrorMessage("Payment voucher amount should greater than 0", 3);
      return false;
    }
    return true;
  };
  savePaymentVoucher = async (e) => {
    
    this.setState({
      isLoading: true,
      isSaveProcessing: true,
    });
    if (!this.validation()) {
      this.setState({
        isLoading: false,
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      TransportationIds: this.state.selectedTransportationItem.map((o) => o.id),
      HotelIds: this.state.selectedHotelItem.map((o) => o.id),
      VisaIds: this.state.selectedVisaItem.map((o) => o.id),
      MiscellaneousIds: this.state.selectedMiscellaneousItem.map((o) => o.id),

      VendorId: this.state.vendorId,
      PaymentVoucherDate: this.state.paymentVoucherDate.toLocaleString(),
      PaymentVoucherAmount: this.state.paymentVoucherAmount,
      Invoice: this.state.invoice,
      Remarks: this.state.remarks,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SavePaymentVouchers`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          
          this.setState({
            isLoading: false,
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setErrorMessage("Payment voucher generated", 1);

        //this.getBatchItems();
        this.setState({
          isLoading: false,
          isSaveProcessing: false,
          isPaymentVoucherModalOpen: true,
          paymentVoucherId: response.data.id,
          batchTransportationItems: [],
          selectedTransportationItem: [],
          batchHotelItems: [],
          selectedHotelItem: [],
          batchVisaItems: [],
          selectedVisaItem: [],
          batchMiscellaneousItems: [],
          selectedMiscellaneousItem: [],
          vendorId: "",
          invoice: "",
          paymentVoucherDate: "",
          paymentVoucherAmount: "",
          remarks: "",
          fromDate: "",
          toDate: "",
        });
      });
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getPaymentVoucherItems();
    }
  };
  onTransferColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.transferColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      transferVisibleColumns: orderedSelectedColumns,
    });
  };

  onHotelColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.hotelColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      hotelVisibleColumns: orderedSelectedColumns,
    });
  };

  onVisaColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.visaColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      visaVisibleColumns: orderedSelectedColumns,
    });
  };

  onMisColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.miscellaneousColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      miscellaneousVisibleColumns: orderedSelectedColumns,
    });
  };
  render() {
    const accept = () => {
      this.savePaymentVoucher();
    };

    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = () => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };

    const transferHeader = (
      <MultiSelect
        value={this.state.transferVisibleColumns}
        options={this.state.transferColumns}
        optionLabel="header"
        onChange={this.onTransferColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const hotelHeader = (
      <MultiSelect
        value={this.state.hotelVisibleColumns}
        options={this.state.hotelColumns}
        optionLabel="header"
        onChange={this.onHotelColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const visaHeader = (
      <MultiSelect
        value={this.state.visaVisibleColumns}
        options={this.state.visaColumns}
        optionLabel="header"
        onChange={this.onVisaColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const misHeader = (
      <MultiSelect
        value={this.state.miscellaneousVisibleColumns}
        options={this.state.miscellaneousColumns}
        optionLabel="header"
        onChange={this.onMisColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                      
                        <h2 className="text-blue h2">
                        <img
                          width={"50px"}
                          src="/vendors/images/bill.gif"
                          alt=""
                          style={{verticalAlign:"middle"}}
                        />{" "}
                          Generate Payment Voucher
                        </h2>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Vendor</label>
                          <Dropdown
                            value={this.state.vendorId}
                            onChange={(e) =>
                              this.handleChange({ vendorId: e.value })
                            }
                            options={this.state.vendors}
                            placeholder="Select a Vendor"
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">From Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.fromDate}
                            onChange={(e) =>
                              this.handleChange({
                                fromDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">To Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.toDate}
                            onChange={(e) =>
                              this.handleChange({
                                toDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Job Card Number</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.jobCardNumber}
                            onChange={(e) =>
                              this.handleChange({
                                jobCardNumber: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        
                      </div>
                      <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <button
                          type="button"
                          style={{ margin: "10px" }}
                          className="btn btn-primary pull-right"
                          onClick={(e) => {
                            this.getPaymentVoucherItems(e);
                          }}
                        >
                          Get Items
                        </button>
                    </div>
                      </div>
                    </div>
                    
                    <div>
                      <Accordion activeIndex={0}>
                        <AccordionTab header="Transportation">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                header={transferHeader}
                                scrollable
                                value={this.state.batchTransportationItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={
                                  this.state.selectedTransportationItem
                                }
                                onSelectionChange={(e) =>
                                  this.handleTransportationSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="pickupTime"
                                  sortable
                                  header="Date & Time"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="bookingRef"
                                  sortable
                                  header="Booking Ref"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                {this.state.transferVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="costBaseAmount"
                                  sortable
                                  header="Net Cost"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="parking"
                                  sortable
                                  header="Parking"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="water"
                                  sortable
                                  header="Water"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="extras"
                                  sortable
                                  header="Extras"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="costTaxAmount"
                                  sortable
                                  header="VAT"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  header="Total Cost"
                                  style={{ minWidth: "200px" }}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Hotel">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={hotelHeader}
                                value={this.state.batchHotelItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedHotelItem}
                                onSelectionChange={(e) =>
                                  this.handleHotelSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="hotelCode"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                {this.state.hotelVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  header="Buy"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Visa">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={visaHeader}
                                value={this.state.batchVisaItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedVisaItem}
                                onSelectionChange={(e) =>
                                  this.handleVisaSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="visaCode"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                {this.state.visaVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  header="Buy"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Miscellaneous">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={misHeader}
                                value={this.state.batchMiscellaneousItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedMiscellaneousItem}
                                onSelectionChange={(e) =>
                                  this.handleMiscellaneousSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                {this.state.miscellaneousVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  header="Buy"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="card-box pt-30 mb-30">
                    <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                    <div className="pb-20">
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice #</label>
                              <input
                                className="form-control"
                                type="text"
                                value={this.state.invoice}
                                onChange={(e) =>
                                  this.handleChange({ invoice: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Payment Voucher Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.paymentVoucherDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    paymentVoucherDate: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Payment Voucher Amount
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                readOnly
                                min="0"
                                value={this.state.paymentVoucherAmount}
                                onChange={(e) =>
                                  this.handleChange({
                                    paymentVoucherAmount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Remarks</label>
                              <textarea
                                className="form-control"
                                value={this.state.remarks}
                                onChange={(e) =>
                                  this.handleChange({ remarks: e.target.value })
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 col-md-12">
                            <Button
                              type="button"
                              label="Submit"
                              loading={this.state.isSaveProcessing}
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                confirm();
                              }}
                            />
                          </div>
                          <Dialog
                            maximizable
                            headerStyle={{
                              backgroundColor: "#fd6511",
                              color: "#FFF",
                            }}
                            contentStyle={{
                              padding: "25px 1.5rem 2rem 1.5rem",
                            }}
                            header="Payment Voucher Preview"
                            visible={this.state.isPaymentVoucherModalOpen}
                            onHide={(e) => {
                              this.setState({
                                isPaymentVoucherModalOpen: false,
                              });
                            }}
                          >
                            <PaymentVoucherReport
                              id={this.state.paymentVoucherId}
                            />
                          </Dialog>
                          <ConfirmDialog />
                          <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && (<PreLoader/>)}
      </>
    );
  }
}

export default PaymentVoucherGeneration;
