import React from "react";

class ViewReceiptInner extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <div>
        <div className="invoice-wrap">
          <div className="invoice-box" id="report">
            <div className="row pb-30 mt-30">
              <div className="col-md-6 mt-30">
                <img
                  className="pl-15"
                  style={{ paddingTop: "0.8rem" }}
                  src="../vendors/images/holiday_makers_rlogo.jpg"
                  width="160"
                  alt=""
                />
                <p className="font-14 mb-5 reportFont pl-15">
                  <strong className="weight-600">
                    Holiday Makers Tourism LLC
                    <br />
                  </strong>
                  Office 418, City 4 Building,
                  <br /> Al Garhoud, Dubai,
                  <br /> United Arab Emirates
                </p>
              </div>
              <div className="col-md-6 mt-30">
                <h4 className="reportTitle weight-600 reportFont">
                  RECEIPT
                </h4>
                <h6 className="text-right 	mb-30 weight-600 reportFont">
                  # {this.props.receipt.receiptCode}
                </h6>
                <div className="text-right">
                  <p className="font-14 mb-5">
                    <strong>Receipt Amount </strong>
                  </p>
                  <h6 className="font-18 mb-5" style={{ color: "#FF6C04" }}>
                    <b>
                      AED{" "}
                      {this.props.receipt.amount}
                    </b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="row pb-30">
              <div className="col-md-6">
                <p className="font-14 mb-5 pl-15">
                  Bill To
                  <br />
                  <strong className="weight-600">
                    {this.props.receipt.customerName}
                  </strong>
                  {this.props.receipt.addressLine1 && (
                    <>
                      <br />
                      {this.props.receipt.addressLine1}
                    </>
                  )}
                  {this.props.receipt.addressLine2 && (
                    <>
                      <br />
                      {this.props.receipt.addressLine2}
                    </>
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <p className="font-14 mb-5">
                    Receipt Date :{" "}
                    <strong className="weight-600">
                      {this.props.receipt.paymentDate}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="invoice-desc font-14 pl-15">
              <div className="invoice-desc-head clearfix font-14">
                <div className="invoice-sub font-14">Description</div>
                <div className="invoice-subtotal font-14">Amount</div>
              </div>
              <div className="invoice-desc-body" style={{ minHeight: "200px" }}>
                <ul>
                  <li className="clearfix">
                    <div className="invoice-sub font-14">
                      {"Payment for invoice "}{"("}{this.props.receipt.invoiceCode}{")"}
                    </div>

                    <div className="invoice-subtotal font-14">
                      <span className="weight-600 font-14">
                        AED{" "}
                        {this.props.receipt.amount}
                      </span>
                    </div>
                  </li>

                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14">Total Amount</div>
                    <div
                      className="invoice-subtotal"
                      style={{ color: "#fff", backgroundColor: "#FF6C04" }}
                    >
                      <span className="weight-600 font-14">
                        AED{" "}
                        {this.props.receipt.amount}
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-total font-14"></div>
                    <div className="invoice-subtotal">
                      <span className="weight-600 font-14">
                        
                      </span>
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-sub font-14">
                      <strong>{"Payment Method : "}</strong>{this.props.receipt.paymentMethod}
                    </div>

                    <div className="invoice-subtotal font-14">
                      
                    </div>
                  </li>
                  <li className="clearfix" style={{ borderBottom: "0px" }}>
                    <div className="invoice-sub font-14">
                      <strong>{"Remark : "}</strong>{this.props.receipt.remark}
                    </div>

                    <div className="invoice-subtotal font-14">
                      
                    </div>
                  </li>
                  
                </ul>
              </div>
              
            </div>
            <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
            <p align="center">
              {" "}
              Office 418, City 4 Building, Al Garhoud, Dubai, United Arab
              Emirates
              <br />
              +971 4 257 9192 | ops@holidaymakers.ae | www.holidaymakers.ae
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewReceiptInner;
