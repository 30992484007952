import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import FullReport from "../components/FullReport";
import DailyReport from "../components/DailyReport";
import HistoryReport from "../components/HistoryReport";
import { MultiSelect } from "primereact/multiselect";
class HistoryReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      users: [],
      vendors: [],
      reportType: [{ code: "Full Report", value: 2 }],
      effectiveDateFrom: null,
      effectiveDateTo: null,
      customerId: "",
      userId: [],
      jobCardCode: "",
      reportTypeId: 2,
      fullReport: [],
      dailyReport: [],
      isLoading: false,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          users: response.users,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  getReportItem = async () => {
    this.setState({
      isLoading: true,
    });
    const dailyReportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetHistoryReportItems?` +
        new URLSearchParams({
          rUserId: this.state.userId.toString(),
          jobCardCode: this.state.jobCardCode,
          dateFrom: this.state.effectiveDateFrom
            ? new Date(this.state.effectiveDateFrom).toDateString()
            : "",
          dateTo: this.state.effectiveDateTo
            ? new Date(this.state.effectiveDateTo).toDateString()
            : "",
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        var fullReport = this.GetBatchTransportItem(
          response.historyGenerateItem
        );
        this.setState({
          isLoading: false,
        });
        this.setState({
          fullReport: fullReport,
          isModalOpen: true,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const jobCards = [];
    const transportations = [];
    const hotels = [];
    const visa = [];
    const miscellaneous = [];

    items.jobCards.map((i) => {
      jobCards.push({
        id: i.id,
        effectiveDate:
          i.effectiveDate == null
            ? null
            : dayjs(i.effectiveDate).format("DD/MM/YYYY HH:mm"),
        createdDate:
          i.createdDate == null
            ? null
            : dayjs(i.createdDate).format("DD/MM/YYYY HH:mm"),
        createdBy: i.createdBy,
        updatedDate:
          i.updatedDate == null
            ? null
            : dayjs(i.updatedDate).format("DD/MM/YYYY HH:mm"),
        updatedBy: i.updatedBy,
        customerId: i.customerId,
        customerName: i.customerName,
        customerRef: i.customerRef,
        jobCardCode: i.jobCardCode,
        jobCardDescription: i.jobCardDescription,
        status: i.status,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        auditDate:
          i.auditDate == null
            ? null
            : dayjs(i.auditDate).format("DD/MM/YYYY HH:mm"),
        auditAction: i.auditAction,
        createdByName: i.createdByName,
        updatedByName: i.updatedByName,
      });
    });

    items.transportations.map((i) => {
      transportations.push({
        id: i.id,
        effectiveDateFrom:
          i.pickupTime == null
            ? null
            : dayjs(i.pickupTime).format("DD/MM/YYYY"),
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        customerName: i.customerName,
        customerRef: i.customerRef,
        pickUpTime:
          i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
        pickUpLocation: i.pickupLocation,
        dropOffLocation: i.dropoffLocation,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        totalPax: i.totalPax,
        adult: i.adults,
        children: i.children,
        vehicleType: i.vehicleType,
        suplierName: i.suplierName,
        batchNo: i.batchNo == "" || i.batchNo == null ? " " : i.batchNo,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        flightDetail: this.GetFlightDetails(i.flightNo.trim(), i.flightTime),
        createdDate:
          i.createdDate == null
            ? null
            : dayjs(i.createdDate).format("DD/MM/YYYY HH:mm"),
        createdBy: i.createdBy,
        updatedDate:
          i.updatedDate == null
            ? null
            : dayjs(i.updatedDate).format("DD/MM/YYYY HH:mm"),
        updatedBy: i.updatedBy,
        auditDate:
          i.auditDate == null
            ? null
            : dayjs(i.auditDate).format("DD/MM/YYYY HH:mm"),
        auditAction: i.auditAction,
        isPaymentVouchered: i.isPaymentVouchered,
        isInvoiced: i.isInvoiced,
        isBatched: i.isBatched,
        type: "Transportation",
        createdByName: i.createdByName,
        updatedByName: i.updatedByName,
      });
    });

    items.hotels.map((i) => {
      hotels.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        vendorName: i.venderName,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Hotel",
        createdDate:
          i.createdDate == null
            ? null
            : dayjs(i.createdDate).format("DD/MM/YYYY HH:mm"),
        createdBy: i.createdBy,
        updatedDate:
          i.updatedDate == null
            ? null
            : dayjs(i.updatedDate).format("DD/MM/YYYY HH:mm"),
        updatedBy: i.updatedBy,
        auditDate:
          i.auditDate == null
            ? null
            : dayjs(i.auditDate).format("DD/MM/YYYY HH:mm"),
        auditAction: i.auditAction,
        isPaymentVouchered: i.isPaymentVouchered,
        isInvoiced: i.isInvoiced,
        createdByName: i.createdByName,
        updatedByName: i.updatedByName,
      });
    });

    items.visa.map((i) => {
      visa.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        vendorName: i.vendorName,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Visa",
        createdDate:
          i.createdDate == null
            ? null
            : dayjs(i.createdDate).format("DD/MM/YYYY HH:mm"),
        createdBy: i.createdBy,
        updatedDate:
          i.updatedDate == null
            ? null
            : dayjs(i.updatedDate).format("DD/MM/YYYY HH:mm"),
        updatedBy: i.updatedBy,
        auditDate:
          i.auditDate == null
            ? null
            : dayjs(i.auditDate).format("DD/MM/YYYY HH:mm"),
        auditAction: i.auditAction,
        isPaymentVouchered: i.isPaymentVouchered,
        isInvoiced: i.isInvoiced,
        createdByName: i.createdByName,
        updatedByName: i.updatedByName,
      });
    });

    items.miscellaneous.map((i) => {
      miscellaneous.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        vendorName: i.vendorName,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Miscellaneous",
        createdDate:
          i.createdDate == null
            ? null
            : dayjs(i.createdDate).format("DD/MM/YYYY HH:mm"),
        createdBy: i.createdBy,
        updatedDate:
          i.updatedDate == null
            ? null
            : dayjs(i.updatedDate).format("DD/MM/YYYY HH:mm"),
        updatedBy: i.updatedBy,
        auditDate:
          i.auditDate == null
            ? null
            : dayjs(i.auditDate).format("DD/MM/YYYY HH:mm"),
        auditAction: i.auditAction,
        isPaymentVouchered: i.isPaymentVouchered,
        isInvoiced: i.isInvoiced,
        createdByName: i.createdByName,
        updatedByName: i.updatedByName,
      });
    });

    var returnItem = {
      batchJobCardItems: jobCards,
      batchTransportationItems: transportations,
      batchHotelItems: hotels,
      batchVisaItems: visa,
      batchMiscellaneousItems: miscellaneous,
      isSearch: true,
    };

    return returnItem;
  };

  GetFlightDetails = (flightNo, flightTime) => {
    var flightDetails = "";

    if (flightNo && flightNo != null && flightNo != "") {
      flightDetails = flightNo;

      if (flightTime && flightTime != null && flightTime != "") {
        flightDetails = `${flightNo} ${dayjs(flightTime).format("HH:mm")}`;
      }
    }

    return flightDetails;
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getReportItem();
    }
  };
  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">
                      <img
                        width={"50px"}
                        src="/vendors/images/history-report.gif"
                        alt=""
                        style={{ verticalAlign: "middle" }}
                      />{" "}
                      History Report
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="title">
                    <h4>Search</h4>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>From Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.effectiveDateFrom}
                            onChange={(e) =>
                              this.handleChange({
                                effectiveDateFrom: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      {this.state.reportTypeId == 2 && (
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>To Date</label>
                            <Calendar
                              dateFormat="dd/mm/yy"
                              style={{ width: "100%" }}
                              value={this.state.effectiveDateTo}
                              onChange={(e) =>
                                this.handleChange({
                                  effectiveDateTo: e.target.value,
                                })
                              }
                              showIcon
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>User</label>
                          <MultiSelect
                            value={this.state.userId}
                            onChange={(e) =>
                              this.handleChange({
                                userId: e.target.value,
                              })
                            }
                            display="chip"
                            options={this.state.users}
                            optionLabel="code"
                            placeholder="Select a User"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Job Card Number</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.jobCardCode}
                            onChange={(e) =>
                              this.handleChange({
                                jobCardCode: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-primary pull-right"
                            onClick={(e) => {
                              this.getReportItem();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Dialog
              maximizable
              style={{ width: "95vw" }}
              header={"History Report"}
              headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
              contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
              visible={this.state.isModalOpen}
              onHide={(e) => {
                this.setState({ isModalOpen: false });
              }}
            >
              <HistoryReport
                fullReport={this.state.fullReport}
                effectiveDateFrom={this.state.effectiveDateFrom}
                effectiveDateTo={this.state.effectiveDateTo}
              />
            </Dialog>
          </div>
        </div>
        {this.state.isLoading && <PreLoader />}
      </div>
    );
  }
}

export default HistoryReportWrap;
