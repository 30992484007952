import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import "../src/styles/style.css";
class TransportationCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transports: [],
      updateRecords: [],
      columns: [
        { field: "pickupTime", header: "Pickup Time" },
        { field: "pickupLocation", header: "Pickup Location" },
        { field: "dropoffLocation", header: "Drop Off Location" },
        { field: "totalPax", header: "Total PAX" },
        { field: "adults", header: "A" },
        { field: "children", header: "C" },
        { field: "infants", header: "I" },
        { field: "vehicleType", header: "Vehicle Type" },
        { field: "flightNo", header: "Flight No" },
        { field: "flightTime", header: "Flight Time" },
        { field: "paxName", header: "Pax Name" },
        { field: "remarks", header: "Remarks" },
      ],
      visibleColumns: [{ field: "pickupTime", header: "Pickup Time" },{ field: "paxName", header: "Pax Name" }],
    };
  }

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  updateCost = async (e) => {
    var payload = {
      UpdatedRecords: this.state.updateRecords,
    };
    const currentUser = localStorage.getItem('token');
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Job/UpdateCost`, payload,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setErrorMessage("Cost successfully updated", 1);
        this.props.getJobTransportations();
      });
  };

  cellEditor = (options) => {
    debugger
    var val=options.value && parseFloat(options.value)==0?"":options.value
    return (
      <InputNumber
        value={val}
        onValueChange={(e) => {
          debugger;
          options.editorCallback(e.value);
        }}
        mode="decimal"
        minFractionDigits="2"
      />
    );
  };

  cellCheckEditor = (options) => {
    debugger;
    return (
      <Checkbox
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  checkCostTemplate = (rowData) => {
    return rowData["isVatIncludedCost"] == 1 ? "Included" : "Not Included";
  };

  checkSellTemplate = (rowData) => {
    return rowData["isVatIncludedSell"] == 1 ? "Included" : "Not Included";
  };

  onCellEditComplete = (e) => {
    debugger;
    let { rowData, newValue, field, originalEvent: event } = e;

    if (newValue >= 0) {
      var newVal=newValue ? parseFloat(newValue) : 0;
      rowData[field] =  newVal.toFixed(2);
      this.reCalculate(rowData);
    } else {
      this.setErrorMessage("Please enter valid value", 3);
      return;
    }
  };

  onCellCheckEditComplete = (e) => {
    debugger;
    let { rowData, newValue, field, originalEvent: event } = e;

    rowData[field] = newValue;
    this.reCalculate(rowData);
  };

  reCalculate = (rowData) => {
    var vatCost = rowData["isVatIncludedCost"];
    var vatSell = rowData["isVatIncludedSell"];

    var netCost = rowData["costBaseAmount"];
    var netVat = vatCost ? parseFloat(netCost) * 0.05 : 0;
    rowData["costTaxAmount"] = netVat.toFixed(2);

    var parking = rowData["parking"];
    var parkingVat = vatCost ? parseFloat(parking) * 0.05 : 0;
    rowData["parkingTaxAmount"] = parkingVat.toFixed(2);

    var water = rowData["water"];
    var waterVat = vatCost ? parseFloat(water) * 0.05 : 0;
    rowData["waterTaxAmount"] = waterVat.toFixed(2);

    var extras = rowData["extras"];
    var extrasVat = vatCost ? parseFloat(extras) * 0.05 : 0;
    rowData["extrasTaxAmount"] = extrasVat.toFixed(2);

    var totalTax = parseFloat(netVat) + parseFloat(parkingVat)+ parseFloat(waterVat)+ parseFloat(extrasVat);
    rowData["totalCostTaxAmount"] = totalTax.toFixed(2);

    var totalCost =
      parseFloat(netCost) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras) +
      parseFloat(totalTax);
    rowData["totalCostPrice"] = totalCost.toFixed(2);

    ///////
    var netSell = rowData["sellBaseAmount"];
    var netTax = vatSell ? parseFloat(netSell) * 0.05 : 0;
    rowData["costTaxAmount"] = netTax.toFixed(2);

    var parkingSell = rowData["parkingSell"];
    var parkingVatSell = vatSell ? parseFloat(parkingSell) * 0.05 : 0;
    rowData["parkingTaxAmountSell"] = parkingVatSell.toFixed(2);

    var waterSell = rowData["waterSell"];
    var waterVatSell = vatSell ? parseFloat(waterSell) * 0.05 : 0;
    rowData["waterTaxAmountSell"] = waterVatSell.toFixed(2);

    var extrasSell = rowData["extrasSell"];
    var extrasVatSell = vatSell ? parseFloat(extrasSell) * 0.05 : 0;
    rowData["extrasTaxAmountSell"] = extrasVatSell.toFixed(2);

    var totalTaxSell = parseFloat(netTax) + parseFloat(parkingVatSell) + parseFloat(waterVatSell) + parseFloat(extrasVatSell);
    rowData["totalSellTaxAmount"] = totalTaxSell.toFixed(2);

    var totalSell =
      parseFloat(netSell) +
      parseFloat(parkingSell) +
      parseFloat(waterSell) +
      parseFloat(extrasSell) +
      parseFloat(totalTaxSell);
    rowData["totalSellPrice"] = totalSell.toFixed(2);

    this.state.updateRecords.push({
      id: rowData["id"],
      costBaseAmount: netCost,
      costTaxAmount: netVat,
      parking: parking,
      parkingTaxAmount: parkingVat,
      water: water,
      waterTaxAmount: waterVat,
      extras: extras,
      extrasTaxAmount: extrasVat,
      sellBaseAmount: netSell,
      sellTaxAmount: netTax,
      parkingSell: parkingSell,
      parkingTaxAmountSell: parkingVatSell,
      waterSell: waterSell,
      waterTaxAmountSell: waterVatSell,
      extrasSell: extrasSell,
      extrasTaxAmountSell: extrasVatSell,
      isVatIncludedCost: vatCost,
      isVatIncludedSell: vatSell,
    });
  };

  onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      visibleColumns: orderedSelectedColumns,
    });
  };

  accept = () => {
    this.updateCost();
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirm = () => {
    if (this.state.updateRecords.length > 0) {
      var accept = this.accept;
      var reject = this.reject;
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    } else {
      this.setErrorMessage("Please update any cost value", 2);
    }
  };
setColumnValue=(options)=>{
  debugger
  var isInvoiced=options && options.rowData && options.rowData.isInvoiced ? true:false;
  var val=options.value && parseFloat(options.value)==0?"0.00":options.value
  return !isInvoiced?this.cellEditor(options):val;
}
setCheckValue=(options)=>{
  debugger
  var isInvoiced=options && options.rowData && options.rowData.isInvoiced ? true:false;
  var val=options && options.value?"Included":"Not Included"
  return !isInvoiced?this.cellCheckEditor(options):val;
}
  render() {
    const header = (
      <MultiSelect
        value={this.state.visibleColumns}
        options={this.state.columns}
        optionLabel="header"
        onChange={this.onColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    return (
      <div>
        <DataTable
          stripedRows
          scrollable
          showGridlines
          header={header}
          value={this.props.transports}
          selectionMode="single"
          dataKey="id"
          metaKeySelection={false}
          sortMode="multiple"
          paginator
          rows={20}
          scrollHeight="600px"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="transportationCode"
            sortable
            header="Booking Ref"
          ></Column>
          {this.state.visibleColumns.map((col) => (
            <Column
              key={col.field}
              sortable
              field={col.field}
              header={col.header}
            />
          ))}
          
          <Column
            field="costBaseAmount"
            sortable
            header="Net Cost"
            editor={(options) => this.cellEditor(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
            field="parking"
            sortable
            header="Parking"
            editor={(options) => this.cellEditor(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
            field="water"
            sortable
            header="Water"
            editor={(options) => this.cellEditor(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
            field="extras"
            sortable
            header="Extras"
            editor={(options) => this.cellEditor(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
            field="isVatIncludedCost"
            sortable
            header="Cost VAT Included"
            editor={(options) => this.cellCheckEditor(options)}
            onCellEditComplete={this.onCellCheckEditComplete}
            body={this.checkCostTemplate}
          ></Column>
          <Column field="totalCostTaxAmount" sortable header="VAT"></Column>
          <Column
            field="totalCostPrice"
            sortable
            body={this.totalCostAmountBodyTemplate}
            header="Total Cost"
          ></Column>
        
          <Column 
            className="sellCol"
            field="sellBaseAmount"
            sortable
            body={this.sellBaseAmountBodyTemplate}
            editor={(options) => this.setColumnValue(options)}
            onCellEditComplete={this.onCellEditComplete}
            header="Net Sell"
          ></Column>
          <Column
          className="sellCol"
            field="parkingSell"
            sortable
            header="Parking"
            editor={(options) => this.setColumnValue(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
          className="sellCol"
            field="waterSell"
            sortable
            header="Water"
            editor={(options) => this.setColumnValue(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
          <Column
          className="sellCol"
            field="extrasSell"
            sortable
            header="Extras"
            editor={(options) => this.setColumnValue(options)}
            onCellEditComplete={this.onCellEditComplete}
          ></Column>
            <Column
            className="sellCol"
            field="isVatIncludedSell"
            sortable
            body={this.checkSellTemplate}
            header="Sell VAT Included"
            editor={(options) => this.setCheckValue(options)}
            onCellEditComplete={this.onCellCheckEditComplete}
          ></Column>
          <Column className="sellCol" field="totalSellTaxAmount" sortable header="VAT"></Column>
          <Column
          className="sellCol"
            field="totalSellPrice"
            sortable
            body={this.sellTotalAmountBodyTemplate}
            header="Total Sell"
          ></Column>
        </DataTable>
        <div className="form-group row">
          <div className="col-sm-12 col-md-12">
            <button
              type="button"
              style={{ margin: "10px" }}
              className="btn btn-primary pull-right"
              onClick={(e) => {
                this.confirm();
              }}
            >
              Submit
            </button>
          </div>
          <ConfirmDialog />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    );
  }
}

export default TransportationCost;
