import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import "react-toastify/dist/ReactToastify.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import PreLoader from "../components/shards/PreLoader";
class InvoiceGeneration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customerId: "",
      batchItems: [],
      proformaInvoices: [],
      expandedRows: [],
      rowClick: true,
      selectedTransportationItem: [],
      selectedHotelItem: [],
      selectedVisaItem: [],
      selectedMiscellaneousItem: [],
      selectedProformaInvoiceItem: [],
      invoiceDate: "",
      invoiceDueDate: "",
      invoiceAmount: "",
      remarks: "",
      transportDescription: "Transport services for ",
      hotelDescription: "Hotel services for ",
      visaDescription: "Visa services for ",
      miscellaneousDescription: "Miscellaneous services for ",
      fromDate: "",
      toDate: "",
      errorMessage: null,
      isModalOpen: false,
      isProformaInvoiceModalOpen: false,
      isSaveProcessing: false,
      batchTransportationItems: [],
      batchHotelItems: [],
      batchVisaItems: [],
      batchMiscellaneousItems: [],
      totalTransportationAmount: 0,
      totalHotelAmount: 0,
      totalVisaAmount: 0,
      totalMiscellaneousAmount: 0,
      jobCardNumber: "",
      isLoading: false,
      transferColumns: [
        { field: "jobCardNo", header: "Job Card No" },
        { field: "pickupLocation", header: "Pickup Location" },
        { field: "dropoffLocation", header: "Drop Off Location" },
        { field: "totalPax", header: "Total PAX" },
        { field: "vehicleType", header: "Vehicle Type" },
        { field: "paxName", header: "Pax Name" },
        { field: "remarks", header: "Remarks" },
      ],
      transferVisibleColumns: [
        { field: "jobCardNo", header: "Job Card No" },
        { field: "pickupLocation", header: "Pickup Location" },
        { field: "dropoffLocation", header: "Drop Off Location" },
        { field: "totalPax", header: "Total PAX" },
        { field: "vehicleType", header: "Vehicle Type" },
        { field: "paxName", header: "Pax Name" },
        { field: "remarks", header: "Remarks" },
      ],
      hotelColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "adults", header: "Adults" },
        { field: "children", header: "Children" },
        { field: "infants", header: "Infants" },
        { field: "hotelName", header: "Hotel Name" },
        { field: "checkIn", header: "Check-in" },
        { field: "checkOut", header: "Check-out" },
        { field: "remarks", header: "Remarks" },
      ],
      hotelVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "adults", header: "Adults" },
        { field: "children", header: "Children" },
        { field: "infants", header: "Infants" },
        { field: "hotelName", header: "Hotel Name" },
        { field: "checkIn", header: "Check-in" },
        { field: "checkOut", header: "Check-out" },
        { field: "remarks", header: "Remarks" },
      ],
      visaColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "passportNo", header: "Passport No" },
        { field: "visaTypeName", header: "Visa Type" },
        { field: "remarks", header: "Remarks" },
      ],
      visaVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "passportNo", header: "Passport No" },
        { field: "visaTypeName", header: "Visa Type" },
        { field: "remarks", header: "Remarks" },
      ],
      miscellaneousColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "description", header: "Description" },
        { field: "misDate", header: "Date & Time" },
        { field: "remarks", header: "Remarks" },
      ],
      miscellaneousVisibleColumns: [
        { field: "paxName", header: "Pax Name" },
        { field: "description", header: "Description" },
        { field: "misDate", header: "Date & Time" },
        { field: "remarks", header: "Remarks" },
      ],
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
        });
      });
  };

  getInvoiceItems = async () => {
    this.setState({
      isLoading: true,
    });
    if (!this.state.customerId || this.state.customerId == "") {
      this.setState({
        isLoading: false,
      });
      this.setErrorMessage("Please select Customer", 3);
      return;
    }
    this.setState({
      batchItems: [],
    });
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    const proformaInvoiceList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetJobCardItemsByCustomerId?` +
        new URLSearchParams({
          customerId: this.state.customerId,
          fromDate: this.state.fromDate
            ? new Date(this.state.fromDate).toDateString()
            : "",
          toDate: this.state.toDate
            ? new Date(this.state.toDate).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.lineGenerateItem.transportations.map((i) => {
          batchTransportationItems.push({
            id: i.id,
            batchNo: i.batchNo,
            jobCardNo: i.jobCardNo,
            jobCardTitle: i.jobCardTitle,
            bookingRef: i.bookingRef,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalCostTaxAmount:
              i.totalCostTaxAmount == null
                ? null
                : i.totalCostTaxAmount.toFixed(2),
            extras: i.extras == null ? null : i.extras.toFixed(2),
            parking: i.parking == null ? null : i.parking.toFixed(2),
            water: i.water == null ? null : i.water.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
            extrasSell: i.extrasSell == null ? null : i.extrasSell.toFixed(2),
            parkingSell:
              i.parkingSell == null ? null : i.parkingSell.toFixed(2),
            waterSell: i.waterSell == null ? null : i.waterSell.toFixed(2),
          });
        });

        response.lineGenerateItem.hotels.map((i) => {
          batchHotelItems.push({
            id: i.id,
            hotelCode: i.hotelCode,
            hotelName: i.hotelName,
            checkIn:
              i.checkIn == null
                ? null
                : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
            checkOut:
              i.checkOut == null
                ? null
                : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            paxName: i.paxName,
            remarks: i.remarks,
            hotelConfirmation: i.hotelConfirmation,
            roomType: i.roomType,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.totalCostPrice.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
          });
        });

        response.lineGenerateItem.visa.map((i) => {
          batchVisaItems.push({
            id: i.id,
            visaCode: i.visaCode,
            passportNo: i.passportNo,
            visaTypeName: i.visaTypeName,
            paxName: i.paxName,
            remarks: i.remarks,
            nationality: i.nationality,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.totalCostPrice.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
          });
        });

        response.lineGenerateItem.miscellaneous.map((i) => {
          batchMiscellaneousItems.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            paxName: i.paxName,
            paxNumber: i.paxNumber,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
            remarks: i.remarks,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.totalCostPrice.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
          });
        });

        debugger;
        response.proformaInvoices.map((i) => {
          proformaInvoiceList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            jobCardNo: i.jobCardNo,
            invoiceAmount:
              i.invoiceAmount == null ? null : i.invoiceAmount.toFixed(2),
            totalReciptAmount:
              i.totalReciptAmount == null
                ? null
                : i.totalReciptAmount.toFixed(2),
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
          });
        });
        this.setState({
          isLoading: false,
        });
        this.setState({
          batchTransportationItems: batchTransportationItems,
          batchHotelItems: batchHotelItems,
          batchVisaItems: batchVisaItems,
          batchMiscellaneousItems: batchMiscellaneousItems,
          proformaInvoices: proformaInvoiceList,
          isSearch: true,
        });
      });
  };

  totalCostAmountBodyTemplate = (rowData) => {
    return <b>{rowData.totalCostPrice}</b>;
  };

  sellBaseAmountBodyTemplate = (rowData) => {
    return <b>{rowData.sellBaseAmount}</b>;
  };
  sellTotalAmountBodyTemplate = (rowData) => {
    return <b>{rowData.totalSellPrice}</b>;
  };

  handleChange(changeObject) {
    this.setState(changeObject);
  }
  handleSelectChange(items) {
    var totalSellPrice = items.reduce((a, v) => (a = a + v.totalSellPrice), 0);
    this.setState({
      selectedItem: items,
      invoiceAmount: totalSellPrice,
    });
  }
  validation = () => {
    debugger;
    if (
      (this.state.selectedTransportationItem == null ||
        this.state.selectedTransportationItem.length == 0) &&
      (this.state.selectedHotelItem == null ||
        this.state.selectedHotelItem.length == 0) &&
      (this.state.selectedVisaItem == null ||
        this.state.selectedVisaItem.length == 0) &&
      (this.state.selectedMiscellaneousItem == null ||
        this.state.selectedMiscellaneousItem.length == 0)
    ) {
      this.setErrorMessage("Please select item from grid", 3);
      return false;
    }
    if (this.state.customerId == "") {
      this.setErrorMessage("Please select Customer", 3);
      return false;
    }
    if (this.state.invoiceDate == "") {
      this.setErrorMessage("Please select invoice date", 3);
      return false;
    }

    if (this.state.invoiceDueDate == "") {
      this.setErrorMessage("Please select invoice due date", 3);
      return false;
    }

    if (this.state.invoiceAmount <= 0) {
      this.setErrorMessage("Invoice amount should greater than 0", 3);
      return false;
    }
    return true;
  };
  saveInvoice = async (e) => {
    this.setState({
      isLoading: true,
    });
    this.setState({
      isSaveProcessing: true,
    });
    if (!this.validation()) {
      this.setState({
        isLoading: false,
      });
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }

    var payload = {
      TransportationIds: this.state.selectedTransportationItem.map((o) => o.id),
      HotelIds: this.state.selectedHotelItem.map((o) => o.id),
      VisaIds: this.state.selectedVisaItem.map((o) => o.id),
      MiscellaneousIds: this.state.selectedMiscellaneousItem.map((o) => o.id),

      CustomerId: this.state.customerId,
      InvoiceDate: this.state.invoiceDate.toLocaleString(),
      InvoiceDueDate: this.state.invoiceDueDate.toLocaleString(),
      InvoiceAmount: this.state.invoiceAmount,
      Remarks: this.state.remarks,
      TransportDescription: this.state.transportDescription,
      HotelDescription: this.state.hotelDescription,
      VisaDescription: this.state.visaDescription,
      MiscellaneousDescription: this.state.miscellaneousDescription,
      ProformaInvoices: this.state.selectedProformaInvoiceItem.map((o) => o.id),
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SaveInvoice`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.setErrorMessage("Invoice generated", 1);

        this.setState({
          isSaveProcessing: false,
          selectedTransportationItem: null,
          selectedHotelItem: null,
          selectedVisaItem: null,
          selectedMiscellaneousItem: null,
          batchItems: [],
          expandedRows: [],
          proformaInvoices: [],
          customerId: "",
          invoiceDueDate: "",
          invoiceDate: "",
          invoiceAmount: "",
          remarks: "",
          transportDescription: "Transport services for ",
          hotelDescription: "Hotel services for ",
          visaDescription: "Visa services for ",
          miscellaneousDescription: "Miscellaneous services for ",
          fromDate: "",
          toDate: "",
          selectedProformaInvoiceItem: [],
          isProformaInvoiceModalOpen: false,
          batchTransportationItems: [],
          batchHotelItems: [],
          batchVisaItems: [],
          batchMiscellaneousItems: [],
          totalTransportationAmount: 0,
          totalHotelAmount: 0,
          totalVisaAmount: 0,
          totalMiscellaneousAmount: 0,
        });
      });
  };

  checkProformaInvoice = async (e) => {
    debugger;
    if (this.state.proformaInvoices && this.state.proformaInvoices.length > 0) {
      this.setState({
        isSaveProcessing: false,
        isProformaInvoiceModalOpen: true,
      });
      return;
    }

    this.saveInvoice();
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          <b>{data.jobCardTitle}</b>
        </span>
      </React.Fragment>
    );
  };

  accept = () => {
    this.checkProformaInvoice();
  };

  acceptInvoice = () => {
    this.saveInvoice();
  };

  acceptSkipInvoice = () => {
    this.setState({
      selectedProformaInvoiceItem: [],
    });
    this.saveInvoice();
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirm = () => {
    var accept = this.accept;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  handleTransportationSelectChange(items) {
    var totalSellPrice = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalSellPrice)),
      0
    );
    var InvoiceAmount =
      totalSellPrice +
      this.state.totalHotelAmount +
      this.state.totalVisaAmount +
      this.state.totalMiscellaneousAmount;
    this.setState({
      selectedTransportationItem: items,
      totalTransportationAmount: totalSellPrice,
      invoiceAmount: InvoiceAmount.toFixed(2),
    });
  }

  handleHotelSelectChange(items) {
    var totalSellPrice = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalSellPrice)),
      0
    );
    var InvoiceAmount =
      totalSellPrice +
      this.state.totalTransportationAmount +
      this.state.totalVisaAmount +
      this.state.totalMiscellaneousAmount;
    this.setState({
      selectedHotelItem: items,
      totalHotelAmount: totalSellPrice,
      invoiceAmount: InvoiceAmount.toFixed(2),
    });
  }

  handleVisaSelectChange(items) {
    var totalSellPrice = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalSellPrice)),
      0
    );
    var InvoiceAmount =
      totalSellPrice +
      this.state.totalTransportationAmount +
      this.state.totalHotelAmount +
      this.state.totalMiscellaneousAmount;
    this.setState({
      selectedVisaItem: items,
      totalVisaAmount: totalSellPrice,
      invoiceAmount: InvoiceAmount.toFixed(2),
    });
  }

  handleMiscellaneousSelectChange(items) {
    var totalSellPrice = items.reduce(
      (a, v) => (a = a + parseFloat(v.totalSellPrice)),
      0
    );
    var InvoiceAmount =
      totalSellPrice +
      this.state.totalTransportationAmount +
      this.state.totalHotelAmount +
      this.state.totalVisaAmount;
    this.setState({
      selectedMiscellaneousItem: items,
      totalMiscellaneousAmount: totalSellPrice,
      invoiceAmount: InvoiceAmount.toFixed(2),
    });
  }

  confirmInvoice = () => {
    var accept = this.acceptInvoice;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  skipInvoice = () => {
    var accept = this.acceptSkipInvoice;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getInvoiceItems();
    }
  };

  onTransferColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.transferColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      transferVisibleColumns: orderedSelectedColumns,
    });
  };

  onHotelColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.hotelColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      hotelVisibleColumns: orderedSelectedColumns,
    });
  };

  onVisaColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.visaColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      visaVisibleColumns: orderedSelectedColumns,
    });
  };

  onMisColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.miscellaneousColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    this.setState({
      miscellaneousVisibleColumns: orderedSelectedColumns,
    });
  };
  render() {
    const transferHeader = (
      <MultiSelect
        value={this.state.transferVisibleColumns}
        options={this.state.transferColumns}
        optionLabel="header"
        onChange={this.onTransferColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const hotelHeader = (
      <MultiSelect
        value={this.state.hotelVisibleColumns}
        options={this.state.hotelColumns}
        optionLabel="header"
        onChange={this.onHotelColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const visaHeader = (
      <MultiSelect
        value={this.state.visaVisibleColumns}
        options={this.state.visaColumns}
        optionLabel="header"
        onChange={this.onVisaColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );

    const misHeader = (
      <MultiSelect
        value={this.state.miscellaneousVisibleColumns}
        options={this.state.miscellaneousColumns}
        optionLabel="header"
        onChange={this.onMisColumnToggle}
        className="w-full sm:w-20rem"
        display="chip"
      />
    );
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/payment.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          Generate Tax Invoice
                        </h2>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Customer</label>
                          <Dropdown
                            value={this.state.customerId}
                            onChange={(e) =>
                              this.handleChange({ customerId: e.value })
                            }
                            options={this.state.customers}
                            placeholder="Select a Customer"
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">From Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.fromDate}
                            onChange={(e) =>
                              this.handleChange({
                                fromDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">To Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.toDate}
                            onChange={(e) =>
                              this.handleChange({
                                toDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Job Card Number</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.jobCardNumber}
                            onChange={(e) =>
                              this.handleChange({
                                jobCardNumber: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary pull-right"
                            onClick={(e) => {
                              this.getInvoiceItems(e);
                            }}
                          >
                            Get Items
                          </button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Accordion activeIndex={0}>
                        <AccordionTab header="Transportation">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                header={transferHeader}
                                scrollable
                                value={this.state.batchTransportationItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={
                                  this.state.selectedTransportationItem
                                }
                                onSelectionChange={(e) =>
                                  this.handleTransportationSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="bookingRef"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                <Column
                                  field="pickupTime"
                                  sortable
                                  header="Pickup Time"
                                ></Column>
                                {this.state.transferVisibleColumns.map(
                                  (col) => (
                                    <Column
                                      key={col.field}
                                      sortable
                                      field={col.field}
                                      header={col.header}
                                    />
                                  )
                                )}
                                <Column
                                  field="costBaseAmount"
                                  sortable
                                  header="Net Cost"
                                ></Column>
                                <Column
                                  field="parking"
                                  sortable
                                  header="Parking"
                                ></Column>
                                <Column
                                  field="water"
                                  sortable
                                  header="Water"
                                ></Column>
                                <Column
                                  field="extras"
                                  sortable
                                  header="Extras"
                                ></Column>
                                <Column
                                  field="totalCostTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  body={this.totalCostAmountBodyTemplate}
                                  header="Total Cost"
                                ></Column>

                                <Column
                                  field="sellBaseAmount"
                                  sortable
                                  header="Net Sell"
                                  body={this.sellBaseAmountBodyTemplate}
                                ></Column>
                                <Column
                                  field="parkingSell"
                                  sortable
                                  header="Parking"
                                ></Column>
                                <Column
                                  field="waterSell"
                                  sortable
                                  header="Water"
                                ></Column>
                                <Column
                                  field="extrasSell"
                                  sortable
                                  header="Extras"
                                ></Column>
                                <Column
                                  field="totalSellTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalSellPrice"
                                  sortable
                                  body={this.sellTotalAmountBodyTemplate}
                                  header="Total Sell"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Hotel">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={hotelHeader}
                                value={this.state.batchHotelItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedHotelItem}
                                onSelectionChange={(e) =>
                                  this.handleHotelSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="hotelCode"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                {this.state.hotelVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="costBaseAmount"
                                  sortable
                                  header="Net Cost"
                                ></Column>
                                <Column
                                  field="costTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  body={this.totalCostAmountBodyTemplate}
                                  header="Total Cost"
                                ></Column>

                                <Column
                                  field="sellBaseAmount"
                                  sortable
                                  header="Net Sell"
                                  body={this.sellBaseAmountBodyTemplate}
                                ></Column>
                                <Column
                                  field="sellTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalSellPrice"
                                  sortable
                                  body={this.sellTotalAmountBodyTemplate}
                                  header="Total Sell"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Visa">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={visaHeader}
                                value={this.state.batchVisaItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedVisaItem}
                                onSelectionChange={(e) =>
                                  this.handleVisaSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="visaCode"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                {this.state.visaVisibleColumns.map((col) => (
                                  <Column
                                    key={col.field}
                                    sortable
                                    field={col.field}
                                    header={col.header}
                                  />
                                ))}
                                <Column
                                  field="costBaseAmount"
                                  sortable
                                  header="Net Cost"
                                ></Column>
                                <Column
                                  field="costTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  body={this.totalCostAmountBodyTemplate}
                                  header="Total Cost"
                                ></Column>
                                <Column
                                  field="sellBaseAmount"
                                  sortable
                                  header="Net Sell"
                                  body={this.sellBaseAmountBodyTemplate}
                                ></Column>
                                <Column
                                  field="sellTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalSellPrice"
                                  sortable
                                  body={this.sellTotalAmountBodyTemplate}
                                  header="Total Sell"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                        <AccordionTab header="Miscellaneous">
                          <div className="card-box mb-30">
                            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                            <div className="pb-20">
                              <DataTable
                                stripedRows
                                showGridlines
                                scrollable
                                header={misHeader}
                                value={this.state.batchMiscellaneousItems}
                                dataKey="id"
                                metaKeySelection={false}
                                sortMode="multiple"
                                paginator
                                rows={20}
                                selectionMode={"checkbox"}
                                selection={this.state.selectedMiscellaneousItem}
                                onSelectionChange={(e) =>
                                  this.handleMiscellaneousSelectChange(e.value)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                              >
                                <Column
                                  selectionMode="multiple"
                                  style={{ minWidth: "10px" }}
                                ></Column>
                                <Column
                                  field="miscellaneousCode"
                                  sortable
                                  header="Booking Ref"
                                ></Column>
                                {this.state.miscellaneousVisibleColumns.map(
                                  (col) => (
                                    <Column
                                      key={col.field}
                                      sortable
                                      field={col.field}
                                      header={col.header}
                                    />
                                  )
                                )}
                                <Column
                                  field="costBaseAmount"
                                  sortable
                                  header="Net Cost"
                                ></Column>
                                <Column
                                  field="costTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalCostPrice"
                                  sortable
                                  body={this.totalCostAmountBodyTemplate}
                                  header="Total Cost"
                                ></Column>

                                <Column
                                  field="sellBaseAmount"
                                  sortable
                                  header="Net Sell"
                                  body={this.sellBaseAmountBodyTemplate}
                                ></Column>
                                <Column
                                  field="sellTaxAmount"
                                  sortable
                                  header="VAT"
                                ></Column>
                                <Column
                                  field="totalSellPrice"
                                  sortable
                                  body={this.sellTotalAmountBodyTemplate}
                                  header="Total Sell"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </form>
                </div>

                <div>
                  <div className="card-box pt-30 mb-30">
                    <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                    <div className="pb-20">
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Amount</label>
                              <input
                                className="form-control"
                                type="number"
                                readOnly
                                min="0"
                                value={this.state.invoiceAmount}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceAmount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDate: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Due Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDueDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDueDate: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Remarks</label>
                              <textarea
                                className="form-control"
                                value={this.state.remarks}
                                onChange={(e) =>
                                  this.handleChange({ remarks: e.target.value })
                                }
                              ></textarea>
                            </div>
                          </div>
                          {this.state.selectedTransportationItem &&
                            this.state.selectedTransportationItem.length >
                              0 && (
                              <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                  <label className="col-12">
                                    Description for Transportation
                                  </label>
                                  <textarea
                                    className="form-control"
                                    value={this.state.transportDescription}
                                    onChange={(e) =>
                                      this.handleChange({
                                        transportDescription: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          {this.state.selectedHotelItem &&
                            this.state.selectedHotelItem.length > 0 && (
                              <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                  <label className="col-12">
                                    Description for Hotel
                                  </label>
                                  <textarea
                                    className="form-control"
                                    value={this.state.hotelDescription}
                                    onChange={(e) =>
                                      this.handleChange({
                                        hotelDescription: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          {this.state.selectedVisaItem &&
                            this.state.selectedVisaItem.length > 0 && (
                              <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                  <label className="col-12">
                                    Description for Visa
                                  </label>
                                  <textarea
                                    className="form-control"
                                    value={this.state.visaDescription}
                                    onChange={(e) =>
                                      this.handleChange({
                                        visaDescription: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          {this.state.selectedMiscellaneousItem &&
                            this.state.selectedMiscellaneousItem.length > 0 && (
                              <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                  <label className="col-12">
                                    Description for Miscellaneous
                                  </label>
                                  <textarea
                                    className="form-control"
                                    value={this.state.miscellaneousDescription}
                                    onChange={(e) =>
                                      this.handleChange({
                                        miscellaneousDescription:
                                          e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 col-md-12">
                            <Button
                              type="button"
                              label="Submit"
                              loading={this.state.isSaveProcessing}
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.confirm();
                              }}
                            />
                          </div>
                          <ConfirmDialog />
                          <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Dialog
                  maximizable
                  headerStyle={{
                    backgroundColor: "#fd6511",
                    color: "#FFF",
                  }}
                  contentStyle={{
                    padding: "25px 1.5rem 2rem 1.5rem",
                  }}
                  header="Link Proforma Invoices"
                  visible={this.state.isProformaInvoiceModalOpen}
                  style={{ width: "80vw" }}
                  onHide={(e) => {
                    this.setState({
                      isProformaInvoiceModalOpen: false,
                    });
                  }}
                >
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.proformaInvoices}
                      selectionMode={"checkbox"}
                      selection={this.state.selectedProformaInvoiceItem}
                      onSelectionChange={(e) => {
                        this.setState({
                          selectedProformaInvoiceItem: e.value,
                        });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                      ></Column>
                      <Column
                        field="invoiceDate"
                        sortable
                        header="Invoice Date"
                      ></Column>
                      <Column
                        field="invoiceCode"
                        sortable
                        header="Invoice Number"
                      ></Column>
                      <Column
                        field="jobCardNo"
                        sortable
                        header="Job Card No"
                      ></Column>
                      <Column
                        field="invoiceAmount"
                        sortable
                        header="Invoice Amount"
                      ></Column>
                      <Column
                        field="totalReciptAmount"
                        sortable
                        header="Total Receipt Amount"
                      ></Column>
                    </DataTable>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12">
                        <Button
                          type="button"
                          label="Save"
                          loading={this.state.isSaveProcessing}
                          className="col-3 btn btn-primary pull-right"
                          style={{ margin: "10px" }}
                          onClick={(e) => this.confirmInvoice()}
                        />
                        <Button
                          type="button"
                          label=" Skip and Save"
                          loading={this.state.isSaveProcessing}
                          className="col-3 btn btn-warning pull-right"
                          style={{ margin: "10px" }}
                          onClick={(e) => this.skipInvoice()}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default InvoiceGeneration;
