import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import LeftSlider from "./LeftSlider";
import { Menubar } from "primereact/menubar";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  handleChange(changeObject) {
    this.setState(changeObject);
  }
  render() {
    const itemsFinance = [
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        url: "/",
      },
      {
        label: "Logistics",
        icon: "pi pi-fw pi-truck",
        items: [
          {
            label: "View Batches",
            url: "/view_batches",
            icon: "pi pi-fw pi-search",
          },
          {
            label: "Create Batch",
            url: "/batch_process",
            icon: "pi pi-fw pi-cog",
          },
        ],
      },
      {
        label: "Job Cards",
        icon: "pi pi-fw pi-paperclip",
        items: [
          {
            label: "View Job Cards",
            url: "/view_job_cards",
            icon: "pi pi-fw pi-search",
          },
          {
            label: "Add New Job Card",
            url: "/add_job_card",
            icon: "pi pi-fw pi-file-edit",
          },
        ],
      },
      {
        label: "Financials",
        icon: "pi pi-fw pi-dollar",
        items: [
          {
            label: "Update Cost",
            icon: "pi pi-fw pi-pencil",
            url: "/cost_update",
          },
          {
            label: "Generate Payment Voucher",
            icon: "pi pi-fw pi-cog",
            url: "/generate_payment_voucher",
          },
          {
            label: "View Payment Voucher",
            icon: "pi pi-fw pi-search",
            url: "/view_payment_voucher",
          },
          {
            label: "Generate Proforma Invoice",
            icon: "pi pi-fw pi-cog",
            url: "/generate_proforma_invoice",
          },
          {
            label: "View Proforma Invoices",
            icon: "pi pi-fw pi-search",
            url: "/view_proforma_invoices",
          },
          {
            label: "Generate Tax Invoice",
            icon: "pi pi-fw pi-cog",
            url: "/generate_invoice",
          },
          {
            label: "View Invoices",
            icon: "pi pi-fw pi-search",
            url: "/view_invoices",
          }
        ],
      },
      
      {
        label: "Report",
        icon: "pi pi-fw pi-file-pdf",
        items: [
          
          {
            label: "Daily and Full Report",
            icon: "pi pi-fw pi-file",
            url: "/daily_full_report",
          },
          {
            label: "Cost Update Report",
            icon: "pi pi-fw pi-file",
            url: "/cost_report",
          },
          {
            label: "Invoice Report",
            icon: "pi pi-fw pi-file",
            url: "/invoice_report",
          },          
          {
            label: "Un-Invoiced Report",
            icon: "pi pi-fw pi-file",
            url: "/un_invoice_report",
          },
          {
            label: "VAT Report",
            icon: "pi pi-fw pi-file",
            url: "/vat_report",
          },
          {
            label: "Profit and Loss Report",
            icon: "pi pi-fw pi-file",
            url: "/pnl_report",
          },
          {
            label: "History Report",
            icon: "pi pi-fw pi-file",
            url: "/history_report",
          },
        ],
      },
      {
        label: "Master Data",
        icon: "pi pi-fw pi-wrench",
        items: [
          {
            label: "View Customer List",
            icon: "pi pi-fw pi-briefcase",
            url: "/view_customers",
          },
          {
            label: "View Vendor List",
            icon: "pi pi-fw pi-truck",
            url: "/view_vendors",
          },
          {
            label: "View User List",
            icon: "pi pi-fw pi-users",
            url: "/view_users",
          },
          {
            label: "View Events",
            icon: "pi pi-fw pi-calendar-times",
            url: "/view_events",
          },
        ],
      },
    ];

    const itemsOther = [
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        url: "/",
      },
      {
        label: "Logistics",
        icon: "pi pi-fw pi-truck",
        items: [
          {
            label: "View Batches",
            url: "/view_batches",
            icon: "pi pi-fw pi-search",
          },
          {
            label: "Create Batch",
            url: "/batch_process",
            icon: "pi pi-fw pi-cog",
          },
        ],
      },
      {
        label: "Job Cards",
        icon: "pi pi-fw pi-paperclip",
        items: [
          {
            label: "View Job Cards",
            url: "/view_job_cards",
            icon: "pi pi-fw pi-search",
          },
          {
            label: "New Job Cards",
            url: "/add_job_card",
            icon: "pi pi-fw pi-file-edit",
          },
        ],
      },
      {
        label: "Financials",
        icon: "pi pi-fw pi-dollar",
        items: [
          {
            label: "Update Cost",
            icon: "pi pi-fw pi-pencil",
            url: "/cost_update",
          }
        ],
      },
      
      {
        label: "Report",
        icon: "pi pi-fw pi-file-pdf",
        items: [
          
          {
            label: "Daily and Full Report",
            icon: "pi pi-fw pi-file",
            url: "/daily_full_report",
          },
          {
            label: "Cost Update Report",
            icon: "pi pi-fw pi-file",
            url: "/cost_report",
          }
        ],
      },
      
    ];
    const roleId = localStorage.getItem('roleId');
    const userName = localStorage.getItem('userName');
    return (
      <div className="header">
        <div className="header-left">
          <div className="brand-logo">
            <a href="/">
              <img
                src="/vendors/images/holiday_makers_logo.jpg"
                alt=""
                className="dark-logo"
              />
              <img
                src="/vendors/images/holiday_makers_logo.jpg"
                alt=""
                className="light-logo"
              />
            </a>
          </div>

          {roleId==1?(<Menubar model={itemsFinance} />):(<Menubar model={itemsOther} />)}
        </div>
        <div className="header-right">
          <div className="user-info-dropdown" style={{paddingRight:"35px"}}>
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
              >
                {/* <span className="user-icon">
                  <img src="/vendors/images/photos1.jpg" alt="" />
                </span> */}
                <span className="user-name">{userName}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                {/* <a className="dropdown-item" href="profile.html">
                  <i className="dw dw-user1"></i> Profile
                </a> */}
                {/* <a className="dropdown-item" href="profile.html">
                  <i className="dw dw-settings2"></i> Setting
                </a>
                <a className="dropdown-item" href="faq.html">
                  <i className="dw dw-help"></i> Help
                </a> */}
                <a className="dropdown-item" href="/login">
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
          {/* <div className="github-link">
            <a href="#" target="_blank">
              <img src="vendors/images/github.svg" alt="" />
            </a>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Header;
