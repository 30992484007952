import React from "react";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from 'primereact/button';
import "react-toastify/dist/ReactToastify.css";
class AddReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      paymentMethod: "",
      amountOriginal: "",
      amount: "",
      remarks: "",
      receiptDate: "",
      errorMessage: "",
      isError: false,
      paymentMethods: [],
      updateRecords: [],
      lineItems: [],
      lineItemsOrginal: [],
      selectedReceiptItem: [],
      isAutoAllocation: false,
      receiptAmountBalance: "",
      isSaveProcessing:false,
      isRemoveProcessing:false
    };
  }
  componentDidMount = async () => {
    await this.getReferenceData();
    if (this.props.receipt) {
      this.setState({
        id: this.props.receipt.id,
        paymentMethod: this.props.receipt.paymentMethodId,
        amount: this.props.receipt.amount,
        amountOriginal: this.props.receipt.amount,
        receiptAmountBalance:this.props.receipt.amount,
        receiptDate: new Date(this.props.receipt.receiptDate),
        remarks: this.props.receipt.remark,
      });
    }
    debugger
    const invoiceLineItems = [];

    this.props.invoice.invoiceLineItems.batchTransportationItems.map((i) => {
      invoiceLineItems.push({
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        id: i.id,
        totalSellPrice: i.totalSellPrice,
        receiptBalanceAmount: i.receiptBalanceAmount,
        totalReceiptAmount: i.totalReceiptAmount,
        receiptAmount: i.receiptAmount,
        type: "Transportation",
      });
    });

    this.props.invoice.invoiceLineItems.batchHotelItems.map((i) => {
      invoiceLineItems.push({
        id: i.id,
        bookingRef: i.hotelCode,
        jobCardNo: i.jobCardNo,
        totalSellPrice: i.totalSellPrice,
        receiptBalanceAmount: i.receiptBalanceAmount,
        totalReceiptAmount: i.totalReceiptAmount,
        receiptAmount: i.receiptAmount,
        type: "Hotel",
      });
    });

    this.props.invoice.invoiceLineItems.batchVisaItems.map((i) => {
      invoiceLineItems.push({
        id: i.id,
        bookingRef: i.visaCode,
        jobCardNo: i.jobCardNo,
        totalSellPrice: i.totalSellPrice,
        receiptBalanceAmount: i.receiptBalanceAmount,
        totalReceiptAmount: i.totalReceiptAmount,
        receiptAmount: i.receiptAmount,
        type: "Visa",
      });
    });

    this.props.invoice.invoiceLineItems.batchMiscellaneousItems.map((i) => {
      invoiceLineItems.push({
        id: i.id,
        bookingRef: i.miscellaneousCode,
        jobCardNo: i.jobCardNo,
        totalSellPrice: i.totalSellPrice,
        receiptBalanceAmount: i.receiptBalanceAmount,
        totalReceiptAmount: i.totalReceiptAmount,
        receiptAmount: i.receiptAmount,
        type: "Miscellaneous",
      });
    });
    debugger
    this.setState({
      lineItems: invoiceLineItems,
      lineItemsOrginal: invoiceLineItems,
    });
  };
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          paymentMethods: response.paymentMethods,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  validation = () => {
    if (this.state.receiptDate == "") {
      this.setErrorMessage("Please select receipt date", 3);
      return false;
    }
    if (this.state.paymentMethod == "") {
      this.setErrorMessage("Please select payment method", 3);
      return false;
    }
    if (this.state.amount == "") {
      this.setErrorMessage("Please enter receipt amount", 3);
      return false;
    }

    return true;
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  saveReceipt = async (e) => {
    this.setState({
      isSaveProcessing:true
    })
    if (!this.validation()) {
      this.setState({
        isSaveProcessing:false
      })
      return;
    }
    var payload = {
      Id: this.state.id,
      ReceiptDate: this.state.receiptDate,
      Amount: this.state.amount,
      PaymentMethod: this.state.paymentMethod,
      Remark: this.state.remarks,
      InvoiceId: this.props.invoice.id,
      UpdateRecords: this.state.updateRecords,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SaveReceipt`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            isSaveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isSaveProcessing:false
        })
        this.setErrorMessage("Save Successfully", 1);
        window.location.reload();
      });
  };

  removeReceipt = async (e) => {
    this.setState({
      isRemoveProcessing:true
    })
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/RemoveProformaInvoiceReceipt`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            isRemoveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isRemoveProcessing:false
        })
        this.setErrorMessage("Delete Successfully", 1);
        window.location.reload();
      });
  };

  openAllocations = () => {
    if (!this.validation()) {
      return;
    }
    this.setState({
      isModalOpen: true,
      receiptAmountBalance: this.state.amount,
      lineItems: this.state.lineItemsOrginal,
    });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirm = () => {
    var accept = this.openAllocations;
    var reject = this.reject;

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  confirmAllocation = () => {
    var accept = this.saveReceipt;
    var reject = this.reject;

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  confirmRemove = () => {
    var accept = this.removeReceipt;
    var reject = this.reject;

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  cellEditor = (options) => {
    debugger
    var record=options.value;
    var receiptAmount = this.state.receiptAmountBalance;
    var balanceAmount=options.rowData["receiptBalanceAmount"];
    if(receiptAmount<=0){
      record=0;
    }
    if(options.value==0){
      
      if(receiptAmount>0 && receiptAmount>=balanceAmount){
        record=balanceAmount;
      }
      else if(receiptAmount>0 && balanceAmount>receiptAmount){
        record=receiptAmount;
      }
    }
    debugger
    return (
      <InputNumber
        readOnly={this.state.isAutoAllocation}
        value={record}
       /*  min={this.state.receiptAmountBalance}
        max={balanceAmount} */
        onValueChange={(e) => {
          options.editorCallback(e.value);         
        }}
        mode="decimal"
        minFractionDigits="2"
      />
    );
  };
 
  onCellEditComplete = (e) => {
    debugger;
    if(this.state.receiptAmountBalance<=0){
      return
    }
    let { rowData, newValue, field, originalEvent: event } = e;

    if (newValue && newValue!=null && newValue >= 0) {
      var receiptBalanceAmount = parseFloat(rowData["receiptBalanceAmount"]);
      if(receiptBalanceAmount>=newValue){
        rowData[field] = newValue.toFixed(2);
      }
      else{
        rowData[field]=receiptBalanceAmount.toFixed(2);
      }
      this.reCalculate(rowData);
    } else {
      this.setErrorMessage("Please enter valid value", 3);
      return;
    }
  };

  reCalculate = (rowData) => {
    debugger
    /* var totalSellPrice = rowData["totalSellPrice"];
    var totalReceiptAmount = rowData["totalReceiptAmount"];
    var totalReceiptAmount = rowData["receiptAmount"];

    var receiptBalance = totalSellPrice - totalReceiptAmount;

    rowData["receiptBalanceAmount"] = receiptBalance.toFixed(2); */
    if(this.state.receiptAmountBalance<=0){
      return
    }
    var receiptAllocatedAmount = rowData["receiptAmount"];
    /* var receiptBalance =
      this.state.receiptAmountBalance - receiptAllocatedAmount; */

    this.state.updateRecords.push({
      id: rowData["id"],
      type:rowData["type"],
      allocatedAmount: receiptAllocatedAmount,
    });
    debugger
    var receiptAmount =0;
    this.state.lineItems.map(i=>{
      receiptAmount=receiptAmount+parseFloat(i.receiptAmount)
    })

     var receiptBalance =
      this.state.amount - receiptAmount;

    this.setState({
      receiptAmountBalance: receiptBalance.toFixed(2),
    });
  };

  /* autoAllocation = (isAutoAllocate) => {
    debugger;
    if (isAutoAllocate) {
      var updateRecords = [];
      var receiptAmount = this.state.amount;
      var lineCount = this.state.lineItems.length;

      this.state.lineItems.map((i) => {
        if (receiptAmount > i.totalSellPrice) {
          i.totalReceiptAmount = i.totalSellPrice;
          receiptAmount = receiptAmount - i.totalSellPrice;
        } else {
          i.totalReceiptAmount = receiptAmount;
        }

        i.receiptBalanceAmount = i.totalSellPrice - i.totalReceiptAmount;
        if (lineCount == 1) {
          i.receiptBalanceAmount = i.receiptBalanceAmount + receiptAmount;
        }
        lineCount--;
        updateRecords.push({
          id: i.id,
          totalSellPrice: i.totalSellPrice,
          totalReceiptAmount: i.totalReceiptAmount,
          receiptBalance: i.receiptBalance,
        });
      });
      this.setState({
        updateRecords: updateRecords,
      });
    } else {
      this.setState({
        lineItems: this.props.invoice.invoiceLineItems,
      });
    }

    this.setState({
      isAutoAllocation: isAutoAllocate,
    });
  }; */

  receiptSelectChange = (e) => {
    this.setState({
      selectedReceiptItem: e,
    });
  };
  receiptRowSelect = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    var balanceAmount = rowData["receiptBalanceAmount"];
    rowData["receiptAmount"]=balanceAmount;
    this.reCalculate(rowData);
  };
  render() {
    return (
      <>
        {
          <div>
            <form>
              <Fieldset legend="Receipt Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Receipt Date
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <Calendar
                      dateFormat="dd/mm/yy"
                      style={{ width: "100%" }}
                      value={this.state.receiptDate}
                      onChange={(e) =>
                        this.handleChange({
                          receiptDate: e.target.value,
                        })
                      }
                      showIcon
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Amount
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      value={this.state.amount}
                      onChange={(e) =>
                        this.handleChange({
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Payment Method
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <Dropdown
                      style={{ width: "100%" }}
                      value={this.state.paymentMethod}
                      filter
                      onChange={(e) =>
                        this.handleChange({
                          paymentMethod: e.target.value,
                        })
                      }
                      options={this.state.paymentMethods}
                      optionLabel="code"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Remarks
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <textarea
                      className="form-control"
                      value={this.state.remarks}
                      onChange={(e) =>
                        this.handleChange({ remarks: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </Fieldset>

              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  {this.state.id > 0 && (
                    <Button
                      type="button"
                      label="Delete"
                      className="col-3 btn btn-danger pull-right"
                      style={{ margin: "10px" }}
                      loading={this.state.isRemoveProcessing}
                      onClick={(e) => this.confirmRemove()}
                    />
                  )}
                  <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    onClick={(e) => this.confirm()}
                  />
                </div>
              </div>
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
            <Dialog
              maximizable
              headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
              contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
              header="Allocate Receipts"
              visible={this.state.isModalOpen}
              style={{ width: "80vw" }}
              onHide={(e) => {
                this.setState({ isModalOpen: false,lineItems:[],receiptAmount:"" });
              }}
            >
              <DataTable
                stripedRows
                showGridlines
                value={this.state.lineItems}
                selectionMode={"checkbox"}
                dataKey="id"
                metaKeySelection={false}
                sortMode="single"
                paginator
                rows={20}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  field="type"
                  sortable
                  header="Type"
                ></Column>
                <Column
                  field="bookingRef"
                  sortable
                  header="Booking Ref"
                ></Column>
                <Column
                  field="jobCardNo"
                  sortable
                  header="Job Card No"
                ></Column>
                <Column
                  field="totalSellPrice"
                  sortable
                  header="Total Sell"
                ></Column>
                <Column
                  field="totalReceiptAmount"
                  sortable
                  header="Receipt Allocated Amount"
                ></Column>
                <Column
                  field="receiptBalanceAmount"
                  sortable
                  header="Receipt Balance Amount"
                ></Column>
                <Column
                  field="receiptAmount"
                  sortable
                  header="Enter Amount"
                  editor={(options) => this.cellEditor(options)}
                  onCellEditComplete={this.onCellEditComplete}
                ></Column>
              </DataTable>
              <div>
                <h1>
                  Remaining Receipt Balance : {this.state.receiptAmountBalance}
                </h1>
              </div>
              <div className="pd-20">
              <Button
                  type="button"
                  label="Submit"
                  className="btn btn-primary"
                  loading={this.state.isSaveProcessing}
                  onClick={(e) => this.confirmAllocation()}
                />
              </div>
            </Dialog>
            <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        }
      </>
    );
  }
}

export default AddReceipt;
