import React from "react";
import axios from "axios";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from 'primereact/button';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";
class AddEditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      roles: [],
      status: [],
      userName: "",
      password: "",
      roleId: "",
      statusId: "",
      isSaveProcessing:false,
      isRemoveProcessing:false
    };
  }
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    
    await this.getReferenceData();
    if (this.props.user) {
      debugger
      this.setState({
        id: this.props.user.id,
        userName: this.props.user.userName,
        password: this.props.user.password,
        roleId: this.props.user.roleId,
        statusId: this.props.user.statusId,
      });
    }
  };

  getReferenceData = async () => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          roles: response.roles,
          status: response.status,
        });
        this.props.setLoader(false);
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }
  validation = () => {
    if (this.state.userName == "") {
      this.setErrorMessage("Please enter user name", 3);
      return false;
    }
    if (this.state.password == "") {
      this.setErrorMessage("Please enter password", 3);
      return false;
    }
    if (this.state.roleId == "") {
      this.setErrorMessage("Please enter Role", 3);
      return false;
    }
    if (this.state.statusId == "") {
      this.setErrorMessage("Please enter Status", 3);
      return false;
    }
    
    return true;
  };
  saveUser = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing:true
    })
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing:false
      })
      return;
    }
    var payload = {
      Id: this.state.id,
      UserName: this.state.userName,
      Password: this.state.password,
      RoleId: this.state.roleId,
      StatusId: this.state.statusId
    };
    const currentUser = localStorage.getItem('token');
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Master/SaveUser`, payload,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing:false
        })
        window.location.reload();
      });
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirm = () => {
    var accept = this.saveUser;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  render() {
    return (
      <>
        {
          <div>
            <form autocomplete="off">
              <Fieldset legend="Main Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    User Name
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <input
                    autocomplete="off"
                      className="form-control"
                      type="text"
                      placeholder="User Name"
                      value={this.state.userName}
                      onChange={(e) =>
                        this.handleChange({ userName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Password
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <input
                    autocomplete="off"
                      className="form-control"
                      type="text"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={(e) =>
                        this.handleChange({ password: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    System Role
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Dropdown
                      value={this.state.roleId}
                      onChange={(e) =>
                        this.handleChange({ roleId: e.value })
                      }
                      options={this.state.roles}
                      placeholder="Select a Role"
                      optionLabel="code"
                      filter
                      className="col-12"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Status
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Dropdown
                      value={this.state.statusId}
                      onChange={(e) =>
                        this.handleChange({ statusId: e.value })
                      }
                      options={this.state.status}
                      placeholder="Select a Status"
                      optionLabel="code"
                      filter
                      className="col-12"
                    />
                  </div>
                </div>
              </Fieldset>

              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  
                <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    loading={this.state.isSaveProcessing}
                    onClick={(e) => this.confirm(e)}
                  />
                </div>
              </div>
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
            <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          
        }
      </>
    );
  }
}

export default AddEditUser;
