import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import Transportation from "../components/Transportation";
import Hotel from "../components/Hotel";
import Visa from "../components/Visa";
import Miscellaneous from "../components/Miscellaneous";
import TransportationCost from "../components/TransportationCost";
import PreLoader from "../components/shards/PreLoader";
class CostUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobCardCode: "",
      bookingRef: "",
      batchNo: "",
      dateFrom: null,
      dateTo: null,
      customerId: "",
      vendorId: "",
      clientRef: "",
      referenceTypeId: 1,
      customers: [],
      vendors: [],
      referenceType: [],
      transportList: [],
      isLoading: false,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        this.setState({
          isLoading: false,
          customers: response.customers,
          vendors: response.vendors,
          referenceType: response.referenceType,
        });
      });
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      jobCardCode: "",
      bookingRef: "",
      batchNo: "",
      dateFrom: null,
      dateTo: null,
      customerId: "",
      vendorId: "",
      clientRef: "",
      referenceTypeId: 1,
    });
  };

  getJobs = async (e) => {
    this.getJobTransportations();
  };

  getJobTransportations = async () => {
    this.setState({
      isLoading: true,
    });
    debugger;
    const transportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportations?` +
        new URLSearchParams({
          jobCardCode: this.state.jobCardCode,
          bookingRef: this.state.bookingRef,
          batchNo: this.state.batchNo,
          customerId: this.state.customerId,
          vendorId: this.state.vendorId,
          clientRef: this.state.clientRef,
          dateFrom: this.state.dateFrom
            ? new Date(this.state.dateFrom).toDateString()
            : "",
          dateTo: this.state.dateTo
            ? new Date(this.state.dateTo).toDateString()
            : "",
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardTransportations.map((i) =>
          transportList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
            pickupLocation: i.pickupLocation,
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks,
            isVatIncludedCost: i.isVatIncludedCost,
            isVatIncludedSell: i.isVatIncludedSell,
            costBaseAmount:
              i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalCostTaxAmount:
              i.totalCostTaxAmount == null
                ? null
                : i.totalCostTaxAmount.toFixed(2),
            extras: i.extras == null ? 0 : i.extras.toFixed(2),
            extrasTaxAmount:
              i.extrasTaxAmount == null ? null : i.extrasTaxAmount.toFixed(2),
            parking: i.parking == null ? 0 : i.parking.toFixed(2),
            water: i.water == null ? 0 : i.water.toFixed(2),
            extrasSell: i.extrasSell == null ? 0 : i.extrasSell.toFixed(2),
            extrasTaxAmountSell:
              i.extrasTaxAmountSell == null
                ? null
                : i.extrasTaxAmountSell.toFixed(2),
            parkingSell: i.parkingSell == null ? 0 : i.parkingSell.toFixed(2),
            waterSell: i.waterSell == null ? 0 : i.waterSell.toFixed(2),
            sellBaseAmount:
              i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            totalSellTaxAmount:
              i.totalSellTaxAmount == null
                ? null
                : i.totalSellTaxAmount.toFixed(2),
            isJobCardClosed: i.isJobCardClosed,
            isInvoiced:i.isInvoiced
          })
        );

        this.setState({
          transports: transportList,
          isModalOpen: false,
        });
        this.setState({
          isLoading: false,
        });
      });
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getJobs();
    }
  };

  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/update.gif"
                            alt=""
                            style={{verticalAlign:"middle"}}
                          />{" "}
                          Update Cost
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="title">
                        <h4>Search</h4>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">HM Booking Ref</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.bookingRef}
                                onChange={(e) =>
                                  this.handleChange({
                                    bookingRef: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Batch No</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.batchNo}
                                onChange={(e) =>
                                  this.handleChange({
                                    batchNo: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Customer</label>
                              <Dropdown
                                value={this.state.customerId}
                                onChange={(e) =>
                                  this.handleChange({
                                    customerId: e.target.value,
                                  })
                                }
                                options={this.state.customers}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Supplier</label>
                              <Dropdown
                                value={this.state.vendorId}
                                onChange={(e) =>
                                  this.handleChange({
                                    vendorId: e.target.value,
                                  })
                                }
                                options={this.state.vendors}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Client Ref</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.clientRef}
                                onChange={(e) =>
                                  this.handleChange({
                                    clientRef: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">From Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.dateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    dateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">To Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.dateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    dateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Reference Type</label>
                              <Dropdown
                                value={this.state.referenceTypeId}
                                onChange={(e) =>
                                  this.handleChange({
                                    referenceTypeId: e.target.value,
                                  })
                                }
                                options={this.state.referenceType}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 col-md-12">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-warning pull-right"
                              onClick={(e) => {
                                this.resetSearchHandler(e);
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.getJobs(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  <div className="pb-20">
                    {this.state.referenceTypeId == 1 && (
                      <TransportationCost
                        jobCardCode={this.state.jobCardCode}
                        bookingRef={this.state.bookingRef}
                        batchNo={this.state.batchNo}
                        customerId={this.state.customerId}
                        vendorId={this.state.vendorId}
                        clientRef={this.state.clientRef}
                        dateFrom={this.state.dateFrom}
                        dateTo={this.state.dateTo}
                        action={this.getJobTransportations}
                        transports={this.state.transports}
                        getJobTransportations={this.getJobTransportations}
                      />
                    )}
                    {this.state.referenceTypeId == 2 && <Hotel />}
                    {this.state.referenceTypeId == 3 && <Visa />}
                    {this.state.referenceTypeId == 4 && <Miscellaneous />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default CostUpdate;
