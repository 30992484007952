import React from "react";
import AddEditCustomer from "../components/AddEditCustomer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import AddReceipt from "../components/AddReceipt";
import ViewReceipt from "./ViewReceipt";
import PreLoader from "../components/shards/PreLoader";
class InvoicesReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      invoice: null,
      receipt: null,
      receipts: [],
      isModalOpen: false,
      isModalOpenReceipt: false,
      isLoading:false
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getInvoice();
    await this.getReceipts();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getInvoice = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetInvoice?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((i) => {
        var invoiceItem = {
          id: i.invoice.id,
          invoiceCode: i.invoice.invoiceCode,
          customerName: i.invoice.customerName,
          addressLine1: i.invoice.customerAddressLine1,
          addressLine2: i.invoice.customerAddressLine2,
          invoiceAmount:
            i.invoice.invoiceAmount == null
              ? null
              : i.invoice.invoiceAmount.toFixed(2),
          statusName: i.invoice.statusName,
          transportDescription: i.invoice.transportDescription,
          hotelDescription: i.invoice.hotelDescription,
          visaDescription: i.invoice.visaDescription,
          miscellaneousDescription: i.invoice.miscellaneousDescription,
          statusId: i.invoice.statusId,
          invoiceDate:
            i.invoice.invoiceDate == null
              ? null
              : dayjs(i.invoice.invoiceDate).format("DD/MM/YYYY"),
          invoiceDueDate:
            i.invoice.invoiceDueDate == null
              ? null
              : dayjs(i.invoice.invoiceDueDate).format("DD/MM/YYYY"),
          invoiceLineItems: this.GetBatchTransportItem(
            i.invoice.invoiceLineItems
          ),
          transportJobCardNos: i.invoice.transportJobCardNos,
          hotelJobCardNos: i.invoice.hotelJobCardNos,
          visaJobCardNos: i.invoice.visaJobCardNos,
          miscellaneousJobCardNos: i.invoice.miscellaneousJobCardNos,
          totalLinkedProformaReciptAmount:
            i.invoice.totalLinkedProformaReciptAmount,
          linkedProformaInvoices: i.invoice.linkedProformaInvoices,
          linkProformaInvoiceCodes: i.invoice.linkProformaInvoiceCodes,
          invoiceNetAmount:
            i.invoice.invoiceNetAmount == null
              ? null
              : i.invoice.invoiceNetAmount.toFixed(2),
          invoiceVatAmount:
            i.invoice.invoiceVatAmount == null
              ? null
              : i.invoice.invoiceVatAmount.toFixed(2),
          invoiceGrossAmount:
            i.invoice.invoiceGrossAmount == null
              ? null
              : i.invoice.invoiceGrossAmount.toFixed(2),
        };
        this.setState({
          isLoading: false,
        });
        this.setState({
          invoice: invoiceItem,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    items.transportations.map((i) => {
      batchTransportationItems.push({
        jobCardNo: i.jobCardNo,
        jobCardTitle: i.jobCardTitle,
        id: i.id,
        batchNo: i.batchNo,
        bookingRef: i.bookingRef,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        totalCostTaxAmount:
          i.totalCostTaxAmount == null ? null : i.totalCostTaxAmount.toFixed(2),
        extras: i.extras == null ? null : i.extras.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        parking: i.parking == null ? null : i.parking.toFixed(2),
        water: i.water == null ? null : i.water.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        totalSellTaxAmount:
          i.totalSellTaxAmount == null ? null : i.totalSellTaxAmount.toFixed(2),
        extrasSell: i.extrasSell == null ? null : i.extrasSell.toFixed(2),
        parkingSell: i.parkingSell == null ? null : i.parkingSell.toFixed(2),
        waterSell: i.waterSell == null ? null : i.waterSell.toFixed(2),
        receiptBalanceAmount:
          i.receiptBalanceAmount == null
            ? null
            : i.receiptBalanceAmount.toFixed(2),
        totalReceiptAmount:
          i.totalReceiptAmount == null ? null : i.totalReceiptAmount.toFixed(2),
        receiptAmount: 0,
        toString: function () {
          return this.jobCardNo;
        },
        type: "Transportation",
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        receiptBalanceAmount:
          i.receiptBalanceAmount == null
            ? null
            : i.receiptBalanceAmount.toFixed(2),
        totalReceiptAmount:
          i.totalReceiptAmount == null ? null : i.totalReceiptAmount.toFixed(2),
        receiptAmount: 0,
        toString: function () {
          return this.jobCardNo;
        },
        type: "Hotel",
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        receiptBalanceAmount:
          i.receiptBalanceAmount == null
            ? null
            : i.receiptBalanceAmount.toFixed(2),
        totalReceiptAmount:
          i.totalReceiptAmount == null ? null : i.totalReceiptAmount.toFixed(2),
        receiptAmount: 0,
        toString: function () {
          return this.jobCardNo;
        },
        type: "Visa",
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        receiptBalanceAmount:
          i.receiptBalanceAmount == null
            ? null
            : i.receiptBalanceAmount.toFixed(2),
        totalReceiptAmount:
          i.totalReceiptAmount == null ? null : i.totalReceiptAmount.toFixed(2),
        receiptAmount: 0,
        toString: function () {
          return this.jobCardNo;
        },
        type: "Miscellaneous",
      });
    });

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };
  confirmVoid = (item) => {
    debugger;
    this.setState({
      receipt: item,
    });
    var accept = this.voidReceipt;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to void Receipt?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  voidReceipt = async (e) => {
    this.setState({
      isLoading: true,
    });
    debugger;
    var payload = {
      Id: this.state.receipt.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/VoidReceipt`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.setErrorMessage("Receipt Successfully Voided", 1);
        window.location.reload();
      });
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };
  getReceipts = async () => {
    this.setState({
      isLoading: true,
    });
    const receiptList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetReceipts?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.receipts.map((i) =>
          receiptList.push({
            id: i.id,
            receiptCode: i.receiptCode,
            invoiceId: i.invoiceId,
            invoiceCode: i.invoiceCode,
            customerName: i.customerName,
            addressLine1: i.addressLine1,
            addressLine2: i.addressLine2,
            paymentMethodId: i.paymentMethodId,
            amount: i.amount == null ? null : i.amount.toFixed(2),
            paymentMethod: i.paymentMethod,
            remark: i.remark,
            receiptDate: i.paymentDate,
            paymentDate:
              i.paymentDate == null
                ? null
                : dayjs(i.paymentDate).format("DD/MM/YYYY"),
            status:i.status
          })
        );
        this.setState({
          isLoading: false,
        });
        this.setState({
          receipts: receiptList,
        });
      });
  };

  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpenReceipt: true,
              receipt: item,
            })
          }
          icon="pi pi-file"
          severity="secondary"
          tooltip="Receipt"
        />
        {item.status!='Void' && (<Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) => {
            this.confirmVoid(item);
          }}
          icon="pi pi-times"
          severity="secondary"
          tooltip="Void Receipt"
        />)}
      </div>
    );
  };
  render() {
    return (
      <>
        {this.state.invoice && (
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">Invoice Details</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Number</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceCode}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Date</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceDate}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">
                                  Invoice Due Date
                                </label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceDueDate}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Customer Name</label>
                                <p className="col-6">
                                  {this.state.invoice.customerName}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Amount</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceAmount}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">Record Payment</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pd-20">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isModalOpen: true, receipt: null });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.receipts}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        this.setState({  receipt: e.value });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="paymentDate"
                        sortable
                        header="Receipt Date"
                      ></Column>
                      <Column
                        field="receiptCode"
                        sortable
                        header="Receipt Number"
                      ></Column>
                      <Column field="amount" sortable header="Amount"></Column>
                      <Column
                        field="paymentMethod"
                        sortable
                        header="Payment Method"
                      ></Column>
                      <Column field="remark" sortable header="Remark"></Column>
                      <Column field="status" sortable header="Status"></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={
                    this.state.customer == null ? "Add Receipt" : "Edit Receipt"
                  }
                  visible={this.state.isModalOpen}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <AddReceipt
                    invoice={this.state.invoice}
                    receipt={this.state.receipt}
                  />
                </Dialog>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Receipt Preview"
                  visible={this.state.isModalOpenReceipt}
                  onHide={(e) => {
                    this.setState({ isModalOpenReceipt: false });
                  }}
                >
                  <ViewReceipt receipt={this.state.receipt} />
                </Dialog>
              </div>
            </div>
            <ConfirmDialog />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
          </div>
        )}
        {this.state.isLoading && (<PreLoader/>)}
      </>
    );
  }
}

export default InvoicesReceipt;
