import React from "react";
import AddEditCustomer from "../components/AddEditCustomer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

class ViewCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      customer: null,
      customers: [],
      filterCustomers: [],
      isModalOpen: false,
      globalFilterValue: "",
      isLoading: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  }

  onGlobalFilterChange = (e) => {
    debugger;
    const value = e.target.value;
    let _filters = this.state.filters;
    var filterCustomers = this.state.customers;

    if (value && value.length > 0) {
      _filters["global"].value = value;
      filterCustomers = this.state.customers.filter((i) => {
        return (
          i.customerName.toLowerCase().includes(value.toLowerCase()) ||
          i.contactPersonName.toLowerCase().includes(value.toLowerCase()) ||
          i.email.toLowerCase().includes(value.toLowerCase()) ||
          i.contactNumber.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
    //setFilters(_filters);
    //setGlobalFilterValue(value);

    this.setState({
      globalFilterValue: value,
      filters: _filters,
      filterCustomers: filterCustomers,
    });
  };

  renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={this.state.globalFilterValue}
            onChange={this.onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getCustomers();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  setLoader = (val) => {
    this.setState({
      isLoading: val,
    });
  };
  getCustomers = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetCustomers`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
          customers: response.customers,
          filterCustomers: response.customers,
        });
      });
  };

  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="card-box mb-30">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/customer.gif"
                            alt=""
                            style={{verticalAlign:"middle"}}
                          />{" "}
                          View Customers
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="pd-20">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isModalOpen: true, customer: null });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.filterCustomers}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        this.setState({ isModalOpen: true, customer: e.value });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                      header={this.renderHeader}
                      /* filters={this.state.filters}
                      globalFilterFields={['customerName', 'contactPersonName', 'email', 'contactNumber']}*/
                      emptyMessage="No customers found."
                    >
                      <Column
                        field="customerName"
                        sortable
                        header="Customer Name"
                      ></Column>
                      <Column
                        field="contactPersonName"
                        sortable
                        header="Contact Person Name"
                      ></Column>
                      <Column field="email" sortable header="Email"></Column>
                      <Column
                        field="contactNumber"
                        sortable
                        header="Contact Number"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={
                    this.state.customer == null
                      ? "Add Customer"
                      : "Edit Customer"
                  }
                  visible={this.state.isModalOpen}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <AddEditCustomer
                    setLoader={this.setLoader}
                    customer={this.state.customer}
                  />
                </Dialog>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ViewCustomers;
