import React from "react";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "react-toastify/dist/ReactToastify.css";
import DOMPurify from "dompurify";
class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      customerName: '',
      contactPersonName: "",
      email: "",
      contactNumber: "",
      trn: "",
      address1: "",
      address2: "",
      city: "",
      bankCode: "",
      errorMessage: "",
      isError: false,
      countries: [],
      countryCode: "",
      isSaveProcessing: false,
      isRemoveProcessing: false,
      isLoading:false
    };
  }
  componentDidMount = async () => {
    if (this.props.customer) {
      this.setState({
        id: this.props.customer.id,
        customerName: this.props.customer.customerName,
        contactPersonName: this.props.customer.contactPersonName,
        email: this.props.customer.email,
        contactNumber: this.props.customer.contactNumber,
        trn: this.props.customer.trn,
        address1: this.props.customer.address1,
        address2: this.props.customer.address2,
        city: this.props.customer.city,
        countryCode: this.props.customer.countryCode,
      });
    }
    await this.getReferenceData();
  };
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        this.setState({
          isLoading: false,
          countries: response.countries,
        });
        this.props.setLoader(false);
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  validation = () => {
    if (this.state.customerName == "") {
      this.setErrorMessage("Please enter customer name.", 3);
      return false;
    }
    if (this.state.contactPersonName == "") {
      this.setErrorMessage("Please enter contact person name.", 3);
      return false;
    }
    if (this.state.email == "") {
      this.setErrorMessage("Please enter email.", 3);
      return false;
    }
    if(!this.validateEmail(this.state.email)){
      this.setErrorMessage("Invalid email.", 3);
      return false;
    }
    if (this.state.contactNumber == "") {
      this.setErrorMessage("Please enter contact number.", 3);
      return false;
    }

    if (!this.validatePhoneNumber(this.state.contactNumber)) {
      this.setErrorMessage("Invalid contact number format.", 3);
      return false;
    }

    if (this.state.countryCode == "") {
      this.setErrorMessage("Please select country.", 3);
      return false;
    }

    if (this.state.city == "") {
      this.setErrorMessage("Please enter city.", 3);
      return false;
    }

    if (this.state.trn == "") {
      this.setErrorMessage("Please enter TRN.", 3);
      return false;
    }

    if (this.state.trn.length>15) {
      this.setErrorMessage("TRN number should be less than 15 characters.", 3);
      return false;
    }
    
    return true;
  };

  validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  validatePhoneNumber = (number) => {
    const regex = /^\+\d{1,3}\d{10}$/;
    return regex.test(number);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  saveCustomerHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing: true,
    });
    debugger;
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      Id: this.state.id,
      CustomerName: this.state.customerName,
      ContactPersonName: this.state.contactPersonName,
      Email: this.state.email,
      ContactNumber: this.state.contactNumber,
      Trn: this.state.trn,
      Address1: this.state.address1,
      Address2: this.state.address2,
      City: this.state.city,
      CountryCode: this.state.countryCode,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Master/SaveCustomer`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing: false,
        });
        this.setErrorMessage("Save Successfully", 1);
        window.location.reload();
      });
  };

  removeCustomerHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isRemoveProcessing: true,
    });
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Master/RemoveCustomer`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isRemoveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isRemoveProcessing: false,
        });
        this.setErrorMessage("Delete Successfully", 1);
        window.location.reload();
      });
  };

  render() {
    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = (accept) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <>
        {
          <div>
            <form>
              <Fieldset legend="Main Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Customer Name *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Customer Name"
                      value={this.state.customerName}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          customerName: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Contact Person Name *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Contact Person Name"
                      value={this.state.contactPersonName}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          contactPersonName: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Email *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      value={this.state.email}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          email: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Contact Number *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Contact Number"
                      type="tel"
                      value={this.state.contactNumber}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          contactNumber: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Address line 1
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Address line 1"
                      type="text"
                      value={this.state.address1}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          address1: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Address line 2
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Address line 2"
                      type="text"
                      value={this.state.address2}
                     onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          address2: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    City *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="City"
                      type="text"
                      value={this.state.city}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          city: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Country *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <Dropdown
                      style={{ width: "100%" }}
                      value={this.state.countryCode}
                      filter
                      onChange={(e) =>
                        this.handleChange({
                          countryCode: e.target.value,
                        })
                      }
                      options={this.state.countries}
                      optionLabel="code"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
              </Fieldset>
              <br />
              <Fieldset legend="Finance Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    TRN # * 
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="TRN"
                      type="text"
                      value={this.state.trn}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          trn: input,
                        });
                      }}
                    />
                  </div>
                </div>
              </Fieldset>
              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  {this.state.id > 0 && (
                    <Button
                      type="button"
                      label="Delete"
                      className="col-3 btn btn-danger pull-right"
                      style={{ margin: "10px" }}
                      loading={this.state.isRemoveProcessing}
                      onClick={(e) => confirm(this.removeCustomerHandler)}
                    />
                  )}
                  <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    loading={this.state.isSaveProcessing}
                    onClick={(e) => confirm(this.saveCustomerHandler)}
                  />
                </div>
              </div>
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
            <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        }
          
      </>
    );
  }
}

export default AddCustomer;
