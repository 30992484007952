import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
import { MultiSelect } from "primereact/multiselect";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import FullReport from "../components/FullReport";
import DailyReport from "../components/DailyReport";
import PreLoader from "../components/shards/PreLoader";
class ReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      customers: [],
      vendors: [],
      reportType: [
        { code: "Daily Report", value: 1 },
        { code: "Full Report", value: 2 },
      ],
      effectiveDateFrom: null,
      effectiveDateTo: null,
      customerId: [],
      clientRef: "",
      vendorId: [],
      batchNo: "",
      bookingRef: "",
      jobCardCode: "",
      customerName: "",
      vendorName: "",
      reportTypeId: 2,
      fullReport: [],
      dailyReport: [],
      isLoading: false,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
          vendors: response.vendors,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  getReportItem = async () => {
    this.setState({
      isLoading: true,
    });
    const dailyReportList = [];
    const fullReportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetReportItems?` +
        new URLSearchParams({
          clientRef: this.state.clientRef,
          batchNo: this.state.batchNo,
          customerId: this.state.customerId.toString(),
          vendorId: this.state.vendorId.toString(),
          bookingRef: this.state.bookingRef,
          jobCardCode: this.state.jobCardCode,
          reportTypeId: this.state.reportTypeId,
          dateFrom: this.state.effectiveDateFrom
            ? new Date(this.state.effectiveDateFrom).toDateString()
            : "",
          dateTo: this.state.effectiveDateTo
            ? new Date(this.state.effectiveDateTo).toDateString()
            : "",
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.dailyReport.map((i) =>
          dailyReportList.push({
            id: i.id,
            effectiveDateFrom:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY"),
            bookingRef: i.bookingRef,
            jobCardNo: i.jobCardNo,
            customerName: i.customerName,
            customerRef: i.customerRef,
            pickUpTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            pickUpLocation: i.pickupLocation,
            dropOffLocation: i.dropoffLocation,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            totalPax: i.totalPax,
            adult: i.adults,
            children: i.children,
            vehicleType: i.vehicleType,
            suplierName: i.suplierName,
            batchNo: i.batchNo == "" || i.batchNo == null ? " " : i.batchNo,
            remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
            flightDetail: this.GetFlightDetails(
              i.flightNo.trim(),
              i.flightTime
            ),
          })
        );
        /* response.fullReport.map((i) =>
          fullReportList.push({
            id: i.id,
            effectiveDateFrom:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY"),
            bookingRef: i.bookingRef,
            jobCardNo: i.jobCardNo,
            customerName: i.customerName,
            customerRef: i.customerRef,
            pickUpTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            pickUpLocation: i.pickupLocation,
            dropOffLocation: i.dropoffLocation,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            totalPax: i.totalPax,
            adult: i.adults,
            children: i.children,
            vehicleType: i.vehicleType,
            suplierName: i.suplierName,
            batchNo: i.batchNo == "" || i.batchNo == null ? " " : i.batchNo,
            remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
            flightDetail: this.GetFlightDetails(
              i.flightNo.trim(),
              i.flightTime
            ),
          })
        ); */

        var fullReport = this.GetBatchTransportItem(response.fullReport);
        debugger;
        var cName = "";
        var customer = this.state.customers.find(
          (i) => i.value == this.state.customerId
        );
        if (customer && customer != null) {
          cName = customer.code;
        }

        var sName = "";
        var vendor = this.state.vendors.find(
          (i) => i.value == this.state.vendorId
        );
        if (vendor && vendor != null) {
          sName = vendor.code;
        }
        this.setState({
          isLoading: false,
        });
        this.setState({
          customerName: cName,
          vendorName: sName,
          fullReport: fullReport,
          dailyReport: dailyReportList,
          isModalOpen: true,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    items.transportations.map((i) => {
      batchTransportationItems.push({
        id: i.id,
        effectiveDateFrom:
          i.pickupTime == null
            ? null
            : dayjs(i.pickupTime).format("DD/MM/YYYY"),
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        customerName: i.customerName,
        customerRef: i.customerRef,
        pickUpTime:
          i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
        pickUpLocation: i.pickupLocation,
        dropOffLocation: i.dropoffLocation,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        totalPax: i.totalPax,
        adult: i.adults,
        children: i.children,
        vehicleType: i.vehicleType,
        suplierName: i.suplierName,
        batchNo: i.batchNo == "" || i.batchNo == null ? " " : i.batchNo,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        flightDetail: this.GetFlightDetails(i.flightNo.trim(), i.flightTime),
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        vendorName: i.venderName,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Hotel",
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        vendorName: i.vendorName,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Visa",
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        vendorName: i.vendorName,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Miscellaneous",
      });
    });

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };

  GetFlightDetails = (flightNo, flightTime) => {
    var flightDetails = "";

    if (flightNo && flightNo != null && flightNo != "") {
      flightDetails = flightNo;

      if (flightTime && flightTime != null && flightTime != "") {
        flightDetails = `${flightNo} ${dayjs(flightTime).format("HH:mm")}`;
      }
    }

    return flightDetails;
  };
  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getReportItem();
    }
  };
  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">
                      <img
                        width={"50px"}
                        src="/vendors/images/full-report.gif"
                        alt=""
                        style={{ verticalAlign: "middle" }}
                      />{" "}
                      {this.state.reportTypeId == 1
                        ? "Daily Report"
                        : "Full Report"}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="title">
                    <h4>Search</h4>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>
                            {this.state.reportTypeId == 1
                              ? "Pickup Date"
                              : "Pickup From Date"}
                          </label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.effectiveDateFrom}
                            onChange={(e) =>
                              this.handleChange({
                                effectiveDateFrom: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      {this.state.reportTypeId == 2 && (
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>Pickup To Date</label>
                            <Calendar
                              dateFormat="dd/mm/yy"
                              style={{ width: "100%" }}
                              value={this.state.effectiveDateTo}
                              onChange={(e) =>
                                this.handleChange({
                                  effectiveDateTo: e.target.value,
                                })
                              }
                              showIcon
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Customer</label>
                          <MultiSelect
                            value={this.state.customerId}
                            onChange={(e) =>
                              this.handleChange({
                                customerId: e.target.value,
                              })
                            }
                            display="chip"
                            options={this.state.customers}
                            optionLabel="code"
                            placeholder="Select a Customer"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Client Ref</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.clientRef}
                            onChange={(e) =>
                              this.handleChange({
                                clientRef: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Supplier</label>
                          <MultiSelect
                            value={this.state.vendorId}
                            onChange={(e) =>
                              this.handleChange({
                                vendorId: e.target.value,
                              })
                            }
                            display="chip"
                            options={this.state.vendors}
                            optionLabel="code"
                            placeholder="Select a Supplier"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Batch No</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.batchNo}
                            onChange={(e) =>
                              this.handleChange({
                                batchNo: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>HM Booking Ref</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.bookingRef}
                            onChange={(e) =>
                              this.handleChange({
                                bookingRef: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      {this.state.reportTypeId == 2 && (
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>Job Card Number</label>
                            <InputText
                              style={{ width: "100%" }}
                              value={this.state.jobCardCode}
                              onChange={(e) =>
                                this.handleChange({
                                  jobCardCode: e.target.value,
                                })
                              }
                              onKeyUp={(e) => this.enterPressed(e)}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label>Report Type</label>
                          <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.reportTypeId}
                            onChange={(e) =>
                              this.handleChange({
                                reportTypeId: e.target.value,
                              })
                            }
                            options={this.state.reportType}
                            optionLabel="code"
                            className="w-full md:w-14rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12">
                        <button
                          type="button"
                          className="btn btn-primary pull-right"
                          onClick={(e) => {
                            this.getReportItem();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Dialog
              maximizable
              style={{ width: "95vw" }}
              header={
                this.state.reportTypeId == 1 ? "Daily Report" : "Full Report"
              }
              headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
              contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
              visible={this.state.isModalOpen}
              onHide={(e) => {
                this.setState({ isModalOpen: false });
              }}
            >
              {this.state.reportTypeId == 1 ? (
                <DailyReport
                  dailyReport={this.state.dailyReport}
                  effectiveDateFrom={this.state.effectiveDateFrom}
                  customer={this.state.customerName}
                  clientRef={this.state.clientRef}
                  vendor={this.state.vendorName}
                  batchNo={this.state.batchNo}
                  jobCardCode={this.state.jobCardCode}
                  bookingRef={this.state.bookingRef}
                />
              ) : (
                <FullReport
                  fullReport={this.state.fullReport}
                  effectiveDateFrom={this.state.effectiveDateFrom}
                  effectiveDateTo={this.state.effectiveDateTo}
                  customer={this.state.customerName}
                  clientRef={this.state.clientRef}
                  vendor={this.state.vendorName}
                  batchNo={this.state.batchNo}
                  jobCardCode={this.state.jobCardCode}
                  bookingRef={this.state.bookingRef}
                />
              )}
            </Dialog>
            {this.state.isLoading && <PreLoader />}
          </div>
        </div>
      </div>
    );
  }
}

export default ReportWrap;
