import React from "react";
import axios from "axios";
import { Fieldset } from "primereact/fieldset";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
class AddEditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      eventName: "",
      eventFromDate: "",
      eventToDate: "",
      eventTypeId: "",
      remark: "",
      isSaveProcessing: false,
      customers: [],
      customerId:"",
      isRemoveProcessing: false,
      eventTypes: [
        { value: 1, code: "Wedding" },
        { value: 2, code: "Event" },
      ],
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    /*  const user = localStorage.getItem("username");
    if (!user) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    } */
    await this.getReferenceData();

    if (this.props.event) {
      this.setState({
        id: this.props.event.id,
        customerId: this.props.event.customerId,
        eventName: this.props.event.eventName,
        eventFromDate: new Date(this.props.event.eventFromDate),
        eventToDate: new Date(this.props.event.eventToDate),
        eventTypeId: this.props.event.eventTypeId,
        remark: this.props.event.remark,
      });
    }
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.props.setLoader(false);
        this.setState({
          customers: response.customers,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }
  validation = () => {
    if (this.state.eventName == "") {
      this.setErrorMessage("Please enter event name", 3);
      return false;
    }
    if (this.state.eventFromDate == "") {
      this.setErrorMessage("Please select from date", 3);
      return false;
    }
    if (this.state.eventToDate == "") {
      this.setErrorMessage("Please select to date", 3);
      return false;
    }
    if (this.state.eventTypeId == "") {
      this.setErrorMessage("Please select event type", 3);
      return false;
    }

    return true;
  };
  saveEventHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing: true,
    });
    if (!this.validation()) {
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      Id: this.state.id,
      EventName: this.state.eventName,
      EventFromDate: new Date(this.state.eventFromDate).toDateString(),
      EventToDate: new Date(this.state.eventToDate).toDateString(),
      EventTypeId: this.state.eventTypeId,
      CustomerId: this.state.customerId,
      Remark: this.state.remark,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Master/SaveEvent`, payload, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing: false,
        });
        this.setErrorMessage("Event Create Successfully", 1);
        this.props.closeEvent();
      });
  };

  removeEventHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isRemoveProcessing: true,
    });
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Master/RemoveEvent`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isRemoveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isRemoveProcessing: false,
        });
        this.setErrorMessage("Event Removed Successfully", 1);
        this.props.closeEvent();
      });
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirm = () => {
    var accept = this.saveEventHandler;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  confirmRemove = () => {
    var accept = this.removeEventHandler;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  render() {
    return (
      <>
        {
          <div>
            <form>
              <Fieldset legend="Main Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Event Name
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Event Name"
                      value={this.state.eventName}
                      onChange={(e) =>
                        this.handleChange({ eventName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Event Date
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Calendar
                      dateFormat="dd/mm/yy"
                      style={{ width: "100%" }}
                      value={this.state.eventFromDate}
                      onChange={(e) =>
                        this.handleChange({
                          eventFromDate: e.target.value,
                        })
                      }
                      showIcon
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Event Date
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Calendar
                      dateFormat="dd/mm/yy"
                      style={{ width: "100%" }}
                      value={this.state.eventToDate}
                      onChange={(e) =>
                        this.handleChange({
                          eventToDate: e.target.value,
                        })
                      }
                      showIcon
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Event Type
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Dropdown
                      value={this.state.eventTypeId}
                      onChange={(e) =>
                        this.handleChange({ eventTypeId: e.value })
                      }
                      options={this.state.eventTypes}
                      placeholder="Select a Event Type"
                      optionLabel="code"
                      filter
                      className="col-12"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Customer
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Dropdown
                      value={this.state.customerId}
                      onChange={(e) =>
                        this.handleChange({ customerId: e.value })
                      }
                      options={this.state.customers}
                      placeholder="Select a Customer"
                      optionLabel="code"
                      filter
                      className="col-12"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Remark
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <textarea
                      className="form-control"
                      value={this.state.remark}
                      onChange={(e) =>
                        this.handleChange({ remark: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </Fieldset>
              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  {this.state.id > 0 && (
                    <Button
                      type="button"
                      label="Delete"
                      className="col-3 btn btn-danger pull-right"
                      style={{ margin: "10px" }}
                      loading={this.state.isRemoveProcessing}
                      onClick={(e) => this.confirmRemove(e)}
                    />
                  )}
                  <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    loading={this.state.isSaveProcessing}
                    onClick={(e) => this.confirm(e)}
                  />
                </div>
              </div>
              <ConfirmDialog />
              <ToastContainer
                position="top-center"
                autoClose={5000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
          </div>
        }
      </>
    );
  }
}

export default AddEditEvent;
