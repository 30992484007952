import React from "react";
import AddEditVendor from "../components/AddEditVendor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import AddEditUser from "../components/AddEditUser";

class ViewUsers extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      user: null,
      users: [],
      isModalOpen: false,
      isLoading: false,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    debugger;
    await this.getusers();
  };
  handleResponse(response) {
    debugger;
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getusers = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetUsers`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
          users: response.users,
        });
      });
  };

  setLoader = (val) => {
    this.setState({
      isLoading: val,
    });
  };

  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="card-box mb-30">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"60px"}
                            src="/vendors/images/user.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          View Users
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="pd-20">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isModalOpen: true, vendor: null });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.users}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        this.setState({ isModalOpen: true, user: e.value });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="userName"
                        sortable
                        header="User Name"
                      ></Column>
                      <Column
                        field="roleName"
                        sortable
                        header="Role Name"
                      ></Column>
                      <Column
                        field="statusName"
                        sortable
                        header="Status"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={this.state.user == null ? "Add User" : "Edit User"}
                  visible={this.state.isModalOpen}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false, user: null });
                  }}
                >
                  <AddEditUser
                    setLoader={this.setLoader}
                    user={this.state.user}
                  />
                </Dialog>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ViewUsers;
