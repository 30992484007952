import React from "react";
import axios from "axios";
import { Fieldset } from "primereact/fieldset";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import DOMPurify from "dompurify";
import "react-toastify/dist/ReactToastify.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
class AddEditVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      vendorName: "",
      contactPersonName: "",
      email: "",
      contactNumber: "",
      address: "",
      errorMessage: "",
      isError: false,
      trn: "",
      address1: "",
      address2: "",
      city: "",
      bankCode: "",
      iban: "",
      bankName: "",
      bankBranch: "",
      swiftCode: "",
      isSaveProcessing: false,
      isRemoveProcessing: false,
      vendorTypeId: "",
      selectedVendorTypes: [],
      vendorType: [
        { value: 1, code: "Transportation" },
        { value: 2, code: "Hotel" },
        { value: 3, code: "Visa" },
        { value: 4, code: "Miscellaneous" },
      ],
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    /*  const user = localStorage.getItem("username");
    if (!user) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    } */
    if (this.props.vendor) {
      this.setState({
        id: this.props.vendor.id,
        selectedVendorTypes: this.props.vendor.selectedVendorTypes,
        vendorName: this.props.vendor.vendorName,
        contactPersonName: this.props.vendor.contactPersonName,
        email: this.props.vendor.email,
        contactNumber: this.props.vendor.contactNumber,
        trn: this.props.vendor.trn,
        address1: this.props.vendor.address1,
        address2: this.props.vendor.address2,
        city: this.props.vendor.city,
        bankCode: this.props.vendor.bankCode,
        iban: this.props.vendor.iban,
        swiftCode: this.props.vendor.swiftCode,
        bankName: this.props.vendor.bankName,
        bankBranch: this.props.vendor.bankBranch,
      });
    }
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  handleCheckChange(changeObject) {
    debugger;
    var vendorType = this.state.selectedVendorTypes;
    if (vendorType.includes(changeObject)) {
      vendorType.splice(vendorType.indexOf(changeObject), 1);
    } else {
      vendorType.push(changeObject);
    }
    this.setState({
      selectedVendorTypes: vendorType,
    });
  }
  validation = () => {
    if (this.state.vendorName == "") {
      this.setErrorMessage("Please enter vendor name.", 3);
      return false;
    }
    if (this.state.contactPersonName == "") {
      this.setErrorMessage("Please enter contact person name.", 3);
      return false;
    }
    if (this.state.email == "") {
      this.setErrorMessage("Please enter email.", 3);
      return false;
    }
    if(!this.validateEmail(this.state.email)){
      this.setErrorMessage("Invalid email.", 3);
      return false;
    }
    if (this.state.contactNumber == "") {
      this.setErrorMessage("Please enter contact number.", 3);
      return false;
    }
    if (!this.validatePhoneNumber(this.state.contactNumber)) {
      this.setErrorMessage("Invalid contact number format.", 3);
      return false;
    }

    if (this.state.selectedVendorTypes.length<=0) {
      this.setErrorMessage("Please select vendor type.", 3);
      return false;
    }

    if (this.state.countryCode == "") {
      this.setErrorMessage("Please select country.", 3);
      return false;
    }

    if (this.state.city == "") {
      this.setErrorMessage("Please enter city.", 3);
      return false;
    }

    if (this.state.trn == "") {
      this.setErrorMessage("Please enter TRN.", 3);
      return false;
    }

    if (this.state.trn.length>15) {
      this.setErrorMessage("TRN number should be less than 15 characters.", 3);
      return false;
    }

    return true;
  };

  validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  validatePhoneNumber = (number) => {
    const regex = /^\+\d{1,3}\d{10}$/;
    return regex.test(number);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  saveVendorHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing: true,
    });
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      Id: this.state.id,
      vendorName: this.state.vendorName,
      ContactPersonName: this.state.contactPersonName,
      Email: this.state.email,
      ContactNumber: this.state.contactNumber,
      Trn: this.state.trn,
      Address1: this.state.address1,
      Address2: this.state.address2,
      City: this.state.city,
      CountryCode: "United Arab Emirates",
      BankCode: this.state.bankCode,
      Iban: this.state.iban,
      SwiftCode: this.state.swiftCode,
      BankName: this.state.bankName,
      BankBranch: this.state.bankBranch,
      SelectedVendorTypes: this.state.selectedVendorTypes,

    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Master/SaveVendor`, payload, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isSaveProcessing: false,
        });
        this.props.setLoader(false);
        window.location.reload();
      });
  };

  removeVendorHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isRemoveProcessing: true,
    });
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Master/RemoveVendor`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isRemoveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isRemoveProcessing: false,
        });
        this.props.setLoader(false);
        window.location.reload();
      });
  };

  render() {
    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = (accept) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <>
        {
          <div>
            <form>
              <Fieldset legend="Main Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Vendor Name *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Vendor Name"
                      value={this.state.vendorName}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          vendorName: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Contact Person Name *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Contact Person Name"
                      value={this.state.contactPersonName}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          contactPersonName: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Email *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      value={this.state.email}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          email: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Contact Number *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Contact Number"
                      type="tel"
                      value={this.state.contactNumber}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          contactNumber: input,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Address line 1
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Address line 1"
                      type="text"
                      value={this.state.address1}
                      onChange={(e) =>
                        this.handleChange({ address1: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Address line 2
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Address line 2"
                      type="text"
                      value={this.state.address2}
                      onChange={(e) =>
                        this.handleChange({ address2: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    City *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="City"
                      type="text"
                      value={this.state.city}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          city: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Country *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      value={"United Arab Emirates"}
                    />
                  </div>
                </div>
              </Fieldset>
              <br />
              <Fieldset legend="Vendor Type">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="vendorType1"
                    name="Transportation"
                    value={1}
                    checked={this.state.selectedVendorTypes.includes(1)}
                    onChange={(e) => this.handleCheckChange(1)}
                  />
                  <label htmlFor="vendorType1" className="ml-2">
                    Transportation
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="vendorType2"
                    name="Hotel"
                    value={2}
                    checked={this.state.selectedVendorTypes.includes(2)}
                    onChange={(e) => this.handleCheckChange(2)}
                  />
                  <label htmlFor="vendorType2" className="ml-2">
                    Hotel
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="vendorType3"
                    name="Visa"
                    value={3}
                    checked={this.state.selectedVendorTypes.includes(3)}
                    onChange={(e) => this.handleCheckChange(3)}
                  />
                  <label htmlFor="vendorType3" className="ml-2">
                    Visa
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="vendorType4"
                    name="Miscellaneous"
                    value={4}
                    checked={this.state.selectedVendorTypes.includes(4)}
                    onChange={(e) => this.handleCheckChange(4)}
                  />
                  <label htmlFor="vendorType4" className="ml-2">
                    Miscellaneous
                  </label>
                </div>
              </Fieldset>
              <br />
              <Fieldset legend="Finance Details">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    TRN # *
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="TRN"
                      type="text"
                      value={this.state.trn}
                      onChange={(e) => {
                        var input = DOMPurify.sanitize(e.target.value);
                        this.handleChange({
                          trn: input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Bank Code
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Bank Code"
                      type="text"
                      value={this.state.bankCode}
                      onChange={(e) =>
                        this.handleChange({ bankCode: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    IBAN #
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="IBAN"
                      type="text"
                      value={this.state.iban}
                      onChange={(e) =>
                        this.handleChange({ iban: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Swift Code
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Swift Code"
                      type="text"
                      value={this.state.swiftCode}
                      onChange={(e) =>
                        this.handleChange({ swiftCode: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Bank Name
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Bank Name"
                      type="text"
                      value={this.state.bankName}
                      onChange={(e) =>
                        this.handleChange({ bankName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-4 col-form-label">
                    Bank Branch
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <input
                      className="form-control"
                      placeholder="Bank Branch"
                      type="text"
                      value={this.state.bankBranch}
                      onChange={(e) =>
                        this.handleChange({ bankBranch: e.target.value })
                      }
                    />
                  </div>
                </div>
              </Fieldset>
              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  {this.state.id > 0 && (
                    <Button
                      type="button"
                      label="Delete"
                      className="col-3 btn btn-danger pull-right"
                      style={{ margin: "10px" }}
                      loading={this.state.isRemoveProcessing}
                      onClick={(e) => confirm(this.removeVendorHandler)}
                    />
                  )}
                  <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    loading={this.state.isSaveProcessing}
                    onClick={(e) => confirm(this.saveVendorHandler)}
                  />
                </div>
              </div>
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
            <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        }
      </>
    );
  }
}

export default AddEditVendor;
