import React from "react";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from 'primereact/button';
import "react-toastify/dist/ReactToastify.css";
class AddProformaReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      paymentMethod: "",
      amount: "",
      remark: "",
      receiptDate: "",
      errorMessage: "",
      isError: false,
      paymentMethods: [],
      isSaveProcessing:false,
      isRemoveProcessing:false
    };
  }
  componentDidMount = async () => {    
    await this.getReferenceData();
    if (this.props.receipt) {
      this.setState({
        id: this.props.receipt.id,
        paymentMethod:this.props.receipt.paymentMethodId,
        amount:this.props.receipt.amount,
        receiptDate:new Date(this.props.receipt.receiptDate),
        remarks:this.props.receipt.remark,
      });

    }
  };
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          paymentMethods: response.paymentMethods,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  validation = () => {
    if (this.state.receiptDate == "") {
      this.setErrorMessage("Please select receipt date", 3);
      return false;
    }
    if (this.state.paymentMethod == "") {
      this.setErrorMessage("Please select payment method", 3);
      return false;
    }
    if (this.state.amount == "") {
      this.setErrorMessage("Please enter receipt amount", 3);
      return false;
    }

    return true;
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  saveReceipt = async (e) => {
    this.setState({
      isSaveProcessing:true
    })
    if (!this.validation()) {
      this.setState({
        isSaveProcessing:false
      })
      return;
    }
    var payload = {
      Id: this.state.id,
      ReceiptDate: this.state.receiptDate,
      Amount: this.state.amount,
      PaymentMethod: this.state.paymentMethod,
      Remark: this.state.remarks,
      ProformaInvoiceId: this.props.invoice.id,
      JobCardId: this.props.invoice.jobCardId
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SaveProformaInvoiceReceipt`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            isSaveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isSaveProcessing:false
        })
        this.setErrorMessage("Save Successfully", 1);
        window.location.reload();
      });
  };

  removeReceipt = async (e) => {
    this.setState({
      isRemoveProcessing:true
    })
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/RemoveProformaInvoiceReceipt`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            isRemoveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isRemoveProcessing:false
        })
        this.setErrorMessage("Delete Successfully", 1);
        window.location.reload();
      });
  };

  render() {
    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = (accept) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <>
        {
          <div>
            <form>
              <Fieldset legend="Receipt Details">
                
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Receipt Date
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <Calendar
                      dateFormat="dd/mm/yy"
                      style={{ width: "100%" }}
                      value={this.state.receiptDate}
                      onChange={(e) =>
                        this.handleChange({
                          receiptDate: e.target.value,
                        })
                      }
                      showIcon
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Amount
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      value={this.state.amount}
                      onChange={(e) =>
                        this.handleChange({
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">
                    Payment Method
                  </label>
                  <div className="col-sm-12 col-md-9">
                    <Dropdown
                      style={{ width: "100%" }}
                      value={this.state.paymentMethod}
                      filter
                      onChange={(e) =>
                        this.handleChange({
                          paymentMethod: e.target.value,
                        })
                      }
                      options={this.state.paymentMethods}
                      optionLabel="code"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 col-md-3 col-form-label">Remarks</label>
                  <div className="col-sm-12 col-md-9">
                  <textarea
                    className="form-control"
                    value={this.state.remarks}
                    onChange={(e) =>
                      this.handleChange({ remarks: e.target.value })
                    }
                  ></textarea>
                  </div>
                </div>
              </Fieldset>

              <div className="form-group row">
                <div className="col-sm-12 col-md-12">
                  {this.state.id > 0 && (
                    <Button
                      type="button"
                      label="Delete"
                      className="col-3 btn btn-danger pull-right"
                      style={{ margin: "10px" }}
                      loading={this.state.isRemoveProcessing}
                      onClick={(e) => confirm(this.removeReceipt)}
                    />
                  )}
                  <Button
                    type="button"
                    label={this.state.id > 0 ? "Edit" : "Save"}
                    className="col-3 btn btn-primary pull-right"
                    style={{ margin: "10px" }}
                    loading={this.state.isSaveProcessing}
                    onClick={(e) => confirm(this.saveReceipt)}
                  />
                </div>
              </div>
            </form>
            {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
            <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        }
      </>
    );
  }
}

export default AddProformaReceipt;
