import React from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import PaymentVoucherReportInner from "./PaymentVoucherReportInner";
import dayjs, { Dayjs } from "dayjs";
const ExcelJS = require("exceljs");
class PaymentVoucherReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentVoucher: null,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  exportPaymentVoucherLine = () => {
    debugger;
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Transportation");
    
    sheet.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "bookingRef" },
      { header: "Pickup Time", key: "pickupTime" },
      { header: "Pickup Location", key: "pickupLocation" },
      { header: "Dropoff Location", key: "dropoffLocation" },
      { header: "Flight", key: "flight" },
      { header: "PAX Name", key: "paxName" },
      { header: "A", key: "adults" },
      { header: "C", key: "children" },
      { header: "I", key: "infants" },
      { header: "Vehicle Type", key: "vehicleType" },
      { header: "Remarks", key: "remarks" },
      { header: "Net Cost", key: "costBaseAmount" },
      { header: "Parking", key: "parking" },
      { header: "Water", key: "water" },
      { header: "Extras", key: "extras" },
      
      { header: "VAT", key: "totalCostTaxAmount" },
      { header: "Total Cost", key: "totalCostPrice" },
    ];

    const sheetHotel = workbook.addWorksheet("Hotel");
    sheetHotel.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "hotelCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "A", key: "adults" },
      { header: "C", key: "children" },
      { header: "I", key: "infants" },
      { header: "Hotel Name", key: "hotelName" },
      { header: "Check In", key: "checkIn" },
      { header: "Check Out", key: "checkOut" },
      { header: "Remarks", key: "remarks" },
      { header: "Net Cost", key: "costBaseAmount" },
      { header: "VAT", key: "costTaxAmount" },
      { header: "Total Cost", key: "totalCostPrice" },
    ];

    const sheetVisa = workbook.addWorksheet("Visa");
    sheetVisa.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "visaCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "Passport No", key: "passportNo" },
      { header: "Visa Type Name", key: "visaTypeName" },
      { header: "Remarks", key: "remarks" },
      { header: "Net Cost", key: "costBaseAmount" },
      { header: "VAT", key: "costTaxAmount" },
      { header: "Total Cost", key: "totalCostPrice" },
    ];

    const sheetMiscellaneous = workbook.addWorksheet("Miscellaneous");
    sheetMiscellaneous.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "miscellaneousCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "Description", key: "description" },
      { header: "Mis Date", key: "misDate" },
      { header: "Remarks", key: "remarks" },
      { header: "Net Cost", key: "costBaseAmount" },
      { header: "VAT", key: "costTaxAmount" },
      { header: "Total Cost", key: "totalCostPrice" },
    ];
    const promise = Promise.all(
      this.state.paymentVoucher.paymentVoucherTransportationsLineItems.batchTransportationItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          jobCardNo: i.jobCardNo,
          jobCardTitle: i.jobCardTitle,
          bookingRef: i.bookingRef,
          pickupLocation: i.pickupLocation,
          pickupTime: i.pickupTime,
          dropoffLocation: i.dropoffLocation,
          totalPax: i.totalPax,
          adults: i.adults,
          children: i.children,
          infants: i.infants,
          vehicleType: i.vehicleType,
          flightNo: i.flightNo,
          flightTime: i.flightTime,
          paxName: i.paxName,
          remarks: i.remarks,
          costBaseAmount: i.costBaseAmount,
          parking: i.parking,
          water: i.water,
          extras: i.extras,
          totalCostTaxAmount: i.costTaxAmount,
          totalCostPrice: i.totalCostPrice,
        });
      }),

      this.state.paymentVoucher.paymentVoucherTransportationsLineItems.batchHotelItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetHotel.addRow({
          jobCardNo: i.jobCardNo,
          hotelCode: i.hotelCode,
          paxName: i.paxName,
          adults: i.adults,
          children: i.children,
          infants: i.infants,
          hotelName: i.hotelName,
          checkIn: i.checkIn,
          checkOut: i.checkOut,
          remarks: i.remarks,
          totalCostPrice: i.totalCostPrice,
        });
      }),

      this.state.paymentVoucher.paymentVoucherTransportationsLineItems.batchVisaItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetVisa.addRow({
          jobCardNo: i.jobCardNo,
          visaCode: i.visaCode,
          paxName: i.paxName,
          passportNo: i.passportNo,
          visaTypeName: i.visaTypeName,
          remarks: i.remarks,
          totalCostPrice: i.totalCostPrice,
        });
      }),

      this.state.paymentVoucher.paymentVoucherTransportationsLineItems.batchMiscellaneousItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetMiscellaneous.addRow({
          jobCardNo: i.jobCardNo,
          miscellaneousCode: i.miscellaneousCode,
          paxName: i.paxName,
          description: i.description,
          misDate: i.misDate,
          remarks: i.remarks,
          totalCostPrice: i.totalCostPrice,
        });
      })
    );

  

    const paymentVoucherCode = this.state.paymentVoucher.paymentVoucherCode;
    const docName =
    paymentVoucherCode != null || paymentVoucherCode != "" ? `${paymentVoucherCode}.xlsx` : "";
    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = docName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  componentDidMount = async () => {
    debugger;

    await this.getPaymentVoucher(this.props.id);
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getPaymentVoucher = async (id) => {
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetPaymentVoucherById?` +
        new URLSearchParams({
          id: id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        if (response != null && response.paymentVoucher != null) {
          batchList.push({
            id: response.paymentVoucher.id,
            paymentVoucherCode: response.paymentVoucher.paymentVoucherCode,
            paymentVoucherAmount: response.paymentVoucher.paymentVoucherAmount,
            invoiceNo: response.paymentVoucher.invoiceNo,
            vendorName: response.paymentVoucher.vendorName,
            vendorAddressLine1: response.paymentVoucher.vendorAddressLine1,
            vendorAddressLine2: response.paymentVoucher.vendorAddressLine2,
            vendorBankCode: response.paymentVoucher.vendorBankCode,
            vendorBankName: response.paymentVoucher.vendorBankName,
            remark: response.paymentVoucher.remark,
            paymentVoucherDate:
              response.paymentVoucher.paymentVoucherDate == null
                ? null
                : dayjs(response.paymentVoucher.paymentVoucherDate).format(
                    "DD/MM/YYYY"
                  ),
            paymentVoucherTransportationsLineItems: this.GetPaymentVoucherItems(
              response.paymentVoucher.paymentVoucherGenerateItem
            ),
          });
        }
        if (batchList.length > 0) {
          this.setState({
            paymentVoucher: batchList[0],
          });
        }
      });
  };

  GetPaymentVoucherItems = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];

    items.transportations.map((i) => {
      batchTransportationItems.push({
        id: i.id,
        batchNo: i.batchNo,
        bookingRef: i.bookingRef,
        jobCardNo: i.jobCardNo,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null
            ? null
            : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        costBaseAmount: i.costBaseAmount,
        costTaxAmount: i.totalCostTaxAmount,
        totalCostPrice: i.totalCostPrice,
        parking: i.parking,
        water: i.water,
        extras: i.extras,
        type:"Transportation"
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          type:"Hotel"
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          type:"Visa"
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
          type:"Miscellaneous"
      });
    });
    debugger;

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };

  copyToClip = () => {
    var el = document.getElementById("report");
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    this.setErrorMessage("Batch copied to clipboard, You can paste", 1);
  };
  printDiv = (divName) => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.location.reload();
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  render() {
    return (
      <>
        <div className="main-container">
          <div
            className="card-box mb-30"
            style={{ padding: "10px", width: "fit-content" }}
          >
            {this.state.paymentVoucher && (
              <PaymentVoucherReportInner
                paymentVoucher={this.state.paymentVoucher}
                ref={(el) => (this.componentRef = el)}
              />
            )}
            <div className="row">
              <button
                type="button"
                style={{ margin: "10px" }}
                className="btn btn-primary pull-right"
                onClick={(e) => {
                  this.copyToClip();
                }}
              >
                Copy to Clipboard
              </button>

              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    style={{ margin: "10px" }}
                    className="btn btn-secondary pull-right"
                  >
                    Print Report
                  </button>
                )}
                content={() => this.componentRef}
                copyStyles={true}
                pageStyle="print"
              />
              <button
                type="button"
                style={{ margin: "10px" }}
                className="btn btn-secondary pull-right"
                onClick={(e) => {
                  this.exportPaymentVoucherLine();
                }}
              >
                Export
              </button>
            </div>

            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </>
    );
  }
}

export default PaymentVoucherReport;
