import React from "react";

class ViewInvoiceInner extends React.Component {
  constructor(props) {
    super(props);
  }
  GetJobCardDetails = (items) => {
    return (
      <>
        {items.map((i) => {
          return (
            <>
              <span>
                {i.jobCardNo} {i.paxName} x{i.adults}Adults
              </span>
              <br />
            </>
          );
        })}
      </>
    );
  };
  render() {
    return (
      this.props.invoice.invoiceLineItems &&
      this.props.invoice.invoiceLineItems != null && (
        <div>
          <div className="invoice-wrap">
            <div className="invoice-box" id="report">
              <div className="row pb-30 mt-30">
                <div className="col-md-6 mt-20">
                  <img
                    className="pl-15"
                    style={{ paddingTop: "0.8rem" }}
                    src="../vendors/images/holiday_makers_rlogo.jpg"
                    width="160"
                    alt=""
                  />
                  <p className="font-14 mb-5 reportFont pl-15">
                    <strong className="weight-600">
                      Holiday Makers Tourism LLC
                      <br />
                    </strong>
                    Office 418, City 4 Building,
                    <br /> Al Garhoud, Dubai,
                    <br /> United Arab Emirates
                    <br/> TRN : 100547758100003
                  </p>
                </div>
                <div className="col-md-6 mt-20">
                  <h4 className="reportTitle weight-600 reportFont">
                    TAX INVOICE
                  </h4>
                  <h6 className="text-right 	mb-30 weight-600 reportFont">
                    # {this.props.invoice.invoiceCode}
                  </h6>
                  <div className="text-right">
                    <p className="font-14 mb-5">
                      <strong>Due Amount</strong>
                    </p>
                    <h6 className="font-18 mb-5" style={{ color: "#FF6C04" }}>
                      <b>
                        AED{" "}
                        {(
                          this.props.invoice.invoiceAmount -
                          parseFloat(
                            this.props.invoice.totalLinkedProformaReciptAmount
                          ).toFixed(2)
                        ).toFixed(2)}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="row pb-30">
                <div className="col-md-6">
                  <p className="font-14 mb-5 pl-15">
                    Bill To
                    <br />
                    <strong className="weight-600">
                      {this.props.invoice.customerName}
                    </strong>
                    {this.props.invoice.addressLine1 && (
                      <>
                        <br />
                        {this.props.invoice.addressLine1}
                      </>
                    )}
                    {this.props.invoice.addressLine2 && (
                      <>
                        <br />
                        {this.props.invoice.addressLine2}
                      </>
                    )}
                    {this.props.invoice.customerTrn && (
                      <>
                        <br />
                        TRN : {this.props.invoice.customerTrn}
                      </>
                    )}
                    
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                    <p className="font-14 mb-5">
                      Invoice Date :{" "}
                      <strong className="weight-600">
                        {this.props.invoice.invoiceDate}
                      </strong>
                    </p>
                    <p className="font-14 mb-5">
                      Due Date :{" "}
                      <strong className="weight-600">
                        {this.props.invoice.invoiceDueDate}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="invoice-desc pb-30 font-14 pl-15">
                <div className="invoice-desc-head clearfix font-14">
                  <div className="invoice-sub font-14">Description</div>
                  <div className="invoice-subtotal font-14">Amount</div>
                </div>
                <div className="invoice-desc-body">
                  <ul>

                  {this.props.invoice.invoiceLineItems
                      .batchTransportationItems &&
                      this.props.invoice.invoiceLineItems
                        .batchTransportationItems != null &&
                      this.props.invoice.invoiceLineItems
                        .batchTransportationItems.length > 0 && 
                        this.props.invoice.transportJobCardNos &&(
                    <li className="clearfix">
                      <div className="invoice-sub font-14">
                        {this.props.invoice.transportDescription}{" "}
                        {this.props.invoice.transportJobCardNos.join(", ")}
                      </div>

                      <div className="invoice-subtotal font-14">
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceLineItems.batchTransportationItems.reduce(
                            (a, i) => {
                              if (
                                i.totalSellPrice &&
                                (i.totalSellPrice != null) &
                                  (i.totalSellPrice != "")
                              ) {
                                var p =
                                  parseFloat(a) +
                                  (parseFloat(i.totalSellPrice) -
                                    parseFloat(i.totalSellTaxAmount));
                                return p.toFixed(2);
                              }
                            },
                            0
                          )}
                        </span>
                      </div>
                    </li>
                    )}

                    {this.props.invoice.invoiceLineItems
                      .batchHotelItems &&
                      this.props.invoice.invoiceLineItems
                        .batchHotelItems != null &&
                      this.props.invoice.invoiceLineItems
                        .batchHotelItems.length > 0 && 
                        this.props.invoice.hotelJobCardNos &&(
                    <li className="clearfix">
                      <div className="invoice-sub font-14">
                        {this.props.invoice.hotelDescription}{" "}
                        {this.props.invoice.hotelJobCardNos.join(", ")}
                      </div>

                      <div className="invoice-subtotal font-14">
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceLineItems.batchHotelItems.reduce(
                            (a, i) => {
                              if (
                                i.totalSellPrice &&
                                (i.totalSellPrice != null) &
                                  (i.totalSellPrice != "")
                              ) {
                                var p =
                                  parseFloat(a) +
                                  (parseFloat(i.totalSellPrice) -
                                    parseFloat(i.sellTaxAmount));
                                return p.toFixed(2);
                              }
                            },
                            0
                          )}
                        </span>
                      </div>
                    </li>
                    )}

                            
                    {this.props.invoice.invoiceLineItems
                      .batchVisaItems &&
                      this.props.invoice.invoiceLineItems
                        .batchVisaItems != null &&
                      this.props.invoice.invoiceLineItems
                        .batchVisaItems.length > 0 && 
                        this.props.invoice.visaJobCardNos &&(
                    <li className="clearfix">
                      <div className="invoice-sub font-14">
                        {this.props.invoice.visaDescription}{" "}
                        {this.props.invoice.visaJobCardNos.join(", ")}
                      </div>

                      <div className="invoice-subtotal font-14">
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceLineItems.batchVisaItems.reduce(
                            (a, i) => {
                              if (
                                i.totalSellPrice &&
                                (i.totalSellPrice != null) &
                                  (i.totalSellPrice != "")
                              ) {
                                var p =
                                  parseFloat(a) +
                                  (parseFloat(i.totalSellPrice) -
                                    parseFloat(i.sellTaxAmount));
                                return p.toFixed(2);
                              }
                            },
                            0
                          )}
                        </span>
                      </div>
                    </li>
                    )}

                    {this.props.invoice.invoiceLineItems
                      .batchMiscellaneousItems &&
                      this.props.invoice.invoiceLineItems
                        .batchMiscellaneousItems != null &&
                      this.props.invoice.invoiceLineItems
                        .batchMiscellaneousItems.length > 0 && 
                        this.props.invoice.miscellaneousJobCardNos &&(
                        <li className="clearfix">
                          <div className="invoice-sub font-14">
                            {this.props.invoice.miscellaneousDescription}{" "}
                            {this.props.invoice.miscellaneousJobCardNos.join(", ")}
                          </div>

                          <div className="invoice-subtotal font-14">
                            <span className="weight-600 font-14">
                              AED{" "}
                              {this.props.invoice.invoiceLineItems.batchMiscellaneousItems.reduce(
                                (a, i) => {
                                  if (
                                    i.totalSellPrice &&
                                    (i.totalSellPrice != null) &
                                      (i.totalSellPrice != "")
                                  ) {
                                    var p =
                                      parseFloat(a) +
                                      (parseFloat(i.totalSellPrice) -
                                        parseFloat(i.sellTaxAmount));
                                    return p.toFixed(2);
                                  }
                                },
                                0
                              )}
                            </span>
                          </div>
                        </li>
                      )}

                    <li className="clearfix">
                      <div className="invoice-total font-14">Net Amount</div>
                      <div className="invoice-subtotal">
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceNetAmount}
                        </span>
                      </div>
                    </li>
                    <li className="clearfix">
                      <div className="invoice-total font-14">VAT</div>
                      <div className="invoice-subtotal">
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceVatAmount}
                        </span>
                      </div>
                    </li>
                    <li className="clearfix" style={{ borderBottom: "0px" }}>
                      <div className="invoice-total font-14">Gross Amount</div>
                      <div
                        className="invoice-subtotal"
                        style={{ color: "#fff", backgroundColor: "#FF6C04" }}
                      >
                        <span className="weight-600 font-14">
                          AED{" "}
                          {this.props.invoice.invoiceGrossAmount}
                        </span>
                      </div>
                    </li>
                    {this.props.invoice.totalLinkedProformaReciptAmount > 0 && (
                      <>
                        <li className="clearfix">
                          <div className="invoice-sub font-14">
                            {"Adjustments "}
                            {"("}
                            {this.props.invoice.linkProformaInvoiceCodes}
                            {")"}
                          </div>

                          <div className="invoice-subtotal font-14">
                            <span className="weight-600 font-14">
                              AED{" "}
                              {parseFloat(
                                this.props.invoice
                                  .totalLinkedProformaReciptAmount
                              ).toFixed(2)}
                            </span>
                          </div>
                        </li>
                        <li
                          className="clearfix"
                          style={{ borderBottom: "0px" }}
                        >
                          <div className="invoice-total font-14">
                            Total Gross Balance
                          </div>
                          <div
                            className="invoice-subtotal"
                            style={{
                              color: "#fff",
                              backgroundColor: "#FF6C04",
                            }}
                          >
                            <span className="weight-600 font-14">
                              AED{" "}
                              {(
                                this.props.invoice.invoiceGrossAmount -
                                this.props.invoice
                                  .totalLinkedProformaReciptAmount
                              ).toFixed(2)}
                            </span>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="invoice-desc-footer">
                  <div className="invoice-desc-body">
                    <div>
                      <strong className="font-14 mb-5">Note</strong>
                      <p className="font-14 mb-5"></p>
                    </div>
                    <div>
                      <strong className="font-14 mb-5">Account Details </strong>
                      <p className="font-14 mb-5">
                        <strong className="weight-600 font-14">
                          REMITTANCE TO "HOLIDAY MAKERS TOURISM LLC"
                        </strong>
                      </p>
                      <p className="font-14 mb-5 font-14">
                        A/C NO 1015576445201
                      </p>
                      <p className="font-14 mb-5 font-14">
                        EMIRATES BANK INTERNATIONAL,
                      </p>
                      <p className="font-14 mb-5 font-14">
                        IBAN - AE230260001015576445201
                      </p>
                      <p className="font-14 mb-5 font-14">
                        SWIFT CODE EBILAEAD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
              <p align="center">
                {" "}
                Office 418, City 4 Building, Al Garhoud, Dubai, United Arab
                Emirates
                <br />
                +971 4 257 9192 | ops@holidaymakers.ae | www.holidaymakers.ae
              </p>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default ViewInvoiceInner;
