import React from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactToPrint from "react-to-print";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import JobCardFullReportInner from "./JobCardFullReportInner";
import JobCardAgentReportInner from "./JobCardAgentReportInner";
class JobCardAgentReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  printDiv = (divName) => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.location.reload();
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  copyToClip = () => {
    var el = document.getElementById("report");
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    this.setErrorMessage("Report copied to clipboard, You can paste", 1);
  };
  
  
  render() {
    return (
      <div className="main-container">
        <div
          className="card-box mb-30"
          style={{ padding: "10px"}}
        >
          <JobCardAgentReportInner
            jobCard={this.props.jobCard}
            transports={this.props.transports}
            tProfit={this.props.tProfit}
            hotels={this.props.hotels}
            hProfit={this.props.hProfit}
            visas={this.props.visas}
            vProfit={this.props.vProfit}
            miscellanea={this.props.miscellanea}
            mProfit={this.props.mProfit}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
        <div className="row">
          <button
            type="button"
            style={{ margin: "10px" }}
            className="btn btn-primary pull-right"
            onClick={(e) => {
              this.copyToClip();
            }}
          >
            Copy to Clipboard
          </button>

          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                style={{ margin: "10px" }}
                className="btn btn-secondary pull-right"
              >
                Print Report
              </button>
            )}
            content={() => this.componentRef}
            copyStyles={true}
            pageStyle="print"
          />
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default JobCardAgentReport;
