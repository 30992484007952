import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
import "../src/styles/print.css";
class DailyCostReportInner extends React.Component {
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />
          
          {this.props.customer && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Customer Name:{" "}
                <b>
                  <>{this.props.customer}</>
                </b>
              </p>
            </>
          )}

          {this.props.clientRef && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Customer Ref:{" "}
                <b>
                  <>{this.props.clientRef}</>
                </b>
              </p>
            </>
          )}

          {this.props.vendor && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Vendor:{" "}
                <b>
                  <>{this.props.vendor}</>
                </b>
              </p>
            </>
          )}
          {this.props.batchNo && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Batch No:{" "}
                <b>
                  <>{this.props.batchNo}</>
                </b>
              </p>
            </>
          )}
          {this.props.bookingRef && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Booking Ref:{" "}
                <b>
                  <>{this.props.bookingRef}</>
                </b>
              </p>
            </>
          )}
          {this.props.jobCardCode && (
            <>
              <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
                Job Card Number:{" "}
                <b>
                  <>{this.props.jobCardCode}</>
                </b>
              </p>
            </>
          )}
          {this.props.effectiveDateFrom && (
            <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
              Duty Date:
              <b>{dayjs(this.props.effectiveDateFrom).format("DD/MM/YYYY")}</b>
            </p>
          )}
          <br />
          <div className="pb-20">
          <table width="1573" border="1" align="center" style={{border:"1px solid #fff"}}
            >
              <thead>
                <tr
                style={{
                  background: "#fd6511",
                  color:"#FFF",
                  fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif"
                }}
                >
                  <th width={68} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Booking Ref </th>
                  <th width={77} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Pickup Time</th>
                  <th width={136} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Pickup Location</th>
                  <th width={144} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Dropoff Location</th>
                  <th width={87} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Flight No & Time</th>
                  <th width={183} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">PAX Name</th>
                  <th width={50} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Total PAX</th>
                  <th width={28} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">A</th>
                  <th width={25} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">C</th>
                  <th width={117} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Vehicle Type</th>
                  <th width={154} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Remarks</th>
                  <th width={118} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Supplier</th>
                  <th width={94} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}} scope="col">Batch No</th>
                </tr>
              </thead>
              <tbody>
                {this.props.dailyReport.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        background: "#dee2e4",
                        fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif"
                      }}
                    >
                      <td width={68} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.bookingRef}</td>
                      <td width={77} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}><b>{i.pickUpTime}</b></td>
                      <td width={136} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.pickUpLocation}</td>
                      <td width={144} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.dropOffLocation}</td>
                      <td width={87} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.flightDetail}</td>
                      <td width={183} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.paxName}</td>
                      <td width={50} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.totalPax}</td>
                      <td width={28} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.adult}</td>
                      <td width={25} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.children}</td>
                      <td width={117} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.vehicleType}</td>
                      <td width={154} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.remarks}</td>
                      <td width={118} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.suplierName}</td>
                      <td width={94} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.batchNo}</td>
                    </tr>
                  ) : (
                    <tr style={{
                      background: "#f6f6f7",
                      fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif"
                    }}>
                      <td width={68} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.bookingRef}</td>
                      <td width={77} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}><b>{i.pickUpTime}</b></td>
                      <td width={136} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.pickUpLocation}</td>
                      <td width={144} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.dropOffLocation}</td>
                      <td width={87} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.flightDetail}</td>
                      <td width={183} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.paxName}</td>
                      <td width={50} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.totalPax}</td>
                      <td width={28} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.adult}</td>
                      <td width={25} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.children}</td>
                      <td width={117} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.vehicleType}</td>
                      <td width={154} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.remarks}</td>
                      <td width={118} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.suplierName}</td>
                      <td width={94} style={{padding:"5px",border:"1px solid #fff",fontFamily:"Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",fontSize:"14px"}}>{i.batchNo}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default DailyCostReportInner;
