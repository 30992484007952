import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import LayoutAdmin from './components/shards/LayoutAdmin';
import ViewVendors from './pages/ViewVendors';
import ViewCustomers from './pages/ViewCustomers';
import ViewJobCards from './pages/ViewJobCards';
import AddJobCardWrap from './pages/AddJobCardWrap';
import ReportWrap from './pages/ReportWrap';
import ViewInvoice from './pages/ViewInvoice';
import ViewJobCardWarp from './pages/ViewJobCardWarp';
import PrintJobCard from './pages/PrintJobCard';
import BatchProcess from './pages/BatchProcess';
import ViewBatches from './pages/ViewBatch';
import CostUpdate from './pages/CostUpdate';
import Test from './components/Chartline';
import PrintAgentJobCard from './pages/PrintAgentJobCard';
import PaymentVoucherGeneration from './pages/PaymentVoucherGeneration';
import ViewPaymentVouchers from './pages/ViewPaymentVouchers';
import InvoiceGeneration from './pages/InvoiceGeneration';
import ViewInvoices from './pages/ViewInvoices';
import ProformaInvoiceGeneration from './pages/ProformaInvoiceGeneration';
import ViewProformaInvoices from './pages/ViewProformaInvoices';
import ViewUsers from './pages/ViewUsers';
import ViewProformaInvoiceReceipt from './pages/ProformaInvoicesReceipt';
import ProformaInvoicesReceiptWarp from './pages/ProformaInvoicesReceiptWarp';
import InvoicesReceiptWarp from './pages/InvoicesReceiptWarp';
import InvoiceReportWrap from './pages/InvoiceReportWrap';
import JobCardFullReportWrap from './pages/JobCardFullReportWrap';
import ViewEvents from './pages/ViewEvents';
import PNAReportWrap from './pages/PNAReportWrap';
import CostReportWrap from './pages/CostReportWrap';
import VatReportWrap from './pages/VatReportWrap';
import UnInvoiceReportWrap from './pages/UnInvoiceReportWrap';
import HistoryReportWrap from './pages/HistoryReportWrap';

function App() {
  return (
    <BrowserRouter>
      <main>
    <Routes>
    <Route  path={`${process.env.PUBLIC_URL}/login`} element={<Login/>} />
    <Route  path={`${process.env.PUBLIC_URL}/test`} element={<Test/>} />
    
    <Route  path={`${process.env.PUBLIC_URL}/`} element={(<LayoutAdmin><Dashboard/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_vendors`} element={(<LayoutAdmin><ViewVendors/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_events`} element={(<LayoutAdmin><ViewEvents/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_users`} element={(<LayoutAdmin><ViewUsers/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/batch_process`} element={(<LayoutAdmin><BatchProcess/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_batches`} element={(<LayoutAdmin><ViewBatches/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/cost_update`} element={(<LayoutAdmin><CostUpdate/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_customers`} element={(<LayoutAdmin><ViewCustomers/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_job_cards`} element={(<LayoutAdmin><ViewJobCards/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_job_card/:jobCardId`} element={<ViewJobCardWarp/>}/>
    <Route  path={`${process.env.PUBLIC_URL}/add_job_card`} element={(<LayoutAdmin><AddJobCardWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/daily_full_report`} element={(<LayoutAdmin><ReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/history_report`} element={(<LayoutAdmin><HistoryReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/cost_report`} element={(<LayoutAdmin><CostReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/un_invoice_report`} element={(<LayoutAdmin><UnInvoiceReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_invoice`} element={(<LayoutAdmin><ViewInvoice/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/print_full_jobcard/:jobCardId`} element={<PrintJobCard/>}/>
    <Route  path={`${process.env.PUBLIC_URL}/print_agent_jobcard/:jobCardId`} element={<PrintAgentJobCard/>}/>
    <Route  path={`${process.env.PUBLIC_URL}/generate_payment_voucher`} element={(<LayoutAdmin><PaymentVoucherGeneration/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_payment_voucher`} element={(<LayoutAdmin><ViewPaymentVouchers/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/generate_invoice`} element={(<LayoutAdmin><InvoiceGeneration/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/generate_proforma_invoice`} element={(<LayoutAdmin><ProformaInvoiceGeneration/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_invoices`} element={(<LayoutAdmin><ViewInvoices/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/view_proforma_invoices`} element={(<LayoutAdmin><ViewProformaInvoices/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/generate_payment_receipt`} element={(<LayoutAdmin><ViewInvoice/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/proforma_invoice_receipt/:invoiceId`} element={<ProformaInvoicesReceiptWarp/>}/>
    <Route  path={`${process.env.PUBLIC_URL}/invoice_receipt/:invoiceId`} element={<InvoicesReceiptWarp/>}/>
    <Route  path={`${process.env.PUBLIC_URL}/invoice_report`} element={(<LayoutAdmin><InvoiceReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/vat_report`} element={(<LayoutAdmin><VatReportWrap/></LayoutAdmin>)}/>
    <Route  path={`${process.env.PUBLIC_URL}/pnl_report`} element={(<LayoutAdmin><PNAReportWrap/></LayoutAdmin>)}/>
    </Routes>
    </main>
    </BrowserRouter>
  );
}

export default App;
