import React from "react";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import PreLoader from "../components/shards/PreLoader";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      username: "",
      isLoading:false
    };
  }

  componentDidMount = async () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("roleId");
  };
  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }
  /* login = async () => {
    if (this.state.username == "prathibha" && this.state.password == "123456") {
      localStorage.setItem("username", this.state.username);
      window.location.href = `${process.env.PUBLIC_URL}/`;
    }
    toast.error("Invalid Login");
    localStorage.setItem("username", null);
  }; */
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  login = async () => {
    this.setState({
      isLoading: true,
    });
    debugger;
    var payload = {
      username: this.state.username,
      password: this.state.password,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Master/LoginUser`, payload)
      .then((response) => {
        debugger;
        if (!response.data.created) {
          this.setState({
            isError: true,
            errorMessage: response.data.error.message,
            isLoading: false,
          });
          this.setErrorMessage(response.data.error.message, 3);
          return;
        }
        debugger;
        localStorage.setItem("roleId", response.data.roleId);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userName", response.data.userName);
        window.location.href = `${process.env.PUBLIC_URL}/`;
      });
  };
  render() {
    return (
      <div>
        <body
          className="login-page"
          style={{
            backgroundImage: "url(https://metapro.ae/apps/odyssey/hm/bg.jpg)",
            backgroundPosition: "top",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center pd-50">
            <div className="container">
              <div className="row" style={{ paddingTop: "15vh" }}>
                <div className="col-md-6 col-lg-7">
                  <img
                    style={{ paddingTop: "5vh" }}
                    src="vendors/images/holiday_makers_logo.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="login-box bg-white box-shadow border-radius-10">
                    <div className="login-title center">
                      <img src="vendors/images/odyssey_logo.png" alt="" />
                      <br />
                    </div>
                    <form>
                      <div className="input-group custom">
                        <InputText
                          style={{ width: "100%" }}
                          value={this.state.username}
                          onChange={(e) =>
                            this.handleChange({
                              username: e.target.value,
                            })
                          }
                        />
                        <div className="input-group-append custom">
                          <span className="input-group-text">
                            <i className="icon-copy dw dw-user1"></i>
                          </span>
                        </div>
                      </div>
                      <div className="input-group custom">
                        <InputText
                          type={"password"}
                          style={{ width: "100%" }}
                          value={this.state.password}
                          onChange={(e) =>
                            this.handleChange({
                              password: e.target.value,
                            })
                          }
                        />
                        <div className="input-group-append custom">
                          <span className="input-group-text">
                            <i className="dw dw-padlock1"></i>
                          </span>
                        </div>
                      </div>
                      {/* <div className="row pb-30">
                        <div className="col-6">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck1"
                            >
                              Remember
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="forgot-password">
                            <a href="forgot-password.html">Forgot Password</a>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="input-group mb-0">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary btn-lg btn-block"
                              onClick={(e) => {
                                this.login();
                              }}
                            >
                              Sign In
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <br />
                      <p
                        style={{
                          fontSize: "15px",
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        Infused with passion by{" "}
                        <a
                          style={{ color: "#fff" }}
                          href="http://metapro.ae/"
                          target="_blank"
                        >
                          Meta Pro Solutions
                        </a>{" "}
                        Dubai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
        {this.state.isLoading && (<PreLoader/>)}
      </div>
    );
  }
}

export default Login;
