import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Chart } from "primereact/chart";
import dayjs, { Dayjs } from "dayjs";
import Chartline from "../components/Chartline";
import ChartPolarArea from "../components/ChartPolarArea";
import PreLoader from "../components/shards/PreLoader";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: null,
      chartData: {},
      chartOption: {},
      chartData_2: {},
      chartOption_2: {},
      chartData_3: {},
      chartOption_3: {},
      events: [],
      customersMonthly: [],
      customersYearly: [],
      vendorsMonthly: [],
      vendorsYearly: [],
      upcomingTransfers: [],
      upcomingHotels: [],
      upcomingVisas: [],
      upcomingMiscellaneours: [],
      unBatchTransfers: [],
      c1Generated: [],
      c1Paid: [],
      c1UnPaid: [],
      c2Monthly: [],
      c2Yearly: [],
      totalInvoice: 0,
      totalInvoiceValue: 0,
      totalGeneratedInvoice: 0,
      totalPaidInvoice: 0,
      totalUnPaidInvoice: 0,
      totalJobCardMonthly: 0,
      totalJobCardValueMonthly: 0,
      totalJobCardValueYearly: 0,
      totalJobCardYearly: 0,
      paidPresentage: 0,
      isModalOpen: false,
      selectedEvent: null,
      roleId: null,
      isLoading:false
    };
  }

  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    await this.getDashboardItems();
    const roleId = localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
      isLoading: false,
    });
  };
  handleResponse(response) {
    debugger;
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getDashboardItems = async () => {
    const eventList = [];
    const customerMonthlyList = [];
    const customerYearlyList = [];
    const vendorMonthlyList = [];
    const vendorYearlyList = [];
    const upcomingTransfersList = [];
    const upcomingHotelsList = [];
    const upcomingVisasList = [];
    const upcomingMiscellaneoursList = [];
    const unBatchTransfersList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetDashboardItems`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.dashboard.events.map((i) =>
          eventList.push({
            title: `${i.eventName} - ${i.eventType}`,
            eventName: i.eventName,
            start: new Date(i.eventFromDate),
            end: new Date(i.eventToDate),
            eventFromDateStr:
              i.eventFromDate == null
                ? null
                : dayjs(i.eventFromDate).format("DD/MM/YYYY"),
            eventToDateStr:
              i.eventToDate == null
                ? null
                : dayjs(i.eventToDate).format("DD/MM/YYYY"),
            eventType: i.eventType,
            remark: i.remark,
            customerName: i.customerName,
          })
        );

        response.dashboard.customersMonthly.map((i) =>
          customerMonthlyList.push({
            customerName: i.customerName,
            totalBookingCount: i.totalBookingCount,
          })
        );

        response.dashboard.customersYearly.map((i) =>
          customerYearlyList.push({
            customerName: i.customerName,
            totalBookingCount: i.totalBookingCount,
          })
        );

        response.dashboard.vendorsMonthly.map((i) =>
          vendorMonthlyList.push({
            vendorName: i.vendorName,
            totalBookingCount: i.totalBookingCount,
          })
        );

        response.dashboard.vendorsYearly.map((i) =>
          vendorYearlyList.push({
            vendorName: i.vendorName,
            totalBookingCount: i.totalBookingCount,
          })
        );

        response.dashboard.upcomingTransfers.map((i) =>
          upcomingTransfersList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY"),
          })
        );

        response.dashboard.upcomingHotels.map((i) =>
          upcomingHotelsList.push({
            id: i.id,
            hotelCode: i.hotelCode,
            checkIn:
              i.checkIn == null ? null : dayjs(i.checkIn).format("DD/MM/YYYY"),
          })
        );

        response.dashboard.upcomingVisas.map((i) =>
          upcomingVisasList.push({
            id: i.id,
            visaCode: i.visaCode,
            visaDate:
              i.createdDate == null
                ? null
                : dayjs(i.createdDate).format("DD/MM/YYYY"),
          })
        );

        response.dashboard.upcomingMiscellaneours.map((i) =>
          upcomingMiscellaneoursList.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            misDate:
              i.misDate == null ? null : dayjs(i.misDate).format("DD/MM/YYYY"),
          })
        );

        response.dashboard.unBatchTransfers.map((i) =>
          unBatchTransfersList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY"),
          })
        );
        debugger;
        this.setState({
          events: eventList,
          customersMonthly: customerMonthlyList,
          customersYearly: customerYearlyList,
          vendorsMonthly: vendorMonthlyList,
          vendorsYearly: vendorYearlyList,
          upcomingTransfers: upcomingTransfersList,
          upcomingHotels: upcomingHotelsList,
          upcomingVisas: upcomingVisasList,
          upcomingMiscellaneours: upcomingMiscellaneoursList,
          unBatchTransfers: unBatchTransfersList,
          c1Generated: response.dashboard.chart_1.generated,
          c1Paid: response.dashboard.chart_1.paid,
          c1UnPaid: response.dashboard.chart_1.unPaid,
          c2Monthly: response.dashboard.chart_2.monthly,
          c2Yearly: response.dashboard.chart_2.yearly,
          totalInvoice: response.dashboard.totalInvoice,
          totalInvoiceValue: response.dashboard.totalInvoiceValue,
          totalGeneratedInvoice: response.dashboard.totalGeneratedInvoice,
          totalPaidInvoice: response.dashboard.totalPaidInvoice,
          totalUnPaidInvoice: response.dashboard.totalUnPaidInvoice,
          totalJobCardMonthly: response.dashboard.totalJobCardMonthly,
          totalJobCardValueMonthly: response.dashboard.totalJobCardValueMonthly,
          totalJobCardValueYearly: response.dashboard.totalJobCardValueYearly,
          totalJobCardYearly: response.dashboard.totalJobCardYearly,
          paidPresentage: response.dashboard.paidPresentage,
        });
      });
  };

  handleEventSelect = (event) => {
    debugger;
    this.setState({
      selectedEvent: event,
      isModalOpen: true,
    });
  };

  setChart_2_Data = () => {
    var documentStyle = getComputedStyle(document.documentElement);
    var textColor = documentStyle.getPropertyValue("--text-color");
    var surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    var data = {
      datasets: [
        {
          data: this.state.c2Monthly, //[11, 16, 7, 3],
          backgroundColor: [
            documentStyle.getPropertyValue("--red-500"),
            documentStyle.getPropertyValue("--green-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--bluegray-500"),
            documentStyle.getPropertyValue("--blue-500"),
          ],
        },
      ],
      labels: ["Transportation", "Hotel", "Visa", "Miscellaneous"],
    };
    var options = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    this.setState({
      chartData_2: data,
      chartOption_2: options,
    });
  };

  setChart_3_Data = () => {
    var documentStyle = getComputedStyle(document.documentElement);
    var textColor = documentStyle.getPropertyValue("--text-color");
    var surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    var data = {
      datasets: [
        {
          data: this.state.c2Yearly, //[11, 16, 7, 3],
          backgroundColor: [
            documentStyle.getPropertyValue("--red-500"),
            documentStyle.getPropertyValue("--green-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--bluegray-500"),
            documentStyle.getPropertyValue("--blue-500"),
          ],
        },
      ],
      labels: ["Transportation", "Hotel", "Visa", "Miscellaneous"],
    };
    var options = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    this.setState({
      chartData_3: data,
      chartOption_3: options,
    });
  };

  render() {
    const localizer = momentLocalizer(moment);
    return (
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          {this.state.roleId == 1 && (
            <>
              <div className="row pb-10">
                <div className="col-6 mb-20">
                  <Card
                    title="Receivables"
                    className="height-100-p widget-style3 dashboard-receivables-summery"
                  >
                    <div className="font-14 text-secondary weight-500">
                    <span className="font-24 text-dark">Total Invoices{" "}</span>
                      {
                        <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                          {this.state.totalInvoice}
                        </span>
                      }
                      <span className="font-24 text-dark">{" "}with a value of </span>
                      {
                        <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                          AED{" "}{this.state.totalInvoiceValue}
                        </span>
                      }
                    </div>
                    <ProgressBar
                      value={this.state.paidPresentage}
                      className="mb-20"
                      showValue={false}
                      color="#fd6511"
                      style={{ height: "22px",marginTop:"30px" }}
                    ></ProgressBar>
                    <div className="row">
                      <div className="col-md-4 mb-20">
                        <div
                          className="font-20 weight-500"
                          style={{ color: "#495057" }}
                        >
                          Generated (Un-Paid)
                        </div>
                        <div className="weight-700 font-24 text-dark">
                          {this.state.totalGeneratedInvoice}
                        </div>
                      </div>
                      <div className="col-md-4 mb-20">
                        <div
                          className="font-20 weight-500"
                          style={{ color: "#fd6511" }}
                        >
                          Paid
                        </div>
                        <div className="weight-700 font-24 text-dark">
                          {this.state.totalPaidInvoice}
                        </div>
                      </div>
                      <div className="col-md-4 mb-20">
                        <div className="font-20 text-secondary weight-500">
                          Partially Paid
                        </div>
                        <div className="weight-700 font-24 text-dark">
                          {" "}
                          {this.state.totalUnPaidInvoice}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-6 mb-20">
                  <Card
                    title="Job Cards"
                    className="height-100-p widget-style3 dashboard-jobcard-summery"
                  >
                    <TabView>
                      <TabPanel header="MTD" style={{fontSize:"24px"}}>
                        <div className="font-14 text-secondary weight-500" style={{marginTop:"30px"}}>
                          {
                            <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                              {this.state.totalJobCardMonthly}
                            </span>
                          }{" "}
                          <span className="weight-500 font-20 text-dark">
                          Job Cards with a value of{" "}</span>
                          {
                            <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                              AED{" "}{this.state.totalJobCardValueMonthly}
                            </span>
                          }
                        </div>
                      </TabPanel>
                      <TabPanel header="YTD" style={{fontSize:"24px"}}>
                        <div className="font-14 text-secondary weight-500" style={{marginTop:"30px"}}>
                          {
                            <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                              {this.state.totalJobCardYearly}
                            </span>
                          }{" "}
                          <span className="weight-500 font-20 text-dark">
                          Job Cards with a value of{" "}</span>
                          {
                            <span className="weight-700 font-24" style={{ color: "#fd6511" }}>
                              AED{" "}{this.state.totalJobCardValueYearly}
                            </span>
                          }
                        </div>
                      </TabPanel>
                    </TabView>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-20">
                  <div className="row">
                    <div className="col-md-6 mb-20">
                      <Card
                        title="Receivables"
                        className="height-100-p widget-style3"
                        style={{ border: "none" }}
                      >
                        <div className="card">
                          {this.state.c1Generated.length > 0 && (
                            <>
                              <Chartline
                                c1Generated={this.state.c1Generated}
                                c1Paid={this.state.c1Paid}
                                c1UnPaid={this.state.c1UnPaid}
                              />
                            </>
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="col-md-6 mb-20">
                      <Card
                        title="Job Cards"
                        className="height-100-p widget-style3"
                      >
                        <div
                          className="card flex justify-content-center"
                          style={{ border: "none" }}
                        >
                          <TabView>
                            <TabPanel header="MTD">
                              {this.state.c2Monthly.length > 0 && (
                                <>
                                  <ChartPolarArea
                                    charData={this.state.c2Monthly}
                                  />
                                </>
                              )}
                              
                            </TabPanel>
                            <TabPanel header="YTD">
                            {this.state.c2Monthly.length > 0 && (
                                <>
                                  <ChartPolarArea
                                    charData={this.state.c2Yearly}
                                  />
                                </>
                              )}
                            </TabPanel>
                          </TabView>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-20">
                  <div className="row">
                    <div className="col-md-6 mb-20">
                      <div className="card-box pb-10 height-100-p widget-style3">
                        <div className="h5 pd-20 mb-0">Top 10 Customers</div>
                        <TabView>
                          <TabPanel header="MTD">
                            <table
                              class="table table-striped table-fixed"
                              style={{ overflow: "inherit" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f6f6f7 !important",
                                  }}
                                >
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Customers Name
                                  </th>
                                  <th>Total Booking</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.customersMonthly.map((i, index) =>
                                  !(index % 2) ? (
                                    <tr
                                      className="tableOddrow"
                                      style={{
                                        fontSize: "10px !important;",
                                        background: "#f5cc98 !important",
                                      }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.customerName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  ) : (
                                    <tr
                                      style={{ fontSize: "10px !important;" }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.customerName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel header="YTD">
                            <table
                              class="table table-striped table-fixed"
                              style={{ overflow: "inherit" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f6f6f7 !important",
                                  }}
                                >
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Customers Name
                                  </th>
                                  <th>Total Booking</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.customersYearly.map((i, index) =>
                                  !(index % 2) ? (
                                    <tr
                                      className="tableOddrow"
                                      style={{
                                        fontSize: "10px !important;",
                                        background: "#f5cc98 !important",
                                      }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.customerName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  ) : (
                                    <tr
                                      style={{ fontSize: "10px !important;" }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.customerName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                        </TabView>
                      </div>
                    </div>
                    <div className="col-md-6 mb-20">
                      <div className="card-box pb-10 height-100-p widget-style3">
                        <div className="h5 pd-20 mb-0">Top 10 Vendors</div>
                        <TabView>
                          <TabPanel header="MTD">
                            <table
                              class="table table-striped table-fixed"
                              style={{ overflow: "inherit" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f6f6f7 !important",
                                  }}
                                >
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Vendor Name
                                  </th>
                                  <th>Total Booking</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.vendorsMonthly.map((i, index) =>
                                  !(index % 2) ? (
                                    <tr
                                      className="tableOddrow"
                                      style={{
                                        fontSize: "10px !important;",
                                        background: "#f5cc98 !important",
                                      }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.vendorName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  ) : (
                                    <tr
                                      style={{ fontSize: "10px !important;" }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.vendorName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel header="YTD">
                            <table
                              class="table table-striped table-fixed"
                              style={{ overflow: "inherit" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f6f6f7 !important",
                                  }}
                                >
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Vendor Name
                                  </th>
                                  <th>Total Booking</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.vendorsYearly.map((i, index) =>
                                  !(index % 2) ? (
                                    <tr
                                      className="tableOddrow"
                                      style={{
                                        fontSize: "10px !important;",
                                        background: "#f5cc98 !important",
                                      }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.vendorName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  ) : (
                                    <tr
                                      style={{ fontSize: "10px !important;" }}
                                    >
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {i.vendorName}
                                      </td>
                                      <td>{i.totalBookingCount}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                        </TabView>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-md-12 mb-20">
              <div className="row">
                <div className="col-md-6 mb-20">
                  <div className="card-box pb-10 height-100-p widget-style3">
                    <div className="h5 pd-20 mb-0">
                      Top 10 Upcoming Activities
                    </div>
                    <TabView>
                      <TabPanel header="Transportation">
                        <table
                          class="table table-striped table-fixed"
                          style={{ overflow: "inherit" }}
                        >
                          <thead>
                            <tr
                              style={{
                                fontSize: "10px !important;",
                                background: "#f6f6f7 !important",
                              }}
                            >
                              <th style={{ whiteSpace: "nowrap" }}>
                                Transportation Number
                              </th>
                              <th>Pickup Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.upcomingTransfers.map((i, index) =>
                              !(index % 2) ? (
                                <tr
                                  className="tableOddrow"
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f5cc98 !important",
                                  }}
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.transportationCode}
                                    </a>
                                  </td>
                                  <td>{i.pickupTime}</td>
                                </tr>
                              ) : (
                                <tr style={{ fontSize: "10px !important;" }}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.transportationCode}
                                    </a>
                                  </td>
                                  <td>{i.pickupTime}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel header="Hotel">
                        <table
                          class="table table-striped table-fixed"
                          style={{ overflow: "inherit" }}
                        >
                          <thead>
                            <tr
                              style={{
                                fontSize: "10px !important;",
                                background: "#f6f6f7 !important",
                              }}
                            >
                              <th style={{ whiteSpace: "nowrap" }}>
                                Hotel Number
                              </th>
                              <th>CheckIn Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.upcomingHotels.map((i, index) =>
                              !(index % 2) ? (
                                <tr
                                  className="tableOddrow"
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f5cc98 !important",
                                  }}
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.hotelCode}
                                    </a>
                                  </td>
                                  <td>{i.checkIn}</td>
                                </tr>
                              ) : (
                                <tr style={{ fontSize: "10px !important;" }}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.hotelCode}
                                    </a>
                                  </td>
                                  <td>{i.checkIn}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel header="Visa">
                        <table
                          class="table table-striped table-fixed"
                          style={{ overflow: "inherit" }}
                        >
                          <thead>
                            <tr
                              style={{
                                fontSize: "10px !important;",
                                background: "#f6f6f7 !important",
                              }}
                            >
                              <th style={{ whiteSpace: "nowrap" }}>
                                Visa Number
                              </th>
                              <th>Visa Created Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.upcomingVisas.map((i, index) =>
                              !(index % 2) ? (
                                <tr
                                  className="tableOddrow"
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f5cc98 !important",
                                  }}
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.visaCode}
                                    </a>
                                  </td>
                                  <td>{i.visaDate}</td>
                                </tr>
                              ) : (
                                <tr style={{ fontSize: "10px !important;" }}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.visaCode}
                                    </a>
                                  </td>
                                  <td>{i.visaDate}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel header="Miscellaneous">
                        <table
                          class="table table-striped table-fixed"
                          style={{ overflow: "inherit" }}
                        >
                          <thead>
                            <tr
                              style={{
                                fontSize: "10px !important;",
                                background: "#f6f6f7 !important",
                              }}
                            >
                              <th style={{ whiteSpace: "nowrap" }}>
                                Miscellaneous Number
                              </th>
                              <th>Miscellaneous Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.upcomingMiscellaneours.map((i, index) =>
                              !(index % 2) ? (
                                <tr
                                  className="tableOddrow"
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f5cc98 !important",
                                  }}
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.miscellaneousCode}
                                    </a>
                                  </td>
                                  <td>{i.misDate}</td>
                                </tr>
                              ) : (
                                <tr style={{ fontSize: "10px !important;" }}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/view_job_card/${i.id}`}
                                    >
                                      {i.miscellaneousCode}
                                    </a>
                                  </td>
                                  <td>{i.misDate}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
                <div className="col-md-6 mb-20">
                  <div className="card-box pb-10 height-100-p widget-style3">
                    <div className="h5 pd-20 mb-0">Un-Batched Bookings</div>
                    <TabView>
                      <TabPanel header="Transportation">
                        <table
                          class="table table-striped table-fixed"
                          style={{ overflow: "inherit" }}
                        >
                          <thead>
                            <tr
                              style={{
                                fontSize: "10px !important;",
                                background: "#f6f6f7 !important",
                              }}
                            >
                              <th style={{ whiteSpace: "nowrap" }}>
                                Transportation Number
                              </th>
                              <th>Pickup Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.unBatchTransfers.map((i, index) =>
                              !(index % 2) ? (
                                <tr
                                  className="tableOddrow"
                                  style={{
                                    fontSize: "10px !important;",
                                    background: "#f5cc98 !important",
                                  }}
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {i.transportationCode}
                                  </td>
                                  <td>{i.pickupTime}</td>
                                </tr>
                              ) : (
                                <tr style={{ fontSize: "10px !important;" }}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {i.transportationCode}
                                  </td>
                                  <td>{i.pickupTime}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-20">
              <div className="card-box pb-10 pd-ltr-20">
                {this.state.events.length}
                <Calendar
                  localizer={localizer}
                  events={this.state.events}
                  startAccessor="start"
                  endAccessor="end"
                  onSelectEvent={this.handleEventSelect}
                  style={{ height: 800 }}
                />
              </div>
            </div>
          </div>

          <>
            {this.state.selectedEvent && (
              <Dialog
                maximizable
                headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                header={`${this.state.selectedEvent.title}`}
                visible={this.state.isModalOpen}
                style={{ width: "50vw" }}
                onHide={(e) => {
                  this.setState({ isModalOpen: false });
                }}
              >
                {this.state.selectedEvent && (
                  <>
                    Event Start : {this.state.selectedEvent.eventFromDateStr}{" "}
                    and Event End : {this.state.selectedEvent.eventToDateStr}
                    <br />
                    Customer Name : {this.state.selectedEvent.customerName}
                    <br />
                    Remark : {this.state.selectedEvent.remark}
                  </>
                )}
              </Dialog>
            )}
          </>
        </div>
        {this.state.isLoading && (<PreLoader/>)}
      </div>
    );
  }
}

export default Dashboard;
