import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
import { MultiSelect } from "primereact/multiselect";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

class ViewJobCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      filterJobs: [],
      jobCardCode: "",
      jobCardDescription: "",
      customerName: "",
      effectiveDateFrom: null,
      effectiveDateTo: null,
      customerId: [],
      jobCardStatusId: [],
      customers: [],
      jobCardStatus: [],
      isLoading: false,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getJobs(true);
    await this.getReferenceData();
  };
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          customers: response.customers,
          jobCardStatus: response.jobCardStatus,
          isLoading: false,
        });
      });
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getJobs = async (isFirstLoad) => {
    debugger
    this.setState({
      isLoading: true,
    });
    const jobList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobs?` +
        new URLSearchParams({
          jobCardCode: this.state.jobCardCode,
          jobCardDescription: this.state.jobCardDescription,
          customerId: this.state.customerId.toString(),
          statusId: this.state.jobCardStatusId.toString(),
          effectiveDateFrom: this.state.effectiveDateFrom
            ? new Date(this.state.effectiveDateFrom).toDateString()
            : "",
          effectiveDateTo: this.state.effectiveDateTo
            ? new Date(this.state.effectiveDateTo).toDateString()
            : "",
          isFirstLoad: isFirstLoad,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCards.map((i) =>
          jobList.push({
            id: i.id,
            jobCardCode: i.jobCardCode,
            jobCardDescription: i.jobCardDescription,
            customerId: i.customerId,
            customerName: i.customerName,
            customerRef: i.customerRef,
            status: i.status,
            effectiveDate:
              i.effectiveDate == null
                ? null
                : dayjs(i.effectiveDate).format("DD/MM/YYYY"),
            remarks: i.remarks,
          })
        );

        if (
          !(
            this.state.jobCardCode ||
            this.state.jobCardDescription ||
            this.state.customerId ||
            this.state.effectiveDateFrom ||
            this.state.effectiveDateTo
          )
        ) {
          this.setState({
            jobs: jobList,
          });
        }
        this.setState({
          filterJobs: jobList,
        });
        this.setState({
          isLoading: false,
        });
      });
  };

  openJobCard(e) {
    window.location.href = `${process.env.PUBLIC_URL}/view_job_card/${e.value.id}`;
  }
  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      jobCardCode: "",
      jobCardDescription: "",
      customerName: "",
      effectiveDateFrom: null,
      effectiveDateTo: null,
      filterJobs: this.state.jobs,
      customerId: "",
    });
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getJobs(false);
    }
  };

  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          {" "}
                          <img
                            width={"50px"}
                            src="/vendors/images/view.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          View Job Cards
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="title">
                        <h4>Search</h4>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Customer</label>
                              <MultiSelect
                                value={this.state.customerId}
                                onChange={(e) =>
                                  this.handleChange({
                                    customerId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.customers}
                                optionLabel="code"
                                placeholder="Select a Customer"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Description</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardDescription}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardDescription: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Status</label>
                              <MultiSelect
                                value={this.state.jobCardStatusId}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardStatusId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.jobCardStatus}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Job Card Creation From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.effectiveDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    effectiveDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">To Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.effectiveDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    effectiveDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-warning pull-right"
                              onClick={(e) => {
                                this.resetSearchHandler(e);
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.getJobs(false);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  {this.state.filterJobs &&
                    this.state.filterJobs.length > 0 && (
                      <div className="pb-20">
                        <DataTable
                          stripedRows
                          showGridlines
                          value={this.state.filterJobs}
                          selectionMode="single"
                          onSelectionChange={(e) => {
                            this.openJobCard(e);
                          }}
                          dataKey="id"
                          metaKeySelection={false}
                          sortMode="multiple"
                          paginator
                          rows={20}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          tableStyle={{ minWidth: "50rem" }}
                        >
                          <Column
                            style={{ width: "80px" }}
                            field="jobCardCode"
                            sortable
                            header="Job Card"
                          ></Column>
                          <Column
                            field="jobCardDescription"
                            sortable
                            header="Job Card Description"
                          ></Column>
                          <Column
                            field="customerName"
                            sortable
                            header="Customer Name"
                          ></Column>
                          <Column
                            field="customerRef"
                            sortable
                            header="Customer Ref"
                          ></Column>
                          <Column
                            style={{ width: "100px" }}
                            field="effectiveDate"
                            sortable
                            header="Effective Date"
                          ></Column>
                          <Column
                            style={{ width: "100px" }}
                            field="status"
                            sortable
                            header="Status"
                          ></Column>
                          <Column field="remarks" header="Remarks"></Column>
                        </DataTable>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {this.state.isLoading && <PreLoader />}
          </div>
        }
      </>
    );
  }
}

export default ViewJobCards;
