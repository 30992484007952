import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
import "../src/styles/print.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
class HistoryReportInner extends React.Component {
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />

          {this.props.effectiveDateFrom && (
            <p style={{ fontSize: "12px", margin: "0", paddingTop: "15px" }}>
              Duty Date: From{" "}
              <b>
                <>
                  {dayjs(this.props.effectiveDateFrom).format("DD/MM/YYYY")} to{" "}
                  {dayjs(this.props.effectiveDateTo).format("DD/MM/YYYY")}
                </>
              </b>
            </p>
          )}
          <br />

          <div className="pb-20">
            <h5>Job Card</h5>
            <table
              width="1573"
              border="1"
              align="center"
              style={{ border: "1px solid #fff", width: "fit-content" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#fd6511",
                    color: "#FFF",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Date
                  </th>
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Effective Date
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Job Card Number
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Job Card Description
                  </th>
                  <th
                    width={84}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Customer Name
                  </th>
                  <th
                    width={74}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Customer Ref
                  </th>
                  <th
                    width={77}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Remarks
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created By
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated By
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.fullReport.batchJobCardItems.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        background: "#dee2e4",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.effectiveDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardDescription}
                      </td>
                      <td
                        width={84}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.customerName}
                      </td>
                      <td
                        width={74}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.customerRef}
                      </td>
                      <td
                        width={77}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.status}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        background: "#f6f6f7",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.effectiveDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardDescription}
                      </td>
                      <td
                        width={84}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.customerName}
                      </td>
                      <td
                        width={74}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.customerRef}
                      </td>
                      <td
                        width={77}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.status}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          <div className="pb-20">
            <h5>Transportation</h5>
            <table
              width="1573"
              border="1"
              align="center"
              style={{ border: "1px solid #fff", width: "fit-content" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#fd6511",
                    color: "#FFF",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Date
                  </th>
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Date
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    B Ref#{" "}
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    J#
                  </th>
                  <th
                    width={84}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Customer Name
                  </th>
                  <th
                    width={74}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Customer Ref
                  </th>
                  <th
                    width={77}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Pickup Time
                  </th>
                  <th
                    width={136}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Pickup Location
                  </th>
                  <th
                    width={144}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Dropoff Location
                  </th>
                  <th
                    width={87}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Flight No & Time
                  </th>
                  <th
                    width={183}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    PAX Name
                  </th>
                  <th
                    width={50}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Total PAX
                  </th>
                  <th
                    width={28}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    A
                  </th>
                  <th
                    width={25}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    C
                  </th>
                  <th
                    width={117}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Vehicle Type
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Remarks
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Payment Vouchered
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Invoiced
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Batched
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created By
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated By
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.fullReport.batchTransportationItems.map(
                  (i, index) =>
                    !(index % 2) ? (
                      <tr
                        className="tableOddrow"
                        style={{
                          background: "#dee2e4",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        }}
                      >
                        <td
                          width={66}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.auditDate}</b>
                        </td>
                        <td
                          width={66}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.effectiveDateFrom}</b>
                        </td>
                        <td
                          width={68}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.bookingRef}
                        </td>
                        <td
                          width={68}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.jobCardNo}
                        </td>
                        <td
                          width={84}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.customerName}
                        </td>
                        <td
                          width={74}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.customerRef}
                        </td>
                        <td
                          width={77}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.pickUpTime}</b>
                        </td>
                        <td
                          width={136}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.pickUpLocation}
                        </td>
                        <td
                          width={144}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.dropOffLocation}
                        </td>
                        <td
                          width={87}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.flightDetail}
                        </td>
                        <td
                          width={183}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.paxName}
                        </td>
                        <td
                          width={50}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.totalPax}
                        </td>
                        <td
                          width={28}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.adult}
                        </td>
                        <td
                          width={25}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.children}
                        </td>
                        <td
                          width={117}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.vehicleType}
                        </td>
                        <td
                          width={350}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.remarks}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isPaymentVouchered ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isInvoiced ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isBatched ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={118}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.createdDate}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.createdByName}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.updatedDate}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.updatedByName}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.auditAction}
                        </td>
                      </tr>
                    ) : (
                      <tr
                        style={{
                          background: "#f6f6f7",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        }}
                      >
                        <td
                          width={66}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.auditDate}</b>
                        </td>
                        <td
                          width={66}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.effectiveDateFrom}</b>
                        </td>
                        <td
                          width={68}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.bookingRef}
                        </td>
                        <td
                          width={68}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.jobCardNo}
                        </td>
                        <td
                          width={84}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.customerName}
                        </td>
                        <td
                          width={74}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.customerRef}
                        </td>
                        <td
                          width={77}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          <b>{i.pickUpTime}</b>
                        </td>
                        <td
                          width={136}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.pickUpLocation}
                        </td>
                        <td
                          width={144}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.dropOffLocation}
                        </td>
                        <td
                          width={87}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.flightDetail}
                        </td>
                        <td
                          width={183}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.paxName}
                        </td>
                        <td
                          width={50}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.totalPax}
                        </td>
                        <td
                          width={28}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.adult}
                        </td>
                        <td
                          width={25}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.children}
                        </td>
                        <td
                          width={117}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.vehicleType}
                        </td>
                        <td
                          width={350}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.remarks}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isPaymentVouchered ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isInvoiced ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.isBatched ? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                        </td>
                        <td
                          width={118}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.createdDate}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.createdByName}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.updatedDate}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.updatedByName}
                        </td>
                        <td
                          width={94}
                          style={{
                            padding: "5px",
                            border: "1px solid #fff",
                            fontFamily:
                              "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {i.auditAction}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>

          <div className="pb-20">
            <h5>Hotel</h5>
            <table
              width="1573"
              border="1"
              align="center"
              style={{ border: "1px solid #fff", width: "fit-content" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#fd6511",
                    color: "#FFF",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Date
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    B Ref#{" "}
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    J#
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Hotel Name
                  </th>
                  <th
                    width={150}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Check-in
                  </th>
                  <th
                    width={150}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Check-out
                  </th>
                  <th
                    width={250}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    PAX Name
                  </th>
                  <th
                    width={50}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Total PAX
                  </th>
                  <th
                    width={28}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    A
                  </th>
                  <th
                    width={25}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    C
                  </th>
                  <th
                    width={25}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    I
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Remarks
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Vendor Name
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Payment Vouchered
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Invoiced
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created By
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated By
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.fullReport.batchHotelItems.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        background: "#dee2e4",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.hotelCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.hotelName}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.checkIn}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.checkOut}
                      </td>
                      <td
                        width={250}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={50}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.totalPax}
                      </td>
                      <td
                        width={28}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.adults}
                      </td>
                      <td
                        width={25}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.children}
                      </td>
                      <td
                        width={25}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.infants}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        background: "#f6f6f7",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.hotelCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.hotelName}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.checkIn}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.checkOut}
                      </td>
                      <td
                        width={250}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={50}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.totalPax}
                      </td>
                      <td
                        width={28}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.adults}
                      </td>
                      <td
                        width={25}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.children}
                      </td>
                      <td
                        width={25}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.infants}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={350}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          <div className="pb-20">
            <h5>Visa</h5>
            <table
              width="1573"
              border="1"
              align="center"
              style={{ border: "1px solid #fff", width: "fit-content" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#fd6511",
                    color: "#FFF",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Date
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    B Ref#{" "}
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    J#
                  </th>
                  <th
                    width={550}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    PAX Name
                  </th>
                  <th
                    width={150}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Passport Number
                  </th>
                  <th
                    width={74}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Visa Type
                  </th>
                  <th
                    width={600}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Remarks
                  </th>
                  <th
                    width={300}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Vendor Name
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Payment Vouchered
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Invoiced
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created By
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated By
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.fullReport.batchVisaItems.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        background: "#dee2e4",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.visaCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.passportNo}
                      </td>
                      <td
                        width={77}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.visaTypeName}
                      </td>
                      <td
                        width={600}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={300}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        background: "#f6f6f7",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.visaCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.passportNo}
                      </td>
                      <td
                        width={77}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.visaTypeName}
                      </td>
                      <td
                        width={600}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={300}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          <div className="pb-20">
            <h5>Miscellaneous</h5>
            <table
              width="1573"
              border="1"
              align="center"
              style={{ border: "1px solid #fff", width: "fit-content" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#fd6511",
                    color: "#FFF",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <th
                    width={66}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Date
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    B Ref#{" "}
                  </th>
                  <th
                    width={68}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    J#
                  </th>
                  <th
                    width={550}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    PAX Name
                  </th>
                  <th
                    width={74}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Description
                  </th>
                  <th
                    width={150}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Mis Date
                  </th>
                  <th
                    width={550}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Remarks
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Vendor Name
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Payment Vouchered
                  </th>
                  <th
                    width={94}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Is Invoiced
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Created By
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated Date
                  </th>
                  <th
                    width={350}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Updated By
                  </th>
                  <th
                    width={118}
                    style={{
                      padding: "5px",
                      border: "1px solid #fff",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      fontSize: "14px",
                    }}
                    scope="col"
                  >
                    Audit Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.fullReport.batchMiscellaneousItems.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        background: "#dee2e4",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.miscellaneousCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.description}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.misDate}</b>
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced? (
                            <img
                              src="../vendors/images/checked.png"
                              width="24"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../vendors/images/unchecked.png"
                              width="24"
                              alt=""
                            />
                          )}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        background: "#f6f6f7",
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                      }}
                    >
                      <td
                        width={66}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.auditDate}</b>
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.miscellaneousCode}
                      </td>
                      <td
                        width={68}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.jobCardNo}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.paxName}
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.description}
                      </td>
                      <td
                        width={150}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <b>{i.misDate}</b>
                      </td>
                      <td
                        width={550}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.remarks}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.vendorName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isPaymentVouchered}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.isInvoiced}
                      </td>
                      <td
                        width={118}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.createdByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedDate}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.updatedByName}
                      </td>
                      <td
                        width={94}
                        style={{
                          padding: "5px",
                          border: "1px solid #fff",
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {i.auditAction}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default HistoryReportInner;
