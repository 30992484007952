import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import dayjs, { Dayjs } from "dayjs";

import "react-toastify/dist/ReactToastify.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import PreLoader from "../components/shards/PreLoader";
class ProformaInvoiceGeneration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customerId: "",
      jobCards: [],
      invoiceDate: "",
      invoiceDueDate: "",
      invoiceAmount: "",
      remarks: "",
      description: "",
      errorMessage: null,
      isModalOpen: false,
      isSaveProcessing: false,
      isLoading: false,
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
        });
      });
  };

  getJobCardReferenceData = async (cusId) => {
    this.setState({
      isLoading: true,
    });
    if (!cusId || cusId == "") {
      this.setState({
        isLoading: false,
      });
      this.setErrorMessage("Please select Customer", 3);
      return;
    }
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetJobCardReferanceData?` +
        new URLSearchParams({
          customerId: cusId,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          jobCards: response.jobCards,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
  }

  handleCustomerChange(customerId) {
    debugger;
    this.getJobCardReferenceData(customerId);
    this.setState({ customerId: customerId });
  }

  validation = () => {
    debugger;

    if (this.state.customerId == "") {
      this.setErrorMessage("Please select customer", 3);
      return false;
    }
    if (this.state.jobCardId == "") {
      this.setErrorMessage("Please select job card", 3);
      return false;
    }
    if (this.state.invoiceDate == "") {
      this.setErrorMessage("Please select invoice date", 3);
      return false;
    }

    if (this.state.invoiceDueDate == "") {
      this.setErrorMessage("Please select invoice due date", 3);
      return false;
    }

    if (this.state.invoiceAmount <= 0) {
      this.setErrorMessage("Invoice amount should greater than 0", 3);
      return false;
    }

    if (this.state.description == "") {
      this.setErrorMessage("Please enter description", 3);
      return false;
    }
    return true;
  };
  saveInvoice = async (e) => {
    this.setState({
      isLoading: true,
    });
    this.setState({
      isSaveProcessing: true,
    });
    if (!this.validation()) {
      this.setState({
        isLoading: false,
      });
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      CustomerId: this.state.customerId,
      InvoiceDate: this.state.invoiceDate.toLocaleString(),
      InvoiceDueDate: this.state.invoiceDueDate.toLocaleString(),
      InvoiceAmount: this.state.invoiceAmount,
      JobCardId: this.state.jobCardId,
      Description: this.state.description,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SaveProformaInvoice`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setErrorMessage("Invoice generated", 1);
        this.setState({
          isLoading: false,
        });
        this.setState({
          isSaveProcessing: false,
          customerId: "",
          invoiceDueDate: "",
          invoiceDate: "",
          invoiceAmount: "",
          jobCardId: "",
          description: "",
        });
      });
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  render() {
    const accept = () => {
      this.saveInvoice();
    };

    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = () => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/bill.gif"
                            alt=""
                            style={{verticalAlign:"middle"}}
                          />{" "}
                          Generate Proforma Invoice
                        </h2>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Customer</label>
                          <Dropdown
                            value={this.state.customerId}
                            onChange={(e) => this.handleCustomerChange(e.value)}
                            options={this.state.customers}
                            placeholder="Select a Customer"
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Job Card</label>
                          <Dropdown
                            value={this.state.jobCardId}
                            onChange={(e) =>
                              this.handleChange({ jobCardId: e.value })
                            }
                            options={this.state.jobCards}
                            placeholder="Select a Job Card"
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Invoice Amount</label>
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.invoiceAmount}
                            onChange={(e) =>
                              this.handleChange({
                                invoiceAmount: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Invoice Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.invoiceDate}
                            onChange={(e) =>
                              this.handleChange({
                                invoiceDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Invoice Due Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.invoiceDueDate}
                            onChange={(e) =>
                              this.handleChange({
                                invoiceDueDate: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Description</label>
                          <textarea
                            className="form-control"
                            value={this.state.description}
                            onChange={(e) =>
                              this.handleChange({ description: e.target.value })
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12">
                        <Button
                          type="button"
                          label="Submit"
                          loading={this.state.isSaveProcessing}
                          style={{ margin: "10px" }}
                          className="btn btn-primary pull-right"
                          onClick={(e) => {
                            confirm();
                          }}
                        />
                      </div>
                      <ConfirmDialog />
                      <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ProformaInvoiceGeneration;
