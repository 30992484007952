import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import dayjs, { Dayjs } from "dayjs";
import PreLoader from "../components/shards/PreLoader";
import "react-toastify/dist/ReactToastify.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import BatchCopyItems from "../components/BatchCopyItems";
import EditTransportation from "../components/EditTransportation";

class BatchProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batchItems: [],
      batchDate: null,
      rowClick: true,
      selectedItem: null,
      vendors: [],
      isSearch: false,
      vendorId: "",
      errorMessage: null,
      isModalOpen: false,
      isModalEdit: false,
      batchNo: "",
      suplierName: "",
      isSaveProcessing: false,
      transportationId: 0,
      isLoading: false,
    };
  }

  /* toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  } */
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    await this.getReferenceData();
    this.setState({
      isLoading: false,
    });
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getReferenceData = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          vendors: response.vendors,
        });
      });
  };

  getBatchItems = async () => {
    this.setState({
      batchItems: [],
      isLoading: true,
    });
    const batchItems = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetBatchItems?` +
        new URLSearchParams({
          batchDate: this.state.batchDate
            ? new Date(this.state.batchDate).toDateString()
            : "",
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.jobCardTransportation.map((i) => {
          batchItems.push({
            id: i.id,
            bookingRef: i.bookingRef,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
            flightDetail: this.GetFlightDetails(
              i.flightNo.trim(),
              i.flightTime
            ),
          });
        });
        debugger;
        this.setState({
          batchItems: batchItems,
          isSearch: true,
          isLoading: false,
        });
      });
  };
  GetFlightDetails = (flightNo, flightTime) => {
    debugger;
    var flightDetails = "";
    debugger;
    if (flightNo && flightNo != null && flightNo != "") {
      flightDetails = flightNo;

      if (flightTime && flightTime != null && flightTime != "") {
        flightDetails = `${flightNo} ${dayjs(flightTime).format("HH:mm")}`;
      }
    }

    return flightDetails;
  };
  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }
  validation = () => {
    debugger;
    if (
      this.state.selectedItem == null ||
      this.state.selectedItem.length == 0
    ) {
      this.setErrorMessage("Please select item from grid", 3);
      return false;
    }
    if (this.state.vendorId == "") {
      this.setErrorMessage("Please select vendor", 3);
      return false;
    }
    if (this.state.batchDate == null) {
      this.setErrorMessage("Please select batch date", 3);
      return false;
    }
    return true;
  };
  saveBatch = async (e) => {
    this.setState({
      isSaveProcessing: true,
      isLoading: true,
    });
    if (!this.validation()) {
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }
    var payload = {
      Ids: this.state.selectedItem.map((o) => o.id),
      VendorId: this.state.vendorId,
      BatchDate: this.state.batchDate.toLocaleString(),
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/SavetBatchItems`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            isSaveProcessing: false,
            isLoading: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.setErrorMessage("Batch update done", 1);
        this.setState({
          isSaveProcessing: false,
          isLoading: false,
          batchDate: response.data.batchDetail.batchDateStn,
          suplierName: response.data.batchDetail.suplierName,
          batchNo: response.data.batchDetail.batchNo,
          isModalOpen: true,
        });
        //this.getBatchItems();
      });
  };
  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  ReportHeader = () => {
    debugger;
    return (
      <div>
        {this.state.batchNo &&
          this.state.suplierName &&
          this.state.batchDate && (
            <div>
              <p style={{ margin: "0px" }}>Batch No : {this.state.batchNo}</p>
              <p style={{ margin: "0px" }}>
                Suplier Name : {this.state.suplierName}
              </p>
              <p style={{ margin: "0px", marginBottom: "5px" }}>
                Duty Date : {this.state.batchDate}
              </p>
            </div>
          )}
      </div>
    );
  };
  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          className="m-1"
          style={{ width: "2rem", height: "2rem" }}
          onClick={(e) =>
            this.setState({
              isModalEdit: true,
              transportationId: item.id,
            })
          }
          icon="pi pi-pencil"
          severity="secondary"
          tooltip="Edit"
        />
      </div>
    );
  };

  closeTranspotationModal = () => {
    this.setState({
      isModalEdit: false,
    });
    this.getBatchItems();
  };
  render() {
    const accept = () => {
      this.saveBatch();
    };

    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = () => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };

    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/notebook.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          New Batch
                        </h2>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Batch Date</label>
                          {/* <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.batchDate}
                            onChange={(e) =>
                              this.handleChange({
                                batchDate: e.target.value,
                              })
                            }
                            showIcon
                          /> */}
                          <input
                            className="form-control"
                            value={this.state.batchDate}
                            onChange={(e) =>
                              this.handleChange({
                                batchDate: e.target.value,
                              })
                            }
                            type="date"
                            id="batchDate"
                            name="batchDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12">
                        <button
                          type="button"
                          style={{ margin: "10px" }}
                          className="btn btn-primary pull-right"
                          onClick={(e) => {
                            this.getBatchItems(e);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {this.state.isSearch && (
                  <div className="card-box mb-30">
                    <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
                    <div className="pb-20">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.batchItems}
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        selectionMode={"checkbox"}
                        selection={this.state.selectedItem}
                        onSelectionChange={(e) =>
                          this.handleChange({
                            selectedItem: e.value,
                          })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column
                          field="bookingRef"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="pickupTime"
                          sortable
                          header="Pickup Time"
                        ></Column>
                        <Column
                          field="pickupLocation"
                          sortable
                          header="Pickup Location"
                        ></Column>
                        <Column
                          field="dropoffLocation"
                          sortable
                          header="Drop Off Location"
                        ></Column>
                        <Column
                          field="totalPax"
                          sortable
                          header="Total PAX"
                        ></Column>
                        <Column field="adults" sortable header="A"></Column>
                        <Column field="children" sortable header="C"></Column>
                        <Column field="infants" sortable header="I"></Column>
                        <Column
                          field="vehicleType"
                          sortable
                          header="Vehicle Type"
                        ></Column>
                        <Column
                          field="flightNo"
                          sortable
                          header="Flight No"
                        ></Column>
                        <Column
                          field="flightTime"
                          sortable
                          header="Flight Time"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="action"
                          sortable
                          body={this.viewItemTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <form>
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                      Batch to Vendor
                                    </label>
                                    <div className="col-sm-12 col-md-8">
                                      <Dropdown
                                        value={this.state.vendorId}
                                        onChange={(e) => {
                                          this.handleChange({
                                            vendorId: e.value,
                                          });
                                        }}
                                        options={this.state.vendors}
                                        placeholder="Select a Vendor"
                                        optionLabel="code"
                                        className="col-12"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-sm-12 col-md-12">
                                  <ConfirmDialog />

                                  <Button
                                    type="button"
                                    label="Submit"
                                    loading={this.state.isSaveProcessing}
                                    style={{ margin: "10px" }}
                                    className="btn btn-primary pull-right"
                                    onClick={(e) => {
                                      confirm();
                                    }}
                                  />
                                </div>
                              </div>
                            </form>
                            <Dialog
                              maximizable
                              visible={this.state.isModalEdit}
                              style={{ width: "80vw" }}
                              headerStyle={{
                                backgroundColor: "#fd6511",
                                color: "#FFF",
                              }}
                              contentStyle={{
                                padding: "25px 1.5rem 2rem 1.5rem",
                              }}
                              header="Edit Transportation "
                              onHide={(e) => {
                                this.setState({ isModalEdit: false });
                              }}
                            >
                              <br />
                              <EditTransportation
                                id={this.state.transportationId}
                                closeTranspotation={
                                  this.closeTranspotationModal
                                }
                              />
                            </Dialog>
                            <Dialog
                              maximizable
                              visible={this.state.isModalOpen}
                              style={{ width: "50vw" }}
                              headerStyle={{
                                backgroundColor: "#fd6511",
                                color: "#FFF",
                              }}
                              contentStyle={{
                                padding: "25px 1.5rem 2rem 1.5rem",
                              }}
                              onHide={(e) => {
                                this.setState({ isModalOpen: false });
                                window.location.reload();
                              }}
                            >
                              {this.state.batchNo &&
                                this.state.suplierName &&
                                this.state.batchDate &&
                                this.state.selectedItem.length > 0 && (
                                  <BatchCopyItems
                                    batchItems={this.state.selectedItem}
                                    batchNo={this.state.batchNo}
                                    suplier={this.state.suplierName}
                                    date={dayjs(this.state.batchDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  />
                                )}
                            </Dialog>
                            <ToastContainer
                              position="top-center"
                              autoClose={5000}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isLoading && <PreLoader />}
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default BatchProcess;
