import React from 'react'
import { useParams } from 'react-router-dom';
import LayoutAdmin from '../components/shards/LayoutAdmin'
import ViewJobCard from '../components/ViewJobCard'
import ViewProformaInvoiceReceipt from './ProformaInvoicesReceipt';

export default function ProformaInvoicesReceiptWarp() {
 
    let { invoiceId } = useParams();
  return (
    <LayoutAdmin><ViewProformaInvoiceReceipt id={invoiceId}/></LayoutAdmin>
  )
}
