import React from "react";
import ViewInvoiceInner from "../components/ViewInvoiceInner";
import { ToastContainer, toast } from "react-toastify";
import ReactToPrint from "react-to-print";
const ExcelJS = require("exceljs");

class ViewInvoice extends React.Component {
  constructor(props) {
    super(props);
  }
  printDiv = (divName) => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.location.reload();
  };

  exportInvoiceLine = () => {
    debugger;
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Transportation");

    sheet.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "bookingRef" },
      { header: "Customer Ref", key: "customerRef" },
      { header: "Pickup Time", key: "pickupTime" },
      { header: "Pickup Location", key: "pickupLocation" },
      { header: "Dropoff Location", key: "dropoffLocation" },
      { header: "Flight No", key: "flightNo" },
      { header: "Flight Time", key: "flightTime" },
      { header: "PAX Name", key: "paxName" },
      { header: "A", key: "adults" },
      { header: "C", key: "children" },
      { header: "I", key: "infants" },
      { header: "Vehicle Type", key: "vehicleType" },
      { header: "Remarks", key: "remarks" },
      { header: "Net Sell", key: "sellBaseAmount" },
      { header: "Parking", key: "parkingSell" },
      { header: "Water", key: "waterSell" },
      { header: "Extras", key: "extrasSell" },
      
      { header: "VAT", key: "totalSellTaxAmount" },
      { header: "Total Sell", key: "totalSellPrice" },
    ];

    const sheetHotel = workbook.addWorksheet("Hotel");
    sheetHotel.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "hotelCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "A", key: "adults" },
      { header: "C", key: "children" },
      { header: "I", key: "infants" },
      { header: "Hotel Name", key: "hotelName" },
      { header: "Check In", key: "checkIn" },
      { header: "Check Out", key: "checkOut" },
      { header: "Remarks", key: "remarks" },
      { header: "VAT", key: "sellTaxAmount" },
      { header: "Total Sell", key: "totalSellPrice" },
    ];

    const sheetVisa = workbook.addWorksheet("Visa");
    sheetVisa.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "visaCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "Passport No", key: "passportNo" },
      { header: "Visa Type Name", key: "visaTypeName" },
      { header: "Remarks", key: "remarks" },
      { header: "VAT", key: "sellTaxAmount" },
      { header: "Total Sell", key: "totalSellPrice" },
    ];

    const sheetMiscellaneous = workbook.addWorksheet("Miscellaneous");
    sheetMiscellaneous.columns = [
      { header: "Job Card No", key: "jobCardNo" },
      { header: "Booking Ref", key: "miscellaneousCode" },
      { header: "PAX Name", key: "paxName" },
      { header: "Description", key: "description" },
      { header: "Mis Date", key: "misDate" },
      { header: "Remarks", key: "remarks" },
      { header: "VAT", key: "sellTaxAmount" },
      { header: "Total Sell", key: "totalSellPrice" },
    ];

    const promise = Promise.all(
      this.props.invoice.invoiceLineItems.batchTransportationItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          jobCardNo: i.jobCardNo,
          jobCardTitle: i.jobCardTitle,
          bookingRef: i.bookingRef,
          customerRef: i.customerRef,
          pickupLocation: i.pickupLocation,
          pickupTime: i.pickupTime,
          dropoffLocation: i.dropoffLocation,
          totalPax: i.totalPax,
          adults: i.adults,
          children: i.children,
          infants: i.infants,
          vehicleType: i.vehicleType,
          flightNo: i.flightNo,
          flightTime: i.flightTime,
          paxName: i.paxName,
          remarks: i.remarks,
          sellBaseAmount: i.sellBaseAmount,
          parkingSell: i.parkingSell,
          waterSell: i.waterSell,
          extrasSell: i.extrasSell,
          totalSellTaxAmount: i.totalSellTaxAmount,
          totalSellPrice: i.totalSellPrice,
        });
      }),

      this.props.invoice.invoiceLineItems.batchHotelItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetHotel.addRow({
          jobCardNo: i.jobCardNo,
          hotelCode: i.hotelCode,
          paxName: i.paxName,
          adults: i.adults,
          children: i.children,
          infants: i.infants,
          hotelName: i.hotelName,
          checkIn: i.checkIn,
          checkOut: i.checkOut,
          remarks: i.remarks,
          sellTaxAmount: i.sellTaxAmount,
          totalSellPrice: i.totalSellPrice,
        });
      }),

      this.props.invoice.invoiceLineItems.batchVisaItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetVisa.addRow({
          jobCardNo: i.jobCardNo,
          visaCode: i.visaCode,
          paxName: i.paxName,
          passportNo: i.passportNo,
          visaTypeName: i.visaTypeName,
          remarks: i.remarks,
          sellTaxAmount: i.sellTaxAmount,
          totalSellPrice: i.totalSellPrice,
        });
      }),

      this.props.invoice.invoiceLineItems.batchMiscellaneousItems.map(async (i, index) => {
        const rowNumber = index + 1;
        sheetMiscellaneous.addRow({
          jobCardNo: i.jobCardNo,
          miscellaneousCode: i.miscellaneousCode,
          paxName: i.paxName,
          description: i.description,
          misDate: i.misDate,
          remarks: i.remarks,
          sellTaxAmount: i.sellTaxAmount,
          totalSellPrice: i.totalSellPrice,
        });
      })

    );

    const invoiceCode = this.props.invoice.invoiceCode;
    const docName =
      invoiceCode != null || invoiceCode != "" ? `${invoiceCode}.xlsx` : "";
    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = docName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  render() {
    return (
      <div className="main-container">
        <div
          className="card-box  mb-30"
          style={{ padding: "10px", width: "fit-content" }}
        >
          <ViewInvoiceInner
            invoice={this.props.invoice}
            ref={(el) => (this.componentRef = el)}
          />
          <div className="row">
            <button
              type="button"
              style={{ margin: "10px" }}
              className="btn btn-secondary pull-right"
              onClick={(e) => {
                this.exportInvoiceLine();
              }}
            >
              Export
            </button>
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  style={{ margin: "10px" }}
                  className="btn btn-primary pull-right"
                >
                  Print Report
                </button>
              )}
              content={() => this.componentRef}
              copyStyles={true}
              pageStyle="print"
            />
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    );
  }
}

export default ViewInvoice;
