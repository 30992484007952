import React from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { Button } from 'primereact/button';
import "react-toastify/dist/ReactToastify.css";
class AddJobCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDescription: "",
      customerId: "",
      customerRef: "",
      effectiveDate: "",
      remarks: "",
      errorMessage: "",
      isError: false,
      customers: [],
      isSaveProcessing:false,
      isRemoveProcessing:false
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    if (this.props.job) {
      this.setState({
        id: this.props.job.id,
        jobDescription: this.props.job.jobCardDescription,
        customerId: this.props.job.customerId,
        customerRef: this.props.job.customerRef,
        effectiveDate: new Date(this.props.job.effectiveDate),
        remarks: this.props.job.remarks,
      });
    }
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getReferenceData = async () => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.props.setLoader(false);
        this.setState({
          customers: response.customers,
        });
      });
  };

  validation = () => {
    if (this.state.jobDescription == "") {
      this.setErrorMessage("Please enter job card description", 3);
      return false;
    }
    if (this.state.customerId == "") {
      this.setErrorMessage("Please select customer", 3);
      return false;
    }
    if (this.state.effectiveDate == "") {
      this.setErrorMessage("Please enter effective date", 3);
      return false;
    }
    
    return true;
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  saveJobCardHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing:true
    })
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing:false
      })
      return;
    }
    var payload = {
      id: this.state.id,
      jobDescription: this.state.jobDescription,
      customerId: this.state.customerId,
      customerRef: this.state.customerRef,
      effectiveDate: new Date(this.state.effectiveDate).toDateString(),
      remarks: this.state.remarks,
    };
    const currentUser = localStorage.getItem('token');
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Job/SaveJob`, payload,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing:false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing:false
        })
        window.location.href = `${process.env.PUBLIC_URL}/view_job_card/${response.data.id}`;
      });
  };

  render() {
    const reject = () => {
      this.setErrorMessage("Process Cancel", 2);
    };

    const confirm = (accept) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    };
    return (
      <form>
        <div className="form-group row">
          <label className="col-sm-12 col-md-2 col-form-label">
            Job Card Description
          </label>
          <div className="col-sm-12 col-md-10">
            <input
              className="form-control"
              type="text"
              placeholder="Job Card Description"
              value={this.state.jobDescription}
              onChange={(e) =>
                this.handleChange({ jobDescription: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-md-2 col-form-label">Customer</label>
          <div className="col-sm-12 col-md-10">
            <Dropdown
              value={this.state.customerId}
              onChange={(e) => this.handleChange({ customerId: e.value })}
              options={this.state.customers}
              placeholder="Select a Customer"
              optionLabel="code"
              filter
              className="col-12"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-md-2 col-form-label">
            Customer Ref
          </label>
          <div className="col-sm-12 col-md-10">
            <input
              className="form-control"
              type="text"
              placeholder="Customer Ref"
              value={this.state.customerRef}
              onChange={(e) =>
                this.handleChange({ customerRef: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-md-2 col-form-label">
            Effective Date
          </label>
          <div className="col-sm-12 col-md-10">
            <Calendar
              dateFormat="dd/mm/yy"
              style={{ width: "100%" }}
              value={this.state.effectiveDate}
              onChange={(e) =>
                this.handleChange({
                  effectiveDate: e.target.value,
                })
              }
              showIcon
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-md-2 col-form-label">Remarks</label>
          <div className="col-sm-12 col-md-10">
            <textarea
              className="form-control"
              placeholder="Remarks"
              value={this.state.remarks}
              onChange={(e) => this.handleChange({ remarks: e.target.value })}
            ></textarea>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12 col-md-12">
          <Button
              type="button"
              label="Submit"
              loading={this.state.isSaveProcessing}
              className="btn btn-primary pull-right"
              onClick={(e) => confirm(this.saveJobCardHandler)}
            />
          </div>
        </div>
        <ConfirmDialog />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
      </form>
    );
  }
}

export default AddJobCard;
