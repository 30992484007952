import React from 'react'

export default function PreLoader() {
  return (
    <>
    {<div
          style={{
            position: "fixed",
            top: "0",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.9)",
            width: "100vw",
            height: "100vh",
            zIndex: "999999",
          }}
        >
          <img
            width={'25%'}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            }}
            src="/vendors/images/loading.gif"
            alt=""
          />
        </div>}
    </>
  )
}
