import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import ViewInvoice from "../pages/ViewInvoice";
class PNLReportInner extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpenInvoice: false,
      invoice: null,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          {this.props.dateFrom &&
            (<p
              style={{
                fontFamily:
                  "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                fontSize: "14.5px",
                margin: "0",
                paddingTop: "15px",
              }}
            >
              <b>Date From&nbsp;:&nbsp;
               {dayjs(this.props.dateFrom).format(
                "DD/MM/YYYY"
              )}</b>
            </p>)
          }
          {this.props.dateTo &&
            (<p
              style={{
                fontFamily:
                  "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                fontSize: "14.5px",
                margin: "0",
                paddingTop: "15px",
              }}
            >
              <b>Date To&nbsp;:&nbsp;
              {dayjs(this.props.dateTo).format(
                "DD/MM/YYYY"
              )}</b>
            </p>)
          }
          <br />

          <br />
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Job Card No</th>
                  <th>Description</th>
                  <th>Customer</th>
                  <th>Invoice Amount</th>
                  <th>Receipt Amount</th>
                  <th>Payment Voucher Amount</th>
                  <th>Profit/Loss</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.reportItem.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{i.jobCardNo}</td>
                      <td>{i.description}</td>
                      <td>{i.customerName}</td>
                      <td>{i.invoiceAmount}</td>
                      <td>{i.receiptAmount}</td>
                      <td>{i.paymentVoucherAmount}</td>
                      <td>{i.profitLost}</td>
                      <td>
                        {i.profitLost > 0 ? (
                          <img
                            src="../vendors/images/increase.png"
                            width="50"
                            alt=""
                          />
                        ) : (
                          <img
                            src="../vendors/images/decrease.png"
                            width="50"
                            alt=""
                          />
                        )}
                      </td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>{i.jobCardNo}</td>
                      <td>{i.description}</td>
                      <td>{i.customerName}</td>
                      <td>{i.invoiceAmount}</td>
                      <td>{i.receiptAmount}</td>
                      <td>{i.paymentVoucherAmount}</td>
                      <td>{i.profitLost}</td>
                      <td>
                        {i.profitLost > 0 ? (
                          <img
                            src="../vendors/images/increase.png"
                            width="50"
                            alt=""
                          />
                        ) : (
                          <img
                            src="../vendors/images/decrease.png"
                            width="50"
                            alt=""
                          />
                        )}
                      </td>
                    </tr>
                  )
                )}
                <tr style={{ fontSize: "10px !important;" }}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>
                      {this.props.totalInvoiceAmount}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {this.props.totalReceiptAmount}
                    </strong>
                  </td>
                  <td>
                    <strong>
                    {this.props.totalPaymentVoucherAmount}
                    </strong>
                  </td>
                  <td>
                    <strong>
                    {this.props.totalProfitLost}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {this.props.reportItem.reduce((a, i) => {
                        if (
                          i.invoiceNetAmount &&
                          (i.invoiceNetAmount != null) &
                            (i.invoiceNetAmount != "")
                        ) {
                          var p =
                            parseFloat(a) + parseFloat(i.invoiceNetAmount);
                          return p.toFixed(2);
                        }
                      }, 0)}
                    </strong>
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default PNLReportInner;
