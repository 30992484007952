import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
class JobCardFullReportInner extends React.Component {
  render() {
    var totalIncome = 0;
    var totalExpences = 0;
    var profitAndLost = 0;

    if (this.props.vouchers && this.props.vouchers.length > 0) {
      totalExpences = this.props.vouchers.reduce((a, v) => {
        var p = parseFloat(a) + parseFloat(v.amount);
        return p.toFixed(2);
      }, 0);
    }

    if (this.props.receipts && this.props.receipts.length > 0) {
      totalIncome = this.props.receipts.reduce((a, v) => {
        var p = parseFloat(a) + parseFloat(v.amount);
        return p.toFixed(2);
      }, 0);
    }
    profitAndLost = (totalIncome - totalExpences).toFixed(2);
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />
          {this.props.jobCard && (
            <>
            <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Job Card&nbsp;:&nbsp;
                <strong>{this.props.jobCard.headerTitle}</strong>
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Customer&nbsp;:&nbsp;
                <strong>{this.props.jobCard.customerName}</strong>
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Customer Ref&nbsp;:&nbsp;
                {this.props.jobCard.customerRef}
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Remarks&nbsp;:&nbsp;
                {this.props.jobCard.remarks}
              </p>
              {
                <p
                  style={{
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                    fontSize: "14.5px",
                    margin: "0",
                    paddingTop: "15px",
                  }}
                >
                  Creation Date&nbsp;:&nbsp;
                  {dayjs(this.props.jobCard.createdDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </p>
              }
            </>
          )}
          <br />
          <br />
          <h5>Tranportation</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Pickup Time</th>
                  <th>Pickup Location</th>
                  <th>Drop-off Location</th>
                  <th>Total PAX</th>
                  <th>A</th>
                  <th>C</th>
                  <th>I</th>
                  <th>Vehicle Type</th>
                  <th>Flight No</th>
                  <th>Flight Time</th>
                  <th>Pax Name</th>
                  <th>Remarks</th>
                  <th>Net Cost</th>
                  <th>Parking</th>
                  <th>Water</th>
                  <th>Extras</th>
                  <th>VAT</th>
                  <th>Total Cost</th>
                  <th>Net Sell</th>
                  <th>Parking</th>
                  <th>Water</th>
                  <th>Extras</th>
                  <th>VAT</th>
                  <th>Total Sell</th>
                </tr>
              </thead>
              <tbody>
                {this.props.transports.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.transportationCode}
                      </td>
                      <td>{i.pickupTime}</td>
                      <td>{i.pickupLocation}</td>
                      <td>{i.dropoffLocation}</td>
                      <td>{i.totalPax}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.vehicleType}</td>
                      <td>{i.flightNo}</td>
                      <td>{i.flightTime}</td>
                      <td>{i.paxName}</td>
                      <td>{i.remarks}</td>
                      <td style={{ textAlign: "right" }}>{i.costBaseAmount}</td>
                      <td style={{ textAlign: "right" }}>{i.parking}</td>
                      <td style={{ textAlign: "right" }}>{i.water}</td>
                      <td style={{ textAlign: "right" }}>{i.extras}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.totalCostTaxAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>{i.totalCostPrice}</td>
                      <td style={{ textAlign: "right" }}>{i.sellBaseAmount}</td>
                      <td style={{ textAlign: "right" }}>{i.parkingSell}</td>
                      <td style={{ textAlign: "right" }}>{i.waterSell}</td>
                      <td style={{ textAlign: "right" }}>{i.extrasSell}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.totalSellTaxAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>{i.totalSellPrice}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.transportationCode}
                      </td>
                      <td>{i.pickupTime}</td>
                      <td>{i.pickupLocation}</td>
                      <td>{i.dropoffLocation}</td>
                      <td>{i.totalPax}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.vehicleType}</td>
                      <td>{i.flightNo}</td>
                      <td>{i.flightTime}</td>
                      <td>{i.paxName}</td>
                      <td>{i.remarks}</td>
                      <td style={{ textAlign: "right" }}>{i.costBaseAmount}</td>
                      <td style={{ textAlign: "right" }}>{i.parking}</td>
                      <td style={{ textAlign: "right" }}>{i.water}</td>
                      <td style={{ textAlign: "right" }}>{i.extras}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.totalCostTaxAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>{i.totalCostPrice}</td>
                      <td style={{ textAlign: "right" }}>{i.sellBaseAmount}</td>
                      <td style={{ textAlign: "right" }}>{i.parkingSell}</td>
                      <td style={{ textAlign: "right" }}>{i.waterSell}</td>
                      <td style={{ textAlign: "right" }}>{i.extrasSell}</td>
                      <td style={{ textAlign: "right" }}>
                        {i.totalSellTaxAmount}
                      </td>
                      <td style={{ textAlign: "right" }}>{i.totalSellPrice}</td>
                    </tr>
                  )
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>
                    {this.props.transports.reduce((a, i) => {
                      if (
                        i.totalCostPrice &&
                        (i.totalCostPrice != null) & (i.totalCostPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>
                    {this.props.transports.reduce((a, i) => {
                      if (
                        i.totalSellPrice &&
                        (i.totalSellPrice != null) & (i.totalSellPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <strong>
            Total : {this.props.tProfit.toFixed(2)}{" "}
            {this.props.tProfit > 0 ? (
              <img
                src="../vendors/images/up.png"
                width="10"
                height="10"
                alt=""
              />
            ) : (
              <img
                src="../vendors/images/dw.png"
                width="10"
                height="10"
                alt=""
              />
            )}
          </strong>
          <br />
          <br />
          <br />
          <h5>Hotels</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Infants</th>
                  <th>Hotel Name</th>
                  <th>Check-in</th>
                  <th>Check-out</th>
                  <th>Remarks</th>
                  <th>Net Cost</th>
                  <th>VAT</th>
                  <th>Total Cost</th>
                  <th>Net Sell</th>
                  <th>VAT</th>
                  <th>Total Sell</th>
                </tr>
              </thead>
              <tbody>
                {this.props.hotels.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{i.hotelCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.hotelName}</td>
                      <td>{i.checkIn}</td>
                      <td>{i.checkOut}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>{i.hotelCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.hotelName}</td>
                      <td>{i.checkIn}</td>
                      <td>{i.checkOut}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.hotels.length > 0 && (
                <tfoot>
                  <tr></tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.hotels.reduce((a, i) => {
                      if (
                        i.totalCostPrice &&
                        (i.totalCostPrice != null) & (i.totalCostPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.hotels.reduce((a, i) => {
                      if (
                        i.totalSellPrice &&
                        (i.totalSellPrice != null) & (i.totalSellPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          <strong>
            Total : {this.props.hProfit.toFixed(2)}{" "}
            {this.props.hProfit > 0 ? (
              <img
                src="../vendors/images/up.png"
                width="10"
                height="10"
                alt=""
              />
            ) : (
              <img
                src="../vendors/images/dw.png"
                width="10"
                height="10"
                alt=""
              />
            )}
          </strong>
          <br />
          <br />
          <br />
          <h5>Visa</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Passport Number</th>
                  <th>Visa Type</th>
                  <th>Remarks</th>
                  <th>Net Cost</th>
                  <th>VAT</th>
                  <th>Total Cost</th>
                  <th>Net Sell</th>
                  <th>VAT</th>
                  <th>Total Sell</th>
                </tr>
              </thead>
              <tbody>
                {this.props.visas.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{i.visaCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.passportNo}</td>
                      <td>{i.visaTypeName}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>{i.visaCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.passportNo}</td>
                      <td>{i.visaTypeName}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.visas.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.visas.reduce((a, i) => {
                      if (
                        i.totalCostPrice &&
                        (i.totalCostPrice != null) & (i.totalCostPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.visas.reduce((a, i) => {
                      if (
                        i.totalSellPrice &&
                        (i.totalSellPrice != null) & (i.totalSellPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          <strong>
            Total : {this.props.vProfit}{" "}
            {this.props.vProfit > 0 ? (
              <img
                src="../vendors/images/up.png"
                width="10"
                height="10"
                alt=""
              />
            ) : (
              <img
                src="../vendors/images/dw.png"
                width="10"
                height="10"
                alt=""
              />
            )}
          </strong>
          <br />
          <br />
          <br />
          <h5>Miscellaneous</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Description</th>
                  <th>Date &amp; Time</th>
                  <th>Remarks</th>
                  <th>Net Cost</th>
                  <th>VAT</th>
                  <th>Total Cost</th>
                  <th>Net Sell</th>
                  <th>VAT</th>
                  <th>Total Sell</th>
                </tr>
              </thead>
              <tbody>
                {this.props.miscellanea.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.miscellaneousCode}
                      </td>
                      <td>{i.paxName}</td>
                      <td>{i.description}</td>
                      <td>{i.misDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.miscellaneousCode}
                      </td>
                      <td>{i.paxName}</td>
                      <td>{i.description}</td>
                      <td>{i.misDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.costBaseAmount}</td>
                      <td>{i.costTaxAmount}</td>
                      <td>{i.totalCostPrice}</td>
                      <td>{i.sellBaseAmount}</td>
                      <td>{i.sellTaxAmount}</td>
                      <td>{i.totalSellPrice}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.miscellanea.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.miscellanea.reduce((a, i) => {
                      if (
                        i.totalCostPrice &&
                        (i.totalCostPrice != null) & (i.totalCostPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.miscellanea.reduce((a, i) => {
                      if (
                        i.totalSellPrice &&
                        (i.totalSellPrice != null) & (i.totalSellPrice != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          <strong>
            Total : {this.props.mProfit}{" "}
            {this.props.mProfit > 0 ? (
              <img
                src="../vendors/images/up.png"
                width="10"
                height="10"
                alt=""
              />
            ) : (
              <img
                src="../vendors/images/dw.png"
                width="10"
                height="10"
                alt=""
              />
            )}
          </strong>
          <br />
          <br />
          <br />
          <h5>Finance</h5>
          <h6>Payment Voucher</h6>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Voucher Number</th>
                  <th>Vendor Name</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.vouchers.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.voucherDate}
                      </td>
                      <td>{i.voucherCode}</td>
                      <td>{i.vendorName}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.voucherDate}
                      </td>
                      <td>{i.voucherCode}</td>
                      <td>{i.vendorName}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.vouchers.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.vouchers.reduce((a, i) => {
                      if (
                        i.amount &&
                        (i.amount != null) & (i.amount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.amount);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          {this.props.vouchers.length == 0 && ( <br />)}
          <h6>Proforma Invoices</h6>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Invoice Number</th>
                  <th>Due Date</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.proformaInvoices.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceDate}
                      </td>
                      <td>{i.invoiceCode}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceDate}
                      </td>
                      <td>{i.invoiceCode}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.proformaInvoices.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.proformaInvoices.reduce((a, i) => {
                      if (
                        i.amount &&
                        (i.amount != null) & (i.amount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.amount);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          {this.props.proformaInvoices.length == 0 && ( <br />)}
          <h6>Proforma Invoice Receipts</h6>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Receipt Number</th>
                  <th>Invoice Number</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.proformaInvoicesReceipts.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.receiptDate}
                      </td>
                      <td>{i.receiptCode}</td>
                      <td>{i.invoiceNo}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.receiptDate}
                      </td>
                      <td>{i.receiptCode}</td>
                      <td>{i.invoiceNo}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.proformaInvoicesReceipts.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.proformaInvoicesReceipts.reduce((a, i) => {
                      if (
                        i.amount &&
                        (i.amount != null) & (i.amount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.amount);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          {this.props.proformaInvoicesReceipts.length == 0 && ( <br />)}
          <h6>Tax Invoices</h6>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Invoice Number</th>
                  <th>Due Date</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.invoices.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceDate}
                      </td>
                      <td>{i.invoiceCode}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceDate}
                      </td>
                      <td>{i.invoiceCode}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.invoices.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.invoices.reduce((a, i) => {
                      if (
                        i.amount &&
                        (i.amount != null) & (i.amount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.amount);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          {this.props.invoices.length == 0 && ( <br />)}
          <h6>Tax Invoice Receipts</h6>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th>Receipt Number</th>
                  <th>Invoice Number</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.receipts.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.receiptDate}
                      </td>
                      <td>{i.receiptCode}</td>
                      <td>{i.invoiceNo}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.receiptDate}
                      </td>
                      <td>{i.receiptCode}</td>
                      <td>{i.invoiceNo}</td>
                      <td>{i.remarks}</td>
                      <td>{i.amount}</td>
                    </tr>
                  )
                )}
              </tbody>
              {this.props.receipts.length > 0 && (
                <tfoot>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {this.props.receipts.reduce((a, i) => {
                      if (
                        i.amount &&
                        (i.amount != null) & (i.amount != "")
                      ) {
                        var p = parseFloat(a) + parseFloat(i.amount);
                        return p.toFixed(2);
                      }
                    }, 0)}
                  </th>
                </tfoot>
              )}
            </table>
          </div>
          {this.props.receipts.length == 0 && ( <br />)}
          <strong>
            Total : {profitAndLost}{" "}
            {profitAndLost > 0 ? (
              <img
                src="../vendors/images/up.png"
                width="10"
                height="10"
                alt=""
              />
            ) : (
              <img
                src="../vendors/images/dw.png"
                width="10"
                height="10"
                alt=""
              />
            )}
          </strong>
          <br />
          <br />
          <br />
          <div classname="divClass" class="divFooter">
            Printed from <strong>Logistics Pro</strong>
          </div>
        </div>
      </>
    );
  }
}

export default JobCardFullReportInner;
