import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs, { Dayjs } from "dayjs";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import BatchCopyItems from "../components/BatchCopyItems";
import PreLoader from "../components/shards/PreLoader";

class ViewBatches extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      batchCode: "",
      batchDateFrom: null,
      batchDateTo: null,
      vendorId: [],
      vendors: [],
      batchItems: [],
      batchItem: null,
      suplierName: "",
      batchDate: "",
      batchCodeV: "",
      jobCardNumber: "",
      batch: null,
      isLoading: false,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    await this.getReferenceData();
    await this.getBatches(true);
    this.setState({
      isLoading: false,
    });
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getBatches = async (isFirstLoad) => {
    this.setState({
      isLoading: true,
    });

    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetBatches?` +
        new URLSearchParams({
          batchCode: this.state.batchCode,
          batchDateFrom: this.state.batchDateFrom
            ? new Date(this.state.batchDateFrom).toDateString()
            : "",
          batchDateTo: this.state.batchDateTo
            ? new Date(this.state.batchDateTo).toDateString()
            : "",
          vendorId: this.state.vendorId.toString(),
          jobCardNumber: this.state.jobCardNumber,
          isFirstLoad:isFirstLoad
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.batches.map((i) =>
          batchList.push({
            id: i.id,
            batchCode: i.batchCode,
            vendorName: i.vendorName,
            batchDate:
              i.batchDate == null
                ? null
                : dayjs(i.batchDate).format("DD/MM/YYYY"),
            batchItems: this.GetBatchTransportItem(i.batchTransportItems),
            batchTransportItems: this.GetBatchTransportItem(
              i.batchTransportItems
            ),
          })
        );

        if (
          !(
            this.state.batchCode ||
            this.state.vendorId ||
            this.state.batchDateFrom ||
            this.state.batchDateTo
          )
        ) {
          this.setState({
            batches: batchList,
          });
        }
        this.setState({
          filterBatches: batchList,
          isLoading: false,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    this.setState({
      isLoading: true,
    });
    const batchItems = [];
    items.map((i) => {
      batchItems.push({
        id: i.id,
        bookingRef: i.bookingRef,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null
            ? ""
            : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? "" : dayjs(i.flightTime).format(" HH:mm"),
        paxName: i.paxName,
        flight: `${i.flightNo} ${
          i.flightTime == null ? "" : dayjs(i.flightTime).format(" HH:mm")
        }`,
        remarks: i.remarks,
        flightDetail: this.GetFlightDetails(i.flightNo.trim(), i.flightTime),
      });
    });
    this.setState({
      isLoading: false,
    });
    return batchItems;
  };

  GetFlightDetails = (flightNo, flightTime) => {
    var flightDetails = "";

    if (flightNo && flightNo != null && flightNo != "") {
      flightDetails = flightNo;

      if (flightTime && flightTime != null && flightTime != "") {
        flightDetails = `${flightNo} ${dayjs(flightTime).format("HH:mm")}`;
      }
    }

    return flightDetails;
  };
  getReferenceData = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          vendors: response.vendors,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      batchCode: "",
      batchDateFrom: null,
      batchDateTo: null,
      filterBatches: this.state.batches,
    });
  };
  actionBodyTemplate = (item) => {
    return (
      <Button
        onClick={(e) => this.confirm(item)}
        icon="pi pi-times"
        severity="danger"
      />
    );
  };

  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          style={{ width: "1.5rem", height: "1.5rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpen: true,
              batchItems: item.batchItems,
              batch: item,
            })
          }
          icon="pi pi-book"
          severity="secondary"
          tooltip="View Items"
        />
        <Button
          style={{ width: "1.5rem", height: "1.5rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpenCopy: true,
              selectedItem: item.batchTransportItems,
              suplierName: item.vendorName,
              batchDate: item.batchDate,
              batchCodeV: item.batchCode,
            })
          }
          icon="pi pi-copy"
          severity="secondary"
          tooltip="Copy Items"
        />
      </div>
    );
  };

  removeItemFromBatchHandler = async (e) => {
    this.setState({
      isLoading: true,
    });
    var payload = {
      Id: this.state.batchItem.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/RemoveBatchItemFromList`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setErrorMessage("Delete Successfully", 1);
        window.location.reload();
      });
    this.setState({
      isLoading: false,
    });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: "Copied!" });
  };
  confirm = (item) => {
    this.setState({
      batchItem: item,
    });
    var accept = this.removeItemFromBatchHandler;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to remove from batch?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  selectAll = () => {
    var r = document.createRange();
    r.selectNode(document.getElementById("elements"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };
  GetBatchItemTitle = () => {
    return (
      <>
        {this.state.batch && this.state.batch != null && (
          <>
            {this.state.batch.batchCode} - {this.state.batch.vendorName}
          </>
        )}
      </>
    );
  };
  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      this.setState({
        isLoading: true,
      });
      await this.getBatches(false);
      this.setState({
        isLoading: false,
      });
    }
  };
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          {" "}
                          <img
                            width={"50px"}
                            src="/vendors/images/document.gif"
                            alt=""
                            style={{verticalAlign:"middle"}}
                          />{" "}
                          View Batches
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="title">
                        <h4>Search</h4>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Batch Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.batchCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    batchCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Vendor</label>
                              <MultiSelect
                                value={this.state.vendorId}
                                onChange={(e) =>
                                  this.handleChange({
                                    vendorId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.vendors}
                                optionLabel="code"
                                placeholder="Select a Vendor"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Batch From Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.batchDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    batchDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Batch To Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.batchDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    batchDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardNumber}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardNumber: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-warning pull-right"
                              onClick={(e) => {
                                this.resetSearchHandler(e);
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.setState({
                                  isLoading: true,
                                });
                                this.getBatches(false);
                                this.setState({
                                  isLoading: false,
                                });
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  {this.state.filterBatches && this.state.filterBatches.length>0 && ( <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.filterBatches}
                      selectionMode="single"
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="batchCode"
                        sortable
                        header="Batch Number"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="vendorName"
                        sortable
                        header="Vendor Name"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="batchDate"
                        sortable
                        header="Duty Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>)}
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={this.GetBatchItemTitle}
                  visible={this.state.isModalOpen}
                  style={{ width: "80vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <br />
                  <DataTable
                    stripedRows
                    showGridlines
                    value={this.state.batchItems}
                    selectionMode="single"
                    dataKey="id"
                    metaKeySelection={false}
                    sortMode="multiple"
                    paginator
                    rows={20}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field="bookingRef"
                      sortable
                      header="Booking Ref"
                    ></Column>
                    <Column
                      field="pickupTime"
                      sortable
                      style={{ fontWeight: "bold" }}
                      header="Pickup Time"
                    ></Column>
                    <Column
                      field="pickupLocation"
                      sortable
                      header="Pickup Location"
                    ></Column>
                    <Column
                      field="dropoffLocation"
                      sortable
                      header="Dropoff Location"
                    ></Column>
                    <Column field="flight" sortable header="Flight"></Column>
                    <Column field="paxName" sortable header="PAX Name"></Column>
                    <Column
                      field="totalPax"
                      sortable
                      header="Total PAX"
                    ></Column>
                    <Column field="adults" sortable header="A"></Column>
                    <Column field="children" sortable header="C"></Column>
                    <Column field="infants" sortable header="I"></Column>
                    <Column
                      field="vehicleType"
                      sortable
                      header="Vehicle Type"
                    ></Column>
                    <Column field="remarks" sortable header="Remarks"></Column>
                    <Column
                      field="action"
                      sortable
                      body={this.actionBodyTemplate}
                      header="Action"
                    ></Column>
                  </DataTable>
                </Dialog>
                <Dialog
                  maximizable
                  visible={this.state.isModalOpenCopy}
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpenCopy: false });
                  }}
                >
                  {this.state.batchCodeV &&
                    this.state.suplierName &&
                    this.state.batchDate &&
                    this.state.selectedItem.length > 0 && (
                      <BatchCopyItems
                        batchItems={this.state.selectedItem}
                        batchNo={this.state.batchCodeV}
                        suplier={this.state.suplierName}
                        date={this.state.batchDate}
                      />
                    )}
                </Dialog>
                <ConfirmDialog />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
            {this.state.isLoading && <PreLoader />}
          </div>
        }
      </>
    );
  }
}

export default ViewBatches;
