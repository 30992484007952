import React from 'react'
import Header from './Header'
import LeftSlider from './LeftSlider'
import PreLoader from './PreLoader'
import RightSlider from './RightSlider'

export default function LayoutAdmin({children}) {
  return (
    <div>
       {/*  <PreLoader/> */}
      <Header/>
      <LeftSlider/>
      <div className="mobile-menu-overlay"></div>
      {children}
    </div>
  )
}
