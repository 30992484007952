import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { Fieldset } from "primereact/fieldset";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import "../src/styles/style.css";
class Transportation extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      transports: [],
      pickUpLocations: [],
      filterPickUpLocations: [],
      dropOffLocations: [],
      filterDropOffLocations: [],
      vehicleTypes: [],
      filterVehicleTypes: [],
      transport: null,
      customerRef: "",
      paxName: "",
      adults: "",
      children: "",
      infants: "",
      vehicleType: "",
      pickupLocation: "",
      pickupTime: null,
      dropoffLocation: "",
      flightNo: "",
      flightTime: null,
      isVatIncludedCost: false,
      isVatIncludedSell: false,
      costBaseAmount: "",
      costTaxAmount: "",
      sellBaseAmount: "",
      sellTaxAmount: "",
      totalCostVatPrice: "",
      totalCostAmountPrice: "",
      totalSellVatPrice: "",
      totalSellAmountPrice: "",
      parking: "",
      parkingTaxAmount: "",
      water: "",
      waterTaxAmount: "",
      extras: "",
      extrasTaxAmount: "",
      parkingSell: "",
      parkingTaxAmountSell: "",
      waterSell: "",
      waterTaxAmountSell: "",
      extrasSell: "",
      extrasTaxAmountSell: "",
      isError: false,
      errorMessage: "",
      isSaveProcessing: false,
      isRemoveProcessing: false,
      isDuplicateProcessing: false,
      isInvoiced: false,
      isBatched: false,
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getJobTransportations();
    await this.getReferenceData();
  };

  getReferenceData = async () => {
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          pickUpLocations: response.pickUpLocations,
          dropOffLocations: response.dropOffLocations,
          vehicleTypes: response.vehicleTypes,
          hotelNames: response.hotelNames,
        });
      });
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getJobTransportations = async () => {
    this.props.setLoader(true);
    const transportList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportationsByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardTransportations.map((i) => {
          transportList.push({
            id: i.id,
            transportationCode: i.transportationCode,
            pickupLocation: i.pickupLocation,
            pickupTime:
              i.pickupTime == null
                ? null
                : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
            pickupLocation: i.pickupLocation,
            dropoffLocation: i.dropoffLocation,
            totalPax: i.totalPax,
            adults: i.adults,
            children: i.children,
            infants: i.infants,
            vehicleType: i.vehicleType,
            flightNo: i.flightNo,
            flightTime:
              i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
            paxName: i.paxName,
            remarks: i.remarks,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            parking: i.parking,
            parkingTaxAmount: i.parkingTaxAmount,
            extras: i.extras,
            extrasTaxAmount: i.extrasTaxAmount,
            water: i.water,
            waterTaxAmount: i.waterTaxAmount,
            parkingSell: i.parkingSell,
            parkingTaxAmountSell: i.parkingTaxAmountSell,
            extrasSell: i.extrasSell,
            extrasTaxAmountSell: i.extrasTaxAmountSell,
            waterSell: i.waterSell,
            waterTaxAmountSell: i.waterTaxAmountSell,
            isInvoiced: i.isInvoiced,
            isBatched: i.isBatched,
          });
        });
        this.props.setLoader(false);
        this.setState({
          transports: transportList,
          isModalOpen: false,
        });
      });
  };

  handleCostTaxChange(costBase) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var totalAmount = 0;
    var extrasTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extras =
      this.state.extras && this.state.extras > 0
        ? parseFloat(this.state.extras)
        : 0;

    var parking =
      this.state.parking && this.state.parking > 0
        ? parseFloat(this.state.parking)
        : 0;
    var water =
      this.state.water && this.state.water > 0
        ? parseFloat(this.state.water)
        : 0;

    if (this.state.isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      extrasTax = extras * 0.05;
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      totalTax = tax + extrasTax + parkingTax + waterTax;
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      costBaseAmount: costBase,
      costTaxAmount: costTax,
      totalCostVatPrice: totalTax.toFixed(2),
      extrasTaxAmount: extrasTax,
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      totalCostAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleTotalCostTaxChange(
    costBase,
    isVatIncludedCost,
    extras,
    parking,
    water
  ) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var totalAmount = 0;
    var extrasTax = 0;
    var parkingTax = 0;
    var waterTax = 0;

    if (isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      extrasTax = extras * 0.05;
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      totalTax = tax + extrasTax + parkingTax + waterTax;
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalCostVatPrice: totalTax.toFixed(2),
      extrasTaxAmount: extrasTax,
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      totalCostAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleCostTaxFlagChange(vat) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var costBase =
      this.state.costBaseAmount && this.state.costBaseAmount > 0
        ? parseFloat(this.state.costBaseAmount)
        : 0;
    var extras =
      this.state.extras && this.state.extras > 0
        ? parseFloat(this.state.extras)
        : 0;
    var parking =
      this.state.parking && this.state.parking > 0
        ? parseFloat(this.state.parking)
        : 0;
    var water =
      this.state.water && this.state.water > 0
        ? parseFloat(this.state.water)
        : 0;
    if (vat) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax = tax + parkingTax + waterTax + extrasTax;
    }

    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);
    this.setState({
      costBaseAmount: costBase,
      costTaxAmount: costTax,
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      extrasTaxAmount: extrasTax,
      totalCostVatPrice: totalTax.toFixed(2),
      totalCostAmountPrice: totalAmount.toFixed(2),
    });

    this.setState({
      isVatIncludedCost: vat,
    });
  }

  handleCostTaxChangeByExtras(extras) {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var costBase =
      this.state.costBaseAmount && this.state.costBaseAmount > 0
        ? parseFloat(this.state.costBaseAmount)
        : 0;
    var parking =
      this.state.parking && this.state.parking > 0
        ? parseFloat(this.state.parking)
        : 0;
    var water =
      this.state.water && this.state.water > 0
        ? parseFloat(this.state.water)
        : 0;
    if (this.state.isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      waterTax = water * 0.05;
      parkingTax = parking * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(waterTax) +
        parseFloat(parkingTax) +
        parseFloat(extrasTax);
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);
    this.setState({
      totalCostVatPrice: totalTax.toFixed(2),
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      extrasTaxAmount: extrasTax,
      extras: extras,
      totalCostAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleCostTaxChangeByParking = (parking) => {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var costBase =
      this.state.costBaseAmount && this.state.costBaseAmount > 0
        ? parseFloat(this.state.costBaseAmount)
        : 0;
    var extras =
      this.state.extras && this.state.extras > 0
        ? parseFloat(this.state.extras)
        : 0;
    var water =
      this.state.water && this.state.water > 0
        ? parseFloat(this.state.water)
        : 0;

    if (this.state.isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      waterTax = water * 0.05;
      parkingTax = parking * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(waterTax) +
        parseFloat(parkingTax) +
        parseFloat(extrasTax);
    }

    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalCostVatPrice: totalTax.toFixed(2),
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      extrasTaxAmount: extrasTax,
      parking: parking,
      totalCostAmountPrice: totalAmount.toFixed(2),
    });
  };

  handleCostTaxChangeByWater = (water) => {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var costBase =
      this.state.costBaseAmount && this.state.costBaseAmount > 0
        ? parseFloat(this.state.costBaseAmount)
        : 0;
    var extras =
      this.state.extras && this.state.extras > 0
        ? parseFloat(this.state.extras)
        : 0;
    var parking =
      this.state.parking && this.state.parking > 0
        ? parseFloat(this.state.parking)
        : 0;

    if (this.state.isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      waterTax = water * 0.05;
      parkingTax = parking * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(waterTax) +
        parseFloat(parkingTax) +
        parseFloat(extrasTax);
    }

    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalCostVatPrice: totalTax.toFixed(2),
      parkingTaxAmount: parkingTax,
      waterTaxAmount: waterTax,
      extrasTaxAmount: extrasTax,
      water: water,
      totalCostAmountPrice: totalAmount.toFixed(2),
    });
  };

  handleSellTaxChange(_costBase) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var totalAmount = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var costBase = _costBase && _costBase > 0 ? parseFloat(_costBase) : 0;
    var extras =
      this.state.extrasSell && this.state.extrasSell > 0
        ? parseFloat(this.state.extrasSell)
        : 0;

    var parking =
      this.state.parkingSell && this.state.parkingSell > 0
        ? parseFloat(this.state.parkingSell)
        : 0;
    var water =
      this.state.waterSell && this.state.waterSell > 0
        ? parseFloat(this.state.waterSell)
        : 0;

    if (this.state.isVatIncludedSell) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax = tax + extrasTax + parkingTax + waterTax;
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      sellBaseAmount: _costBase,
      sellTaxAmount: costTax,
      totalSellVatPrice: totalTax.toFixed(2),
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      totalSellAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleTotalSellTaxChange(
    costBase,
    isVatIncludedCost,
    extras,
    parking,
    water
  ) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var totalAmount = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;

    if (isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);

      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax = tax + extrasTax + parkingTax + waterTax;
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalSellVatPrice: totalTax.toFixed(2),
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      totalSellAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleSellTaxFlagChange(vat) {
    debugger;
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var sellBase =
      this.state.sellBaseAmount && this.state.sellBaseAmount > 0
        ? parseFloat(this.state.sellBaseAmount)
        : 0;
    var extras =
      this.state.extrasSell && this.state.extrasSell > 0
        ? parseFloat(this.state.extrasSell)
        : 0;
    var parking =
      this.state.parkingSell && this.state.parkingSell > 0
        ? parseFloat(this.state.parkingSell)
        : 0;
    var water =
      this.state.waterSell && this.state.waterSell > 0
        ? parseFloat(this.state.waterSell)
        : 0;
    if (vat) {
      var tax = parseFloat(sellBase) * 0.05;
      costTax = tax.toFixed(2);
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax = tax + extrasTax + parkingTax + waterTax;
    }

    totalAmount =
      parseFloat(sellBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);
    this.setState({
      sellBaseAmount: sellBase,
      sellTaxAmount: costTax,
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      totalSellVatPrice: totalTax.toFixed(2),
      totalSellAmountPrice: totalAmount.toFixed(2),
    });

    this.setState({
      isVatIncludedSell: vat,
    });
  }

  handleSellTaxChangeByExtras(_extras) {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    debugger;
    var extras = _extras && _extras > 0 ? parseFloat(_extras) : 0;
    var costBase =
      this.state.sellBaseAmount && this.state.sellBaseAmount > 0
        ? parseFloat(this.state.sellBaseAmount)
        : 0;
    var parking =
      this.state.parkingSell && this.state.parkingSell > 0
        ? parseFloat(this.state.parkingSell)
        : 0;
    var water =
      this.state.waterSell && this.state.waterSell > 0
        ? parseFloat(this.state.waterSell)
        : 0;
    if (this.state.isVatIncludedSell) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(parkingTax) +
        parseFloat(waterTax) +
        parseFloat(extrasTax);
    }
    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);
    this.setState({
      totalSellVatPrice: totalTax.toFixed(2),
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      extrasSell: _extras,
      totalSellAmountPrice: totalAmount.toFixed(2),
    });
  }

  handleSellTaxChangeByParking = (_parking) => {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var parking = _parking && _parking > 0 ? parseFloat(_parking) : 0;
    var costBase =
      this.state.sellBaseAmount && this.state.sellBaseAmount > 0
        ? parseFloat(this.state.sellBaseAmount)
        : 0;
    var extras =
      this.state.extrasSell && this.state.extrasSell > 0
        ? parseFloat(this.state.extrasSell)
        : 0;
    var water =
      this.state.waterSell && this.state.waterSell > 0
        ? parseFloat(this.state.waterSell)
        : 0;

    if (this.state.isVatIncludedSell) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(parkingTax) +
        parseFloat(waterTax) +
        parseFloat(extrasTax);
    }

    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalSellVatPrice: totalTax.toFixed(2),
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      parkingSell: _parking,
      totalSellAmountPrice: totalAmount.toFixed(2),
    });
  };

  handleSellTaxChangeByWater = (_water) => {
    var costTax = 0;
    var totalTax = 0;
    var parkingTax = 0;
    var waterTax = 0;
    var extrasTax = 0;
    var totalAmount = 0;
    var water = _water && _water > 0 ? parseFloat(_water) : 0;
    var costBase =
      this.state.sellBaseAmount && this.state.sellBaseAmount > 0
        ? parseFloat(this.state.sellBaseAmount)
        : 0;
    var extras =
      this.state.extrasSell && this.state.extrasSell > 0
        ? parseFloat(this.state.extrasSell)
        : 0;
    var parking =
      this.state.parkingSell && this.state.parkingSell > 0
        ? parseFloat(this.state.parkingSell)
        : 0;
    if (this.state.isVatIncludedSell) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax.toFixed(2);
      parkingTax = parking * 0.05;
      waterTax = water * 0.05;
      extrasTax = extras * 0.05;
      totalTax =
        parseFloat(tax) +
        parseFloat(parkingTax) +
        parseFloat(waterTax) +
        parseFloat(extrasTax);
    }

    totalAmount =
      parseFloat(costBase) +
      parseFloat(totalTax) +
      parseFloat(parking) +
      parseFloat(water) +
      parseFloat(extras);

    this.setState({
      totalSellVatPrice: totalTax.toFixed(2),
      parkingTaxAmountSell: parkingTax,
      waterTaxAmountSell: waterTax,
      extrasTaxAmountSell: extrasTax,
      waterSell: _water,
      totalSellAmountPrice: totalAmount.toFixed(2),
    });
  };

  handleChange(changeObject) {
    debugger;
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  footerContent = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={(e) => {
            this.setState({ isModalOpen: false });
          }}
          severity="secondary"
        />
        {this.state.id > 0 && (
          <>
            {!(this.state.isInvoiced || this.state.isBatched) && (
              <Button
                label="Delele"
                icon="pi pi-times"
                loading={this.state.isRemoveProcessing}
                severity="danger"
                onClick={(e) => {
                  this.removeJobTransportationHandler(e);
                }}
              />
            )}
            <Button
              label="Duplicate"
              loading={this.state.isDuplicateProcessing}
              icon="pi pi-copy"
              onClick={(e) => {
                this.setState({ isModalOpen: false });
                this.confirm();
              }}
            />
          </>
        )}
        {!this.state.isInvoiced && (
          <Button
            label="Save"
            icon="pi pi-check"
            loading={this.state.isSaveProcessing}
            onClick={(e) => {
              this.saveTransportationHandler(e);
            }}
          />
        )}
        <ConfirmDialog />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };
  accept = () => {
    this.duplicateTransportationHandler();
  };
  confirm = () => {
    debugger;
    var accept = this.accept;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  openTableModal = async (e) => {
    debugger;
    this.resetFields();
    await this.getJobTransportation(e.value.id);
  };

  getJobTransportation = async (id) => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return await fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobTransportationById?` +
        new URLSearchParams({
          id: id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          id: response.jobCardTransportation.id,
          customerRef: response.jobCardTransportation.customerRef,
          paxName: response.jobCardTransportation.paxName,
          adults: response.jobCardTransportation.adults,
          children: response.jobCardTransportation.children,
          infants: response.jobCardTransportation.infants,
          vehicleType: response.jobCardTransportation.vehicleType,
          pickupLocation: response.jobCardTransportation.pickupLocation,
          pickupTime:
            response.jobCardTransportation.pickupTime == null
              ? null
              : response.jobCardTransportation.pickupTime,
          childrePickupTimen: response.jobCardTransportation.pickupTime,
          dropoffLocation: response.jobCardTransportation.dropoffLocation,
          flightNo: response.jobCardTransportation.flightNo,
          flightTime:
            response.jobCardTransportation.flightTime == null
              ? null
              : dayjs(response.jobCardTransportation.flightTime).format(
                  "HH:mm"
                ),
          isVatIncludedCost: response.jobCardTransportation.isVatIncludedCost,
          isVatIncludedSell: response.jobCardTransportation.isVatIncludedSell,
          costBaseAmount: response.jobCardTransportation.costBaseAmount,
          costTaxAmount:
            response.jobCardTransportation.costTaxAmount == null
              ? null
              : response.jobCardTransportation.costTaxAmount.toFixed(2),
          costTotalAmount:
            response.jobCardTransportation.totalCostPrice == null
              ? null
              : response.jobCardTransportation.totalCostPrice.toFixed(2),
          sellBaseAmount: response.jobCardTransportation.sellBaseAmount,
          sellTaxAmount:
            response.jobCardTransportation.sellTaxAmount == null
              ? null
              : response.jobCardTransportation.sellTaxAmount.toFixed(2),
          sellTotalAmount:
            response.jobCardTransportation.totalSellPrice == null
              ? null
              : response.jobCardTransportation.totalSellPrice.toFixed(2),
          parking: response.jobCardTransportation.parking,
          water: response.jobCardTransportation.water,
          extras: response.jobCardTransportation.extras,
          parkingSell: response.jobCardTransportation.parkingSell,
          waterSell: response.jobCardTransportation.waterSell,
          extrasSell: response.jobCardTransportation.extrasSell,
          remarks: response.jobCardTransportation.remarks,
          isInvoiced: response.jobCardTransportation.isInvoiced,
          isBatched: response.jobCardTransportation.isBatched,
          isModalOpen: true,
        });
        this.props.setLoader(false);
        this.handleTotalCostTaxChange(
          response.jobCardTransportation.costBaseAmount,
          response.jobCardTransportation.isVatIncludedCost,
          response.jobCardTransportation.extras,
          response.jobCardTransportation.parking,
          response.jobCardTransportation.water
        );

        this.handleTotalSellTaxChange(
          response.jobCardTransportation.sellBaseAmount,
          response.jobCardTransportation.isVatIncludedSell,
          response.jobCardTransportation.extrasSell,
          response.jobCardTransportation.parkingSell,
          response.jobCardTransportation.waterSell
        );
      });
  };

  removeJobTransportationHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isRemoveProcessing: true,
    });
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/RemoveTransportation`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isRemoveProcessing: false,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isModalOpen: false,
          isRemoveProcessing: false,
        });
        this.resetFields();
        this.getJobTransportations();
      });
  };
  validation = () => {
    if (this.state.paxName == "") {
      this.setErrorMessage("Please enter passenger name.", 3);
      return false;
    }

    if (this.state.adults == "") {
      this.setErrorMessage("Please enter number of adults.", 3);
      return false;
    }

    if (this.state.vehicleType == "") {
      this.setErrorMessage("Please enter vehicle type.", 3);
      return false;
    }

    if (this.state.pickupLocation == "") {
      this.setErrorMessage("Please enter pick-up location.", 3);
      return false;
    }

    if (this.state.pickupTime == null) {
      this.setErrorMessage("Please enter pick-up time.", 3);
      return false;
    }

    if (this.state.dropoffLocation == null) {
      this.setErrorMessage("Please enter drop-off location.", 3);
      return false;
    }

    return true;
  };

  getAutoCompleteValue = (data) => {
    if (data && data.value && data.value.length > 0) {
      return data.value;
    }

    if (data && data.length > 0) {
      return data;
    }

    return "";
  };
  saveTransportationHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing: true,
    });
    debugger;
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }

    var payload = {
      Id: this.state.id,
      JobCardId: this.props.id,
      CustomerRef: this.state.customerRef,
      PaxName: this.state.paxName,
      Adults: this.state.adults == "" ? 0 : this.state.adults,
      Children: this.state.children == "" ? 0 : this.state.children,
      Infants: this.state.infants == "" ? 0 : this.state.infants,
      //VehicleType: !this.state.vehicleType || this.state.vehicleType==""?"":this.state.vehicleType.value,
      VehicleType: this.getAutoCompleteValue(this.state.vehicleType),
      //PickupLocation: !this.state.pickupLocation || this.state.pickupLocation==""?"":this.state.pickupLocation.value,
      PickupLocation: this.getAutoCompleteValue(this.state.pickupLocation),
      PickupTime:
        this.state.pickupTime == null
          ? null
          : this.state.pickupTime.toLocaleString(),
      ChildrePickupTimen: this.state.pickupTime,
      //DropoffLocation: !this.state.dropoffLocation || this.state.dropoffLocation==""?"":this.state.dropoffLocation.value,
      DropoffLocation: this.getAutoCompleteValue(this.state.dropoffLocation),
      FlightNo: this.state.flightNo,
      FlightTime:
        this.state.flightTime == null
          ? null
          : this.state.flightTime.toLocaleString(),
      IsVatIncludedCost: this.state.isVatIncludedCost
        ? this.state.isVatIncludedCost
        : false,
      IsVatIncludedSell: this.state.isVatIncludedSell
        ? this.state.isVatIncludedSell
        : false,
      CostBaseAmount:
        this.state.costBaseAmount == "" ? 0 : this.state.costBaseAmount,
      CostTaxAmount:
        this.state.costTaxAmount == "" ? 0 : this.state.costTaxAmount,
      SellBaseAmount:
        this.state.sellBaseAmount == "" ? 0 : this.state.sellBaseAmount,
      SellTaxAmount:
        this.state.sellTaxAmount == "" ? 0 : this.state.sellTaxAmount,
      Parking: this.state.parking == "" ? 0 : this.state.parking,
      ParkingTaxAmount:
        this.state.parkingTaxAmount == "" ? 0 : this.state.parkingTaxAmount,
      Water: this.state.water == "" ? 0 : this.state.water,
      WaterTaxAmount:
        this.state.waterTaxAmount == "" ? 0 : this.state.waterTaxAmount,
      Extras: this.state.extras == "" ? 0 : this.state.extras,
      ExtrasTaxAmount:
        this.state.extrasTaxAmount == "" ? 0 : this.state.extrasTaxAmount,
      ParkingSell: this.state.parkingSell == "" ? 0 : this.state.parkingSell,
      ParkingTaxAmountSell:
        this.state.parkingTaxAmountSell == ""
          ? 0
          : this.state.parkingTaxAmountSell,
      waterSell: this.state.waterSell == "" ? 0 : this.state.waterSell,
      WaterTaxAmountSell:
        this.state.waterTaxAmountSell == "" ? 0 : this.state.waterTaxAmountSell,
      extrasSell: this.state.extrasSell == "" ? 0 : this.state.extrasSell,
      ExtrasTaxAmountSell:
        this.state.extrasTaxAmountSell == ""
          ? 0
          : this.state.extrasTaxAmountSell,
      Remarks: this.state.remarks,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/SaveTransportation`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
            isModalOpen: false,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing: false,
          isModalOpen: false,
        });
        this.resetFields();
        this.getJobTransportations();
        this.getReferenceData();
      });
  };

  duplicateTransportationHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isDuplicateProcessing: true,
    });
    var payload = {
      JobCardId: this.props.id,
      CustomerRef: this.state.customerRef,
      PaxName: this.state.paxName,
      Adults: this.state.adults == "" ? 0 : this.state.adults,
      Children: this.state.children == "" ? 0 : this.state.children,
      Infants: this.state.infants == "" ? 0 : this.state.infants,
      VehicleType: this.state.vehicleType,
      PickupLocation: this.state.pickupLocation,
      PickupTime:
        this.state.pickupTime == null
          ? null
          : this.state.pickupTime.toLocaleString(),
      ChildrePickupTimen: this.state.pickupTime,
      DropoffLocation: this.state.dropoffLocation,
      FlightNo: this.state.flightNo,
      FlightTime:
        this.state.flightTime == null
          ? null
          : this.state.flightTime.toLocaleString(),
      IsVatIncludedCost: this.state.isVatIncludedCost
        ? this.state.isVatIncludedCost
        : false,
      IsVatIncludedSell: this.state.isVatIncludedSell
        ? this.state.isVatIncludedSell
        : false,
      CostBaseAmount:
        this.state.costBaseAmount == "" ? 0 : this.state.costBaseAmount,
      CostTaxAmount:
        this.state.costTaxAmount == "" ? 0 : this.state.costTaxAmount,
      SellBaseAmount:
        this.state.sellBaseAmount == "" ? 0 : this.state.sellBaseAmount,
      SellTaxAmount:
        this.state.sellTaxAmount == "" ? 0 : this.state.sellTaxAmount,
      Parking: this.state.parking == "" ? 0 : this.state.parking,
      ParkingTaxAmount:
        this.state.parkingTaxAmount == "" ? 0 : this.state.parkingTaxAmount,
      Water: this.state.water == "" ? 0 : this.state.water,
      WaterTaxAmount:
        this.state.waterTaxAmount == "" ? 0 : this.state.waterTaxAmount,
      Extras: this.state.extras == "" ? 0 : this.state.extras,
      ExtrasTaxAmount:
        this.state.extrasTaxAmount == "" ? 0 : this.state.extrasTaxAmount,
      ParkingSell: this.state.parkingSell == "" ? 0 : this.state.parkingSell,
      ParkingTaxAmountSell:
        this.state.parkingTaxAmountSell == ""
          ? 0
          : this.state.extrasTaxAmountSell,
      waterSell: this.state.waterSell == "" ? 0 : this.state.waterSell,
      WaterTaxAmountSell:
        this.state.waterTaxAmountSell == "" ? 0 : this.state.waterTaxAmountSell,
      extrasSell: this.state.extrasSell == "" ? 0 : this.state.extrasSell,
      ExtrasTaxAmountSell:
        this.state.extrasTaxAmountSell == ""
          ? 0
          : this.state.extrasTaxAmountSell,
      Remarks: this.state.remarks,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/SaveTransportation`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isDuplicateProcessing: false,
            errorMessage: response.data.successMessage,
            isModalOpen: false,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isDuplicateProcessing: false,
        });
        this.resetFields();
        this.getJobTransportations();
      });
  };

  resetFields = () => {
    this.setState({
      id: 0,
      customerRef: "",
      paxName: "",
      adults: "",
      children: "",
      infants: "",
      vehicleType: "",
      pickupLocation: "",
      pickupTime: null,
      dropoffLocation: "",
      flightNo: "",
      flightTime: null,
      isVatIncludedCost: false,
      isVatIncludedSell: false,
      costBaseAmount: "",
      costTaxAmount: "",
      sellBaseAmount: "",
      sellTaxAmount: "",
      remarks: "",
      parking: "",
      water: "",
      extras: "",
      totalCostVatPrice: "",
      totalCostAmountPrice: "",
      parkingSell: "",
      waterSell: "",
      extrasSell: "",
      totalSellVatPrice: "",
      totalSellAmountPrice: "",
      isInvoiced: false,
      isBatched: false,
    });
  };

  isInvoiced = (option) => {
    return (
      <>
        {option.isInvoiced ? (
          <img src="../vendors/images/checked.png" width="24" alt="" />
        ) : (
          <img src="../vendors/images/unchecked.png" width="24" alt="" />
        )}
      </>
    );
  };

  isBatched = (option) => {
    return (
      <>
        {option.isBatched ? (
          <img src="../vendors/images/checked.png" width="24" alt="" />
        ) : (
          <img src="../vendors/images/unchecked.png" width="24" alt="" />
        )}
      </>
    );
  };

  removeDuplicates = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index);
  };
  pickupSearch = (event) => {
    setTimeout(() => {
      debugger;
      var _filteredPickUpLocations;

      if (!event.query.trim().length) {
        _filteredPickUpLocations = this.state.pickUpLocations;
      } else {
        _filteredPickUpLocations = this.state.pickUpLocations.filter((item) => {
          return item.value.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      this.setState({
        pickupLocation: event.query,
        filterPickUpLocations: _filteredPickUpLocations,
      });
    }, 1);
  };

  dropOffSearch = (event) => {
    setTimeout(() => {
      let _filteredDropOffLocations;

      if (!event.query.trim().length) {
        _filteredDropOffLocations = this.state.dropOffLocations;
      } else {
        _filteredDropOffLocations = this.state.dropOffLocations.filter(
          (item) => {
            return item.value
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          }
        );
      }

      this.setState({
        dropoffLocation: event.query,
        filterDropOffLocations: _filteredDropOffLocations,
      });
    }, 250);
  };

  vehicleTypeSearch = (event) => {
    setTimeout(() => {
      let _filteredVehicleTypeLocations;

      if (!event.query.trim().length) {
        _filteredVehicleTypeLocations = this.state.vehicleTypes;
      } else {
        _filteredVehicleTypeLocations = this.state.vehicleTypes.filter(
          (item) => {
            return item.value
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          }
        );
      }

      this.setState({
        vehicleType: event.query,
        filterVehicleTypes: _filteredVehicleTypeLocations,
      });
    }, 250);
  };

  legendTemplate = () => {
    return (
      <div className="flex align-items-center text-primary">
        <span className="pi pi-user mr-2"></span>
        <span className="font-bold text-lg">User Details</span>
      </div>
    );
  };
  render() {
    return (
      <div>
        {this.props.id > 0 && (
          <div>
            <Button
              icon="pi pi-plus"
              style={{ margin: "5px" }}
              severity="info"
              tooltip="Add New"
              onClick={(e) => {
                this.resetFields();
                this.setState({
                  isModalOpen: true,
                  transport: null,
                });
              }}
              tooltipOptions={{ position: "top" }}
            />
          </div>
        )}
        <DataTable
          stripedRows
          showGridlines
          value={this.state.transports}
          selectionMode="single"
          onSelectionChange={(e) => {
            this.openTableModal(e);
          }}
          dataKey="id"
          metaKeySelection={false}
          sortMode="multiple"
          paginator
          rows={20}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="transportationCode"
            sortable
            header="Booking Ref"
          ></Column>
          <Column
            field="pickupTime"
            sortable
            header="Pickup Time"
            style={{ fontWeight: "bold" }}
          ></Column>
          <Column
            field="pickupLocation"
            sortable
            header="Pickup Location"
          ></Column>
          <Column
            field="dropoffLocation"
            sortable
            header="Drop Off Location"
          ></Column>
          <Column field="totalPax" sortable header="Total PAX"></Column>
          <Column field="adults" sortable header="A"></Column>
          <Column field="children" sortable header="C"></Column>
          <Column field="infants" sortable header="I"></Column>
          <Column field="vehicleType" sortable header="Vehicle Type"></Column>
          <Column field="flightNo" sortable header="Flight No"></Column>
          <Column field="flightTime" sortable header="Flight Time"></Column>
          <Column field="paxName" sortable header="Pax Name"></Column>
          <Column field="remarks" sortable header="Remarks"></Column>
          <Column field="totalCostPrice" sortable header="Buy"></Column>
          <Column field="totalSellPrice" sortable header="Sell"></Column>
          <Column body={this.isInvoiced} sortable header="Is Invoiced"></Column>
          <Column body={this.isBatched} sortable header="Is Batched"></Column>
        </DataTable>

        <Dialog
          maximizable
          style={{ zIndex: "auto" }}
          headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
          contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
          visible={this.state.isModalOpen}
          header="Add Transportation"
          onHide={(e) => {
            this.setState({ isModalOpen: false });
          }}
          footer={this.footerContent()}
        >
          <form>
            <Fieldset legend="Main Details">
              <div className="form-group row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Customer Ref
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.customerRef}
                        onChange={(e) =>
                          this.handleChange({
                            customerRef: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Pax Name *
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.paxName}
                        onChange={(e) =>
                          this.handleChange({
                            paxName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Pax No
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-4">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Adults *
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.adults}
                            onChange={(e) =>
                              this.handleChange({
                                adults: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Children
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.children}
                            onChange={(e) =>
                              this.handleChange({
                                children: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Infants
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.infants}
                            onChange={(e) =>
                              this.handleChange({
                                infants: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Pick Up Location *
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <AutoComplete
                        style={{ width: "100%" }}
                        value={this.state.pickupLocation}
                        field="value"
                        suggestions={this.state.filterPickUpLocations}
                        completeMethod={this.pickupSearch}
                        onChange={(e) =>
                          this.handleChange({
                            pickupLocation: e.target.value,
                          })
                        }
                      />
                      {/*  <input
                        className="form-control"
                        type="text"
                        autocomplete="on"
                        value={this.state.pickupLocation}
                        onChange={(e) =>
                          this.handleChange({
                            pickupLocation: e.target.value,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Pick Up Time *
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <input
                        className="form-control"
                        value={this.state.pickupTime}
                        onChange={(e) =>
                          this.handleChange({
                            pickupTime: e.target.value,
                          })
                        }
                        type="datetime-local"
                        id="pickupTime"
                        name="pickupTime"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Drop off Location *
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <AutoComplete
                        style={{ width: "100%" }}
                        value={this.state.dropoffLocation}
                        field="value"
                        suggestions={this.state.filterDropOffLocations}
                        completeMethod={this.dropOffSearch}
                        onChange={(e) =>
                          this.handleChange({
                            dropoffLocation: e.target.value,
                          })
                        }
                      />
                      {/* <input
                        className="form-control"
                        type="text"
                        autocomplete="on"
                        value={this.state.dropoffLocation}
                        onChange={(e) =>
                          this.handleChange({
                            dropoffLocation: e.target.value,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">
                      Vehicle Type *
                    </label>
                    <div className="col-sm-12 col-md-8">
                      <AutoComplete
                        style={{ width: "100%" }}
                        value={this.state.vehicleType}
                        field="value"
                        suggestions={this.state.filterVehicleTypes}
                        completeMethod={this.vehicleTypeSearch}
                        onChange={(e) =>
                          this.handleChange({
                            vehicleType: e.target.value,
                          })
                        }
                      />
                      {/*  <input
                        className="form-control"
                        type="text"
                        autocomplete="on"
                        value={this.state.vehicleType}
                        onChange={(e) =>
                          this.handleChange({
                            vehicleType: e.target.value,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Flight Details
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Flight No
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            value={this.state.flightNo}
                            onChange={(e) =>
                              this.handleChange({
                                flightNo: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Flight Time
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            value={this.state.flightTime}
                            onChange={(e) =>
                              this.handleChange({
                                flightTime: e.target.value,
                              })
                            }
                            type="time"
                            id="flightTime"
                            name="flightTime"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />
            <Fieldset legend="Cost Price">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Cost Price
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-3">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.costBaseAmount}
                            onChange={(e) =>
                              this.handleCostTaxChange(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Parking
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.parking}
                                onChange={(e) =>
                                  this.handleCostTaxChangeByParking(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Water
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.water}
                                onChange={(e) =>
                                  this.handleCostTaxChangeByWater(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Extras
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.extras}
                                onChange={(e) =>
                                  this.handleCostTaxChangeByExtras(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Total Cost
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-6 col-form-label">
                          VAT
                        </label>
                        <div className="col-sm-12 col-md-6">
                          <Checkbox
                            checked={this.state.isVatIncludedCost}
                            onChange={(e) =>
                              this.handleCostTaxFlagChange(e.checked)
                            }
                          ></Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label"></label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly
                            value={this.state.totalCostVatPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Amount
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            readOnly
                            min="0"
                            value={this.state.totalCostAmountPrice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />
            <Fieldset legend="Sell Price">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Sell Price
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-3">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.sellBaseAmount}
                            onChange={(e) =>
                              this.handleSellTaxChange(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Parking
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.parkingSell}
                                onChange={(e) =>
                                  this.handleSellTaxChangeByParking(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Water
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.waterSell}
                                onChange={(e) =>
                                  this.handleSellTaxChangeByWater(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="row">
                            <label className="col-sm-12 col-md-4 col-form-label">
                              Extras
                            </label>
                            <div className="col-sm-12 col-md-8">
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                value={this.state.extrasSell}
                                onChange={(e) =>
                                  this.handleSellTaxChangeByExtras(
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Total Sell
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-6 col-form-label">
                          VAT
                        </label>
                        <div className="col-sm-12 col-md-6">
                          <Checkbox
                            checked={this.state.isVatIncludedSell}
                            onChange={(e) =>
                              this.handleSellTaxFlagChange(e.checked)
                            }
                          ></Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label"></label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly
                            value={this.state.totalSellVatPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-4 col-form-label">
                          Amount
                        </label>
                        <div className="col-sm-12 col-md-8">
                          <input
                            className="form-control"
                            type="number"
                            readOnly
                            min="0"
                            value={this.state.totalSellAmountPrice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />

            <Fieldset legend="Other">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Remarks
                </label>
                <div className="col-sm-12 col-md-10">
                  <textarea
                    className="form-control"
                    value={this.state.remarks}
                    onChange={(e) =>
                      this.handleChange({ remarks: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </Fieldset>
            <br />
            <div className="form-group row">
              <div className="col-sm-12 col-md-12"></div>
            </div>
          </form>
        </Dialog>

        {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
      </div>
    );
  }
}

export default Transportation;
