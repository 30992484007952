import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import InvoiceReport from "../components/InvoiceReport";
import VatReport from "../components/VatReport";
import { MultiSelect } from "primereact/multiselect";

class VatReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      isModalOpenInvoice: false,
      invoiceCode: "",
      invoiceDateFrom: null,
      invoiceDateTo: null,
      invoiceDueDateFrom: null,
      invoiceDueDateTo: null,
      invoice: null,
      customerId: [],
      statusId: [],
      vendors: [],
      status: [],
      invoiceLineItems: [],
      paymentVoucherItem: null,
      invoiceItem: null,
      isPaymentModalOpen: false,
      jobCardNumber: "",
      isLoading: false,
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    //await this.getInvoices();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
          status: response.invoiceStatuse,
        });
      });
  };

  getInvoices = async (isFirstLoad) => {
    this.setState({
      isLoading: true,
    });
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetInvoices?` +
        new URLSearchParams({
          invoiceCode: this.state.invoiceCode,
          customerId: this.state.customerId.toString(),
          statusId: this.state.statusId.toString(),
          invoiceDateFrom: this.state.invoiceDateFrom
            ? new Date(this.state.invoiceDateFrom).toDateString()
            : "",
          invoiceDateTo: this.state.invoiceDateTo
            ? new Date(this.state.invoiceDateTo).toDateString()
            : "",
          invoiceDueDateFrom: this.state.invoiceDueDateFrom
            ? new Date(this.state.invoiceDueDateFrom).toDateString()
            : "",
          invoiceDueDateTo: this.state.invoiceDueDateTo
            ? new Date(this.state.invoiceDueDateTo).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
          isFirstLoad:isFirstLoad
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.invoices.map((i) =>
          batchList.push({
            id: i.id,
            jobCards: i.jobCards,
            invoiceCode: i.invoiceCode,
            customerName: i.customerName,
            addressLine1: i.customerAddressLine1,
            addressLine2: i.customerAddressLine2,
            invoiceAmount:
              i.invoiceAmount == null ? null : i.invoiceAmount.toFixed(2),
            statusName: i.statusName,
            description: i.description,
            statusId: i.statusId,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            // invoiceLineItems: this.GetBatchTransportItem(i.invoiceLineItems),
            jobCardNos: i.jobCardNos,
            totalLinkedProformaReciptAmount: i.totalLinkedProformaReciptAmount,
            linkedProformaInvoices: i.linkedProformaInvoices,
            linkProformaInvoiceCodes: i.linkProformaInvoiceCodes,
            invoiceNetAmount:
              i.invoiceNetAmount == null ? null : i.invoiceNetAmount.toFixed(2),
            invoiceVatAmount:
              i.invoiceVatAmount == null ? null : i.invoiceVatAmount.toFixed(2),
            invoiceGrossAmount:
              i.invoiceGrossAmount == null
                ? null
                : i.invoiceGrossAmount.toFixed(2),
          })
        );
        this.setState({
          isLoading: false,
        });
        if (
          !(
            this.state.invoiceCode ||
            this.state.customerId ||
            this.state.invoiceDateFrom ||
            this.state.invoiceDateTo ||
            this.state.invoiceDueDateFrom ||
            this.state.invoiceDueDateTo ||
            this.state.statusId
          )
        ) {
          this.setState({
            batches: batchList,
            isModalOpen: true,
          });
        }
        this.setState({
          filterBatches: batchList,
          isModalOpen: true,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];
    debugger;
    items.transportations.map((i) => {
      batchTransportationItems.push({
        jobCardNo: i.jobCardNo,
        jobCardTitle: i.jobCardTitle,
        id: i.id,
        batchNo: i.batchNo,
        bookingRef: i.bookingRef,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null ? null : dayjs(i.pickupTime).format("HH:mm"),
        dropoffLocation: i.dropoffLocation,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        totalCostTaxAmount:
          i.totalCostTaxAmount == null ? null : i.totalCostTaxAmount.toFixed(2),
        extras: i.extras == null ? null : i.extras.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        parking: i.parking == null ? null : i.parking.toFixed(2),
        water: i.water == null ? null : i.water.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        totalSellTaxAmount:
          i.totalSellTaxAmount == null ? null : i.totalSellTaxAmount.toFixed(2),
        extrasSell: i.extrasSell == null ? null : i.extrasSell.toFixed(2),
        parkingSell: i.parkingSell == null ? null : i.parkingSell.toFixed(2),
        waterSell: i.waterSell == null ? null : i.waterSell.toFixed(2),
        type: "Transportation",
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Hotel",
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Visa",
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Miscellaneous",
      });
    });
    debugger;

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getInvoices(false);
    }
  };
  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">
                      <img
                        width={"50px"}
                        src="/vendors/images/tax-report.gif"
                        alt=""
                        style={{ verticalAlign: "middle" }}
                      />{" "}
                      VAT Report
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">From Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.invoiceDateFrom}
                            onChange={(e) =>
                              this.handleChange({
                                invoiceDateFrom: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">To Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.invoiceDateTo}
                            onChange={(e) =>
                              this.handleChange({
                                invoiceDateTo: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Customer</label>
                          <MultiSelect
                            value={this.state.customerId}
                            onChange={(e) =>
                              this.handleChange({
                                customerId: e.target.value,
                              })
                            }
                            display="chip"
                            options={this.state.customers}
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Job Card Number</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.jobCardNumber}
                            onChange={(e) =>
                              this.handleChange({
                                jobCardNumber: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-warning pull-right"
                            onClick={(e) => {
                              this.resetSearchHandler(e);
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary pull-right"
                            onClick={(e) => {
                              this.getInvoices(false);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <Dialog
              maximizable
              style={{ width: "90vw" }}
              header="Invoice Report"
              headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
              contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
              visible={this.state.isModalOpen}
              onHide={(e) => {
                this.setState({ isModalOpen: false });
              }}
            >
              {this.state.filterBatches &&
                this.state.filterBatches.length > 0 && (
                  <VatReport reportItem={this.state.filterBatches} />
                )}
            </Dialog>
            {this.state.isLoading && <PreLoader />}
          </div>
        </div>
      </div>
    );
  }
}

export default VatReportWrap;
