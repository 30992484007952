import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BatchInner from "./BatchInner";
import HotelServiceVoucherInner from "./HotelServiceVoucherInner";
import ReactToPrint from "react-to-print";

class HotelServiceVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  copyToClip = () => {
    var el = document.getElementById("report");
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    this.setErrorMessage("Batch copied to clipboard, You can paste", 1);
  };
  render() {
    return (
      <>
        <div className="main-container">
          <div
            className="card-box mb-30"
            style={{ padding: "10px", width: "100%" }}
          >
            {this.props.selectedItem && (
              <HotelServiceVoucherInner
              selectedItem={this.props.selectedItem}
                ref={(el) => (this.componentRef = el)}
              />
            )}
            <div className="row">
              <button
                type="button"
                style={{ margin: "10px" }}
                className="btn btn-primary pull-right"
                onClick={(e) => {
                  this.copyToClip();
                }}
              >
                Copy to Clipboard
              </button>

              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    style={{ margin: "10px" }}
                    className="btn btn-secondary pull-right"
                  >
                    Print Report
                  </button>
                )}
                content={() => this.componentRef}
                copyStyles={true}
                pageStyle="print"
              />
            </div>

            <ToastContainer
              position="top-center"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </>
    );
  }
}

export default HotelServiceVoucher;
