import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import ViewInvoice from "../pages/ViewInvoice";
class InvoiceReportInner extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isModalOpenInvoice: false,
      invoice: null,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />

          <br />
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Invoice Number</th>
                  <th>Customer Name</th>
                  <th>Invoice Date</th>
                  <th>Invoice Due Date</th>
                  <th>Invoice Amount</th>
                  <th>Status Name</th>
                </tr>
              </thead>
              <tbody>
                {this.props.reportItem.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        <a
                          href="#"
                          onClick={(e) =>
                            this.setState({
                              isModalOpenInvoice: true,
                              invoice: i,
                            })
                          }
                        >
                          {i.invoiceCode}
                        </a>
                      </td>
                      <td>{i.customerName}</td>
                      <td>{i.invoiceDate}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceAmount}
                      </td>
                      <td>{i.statusName}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <a
                          href="#"
                          onClick={(e) =>
                            this.setState({
                              isModalOpenInvoice: true,
                              invoice: i,
                            })
                          }
                        >
                          {i.invoiceCode}
                        </a>
                      </td>
                      <td>{i.customerName}</td>
                      <td>{i.invoiceDate}</td>
                      <td>{i.invoiceDueDate}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.invoiceAmount}
                      </td>
                      <td>{i.statusName}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Dialog
          maximizable
          headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
          contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
          header="Invoice Preview"
          visible={this.state.isModalOpenInvoice}
          onHide={(e) => {
            this.setState({ isModalOpenInvoice: false });
          }}
        >
          <ViewInvoice invoice={this.state.invoice} />
        </Dialog>
      </>
    );
  }
}

export default InvoiceReportInner;
