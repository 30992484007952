import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs, { Dayjs } from "dayjs";
import { Tooltip } from "primereact/tooltip";
import { Accordion, AccordionTab } from "primereact/accordion";
import { MultiSelect } from "primereact/multiselect";
import PreLoader from "../components/shards/PreLoader";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import BatchCopyItems from "../components/BatchCopyItems";
import ViewInvoice from "./ViewInvoice";

class ViewInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      isModalOpenInvoice: false,
      invoiceCode: "",
      invoiceDateFrom: null,
      invoiceDateTo: null,
      invoiceDueDateFrom: null,
      invoiceDueDateTo: null,
      invoice: null,
      customerId: [],
      statusId: [],
      vendors: [],
      status: [],
      invoiceLineItems: [],
      paymentVoucherItem: null,
      invoiceItem: null,
      jobCardNumber: "",
      isPaymentModalOpen: false,
      isLoading: false,
      sales: [
        {
          product: "Bamboo Watch",
          lastYearSale: 51,
          thisYearSale: 40,
          lastYearProfit: 54406,
          thisYearProfit: 43342,
        },
        {
          product: "Black Watch",
          lastYearSale: 83,
          thisYearSale: 9,
          lastYearProfit: 423132,
          thisYearProfit: 312122,
        },
        {
          product: "Blue Band",
          lastYearSale: 38,
          thisYearSale: 5,
          lastYearProfit: 12321,
          thisYearProfit: 8500,
        },
        {
          product: "Blue T-Shirt",
          lastYearSale: 49,
          thisYearSale: 22,
          lastYearProfit: 745232,
          thisYearProfit: 65323,
        },
        {
          product: "Brown Purse",
          lastYearSale: 17,
          thisYearSale: 79,
          lastYearProfit: 643242,
          thisYearProfit: 500332,
        },
        {
          product: "Chakra Bracelet",
          lastYearSale: 52,
          thisYearSale: 65,
          lastYearProfit: 421132,
          thisYearProfit: 150005,
        },
        {
          product: "Galaxy Earrings",
          lastYearSale: 82,
          thisYearSale: 12,
          lastYearProfit: 131211,
          thisYearProfit: 100214,
        },
        {
          product: "Game Controller",
          lastYearSale: 44,
          thisYearSale: 45,
          lastYearProfit: 66442,
          thisYearProfit: 53322,
        },
        {
          product: "Gaming Set",
          lastYearSale: 90,
          thisYearSale: 56,
          lastYearProfit: 765442,
          thisYearProfit: 296232,
        },
        {
          product: "Gold Phone Case",
          lastYearSale: 75,
          thisYearSale: 54,
          lastYearProfit: 21212,
          thisYearProfit: 12533,
        },
      ],
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    await this.getInvoices(true);
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getInvoices = async (isFirstLoad) => {
    this.setState({
      isLoading: true,
    });
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetInvoices?` +
        new URLSearchParams({
          invoiceCode: this.state.invoiceCode,
          customerId: this.state.customerId.toString(),
          statusId: this.state.statusId.toString(),
          invoiceDateFrom: this.state.invoiceDateFrom
            ? new Date(this.state.invoiceDateFrom).toDateString()
            : "",
          invoiceDateTo: this.state.invoiceDateTo
            ? new Date(this.state.invoiceDateTo).toDateString()
            : "",
          invoiceDueDateFrom: this.state.invoiceDueDateFrom
            ? new Date(this.state.invoiceDueDateFrom).toDateString()
            : "",
          invoiceDueDateTo: this.state.invoiceDueDateTo
            ? new Date(this.state.invoiceDueDateTo).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
          isFirstLoad:isFirstLoad
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.invoices.map((i) =>
          batchList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            customerName: i.customerName,
            addressLine1: i.customerAddressLine1,
            addressLine2: i.customerAddressLine2,
            customerTrn: i.customerTrn,
            invoiceAmount:
              i.invoiceAmount == null ? null : i.invoiceAmount.toFixed(2),
            statusName: i.statusName,
            transportDescription: i.transportDescription,
            hotelDescription: i.hotelDescription,
            visaDescription: i.visaDescription,
            miscellaneousDescription: i.miscellaneousDescription,
            statusId: i.statusId,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            invoiceLineItems: this.GetBatchTransportItem(i.invoiceLineItems),
            transportJobCardNos: i.transportJobCardNos,
            hotelJobCardNos: i.hotelJobCardNos,
            visaJobCardNos: i.visaJobCardNos,
            miscellaneousJobCardNos: i.miscellaneousJobCardNos,
            totalLinkedProformaReciptAmount: i.totalLinkedProformaReciptAmount,
            linkedProformaInvoices: i.linkedProformaInvoices,
            linkProformaInvoiceCodes: i.linkProformaInvoiceCodes,
            invoiceNetAmount:
              i.invoiceNetAmount == null ? null : i.invoiceNetAmount.toFixed(2),
            invoiceVatAmount:
              i.invoiceVatAmount == null ? null : i.invoiceVatAmount.toFixed(2),
            invoiceGrossAmount:
              i.invoiceGrossAmount == null
                ? null
                : i.invoiceGrossAmount.toFixed(2),
          })
        );
        this.setState({
          isLoading: false,
        });
        if (
          !(
            this.state.invoiceCode ||
            this.state.customerId ||
            this.state.invoiceDateFrom ||
            this.state.invoiceDateTo ||
            this.state.invoiceDueDateFrom ||
            this.state.invoiceDueDateTo ||
            this.state.statusId
          )
        ) {
          this.setState({
            batches: batchList,
          });
        }
        this.setState({
          filterBatches: batchList,
        });
      });
  };

  GetBatchTransportItem = (items) => {
    const batchTransportationItems = [];
    const batchHotelItems = [];
    const batchVisaItems = [];
    const batchMiscellaneousItems = [];
    items.transportations.map((i) => {
      batchTransportationItems.push({
        jobCardNo: i.jobCardNo,
        jobCardTitle: i.jobCardTitle,
        id: i.id,
        batchNo: i.batchNo,
        bookingRef: i.bookingRef,
        pickupLocation: i.pickupLocation,
        pickupTime:
          i.pickupTime == null
            ? null
            : dayjs(i.pickupTime).format("DD/MM/YYYY HH:mm"),
        dropoffLocation: i.dropoffLocation,
        customerRef: i.customerRef,
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        vehicleType: i.vehicleType,
        flightNo: i.flightNo,
        flightTime:
          i.flightTime == null ? null : dayjs(i.flightTime).format("HH:mm"),
        paxName: i.paxName,
        remarks: i.remarks == "" || i.remarks == null ? " " : i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        totalCostTaxAmount:
          i.totalCostTaxAmount == null ? null : i.totalCostTaxAmount.toFixed(2),
        extras: i.extras == null ? null : i.extras.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        parking: i.parking == null ? null : i.parking.toFixed(2),
        water: i.water == null ? null : i.water.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        totalSellTaxAmount:
          i.totalSellTaxAmount == null ? null : i.totalSellTaxAmount.toFixed(2),
        extrasSell: i.extrasSell == null ? null : i.extrasSell.toFixed(2),
        parkingSell: i.parkingSell == null ? null : i.parkingSell.toFixed(2),
        waterSell: i.waterSell == null ? null : i.waterSell.toFixed(2),
        type: "Transportation",
      });
    });

    items.hotels.map((i) => {
      batchHotelItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        hotelCode: i.hotelCode,
        hotelName: i.hotelName,
        checkIn:
          i.checkIn == null
            ? null
            : dayjs(i.checkIn).format("DD/MM/YYYY HH:mm"),
        checkOut:
          i.checkOut == null
            ? null
            : dayjs(i.checkOut).format("DD/MM/YYYY HH:mm"),
        totalPax: i.totalPax,
        adults: i.adults,
        children: i.children,
        infants: i.infants,
        paxName: i.paxName,
        remarks: i.remarks,
        hotelConfirmation: i.hotelConfirmation,
        roomType: i.roomType,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Hotel",
      });
    });

    items.visa.map((i) => {
      batchVisaItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        visaCode: i.visaCode,
        passportNo: i.passportNo,
        visaTypeName: i.visaTypeName,
        paxName: i.paxName,
        remarks: i.remarks,
        nationality: i.nationality,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Visa",
      });
    });

    items.miscellaneous.map((i) => {
      batchMiscellaneousItems.push({
        id: i.id,
        jobCardNo: i.jobCardNo,
        miscellaneousCode: i.miscellaneousCode,
        paxName: i.paxName,
        paxNumber: i.paxNumber,
        description: i.description,
        misDate:
          i.misDate == null
            ? null
            : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
        remarks: i.remarks,
        costBaseAmount:
          i.costBaseAmount == null ? null : i.costBaseAmount.toFixed(2),
        costTaxAmount:
          i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
        totalCostPrice:
          i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
        sellBaseAmount:
          i.sellBaseAmount == null ? null : i.sellBaseAmount.toFixed(2),
        sellTaxAmount:
          i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
        totalSellPrice:
          i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
        type: "Miscellaneous",
      });
    });

    var returnItem = {
      batchTransportationItems: batchTransportationItems,
      batchHotelItems: batchHotelItems,
      batchVisaItems: batchVisaItems,
      batchMiscellaneousItems: batchMiscellaneousItems,
      isSearch: true,
    };

    return returnItem;
  };

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
          status: response.invoiceStatuse,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      invoiceCode: "",
      jobCardNumber: "",
      invoiceDateFrom: null,
      invoiceDateTo: null,
      invoiceDueDateFrom: null,
      invoiceDueDateTo: null,
      customerId: "",
      statusId: "",
      filterBatches: this.state.batches,
    });
  };
  actionBodyTemplate = (item) => {
    return (
      <Button
        onClick={(e) => this.confirm(item)}
        icon="pi pi-times"
        severity="danger"
      />
    );
  };

  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          className="m-1"
          style={{ width: "2rem", height: "2rem" }}
          onClick={(e) =>
            this.setState({
              isModalOpen: true,
              invoiceLineItems: item.invoiceLineItems,
            })
          }
          icon="pi pi-book"
          severity="secondary"
          tooltip="View Items"
        />
        {item.statusId != 3 && (
          <>
            <Button
              className="m-1"
              style={{ width: "2rem", height: "2rem" }}
              onClick={(e) => {
                this.confirmVoid(item);
              }}
              icon="pi pi-times"
              severity="danger"
              tooltip="Void Invoice"
            />
            <Button
              className="m-1"
              style={{ width: "2rem", height: "2rem" }}
              onClick={(e) => {
                this.openReceipt(item.id);
              }}
              icon="pi pi-dollar"
              severity="secondary"
              tooltip="Record Payment"
            />
          </>
        )}
        <Button
          className="m-1"
          style={{ width: "2rem", height: "2rem" }}
          onClick={(e) =>
            this.setState({
              isModalOpenInvoice: true,
              invoice: item,
            })
          }
          icon="pi pi-file"
          severity="secondary"
          tooltip="Invoice"
        />
      </div>
    );
  };

  voidInvoice = async (e) => {
    this.setState({
      isLoading: true,
    });
    var payload = {
      Id: this.state.invoiceItem.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/VoidInvoice`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.setErrorMessage("Invoice Successfully Voided", 1);
        window.location.reload();
      });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  confirmVoid = (item) => {
    this.setState({
      invoiceItem: item,
    });
    var accept = this.voidInvoice;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to void Invoice?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  totalCostAmountBodyTemplate = (rowData) => {
    return <b>{rowData.totalCostPrice}</b>;
  };

  sellBaseAmountBodyTemplate = (rowData) => {
    return <b>{rowData.sellBaseAmount}</b>;
  };
  sellTotalAmountBodyTemplate = (rowData) => {
    return <b>{rowData.totalSellPrice}</b>;
  };
  openReceipt(id) {
    window.location.href = `${process.env.PUBLIC_URL}/invoice_receipt/${id}`;
  }
  totalNetTransfer = () => {
    return this.state.invoiceLineItems.batchTransportationItems.reduce(
      (a, i) => {
        debugger;
        if (
          i.totalCostPrice &&
          (i.totalCostPrice != null) & (i.totalCostPrice != "")
        ) {
          var p = parseFloat(a) + parseFloat(i.totalCostPrice);
          return p.toFixed(2);
        }
      },
      0
    );
  };
  totalSellTransfer = () => {
    return this.state.invoiceLineItems.batchTransportationItems.reduce(
      (a, i) => {
        debugger;
        if (
          i.totalSellPrice &&
          (i.totalSellPrice != null) & (i.totalSellPrice != "")
        ) {
          var p = parseFloat(a) + parseFloat(i.totalSellPrice);
          return p.toFixed(2);
        }
      },
      0
    );
  };

  totalNetHotel = () => {
    return this.state.invoiceLineItems.batchHotelItems.reduce((a, i) => {
      debugger;
      if (
        i.totalCostPrice &&
        (i.totalCostPrice != null) & (i.totalCostPrice != "")
      ) {
        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
        return p.toFixed(2);
      }
    }, 0);
  };
  totalSellHotel = () => {
    return this.state.invoiceLineItems.batchHotelItems.reduce((a, i) => {
      debugger;
      if (
        i.totalSellPrice &&
        (i.totalSellPrice != null) & (i.totalSellPrice != "")
      ) {
        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
        return p.toFixed(2);
      }
    }, 0);
  };

  totalNetVisa = () => {
    return this.state.invoiceLineItems.batchVisaItems.reduce((a, i) => {
      debugger;
      if (
        i.totalCostPrice &&
        (i.totalCostPrice != null) & (i.totalCostPrice != "")
      ) {
        var p = parseFloat(a) + parseFloat(i.totalCostPrice);
        return p.toFixed(2);
      }
    }, 0);
  };
  totalSellVisa = () => {
    return this.state.invoiceLineItems.batchVisaItems.reduce((a, i) => {
      debugger;
      if (
        i.totalSellPrice &&
        (i.totalSellPrice != null) & (i.totalSellPrice != "")
      ) {
        var p = parseFloat(a) + parseFloat(i.totalSellPrice);
        return p.toFixed(2);
      }
    }, 0);
  };

  totalNetMiscellaneous = () => {
    return this.state.invoiceLineItems.batchMiscellaneousItems.reduce(
      (a, i) => {
        debugger;
        if (
          i.totalCostPrice &&
          (i.totalCostPrice != null) & (i.totalCostPrice != "")
        ) {
          var p = parseFloat(a) + parseFloat(i.totalCostPrice);
          return p.toFixed(2);
        }
      },
      0
    );
  };
  totalSellMiscellaneous = () => {
    return this.state.invoiceLineItems.batchMiscellaneousItems.reduce(
      (a, i) => {
        debugger;
        if (
          i.totalSellPrice &&
          (i.totalSellPrice != null) & (i.totalSellPrice != "")
        ) {
          var p = parseFloat(a) + parseFloat(i.totalSellPrice);
          return p.toFixed(2);
        }
      },
      0
    );
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getInvoices(false);
    }
  };
  totalInvoices = () => {
    return this.state.filterBatches.reduce((a, i) => {
      debugger;
      if (
        i.invoiceAmount &&
        (i.invoiceAmount != null) & (i.invoiceAmount != "")
      ) {
        var p = parseFloat(a) + parseFloat(i.invoiceAmount);
        return p.toFixed(2);
      }
    }, 0);
  };
  footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={this.totalInvoices} footerStyle={{ padding: "0.2rem 0.2rem" }}/>
        <Column/>
        <Column/>
      </Row>
    </ColumnGroup>
  );
  footerGroupTransfer = (
    <ColumnGroup>
      <Row>
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer="Totals:"
          colSpan={19}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalNetTransfer}
        />
        <Column style={{ padding: "0.2rem 0.2rem" }} footer="" colSpan={5} />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalSellTransfer}
        />
      </Row>
    </ColumnGroup>
  );

  footerGroupHotel = (
    <ColumnGroup>
      <Row>
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer="Totals:"
          colSpan={11}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalNetHotel}
        />
        <Column style={{ padding: "0.2rem 0.2rem" }} footer="" colSpan={2} />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalSellHotel}
        />
      </Row>
    </ColumnGroup>
  );

  footerGroupVisa = (
    <ColumnGroup>
      <Row>
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer="Totals:"
          colSpan={7}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalNetVisa}
        />
        <Column style={{ padding: "0.2rem 0.2rem" }} footer="" colSpan={2} />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalSellVisa}
        />
      </Row>
    </ColumnGroup>
  );

  footerGroupMiscellaneous = (
    <ColumnGroup>
      <Row>
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer="Totals:"
          colSpan={7}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalNetMiscellaneous}
        />
        <Column style={{ padding: "0.2rem 0.2rem" }} footer="" colSpan={2} />
        <Column
          style={{ padding: "0.2rem 0.2rem" }}
          footer={this.totalSellMiscellaneous}
        />
      </Row>
    </ColumnGroup>
  );
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/tax.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          View Invoices
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.invoiceCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice To Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Customer</label>
                              <MultiSelect
                                value={this.state.customerId}
                                onChange={(e) =>
                                  this.handleChange({
                                    customerId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.customers}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice Due From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDueDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDueDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice Due To Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDueDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDueDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Status</label>
                              <MultiSelect
                                value={this.state.statusId}
                                onChange={(e) =>
                                  this.handleChange({
                                    statusId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.status}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardNumber}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardNumber: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <button
                                type="button"
                                style={{ margin: "10px" }}
                                className="btn btn-warning pull-right"
                                onClick={(e) => {
                                  this.resetSearchHandler(e);
                                }}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                style={{ margin: "10px" }}
                                className="btn btn-primary pull-right"
                                onClick={(e) => {
                                  this.getInvoices(false);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  {this.state.filterBatches&&this.state.filterBatches.length>0&&(<div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.filterBatches}
                      selectionMode="single"
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                      footerColumnGroup={this.footerGroup}
                    >
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceCode"
                        sortable
                        header="Invoice Number"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="customerName"
                        sortable
                        header="Customer Name"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceDate"
                        sortable
                        header="Invoice Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceDueDate"
                        sortable
                        header="Invoice Due Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceAmount"
                        sortable
                        header="Invoice Amount"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="statusName"
                        sortable
                        header="Status"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>)}
                </div>
                <Dialog
                  maximizable
                  style={{ width: "100vw" }}
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Invoice Items"
                  visible={this.state.isModalOpen}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <Accordion activeIndex={0}>
                    <AccordionTab header="Transportation">
                      <DataTable
                        stripedRows
                        showGridlines
                        selectionMode="single"
                        value={
                          this.state.invoiceLineItems.batchTransportationItems
                        }
                        footerColumnGroup={this.footerGroupTransfer}
                        metaKeySelection={false}
                        sortMode="multiple"
                        dataKey="id"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="jobCardNo"
                          sortable
                          header="Job Card No"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="bookingRef"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="pickupTime"
                          sortable
                          header="Pickup Time"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="pickupLocation"
                          sortable
                          header="Pickup Location"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="dropoffLocation"
                          sortable
                          header="Dropoff Location"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="flightNo"
                          sortable
                          header="Flight No"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="flightTime"
                          sortable
                          header="Flight Time"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="paxName"
                          sortable
                          header="PAX Name"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="totalPax"
                          sortable
                          header="Total PAX"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="adults"
                          sortable
                          header="A"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="children"
                          sortable
                          header="C"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="infants"
                          sortable
                          header="I"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="vehicleType"
                          sortable
                          header="Vehicle Type"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="costBaseAmount"
                          sortable
                          header="Net Cost"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="parking"
                          sortable
                          header="Parking"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="water"
                          sortable
                          header="Water"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="extras"
                          sortable
                          header="Extras"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="totalCostTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="totalCostPrice"
                          sortable
                          body={this.totalCostAmountBodyTemplate}
                          header="Total Cost"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="sellBaseAmount"
                          sortable
                          header="Net Sell"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="parkingSell"
                          sortable
                          header="Parking"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="waterSell"
                          sortable
                          header="Water"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="extrasSell"
                          sortable
                          header="Extras"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="totalSellTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          style={{ padding: "0.5rem 1rem" }}
                          headerStyle={{ padding: "1rem 1rem" }}
                          field="totalSellPrice"
                          sortable
                          body={this.sellTotalAmountBodyTemplate}
                          header="Total Sell"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Hotel">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.invoiceLineItems.batchHotelItems}
                        selectionMode="single"
                        footerColumnGroup={this.footerGroupHotel}
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="hotelCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="adults"
                          sortable
                          header="Adults"
                        ></Column>
                        <Column
                          field="children"
                          sortable
                          header="Children"
                        ></Column>
                        <Column
                          field="infants"
                          sortable
                          header="Infants"
                        ></Column>
                        <Column
                          field="hotelName"
                          sortable
                          header="Hotel Name"
                        ></Column>
                        <Column
                          field="checkIn"
                          sortable
                          header="Check-in"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="checkOut"
                          sortable
                          header="Check-out"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="costBaseAmount"
                          sortable
                          header="Net Cost"
                        ></Column>
                        <Column
                          field="costTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          body={this.totalCostAmountBodyTemplate}
                          header="Total Cost"
                        ></Column>
                        <Column
                          field="sellBaseAmount"
                          sortable
                          header="Net Sell"
                        ></Column>
                        <Column
                          field="sellTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalSellPrice"
                          sortable
                          body={this.sellTotalAmountBodyTemplate}
                          header="Total Sell"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Visa">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.invoiceLineItems.batchVisaItems}
                        selectionMode="single"
                        footerColumnGroup={this.footerGroupVisa}
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="visaCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="passportNo"
                          sortable
                          header="Passport No"
                        ></Column>
                        <Column
                          field="visaTypeName"
                          sortable
                          header="Visa Type"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="costBaseAmount"
                          sortable
                          header="Net Cost"
                        ></Column>
                        <Column
                          field="costTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          body={this.totalCostAmountBodyTemplate}
                          header="Total Cost"
                        ></Column>
                        <Column
                          field="sellBaseAmount"
                          sortable
                          header="Net Sell"
                        ></Column>
                        <Column
                          field="sellTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalSellPrice"
                          sortable
                          body={this.sellTotalAmountBodyTemplate}
                          header="Total Sell"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Miscellaneous">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={
                          this.state.invoiceLineItems.batchMiscellaneousItems
                        }
                        selectionMode="single"
                        footerColumnGroup={this.footerGroupMiscellaneous}
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="miscellaneousCode"
                          sortable
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="paxName"
                          sortable
                          header="Pax Name"
                        ></Column>
                        <Column
                          field="description"
                          sortable
                          header="Description"
                        ></Column>
                        <Column
                          field="misDate"
                          sortable
                          header="Date & Time"
                          style={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                        <Column
                          field="costBaseAmount"
                          sortable
                          header="Net Cost"
                        ></Column>
                        <Column
                          field="costTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalCostPrice"
                          sortable
                          body={this.totalCostAmountBodyTemplate}
                          header="Total Cost"
                        ></Column>
                        <Column
                          field="sellBaseAmount"
                          sortable
                          header="Net Sell"
                        ></Column>
                        <Column
                          field="sellTaxAmount"
                          sortable
                          header="VAT"
                        ></Column>
                        <Column
                          field="totalSellPrice"
                          sortable
                          body={this.sellTotalAmountBodyTemplate}
                          header="Total Sell"
                        ></Column>
                      </DataTable>
                    </AccordionTab>
                  </Accordion>
                </Dialog>
                <Dialog
                  maximizable
                  style={{ width: "100vw" }}
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  visible={this.state.isPaymentModalOpen}
                  onHide={(e) => {
                    this.setState({ isPaymentModalOpen: false });
                  }}
                >
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Receipt Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.receiptDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    receiptDate: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Amount</label>
                              <input
                                className="form-control"
                                type="number"
                                readOnly
                                min="0"
                                value={this.state.receiptAmount}
                                onChange={(e) =>
                                  this.handleChange({
                                    receiptAmount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Remarks</label>
                              <textarea
                                className="form-control"
                                value={this.state.receiptRemarks}
                                onChange={(e) =>
                                  this.handleChange({
                                    receiptRemarks: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 col-md-12">
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-warning pull-right"
                              onClick={(e) => {
                                this.resetSearchHandler(e);
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary pull-right"
                              onClick={(e) => {
                                this.getInvoices(false);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Invoice Preview"
                  visible={this.state.isModalOpenInvoice}
                  onHide={(e) => {
                    this.setState({ isModalOpenInvoice: false });
                  }}
                >
                  <ViewInvoice invoice={this.state.invoice} />
                </Dialog>
                <ConfirmDialog />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ViewInvoices;
