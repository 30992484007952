import React from "react";
import { ToWords } from "to-words";

class PaymentVoucherReportInner extends React.Component {
  GetJobCardDetails = (items) => {
    return (
      <>
        {items.map((i) => {
          return (
            <>
              <span>
                {i.jobCardNo} {i.paxName} x{i.adults}Adults
              </span>
              <br />
            </>
          );
        })}
      </>
    );
  };
  GetVenderDetails = (item) => {
    return (
      <>
        <span>
          <b>{item.vendorName}</b>
        </span>
        {item.vendorAddressLine1 && item.vendorAddressLine1 != "" && (
          <>
            <br />
            <span>{item.vendorAddressLine1}</span>
          </>
        )}
        {item.vendorAddressLine1 && item.vendorAddressLine2 != "" && (
          <>
            <br />
            <span>{item.vendorAddressLine2}</span>
          </>
        )}
        <br />
      </>
    );
  };
  render() {
    const toWords = new ToWords({
      localeCode: "en-AE",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: "Dirham",
          plural: "Dirhams",
          symbol: "د.إ",
          fractionalUnit: {
            name: "Fil",
            plural: "Fils",
            symbol: "",
          },
        },
      },
    });
    return (
      <>
        <div id="report" style={{ width: "100%" }}>
          <p
            style={{
              fontFamily:
                "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
              fontSize: "16px",
              color: "#606060",
            }}
          >
            <table width="100%" height="265" border="0" align="left">
              <tbody>
                <tr>
                  <th colspan="3" bgcolor="#FFFFFF" scope="col">
                    <font
                      color="#FF6C04"
                      style={{ textAlign: "right", fontSize: "24px" }}
                    >
                      Payment Voucher
                    </font>
                  </th>
                  <th colspan="2" bgcolor="#FFFFFF" scope="col">
                    <img
                      src="../vendors/images/holiday_makers_rlogo.jpg"
                      width="206"
                      alt=""
                    />
                  </th>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style={{lineHeight:"35px"}}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td width="300">&nbsp;</td>
                  <td width="90">
                    <font size="3" style={{ textAlign: "center" }}>
                      <strong>Voucher No </strong>
                    </font>
                  </td>
                  <td width="200">
                    {" "}
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.props.paymentVoucher.paymentVoucherCode}
                    </font>
                  </td>
                </tr>
                {/* <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr> */}
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Date</strong>
                    </font>
                  </td>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.props.paymentVoucher.paymentVoucherDate}
                    </font>
                  </td>
                </tr>
                {/*  <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr> */}

                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Amount</strong>
                    </font>
                  </td>
                  <td>
                    <h6 className="font-18 mb-5" style={{ color: "#FF6C04" }}>
                      <b>
                        AED{" "}
                        {this.props.paymentVoucher.paymentVoucherAmount.toFixed(
                          2
                        )}
                      </b>
                    </h6>
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td width="150">
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Paid to</strong>
                    </font>
                  </td>
                  <td width="10">: </td>
                  <td colspan="3">
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.GetVenderDetails(this.props.paymentVoucher)}
                    </font>
                  </td>
                </tr>
                 <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Bank Name</strong>
                    </font>
                  </td>
                  <td width="10">: </td>
                  <td colspan="3">
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.props.paymentVoucher.vendorBankName}
                    </font>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Amount in words</strong>
                    </font>
                  </td>
                  <td width="10">: </td>
                  <td colspan="3">
                    <font size="3" style={{ textAlign: "left" }}>
                      {toWords.convert(
                        this.props.paymentVoucher.paymentVoucherAmount,
                        { currency: true }
                      )}
                    </font>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Bank Code</strong>
                    </font>
                  </td>
                  <td width="10">: </td>
                  <td colspan="3">
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.props.paymentVoucher.vendorBankCode}
                    </font>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>
                    <font size="3" style={{ textAlign: "left" }}>
                      <strong>Remarks</strong>
                    </font>
                  </td>
                  <td width="10">: </td>
                  <td colspan="3">
                    <font size="3" style={{ textAlign: "left" }}>
                      {this.props.paymentVoucher.remark}
                    </font>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td colspan="5">
                    <div className="row">
                      <div className="col-4" style={{ textAlign: "left" }}>
                        <font size="3" style={{ textAlign: "left" }}>
                          <strong>
                            Prepared By&nbsp;&nbsp;_______________
                          </strong>
                        </font>
                      </div>
                      <div className="col-4" style={{ textAlign: "center" }}>
                        <font size="3" style={{ textAlign: "left" }}>
                          <strong>Checked By&nbsp;&nbsp;_______________</strong>
                        </font>
                      </div>
                      <div className="col-4" style={{ textAlign: "right" }}>
                        <font size="3" style={{ textAlign: "left" }}>
                          <strong>
                            Approved By&nbsp;&nbsp;_______________
                          </strong>
                        </font>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <font size="-2">
                      <p align="center">&nbsp;</p>
                    </font>{" "}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
                  </td>
                </tr>

                <tr>
                  <td colspan="5">
                    <font size="-2">
                      <p align="center">
                        {" "}
                        Office 418, City 4 Building, Al Garhoud, Dubai, United
                        Arab Emirates
                        <br />
                        +971 4 257 9192 | ops@holidaymakers.ae |
                        www.holidaymakers.ae
                      </p>
                    </font>
                  </td>
                </tr>
                
              </tbody>
            </table>
            

            <p>&nbsp;</p>
          </p>
          
        </div>
      </>
    );
  }
}

export default PaymentVoucherReportInner;
