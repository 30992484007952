import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Transportation from "../components/Transportation";
import JobCardHeader from "../components/JobCardHeader";
import Hotel from "../components/Hotel";
import Visa from "../components/Visa";
import Miscellaneous from "../components/Miscellaneous";
import Finances from "../components/Finances";
import PreLoader from "../components/shards/PreLoader";
class ViewJobCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
    };
  }
  componentDidMount = async () => {
    const roleId = localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });
  };
  setLoader=(val)=>{
    this.setState({
      isLoading:val
    });
  }
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="card-box mb-30">
                  <JobCardHeader setLoader={this.setLoader} id={this.props.id} />
                  <div className="pb-20">
                    <TabView>
                      <TabPanel header="Transportation ">
                        <Transportation setLoader={this.setLoader} id={this.props.id} />
                      </TabPanel>
                      <TabPanel header="Hotel">
                        <Hotel setLoader={this.setLoader} id={this.props.id} />
                      </TabPanel>
                      <TabPanel header="Visa">
                        <Visa setLoader={this.setLoader} id={this.props.id} />
                      </TabPanel>
                      <TabPanel header="Miscellaneous">
                        <Miscellaneous setLoader={this.setLoader} id={this.props.id} />
                      </TabPanel>
                      {this.state.roleId == 1 && (
                        <TabPanel header="Finances">
                          <Finances setLoader={this.setLoader} id={this.props.id} />
                        </TabPanel>
                      )}
                    </TabView>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isLoading && (<PreLoader/>)}
          </div>
        }
      </>
    );
  }
}

export default ViewJobCard;
