import React from "react";
import AddEditCustomer from "../components/AddEditCustomer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import dayjs, { Dayjs } from "dayjs";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import AddProformaReceipt from "../components/AddProformaReceipt";
import ViewProformaReceipt from "./ViewProformaReceipt";
import PreLoader from "../components/shards/PreLoader";
class ViewProformaInvoiceReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      invoice: null,
      receipt: null,
      receipts: [],
      isModalOpen: false,
      isModalOpenReceipt:false,
      isLoading:false
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getProformaInvoice();
    await this.getReceipts();
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getProformaInvoice = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetProformaInvoice?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        var invoiceItem = {
          id: response.invoice.id,
          invoiceCode: response.invoice.invoiceCode,
          customerName: response.invoice.customerName,
          addressLine1: response.invoice.customerAddressLine1,
          addressLine2: response.invoice.customerAddressLine2,
          invoiceAmount:
            response.invoice.invoiceAmount == null
              ? null
              : response.invoice.invoiceAmount.toFixed(2),
          statusName: response.invoice.statusName,
          description: response.invoice.description,
          statusId: response.invoice.statusId,
          invoiceDate:
            response.invoice.invoiceDate == null
              ? null
              : dayjs(response.invoice.invoiceDate).format("DD/MM/YYYY"),
          invoiceDueDate:
            response.invoice.invoiceDueDate == null
              ? null
              : dayjs(response.invoice.invoiceDueDate).format("DD/MM/YYYY"),
          jobCardNo: response.invoice.jobCardNo,
          jobCardId: response.invoice.jobCardId,
        };
        debugger;
        this.setState({
          isLoading: false,
        });
        this.setState({
          invoice: invoiceItem,
        });
      });
  };

  getReceipts = async () => {
    this.setState({
      isLoading: true,
    });
    const receiptList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetProformaInvoiceReceipts?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.receipts.map((i) =>
          receiptList.push({
            id: i.id,
            receiptCode: i.receiptCode,
            proformaInvoiceId: i.proformaInvoiceId,
            proformaInvoiceCode: i.proformaInvoiceCode,
            jobCardId: i.jobCardId,
            customerName:i.customerName,
            addressLine1:i.addressLine1,
            addressLine2:i.addressLine2,
            paymentMethodId: i.paymentMethodId,
            jobCardNo: i.jobCardNo,
            amount: i.amount == null ? null : i.amount.toFixed(2),
            paymentMethod: i.paymentMethod,
            remark: i.remark,
            receiptDate: i.paymentDate,
            paymentDate:
              i.paymentDate == null
                ? null
                : dayjs(i.paymentDate).format("DD/MM/YYYY"),
          })
        );
        debugger;
        this.setState({
          isLoading: false,
        });
        this.setState({
          receipts: receiptList,
        });
      });
  };

  viewItemTemplate = (item) => {
    return (
      <div>
        <Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpenReceipt: true,
              receipt: item,
            })
          }
          icon="pi pi-file"
          severity="secondary"
          tooltip="Receipt"
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.invoice && (
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          Proforma Invoice Details
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Number</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceCode}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Date</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceDate}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">
                                  Invoice Due Date
                                </label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceDueDate}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Customer Name</label>
                                <p className="col-6">
                                  {this.state.invoice.customerName}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="row">
                                <label className="col-4">Invoice Amount</label>
                                <p className="col-6">
                                  {this.state.invoice.invoiceAmount}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">Record Payment</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pd-20">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isModalOpen: true, receipt: null });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.receipts}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        this.setState({ isModalOpen: true, receipt: e.value });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="paymentDate"
                        sortable
                        header="Receipt Date"
                      ></Column>
                      <Column
                        field="receiptCode"
                        sortable
                        header="Receipt Number"
                      ></Column>
                      <Column
                        field="jobCardNo"
                        sortable
                        header="Job Card No"
                      ></Column>
                      <Column field="amount" sortable header="Amount"></Column>
                      <Column
                        field="paymentMethod"
                        sortable
                        header="Payment Method"
                      ></Column>
                      <Column field="remark" sortable header="Remark"></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={
                    this.state.customer == null ? "Add Receipt" : "Edit Receipt"
                  }
                  visible={this.state.isModalOpen}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <AddProformaReceipt
                    invoice={this.state.invoice}
                    receipt={this.state.receipt}
                  />
                </Dialog>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header="Receipt Preview"
                  visible={this.state.isModalOpenReceipt}
                  onHide={(e) => {
                    this.setState({ isModalOpenReceipt: false });
                  }}
                >
                  <ViewProformaReceipt receipt={this.state.receipt} />
                </Dialog>
              </div>
            </div>
          </div>
        )}
         {this.state.isLoading && (<PreLoader/>)}
      </>
    );
  }
}

export default ViewProformaInvoiceReceipt;
