import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs, { Dayjs } from "dayjs";
import { MultiSelect } from "primereact/multiselect";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import ViewProformaInvoice from "./ViewProformaInvoice";

class ViewProformaInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      isModalOpenInvoice: false,
      invoiceCode: "",
      invoiceDateFrom: null,
      invoiceDateTo: null,
      invoiceDueDateFrom: null,
      invoiceDueDateTo: null,
      invoice: null,
      customerId: [],
      statusId: [],
      vendors: [],
      status: [],
      paymentVoucherItem: null,
      invoiceItem: null,
      isPaymentModalOpen: false,
      jobCardNumber: "",
      isLoading: false,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    await this.getInvoices(true);
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getInvoices = async (isFirstLoad) => {
    this.setState({
      isLoading: true,
    });
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetProformaInvoices?` +
        new URLSearchParams({
          invoiceCode: this.state.invoiceCode,
          customerId: this.state.customerId.toString(),
          statusId: this.state.statusId.toString(),
          invoiceDateFrom: this.state.invoiceDateFrom
            ? new Date(this.state.invoiceDateFrom).toDateString()
            : "",
          invoiceDateTo: this.state.invoiceDateTo
            ? new Date(this.state.invoiceDateTo).toDateString()
            : "",
          invoiceDueDateFrom: this.state.invoiceDueDateFrom
            ? new Date(this.state.invoiceDueDateFrom).toDateString()
            : "",
          invoiceDueDateTo: this.state.invoiceDueDateTo
            ? new Date(this.state.invoiceDueDateTo).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
          isFirstLoad:isFirstLoad
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.invoices.map((i) =>
          batchList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            customerName: i.customerName,
            addressLine1: i.customerAddressLine1,
            addressLine2: i.customerAddressLine2,
            invoiceAmount:
              i.invoiceAmount == null ? null : i.invoiceAmount.toFixed(2),
            statusName: i.statusName,
            description: i.description,
            statusId: i.statusId,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            jobCardNo: i.jobCardNo,
          })
        );
        this.setState({
          isLoading: false,
        });
        if (
          !(
            this.state.invoiceCode ||
            this.state.customerId ||
            this.state.invoiceDateFrom ||
            this.state.invoiceDateTo ||
            this.state.invoiceDueDateFrom ||
            this.state.invoiceDueDateTo ||
            this.state.statusId
          )
        ) {
          this.setState({
            batches: batchList,
          });
        }
        this.setState({
          filterBatches: batchList,
        });
      });
  };

  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
          status: response.invoiceStatuse,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  resetSearchHandler = async (e) => {
    this.setState({
      invoiceCode: "",
      invoiceDateFrom: null,
      invoiceDateTo: null,
      invoiceDueDateFrom: null,
      invoiceDueDateTo: null,
      customerId: "",
      statusId: "",
      filterBatches: this.state.batches,
    });
  };
  actionBodyTemplate = (item) => {
    return (
      <Button
        onClick={(e) => this.confirm(item)}
        icon="pi pi-times"
        severity="danger"
      />
    );
  };
  openReceipt(id) {
    window.location.href = `${process.env.PUBLIC_URL}/proforma_invoice_receipt/${id}`;
  }
  viewItemTemplate = (item) => {
    return (
      <div>
        {item.statusId != 3 && (
          <>
            <Button
              style={{ width: "2rem", height: "2rem" }}
              className="m-1"
              onClick={(e) => {
                this.confirmVoid(item);
              }}
              icon="pi pi-times"
              severity="danger"
              tooltip="Void Invoice"
            />
            <Button
              style={{ width: "2rem", height: "2rem" }}
              className="m-1"
              onClick={(e) => {
                this.openReceipt(item.id);
              }}
              icon="pi pi-dollar"
              severity="secondary"
              tooltip="Record Payment"
            />
          </>
        )}
        <Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpenInvoice: true,
              invoice: item,
            })
          }
          icon="pi pi-file"
          severity="secondary"
          tooltip="Invoice"
        />
      </div>
    );
  };

  voidInvoice = async (e) => {
    this.setState({
      isLoading: true,
    });
    debugger;
    var payload = {
      Id: this.state.invoiceItem.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Logistics/VoidProformaInvoice`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.setState({
            isLoading: false,
          });
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.setState({
          isLoading: false,
        });
        this.setErrorMessage("Invoice Successfully Voided", 1);
        window.location.reload();
      });
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  confirmVoid = (item) => {
    debugger;
    this.setState({
      invoiceItem: item,
    });
    var accept = this.voidInvoice;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to void Invoice?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getInvoices(false);
    }
  };
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="page-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/receipt.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          View Proforma Invoices
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.invoiceCode}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceCode: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice To Date</label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Customer</label>
                              <MultiSelect
                                value={this.state.customerId}
                                onChange={(e) =>
                                  this.handleChange({
                                    customerId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.customers}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice Due From Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDueDateFrom}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDueDateFrom: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">
                                Invoice Due To Date
                              </label>
                              <Calendar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                value={this.state.invoiceDueDateTo}
                                onChange={(e) =>
                                  this.handleChange({
                                    invoiceDueDateTo: e.target.value,
                                  })
                                }
                                showIcon
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Invoice Status</label>
                              <MultiSelect
                                value={this.state.statusId}
                                onChange={(e) =>
                                  this.handleChange({
                                    statusId: e.target.value,
                                  })
                                }
                                display="chip"
                                options={this.state.status}
                                optionLabel="code"
                                filter
                                className="col-12"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="col-12">Job Card Number</label>
                              <InputText
                                style={{ width: "100%" }}
                                value={this.state.jobCardNumber}
                                onChange={(e) =>
                                  this.handleChange({
                                    jobCardNumber: e.target.value,
                                  })
                                }
                                onKeyUp={(e) => this.enterPressed(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <button
                                type="button"
                                style={{ margin: "10px" }}
                                className="btn btn-warning pull-right"
                                onClick={(e) => {
                                  this.resetSearchHandler(e);
                                }}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                style={{ margin: "10px" }}
                                className="btn btn-primary pull-right"
                                onClick={(e) => {
                                  this.getInvoices(false);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-box mb-30">
                  {this.state.filterBatches && this.state.filterBatches.length>0 &&( <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.filterBatches}
                      selectionMode="single"
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceCode"
                        sortable
                        header="Invoice Number"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="customerName"
                        sortable
                        header="Customer Name"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceDate"
                        sortable
                        header="Invoice Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceDueDate"
                        sortable
                        header="Invoice Due Date"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="invoiceAmount"
                        sortable
                        header="Invoice Amount"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="statusName"
                        sortable
                        header="Status"
                      ></Column>
                      <Column
                        style={{ padding: "0.5rem 1rem" }}
                        headerStyle={{ padding: "1rem 1rem" }}
                        field="action"
                        sortable
                        body={this.viewItemTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>)}
                </div>

                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  visible={this.state.isModalOpenInvoice}
                  onHide={(e) => {
                    this.setState({ isModalOpenInvoice: false });
                  }}
                >
                  <ViewProformaInvoice invoice={this.state.invoice} />
                </Dialog>
                <ConfirmDialog />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {this.state.isLoading && <PreLoader />}
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default ViewProformaInvoices;
