import React, { useState } from "react";
import "webdatarocks/webdatarocks.css";
import { Chart } from "primereact/chart";

class ChartPolarArea extends React.Component {
  render() {
    var documentStyle = getComputedStyle(document.documentElement);
    var textColor = documentStyle.getPropertyValue("--text-color");
    var surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    var data = {
      datasets: [
        {
          data: this.props.charData, //[11, 16, 7, 3],
          backgroundColor: [
            documentStyle.getPropertyValue("--red-500"),
            documentStyle.getPropertyValue("--green-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--bluegray-500"),
            documentStyle.getPropertyValue("--blue-500"),
          ],
        },
      ],
      labels: ["Transportation", "Hotel", "Visa", "Miscellaneous"],
    };
    var options = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    return (
      <>
        <Chart
          type="polarArea"
          data={data}
          options={options}
          style={{
            position: "relative",
            width: "50%",
            margin: "auto",
          }}
        />
      </>
    );
  }
}

export default ChartPolarArea;
