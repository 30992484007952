import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import dayjs, { Dayjs } from "dayjs";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import InvoiceReport from "../components/InvoiceReport";
import PNLReport from "../components/PNLReport";
import { MultiSelect } from "primereact/multiselect";
import PreLoader from "../components/shards/PreLoader";
class PNAReportWrap extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      batches: [],
      filterBatches: [],
      isModalOpen: false,
      isModalOpenCopy: false,
      isModalOpenInvoice: false,
      invoiceCode: "",
      dateFrom: null,
      dateTo: null,
      invoice: null,
      customerId: [],
      statusId: [],
      vendors: [],
      customers: [],
      invoiceLineItems: [],
      paymentVoucherItem: null,
      invoiceItem: null,
      isPaymentModalOpen: false,
      totalInvoiceAmount: 0,
      totalReceiptAmount: 0,
      totalPaymentVoucherAmount: 0,
      totalProfitLost: 0,
      jobCardNumber: "",
      isLoading: false,
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  aut;
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getReferenceData();
    //await this.getInvoices();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getReferenceData = async () => {
    this.setState({
      isLoading: true,
    });
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.setState({
          customers: response.customers,
        });
      });
  };

  getInvoices = async () => {
    this.setState({
      isLoading: true,
    });
    const batchList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Logistics/GetPnL?` +
        new URLSearchParams({
          customerId: this.state.customerId.toString(),
          dateFrom: this.state.dateFrom
            ? new Date(this.state.dateFrom).toDateString()
            : "",
          dateTo: this.state.dateTo
            ? new Date(this.state.dateTo).toDateString()
            : "",
          jobCardNumber: this.state.jobCardNumber,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        var totalInvoiceAmount = 0;
        var totalReceiptAmount = 0;
        var totalPaymentVoucherAmount = 0;
        var totalProfitLost = 0;

        response.pnL.map((i) => {
          totalInvoiceAmount = totalInvoiceAmount + i.invoiceAmount;
          totalReceiptAmount = totalReceiptAmount + i.receiptAmount;
          totalPaymentVoucherAmount =
            totalPaymentVoucherAmount + i.paymentVoucherAmount;
          totalProfitLost = totalProfitLost + i.profitLost;

          batchList.push({
            jobCardId: i.jobCardId,
            jobCardNo: i.jobCardNo,
            description: i.description,
            customerName: i.customerName,
            invoiceAmount: i.invoiceAmount.toFixed(2),
            receiptAmount: i.receiptAmount.toFixed(2),
            paymentVoucherAmount: i.paymentVoucherAmount.toFixed(2),
            profitLost: i.profitLost.toFixed(2),
          });
        });
        this.setState({
          isLoading: false,
        });
        this.setState({
          filterBatches: batchList,
          totalInvoiceAmount: totalInvoiceAmount.toFixed(2),
          totalReceiptAmount: totalReceiptAmount.toFixed(2),
          totalPaymentVoucherAmount: totalPaymentVoucherAmount.toFixed(2),
          totalProfitLost: totalProfitLost.toFixed(2),
          isModalOpen: true,
        });
      });
  };

  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }
  enterPressed = async (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      await this.getInvoices();
    }
  };

  render() {
    return (
      <div className="main-container">
        <div className="pd-ltr-20 xs-pd-20-10">
          <div className="min-height-200px">
            <div className="page-header">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="pd-20 text-center">
                    <h2 className="text-blue h2">
                      <img
                        width={"50px"}
                        src="/vendors/images/bar-chart.gif"
                        alt=""
                        style={{ verticalAlign: "middle" }}
                      />{" "}
                      Profit and Loss Report
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">From Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.dateFrom}
                            onChange={(e) =>
                              this.handleChange({
                                dateFrom: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">To Date</label>
                          <Calendar
                            dateFormat="dd/mm/yy"
                            style={{ width: "100%" }}
                            value={this.state.dateTo}
                            onChange={(e) =>
                              this.handleChange({
                                dateTo: e.target.value,
                              })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Customer</label>
                          <MultiSelect
                            value={this.state.customerId}
                            onChange={(e) =>
                              this.handleChange({
                                customerId: e.target.value,
                              })
                            }
                            display="chip"
                            options={this.state.customers}
                            optionLabel="code"
                            filter
                            className="col-12"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="col-12">Job Card Number</label>
                          <InputText
                            style={{ width: "100%" }}
                            value={this.state.jobCardNumber}
                            onChange={(e) =>
                              this.handleChange({
                                jobCardNumber: e.target.value,
                              })
                            }
                            onKeyUp={(e) => this.enterPressed(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-warning pull-right"
                            onClick={(e) => {
                              this.resetSearchHandler(e);
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary pull-right"
                            onClick={(e) => {
                              this.getInvoices(e);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Dialog
              maximizable
              style={{ width: "90vw" }}
              header="Profit and Loss Report"
              headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
              contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
              visible={this.state.isModalOpen}
              onHide={(e) => {
                this.setState({ isModalOpen: false });
              }}
            >
              <PNLReport
                dateFrom={this.state.dateFrom}
                dateTo={this.state.dateTo}
                reportItem={this.state.filterBatches}
                totalInvoiceAmount={this.state.totalInvoiceAmount}
                totalReceiptAmount={this.state.totalReceiptAmount}
                totalPaymentVoucherAmount={this.state.totalPaymentVoucherAmount}
                totalProfitLost={this.state.totalProfitLost}
              />
            </Dialog>
          </div>
        </div>
        {this.state.isLoading && <PreLoader />}
      </div>
    );
  }
}

export default PNAReportWrap;
