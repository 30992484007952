import React from "react";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import AddJobCard from "../components/AddJobCard";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import "react-toastify/dist/ReactToastify.css";
class JobCardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobCard: null,
      isModalOpen: false,
      errorMessage: "",
      isError: false,
      roleId: "",
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    const roleId = localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });
    await this.getJobs();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getJobs = async () => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.props.setLoader(false);
        this.setState({
          jobCard: response.jobCard,
        });
      });
  };

  closeJobCard = async (e) => {
    this.props.setLoader(true);
    var payload = {
      Id: this.props.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Job/CloseJobCard`, payload, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setErrorMessage("Job Card Closed Successfully", 1);
        window.location.reload();
      });
  };

  openJobCard = async (e) => {
    this.props.setLoader(true);
    var payload = {
      Id: this.props.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Job/OpenJobCard`, payload, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setErrorMessage("Job Card Open Successfully", 1);
        window.location.reload();
      });
  };


  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  removeJobCardHandler = async (e) => {
    this.props.setLoader(true);
    var payload = {
      Id: this.props.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Job/RemoveJobCard`, payload, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${currentUser}`,
        },
      })
      .then(async (response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            errorMessage: response.data.successMessage,
          });
        }
        this.props.setLoader(false);
        this.setErrorMessage("Job Card Successfully Deleted", 1);
        await this.delay(5000);

        window.location.href = `${process.env.PUBLIC_URL}/view_job_cards`;
      });
  };
  acceptDelete = () => {
    this.removeJobCardHandler();
  };
  accept = () => {
    this.closeJobCard();
    //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };
  acceptOpen = () => {
    this.openJobCard();
    //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };
  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
    //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };
  confirm = () => {
    var accept = this.accept;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  confirmOpen=()=>{
    var accept = this.acceptOpen;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  confirmDelete = () => {
    var accept = this.acceptDelete;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  openFullReport = () => {
    window.open(
      `${process.env.PUBLIC_URL}/print_full_jobcard/${this.props.id}`,
      "_blank"
    );
  };
  openAgentReport = () => {
    window.open(
      `${process.env.PUBLIC_URL}/print_agent_jobcard/${this.props.id}`,
      "_blank"
    );
  };

  headerTemplate = (options) => {
    const style = { fontSize: "1.25rem" };

    return (
      <div
        class="p-panel-header"
        style={{
          backgroundColor: "#fd6511",
          color: "#FFF",
          fontSize: "1.5rem",
        }}
      >
        <span class="p-panel-title" id="pr_id_69_header">
          {this.state.jobCard.headerTitle}
        </span>
      </div>
    );
  };

  render() {
    return (
      this.state.jobCard && (
        <div>
          <Panel headerTemplate={this.headerTemplate} className="jobpanel">
            <div
              className="form-group row"
              style={{ backgroundColor: "#f6f6f7" }}
            >
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Customer
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {this.state.jobCard.customerName}
                </label>
              </div>
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Customer Ref
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {this.state.jobCard.customerRef}
                </label>
              </div>
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Remarks
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {this.state.jobCard.remarks}
                </label>
              </div>
            </div>
            <div
              className="form-group row"
              style={{ backgroundColor: "#f6f6f7" }}
            >
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Effective Date
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {dayjs(this.state.jobCard.effectiveDate).format("DD/MM/YYYY")}
                </label>
              </div>
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Creation Time
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {dayjs(this.state.jobCard.createdDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </label>
              </div>
              <div className="col-4" style={{ paddingLeft: "0" }}>
                <label
                  className="col-5 col-form-label"
                  style={{ backgroundColor: "#dee2e4" }}
                >
                  Created By
                </label>
                <label
                  className="col-6 col-form-label"
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {this.state.jobCard.createdBy}
                </label>
              </div>
            </div>
            <div className="form-group row">
              {this.state.jobCard.status == "Open" && (
                <div
                  className="col-4"
                  style={{ backgroundColor: "#328d32", paddingLeft: "0" }}
                >
                  <label
                    className="col-5 col-form-label"
                    style={{ backgroundColor: "#dee2e4" }}
                  >
                    Status
                  </label>
                  <label
                    className="col-6 col-form-label"
                    style={{
                      color: "#fff",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    {this.state.jobCard.status}
                  </label>
                </div>
              )}
              {this.state.jobCard.status == "Closed" && (
                <div
                  className="col-4"
                  style={{ backgroundColor: "#cb1b1b", paddingLeft: "0" }}
                >
                  <label
                    className="col-5 col-form-label"
                    style={{ backgroundColor: "#dee2e4" }}
                  >
                    Status
                  </label>
                  <label
                    className="col-6 col-form-label"
                    style={{
                      color: "#fff",
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    {this.state.jobCard.status}
                  </label>
                </div>
              )}
            </div>

            <div className="form-group row">
              <div className="col-6 gap-3">
                <ConfirmDialog />
                {this.state.jobCard.status == "Closed" && (
                  <>
                    {this.state.roleId == 1 && (
                      <Button
                        icon="pi pi-check-circle"
                        style={{ margin: "5px" }}
                        tooltip="Open Job Card"
                        onClick={(e) => {
                          this.confirmOpen();
                        }}
                        tooltipOptions={{ position: "top" }}
                      />
                    )}
                  </>
                )}
                {this.state.jobCard.status == "Open" && (
                  <>
                    {this.state.roleId == 1 && (
                      <Button
                        icon="pi pi-check-circle"
                        style={{ margin: "5px" }}
                        tooltip="Close Job Card"
                        onClick={(e) => {
                          this.confirm();
                        }}
                        tooltipOptions={{ position: "top" }}
                      />
                    )}
                    <Button
                      icon="pi pi-pencil"
                      style={{ margin: "5px" }}
                      severity="warning"
                      tooltip="Edit Job Card"
                      onClick={(e) => {
                        this.setState({ isModalOpen: true });
                      }}
                      tooltipOptions={{ position: "top" }}
                    />
                    {this.state.roleId == 1 && (
                      <Button
                        icon="pi pi-trash"
                        style={{ margin: "5px" }}
                        severity="danger"
                        tooltip="Delete Job Card"
                        tooltipOptions={{ position: "top" }}
                        onClick={(e) => {
                          this.confirmDelete(e);
                        }}
                      />
                    )}
                  </>
                )}
                {this.state.roleId == 1 && (
                  <Button
                    icon="pi pi-print"
                    style={{ margin: "5px" }}
                    severity="info"
                    tooltip="Print Job Card"
                    onClick={(e) => {
                      this.openFullReport();
                    }}
                    tooltipOptions={{ position: "top" }}
                  />
                )}

                <Button
                  icon="pi pi-file"
                  style={{ margin: "5px" }}
                  severity="info"
                  tooltip="Agent Copy"
                  onClick={(e) => {
                    this.openAgentReport();
                  }}
                  tooltipOptions={{ position: "top" }}
                />
              </div>
            </div>
          </Panel>
          <Dialog
            maximizable
            headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
            contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
            header="Edit Job"
            style={{ width: "50vw" }}
            visible={this.state.isModalOpen}
            onHide={(e) => {
              this.setState({ isModalOpen: false });
            }}
          >
            <AddJobCard setLoader={this.props.setLoader} job={this.state.jobCard} />
          </Dialog>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )
    );
  }
}
export default JobCardHeader;
