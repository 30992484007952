import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ToastContainer } from "react-toastify";
class JobCardAgentReportInner extends React.Component {
  render() {
    return (
      <>
        <div id="report" style={{ overflow: "inherit" }}>
          <img
            src="../vendors/images/holiday_makers_logo.jpg"
            width="160"
            alt=""
          />
          <br />
          {this.props.jobCard && (
            <>
            <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Job Card&nbsp;:&nbsp;
                <strong>{this.props.jobCard.headerTitle}</strong>
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Customer&nbsp;:&nbsp;
                <strong>{this.props.jobCard.customerName}</strong>
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Customer Ref&nbsp;:&nbsp;
                {this.props.jobCard.customerRef}
              </p>
              <p
                style={{
                  fontFamily:
                    "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  fontSize: "14.5px",
                  margin: "0",
                  paddingTop: "15px",
                }}
              >
                Remarks&nbsp;:&nbsp;
                {this.props.jobCard.remarks}
              </p>
              {
                <p
                  style={{
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                    fontSize: "14.5px",
                    margin: "0",
                    paddingTop: "15px",
                  }}
                >
                  Creation Date&nbsp;:&nbsp;
                  {dayjs(this.props.jobCard.createdDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </p>
              }
            </>
          )}
          <br />
          <br />
          <h5>Tranportation</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Pickup Time</th>
                  <th>Pickup Location</th>
                  <th>Drop-off Location</th>
                  <th>Total PAX</th>
                  <th>A</th>
                  <th>C</th>
                  <th>I</th>
                  <th>Vehicle Type</th>
                  <th>Flight No</th>
                  <th>Flight Time</th>
                  <th>Pax Name</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {this.props.transports.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.transportationCode}
                      </td>
                      <td>{i.pickupTime}</td>
                      <td>{i.pickupLocation}</td>
                      <td>{i.dropoffLocation}</td>
                      <td>{i.totalPax}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.vehicleType}</td>
                      <td>{i.flightNo}</td>
                      <td>{i.flightTime}</td>
                      <td>{i.paxName}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.transportationCode}
                      </td>
                      <td>{i.pickupTime}</td>
                      <td>{i.pickupLocation}</td>
                      <td>{i.dropoffLocation}</td>
                      <td>{i.totalPax}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.vehicleType}</td>
                      <td>{i.flightNo}</td>
                      <td>{i.flightTime}</td>
                      <td>{i.paxName}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <h5>Hotels</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Infants</th>
                  <th>Hotel Name</th>
                  <th>Check-in</th>
                  <th>Check-out</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {this.props.hotels.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{i.hotelCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.hotelName}</td>
                      <td>{i.checkIn}</td>
                      <td>{i.checkOut}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>{i.hotelCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.adults}</td>
                      <td>{i.children}</td>
                      <td>{i.infants}</td>
                      <td>{i.hotelName}</td>
                      <td>{i.checkIn}</td>
                      <td>{i.checkOut}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <h5>Visa</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Passport Number</th>
                  <th>Visa Type</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {this.props.visas.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{i.visaCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.passportNo}</td>
                      <td>{i.visaTypeName}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>{i.visaCode}</td>
                      <td>{i.paxName}</td>
                      <td>{i.passportNo}</td>
                      <td>{i.visaTypeName}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <h5>Miscellaneous</h5>
          <div className="pb-20">
            <table
              class="table table-striped table-fixed"
              style={{ overflow: "inherit" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "10px !important;",
                    background: "#f6f6f7 !important",
                  }}
                >
                  <th style={{ whiteSpace: "nowrap" }}>Booking Ref</th>
                  <th>Passenger Name</th>
                  <th>Description</th>
                  <th>Date &amp; Time</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {this.props.miscellanea.map((i, index) =>
                  !(index % 2) ? (
                    <tr
                      className="tableOddrow"
                      style={{
                        fontSize: "10px !important;",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.miscellaneousCode}
                      </td>
                      <td>{i.paxName}</td>
                      <td>{i.description}</td>
                      <td>{i.misDate}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  ) : (
                    <tr style={{ fontSize: "10px !important;" }}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {i.miscellaneousCode}
                      </td>
                      <td>{i.paxName}</td>
                      <td>{i.description}</td>
                      <td>{i.misDate}</td>
                      <td>{i.remarks}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <div classname="divClass" class="divFooter">
            Printed from <strong>Logistics Pro</strong>
          </div>
        </div>
      </>
    );
  }
}

export default JobCardAgentReportInner;
