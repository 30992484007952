import React, { useState } from "react";
import 'webdatarocks/webdatarocks.css'
import { Chart } from "primereact/chart";

class Chartline extends React.Component {
  
  render() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Generated",
          data: this.props.c1Generated, //[65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: documentStyle.getPropertyValue("#495057"),
          tension: 0.4,
        },
        {
          label: "Paid",
          data: this.props.c1Paid, //[28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: documentStyle.getPropertyValue("#fd6511"),
          tension: 0.4,
        },
        {
          label: "Un-Paid",
          data: this.props.c1UnPaid, //[20, 38, 50, 79, 46, 57, 30],
          fill: false,
          borderColor: documentStyle.getPropertyValue("#6c757d"),
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    return (
      <>
       <Chart
          type="line"
          data={data}
          options={options}
        />
      </>
    );
  }
}

export default Chartline;
