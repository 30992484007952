import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import dayjs, { Dayjs } from "dayjs";
import PreLoader from "../components/shards/PreLoader";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import AddEditEvent from "../components/AddEditEvent";

class ViewEvents extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      event: null,
      events: [],
      isModalOpen: false,
      isLoading: false,
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    /* const user = localStorage.getItem("username");
    if (!user) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    } */
    await this.getEvents();
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getEvents = async () => {
    this.setState({
      isLoading: true,
    });
    const eventList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetEvents`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.events.map((i) =>
          eventList.push({
            id: i.id,
            eventName: i.eventName,
            eventFromDate: i.eventFromDate,
            eventFromDateStr:
              i.eventFromDate == null
                ? null
                : dayjs(i.eventFromDate).format("DD/MM/YYYY"),
            eventToDate: i.eventToDate,
            eventToDateStr:
              i.eventToDate == null
                ? null
                : dayjs(i.eventToDate).format("DD/MM/YYYY"),
            eventType: i.eventType,
            eventTypeId: i.eventTypeId,
            remark: i.remark,
            customerName: i.customerName,
            customerId: i.customerid,
          })
        );
        this.setState({
          isLoading: false,
          events: eventList,
        });
      });
  };

  modalClose = () => {
    this.setState({ isModalOpen: false, event: null });
    this.getEvents();
  };

  setLoader = (val) => {
    this.setState({
      isLoading: val,
    });
  };
  render() {
    return (
      <>
        {
          <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
              <div className="min-height-200px">
                <div className="card-box mb-30">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="pd-20 text-center">
                        <h2 className="text-blue h2">
                          <img
                            width={"50px"}
                            src="/vendors/images/calendar.gif"
                            alt=""
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          View Events
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="pd-20">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isModalOpen: true, event: null });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                  <div className="pb-20">
                    <DataTable
                      stripedRows
                      showGridlines
                      value={this.state.events}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        this.setState({ isModalOpen: true, event: e.value });
                      }}
                      dataKey="id"
                      metaKeySelection={false}
                      sortMode="multiple"
                      paginator
                      rows={20}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="eventName"
                        sortable
                        header="Event Name"
                      ></Column>
                      <Column
                        field="eventFromDateStr"
                        sortable
                        header="Event From Date"
                      ></Column>
                      <Column
                        field="eventToDateStr"
                        sortable
                        header="Event To Date"
                      ></Column>
                      <Column
                        field="eventType"
                        sortable
                        header="Event Type"
                      ></Column>
                      <Column
                        field="customerName"
                        sortable
                        header="Customer Name"
                      ></Column>
                      <Column field="remark" sortable header="Remark"></Column>
                    </DataTable>
                  </div>
                </div>
                <Dialog
                  maximizable
                  headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
                  contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
                  header={this.state.event == null ? "Add Event" : "Edit Event"}
                  visible={this.state.isModalOpen}
                  style={{ width: "50vw" }}
                  onHide={(e) => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <AddEditEvent
                    setLoader={this.setLoader}
                    event={this.state.event}
                    closeEvent={this.modalClose}
                  />
                </Dialog>
              </div>
            </div>
          </div>
        }
        {this.state.isLoading && <PreLoader />}
      </>
    );
  }
}

export default ViewEvents;
